import React from "react";

const Conditions = () => {
    return (
        <div className="terms_view_container">
            <iframe
                src="https://app.termly.io/document/terms-of-use-for-saas/6d1a4b60-60e1-4d13-9458-c7acf5c4e7d5"
                frameBorder="0"
                style={{ position: "absolute", right: 0, left: 0, bottom: 0, top: 0, width: "100%", height: "100%" }}
            ></iframe>
        </div>
    );
};

export default Conditions;
