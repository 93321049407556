import React, { useEffect } from "react";
import { PlusOutlined, FileOutlined, YoutubeOutlined, EditOutlined, BlockOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { rules_atom, sensible_rule_default, rule_atom, rule_assets_atom, rule_logs_atom } from "../state/rule";
import { user_atom } from "../state/auth";
import { db } from "../firebase";
import { getDocs, query, collection, where, setDoc, doc, deleteDoc } from "firebase/firestore";
import { map } from "ramda";
import { capitalize } from "lodash";
import { mod, matching, not, get, all } from "shades";
import { from } from "rxjs";

const RulesHeader = () => {
    let navigate = useNavigate();
    const [rule, setRule] = useAtom(rule_atom);

    const onCreateNewRule = () => {
        let rule = sensible_rule_default();
        let { id: rule_id } = rule;
        setRule(rule);
        navigate(`/rule/${rule_id}`);
    };

    return (
        <div className="rules_header">
            <div className="header">
                <div className="title">Rules</div>
                <div className="actions">
                    <div className="new_rule_button bg-blue-600">
                        <div className="icon">
                            <PlusOutlined />
                        </div>
                        <div className="text" onClick={onCreateNewRule}>
                            New Rule
                        </div>
                    </div>
                </div>
            </div>

            <div className="resources_container">
                <div className="resource_container">
                    <div className="icon">
                        <FileOutlined />
                    </div>
                    <div className="text">How to get started</div>
                </div>

                <div className="resource_container">
                    <div className="icon">
                        <YoutubeOutlined />
                    </div>
                    <div className="text">How to get started</div>
                </div>
            </div>

            <div className="line_break"></div>
        </div>
    );
};

const Rule = ({ data }) => {
    const [rules, setRules] = useAtom(rules_atom);

    const onSetRuleStatus = () => {
        let status = data.status == "active" ? "paused" : "active";
        from(setDoc(doc(db, "rules", data.id), { status }, { merge: true })).subscribe(() => {
            setRules(mod(matching({ id: data.id }))((value) => ({ ...value, status }))(rules));
        });
    };

    const onDeleteRule = () => {
        let rule_id = data.id;
        from(deleteDoc(doc(db, "rules", rule_id)))(() => {
            setRules(get(matching(not({ id: rule_id })))(rules));
        });
    };

    return (
        <div className="rule_container">
            <div className="switch_status">
                <button
                    type="button"
                    className={`${
                        data.status == "active" ? "bg-blue-600" : "bg-gray-200"
                    }  relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    role="switch"
                    aria-checked="false"
                    onClick={onSetRuleStatus}
                >
                    <span className="sr-only">Use setting</span>

                    <span
                        aria-hidden="true"
                        className={`${
                            data.status == "active" ? "translate-x-5" : "translate-x-0"
                        } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                    ></span>
                </button>
            </div>
            <div className="rule_description">
                <div className="name rule_description_item">
                    <div className="title">Name:</div>
                    <div className="value">{capitalize(data.name || "No Name")}</div>
                </div>
                <div className="action rule_description_item">
                    <div className="title">Action:</div>
                    <div className="value">{capitalize(data.action.text)}</div>
                </div>
                <div className="scope rule_description_item">
                    <div className="title">Scope:</div>
                    <div className="value">{capitalize(data.scope)}</div>
                </div>
                <div className="frequency rule_description_item">
                    <div className="title">Frequency:</div>
                    <div className="value">{`${data.schedule.amount} ${data.schedule.unit}`}</div>
                </div>
            </div>
            <div className="actions">
                <Link className="action_item edit" to={`/rule/${data.id}`}>
                    <EditOutlined />
                </Link>
                <div className="action_item duplicate">
                    <BlockOutlined />
                </div>
                <div className="action_item delete" onClick={onDeleteRule}>
                    <DeleteOutlined />
                </div>
                {/* <Link to={`/rule/${data.id}/logs`} className="action_item logs">
                    Logs
                </Link> */}
            </div>
        </div>
    );
};

const Rules = () => {
    const [user] = useAtom(user_atom);
    const [rules, setRules] = useAtom(rules_atom);
    const [, setRule] = useAtom(rule_atom);
    const [, setRuleAssets] = useAtom(rule_assets_atom);
    const [, setLogs] = useAtom(rule_logs_atom);

    useEffect(() => {
        setRuleAssets({});
        setLogs([]);
        setRule(sensible_rule_default());
    }, []);

    useEffect(() => {
        let rules_query = query(collection(db, "rules"), where("user_id", "==", user.uid));

        getDocs(rules_query).then((response) => {
            let rules_response = response.docs.map((doc) => ({ ...doc.data(), path: doc.id }));
            setRules(rules_response);
        });
    }, []);
    return (
        <div className="rules_view">
            <RulesHeader />

            <div className="rules_modifier">
                <div className="search_container">
                    <div className="input_container">
                        <div className="icon">
                            <SearchOutlined />
                        </div>
                        <div className="value">
                            <input type="text" placeholder="Search" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="rules_container">
                {map(
                    (rule) => (
                        <Rule key={rule.id} data={rule} />
                    ),
                    rules
                )}
            </div>
        </div>
    );
};

export default Rules;
