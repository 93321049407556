import React, { useEffect } from "react";
import Highlight from "react-highlight.js";
import { useAtom } from "jotai";
import { user_atom } from "../state/auth";
import { CopyOutlined, CloseCircleOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";

let code = (user_id) => `
    <script>
        (function roas(params) {
            var user_id  = params.user_id;
            localStorage.setItem("roas_user_id", user_id);
            fetch("https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js").then(function(res){return res.json()}).then(function(response){
                var downloadTokens  = response.downloadTokens;
                var head = document.head;
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js?alt=media&token=" + downloadTokens
                head.appendChild(script);
            })
        })({ user_id: "${user_id}" });
    </script>
`;

const TrackingCode = () => {
    const [user] = useAtom(user_atom);

    const onCopySnippetToClipboard = () => {
        console.log("onCopySnippetToClipboard");
        copy(code(user.uid));
    };

    return (
        <div className="tracking_code_view">
            <div className="content">
                <div className="description_container">
                    <div className="js_logo">
                        <img src="https://seeklogo.com/images/J/javascript-logo-8892AEFCAC-seeklogo.com.png" width={30} />
                    </div>

                    <div className="content">
                        <div className="heading">
                            Install the ROAS snippet on your website - <span>required</span>
                        </div>

                        <div className="description">{`Copy the Segment snippet and paste it high in the <head> of your website.`}</div>
                    </div>
                </div>

                <div className="code_snippet_container">
                    <Highlight language={"javascript"}>{code(user.uid)}</Highlight>
                </div>
                <div className="copy_to_clipboard_container">
                    <div className="button" onClick={onCopySnippetToClipboard}>
                        <div className="icon">
                            <CopyOutlined />
                        </div>
                        <div className="value">Copy Snippet</div>
                    </div>
                </div>

                <div className="status_container">
                    <div className="icon">
                        <CloseCircleOutlined />
                    </div>
                    <div>No Data Flowing</div>
                    <div>Make sure your tracking code is installed correctly.</div>
                    <div className="check_status_button">Check Status</div>
                </div>
            </div>
        </div>
    );
};

export default TrackingCode;
