import { atom } from "jotai";
import {
    pipe,
    defaultTo,
    map,
    prop,
    lensProp,
    view as raview,
    curry,
    applySpec,
    sum,
    pluck,
    filter,
    flip,
    values,
    flatten,
    reverse,
    path,
    intersection,
    equals,
    gte,
    lte,
    lt,
    gt,
    reject,
    flip,
    includes,
    identity,
    ifElse,
    and,
} from "ramda";
import {
    reduce as lodashreduce,
    isEmpty,
    uniqBy as lodashUniqBy,
    size,
    sortBy as lodashSortBy,
    isUndefined,
    toNumber,
    flattenDeep,
    groupBy as lodashGroupBy,
    isFinite,
    isUndefined,
    toString,
} from "lodash";
const loreduce = curry((reducerFn, data) => lodashreduce(data, reducerFn));
import { format } from "date-fns";
import { get as view, all, mod, matching, not } from "shades";
import { rule_atom } from "./rule.js";
import moment from "moment";
import { pipeLog, lomap } from "../../utilities/helpers";

const loUniqBy = flip(lodashUniqBy);
const loSortBy = flip(lodashSortBy);
const loGroupBy = flip(lodashGroupBy);

export const tabs = { campaigns: "Campaigns", adsets: "Ad Sets", ads: "Ads" };

export const ads_manager_state_atom = atom({
    date_range: { since: moment().format("YYYY-MM-DD"), until: moment().format("YYYY-MM-DD") },
    selected_tab: "campaigns",
    selected_column: "",
    sort_column_id: "name",
    sort_order: "ascend",
    filters_dropdown_is_open: false,
    selected_action: "",
    attribution_window: 7,
    attribution_click_setting: "last",
    orders_modal_is_open: false,
    view: "active",
    is_date_picker_open: false,
});

export const ads_manager_atom = atom({
    data: {},
    campaigns: [],
    adsets: [],
    ads: [],
    selected_campaigns: [],
    selected_adsets: [],
    selected_ads: [],
});

export const draft_ads_manager_atom = atom({
    assets: {},
    campaings: [],
    adsets: [],
    ads: [],
});

let expression_predicate_map = {
    equals,
    gte,
    lte,
    lt,
    gt,
    includes: (metric, value) => metric.includes(value),
};

export const AdsManagerItems = {
    visible: {
        campaigns: (items) => {
            return pipe(identity)(items);
        },

        adsets: (selected_campaigns = [], items) => {
            return pipe(
                view(matching({ campaign_id: (campaign_id) => selected_campaigns.includes(campaign_id) })),
                ifElse(pipe(isEmpty, and(isEmpty(selected_campaigns))), () => pipe(identity)(items), identity)
            )(items);
        },

        ads: (selected_campaigns = [], selected_adsets = [], items) => {
            return pipe(
                view(matching({ campaign_id: (campaign_id) => selected_campaigns.includes(campaign_id) })),
                ifElse(pipe(isEmpty, and(isEmpty(selected_campaigns))), () => pipe(identity)(items), identity),
                view(matching({ adset_id: (adset_id) => selected_adsets.includes(adset_id) })),
                ifElse(pipe(isEmpty, and(isEmpty(selected_adsets))), () => pipe(identity)(items), identity)
            )(items);
        },

        get: (selected_campaigns = [], selected_adsets = [], selected_ads = [], type, items) => {
            if (type == "campaigns") return AdsManagerItems.visible.campaigns(items);
            if (type == "adsets") return AdsManagerItems.visible.adsets(selected_campaigns, items);
            if (type == "ads") return AdsManagerItems.visible.ads(selected_campaigns, selected_adsets, items);
        },
    },
};

export const selected_data_atom = atom((get) => {
    let adsmanager = get(ads_manager_atom);
    let { date_range, selected_tab } = get(ads_manager_state_atom);
    let { campaigns, adsets, ads, selected_campaigns, selected_adsets, selected_ads } = get(ads_manager_atom);
    let rule = get(rule_atom);

    // return prop(selected_tab, adsmanager);

    let visible_items = AdsManagerItems.visible.get(selected_campaigns, selected_adsets, selected_ads, selected_tab, prop(selected_tab, adsmanager));

    // let visible_items = visible();

    let expressions = pipe(view("conditions", all, "expressions"))(rule);
    let num_of_filters = pipe(map(values), values, flatten, size)(expressions);

    let typed_value = (metric_type, value) => {
        if (metric_type == "string") return toString(value);
        if (metric_type == "id") return toString(value);
        return toNumber(value);
    };

    const filter_data = (data) => {
        if (num_of_filters > 0) {
            return pipe(
                map((expressions) => {
                    let match = pipe(
                        map((expression) => {
                            let metric = path(["metric", "value"])(expression);
                            let metric_type = path(["metric", "metric_type"])(expression);
                            let predicate = path(["predicate", "value"])(expression);
                            let value = path(["value"])(expression);
                            let value_typed = typed_value(metric_type, value);

                            let item_metric = (item) => {
                                if (!metric_type) {
                                    return pipe(path(["stats", metric]))(item);
                                } else {
                                    return item[metric];
                                }
                            };

                            if (isUndefined(metric) || isUndefined(predicate) || isUndefined(value)) return [];

                            return pipe(
                                filter((item) => {
                                    return expression_predicate_map[predicate](item_metric(item), value_typed);
                                }),
                                flatten
                            )(data);
                        }),
                        (expressions) => loSortBy((value) => size(value), expressions),
                        reverse
                    )(expressions);

                    return match;
                }),
                map((assets) => {
                    return loreduce((prev, curr) => {
                        let intersection_ids = intersection(map(path(["asset_id"]), prev), map(path(["asset_id"]), curr));

                        return pipe(
                            map((id) => view(matching({ ["asset_id"]: id }))(prev)),
                            flatten
                        )(intersection_ids);
                    }, assets);
                }),
                values,
                flatten,
                loUniqBy("asset_id")
            )(expressions);
        } else {
            return data;
        }
    };

    return pipe(filter_data)(visible_items);
});

export const table_settings_atom = atom(false);

export const orders_modal_atom = atom({ is_open: false, context: null, id: null });

let ads_manager_columns = [
    {
        name: "",
        id: "checkbox",
        selected: true,
    },
    {
        name: "",
        id: "actions",
        selected: true,
    },
    {
        name: "Name",
        id: "name",
        selected: true,
    },
    {
        name: "ID",
        id: "id",
        selected: false,
    },
    {
        name: "Cost",
        id: "spend",
        selected: true,
    },
    {
        name: "Fb Revenue",
        id: "fbmade",
        selected: true,
    },
    {
        name: "Revenue",
        id: "roasmade",
        selected: true,
    },
    {
        name: "Fb ROAS",
        id: "fbroas",
        selected: true,
    },
    {
        name: "ROAS",
        id: "roas",
        selected: true,
    },
    {
        name: "Fb Customers",
        id: "fbcustomers",
        selected: false,
    },
    {
        name: "Unique Customers",
        id: "roascustomers",
        selected: true,
    },
    {
        name: "Fb Cost Per Customer",
        id: "fb_cost_per_customer",
        selected: false,
    },
    {
        name: "Cost Per Unique Customer",
        id: "roas_cost_per_customer",
        selected: true,
    },
    {
        name: "Fb Leads",
        id: "fbleads",
        selected: false,
    },
    {
        name: "Leads",
        id: "roasleads",
        selected: false,
    },
    {
        name: "Clicks",
        id: "clicks",
        selected: true,
    },
    {
        name: "Fb Sales",
        id: "fbsales",
        selected: false,
    },
    {
        name: "Sales",
        id: "roassales",
        selected: false,
    },
    {
        name: "Fb Cost Per Lead",
        id: "fb_cost_per_lead",
        selected: false,
    },
    {
        name: "Cost Per Lead",
        id: "roas_cost_per_lead",
        selected: false,
    },
    {
        name: "Fb Cost Per Sale",
        id: "fb_cost_per_sale",
        selected: false,
    },
    {
        name: "Cost Per Sale",
        id: "roas_cost_per_sale",
        selected: false,
    },
    {
        name: "Fb Average Order Value",
        id: "fb_average_order_value",
        selected: false,
    },
    {
        name: "Average Order Value",
        id: "roas_average_order_value",
        selected: false,
    },
    {
        name: "Fb Margin",
        id: "fb_margin",
        selected: false,
    },
    {
        name: "Margin",
        id: "roas_margin",
        selected: false,
    },
    {
        name: "Fb Sales Per Customer",
        id: "fb_average_sales_per_customer",
        selected: false,
    },
    {
        name: "Sales Per Customer",
        id: "roas_average_sales_per_customer",
        selected: false,
    },
];

ads_manager_columns = pipe(lomap((column, idx) => ({ ...column, order: idx })))(ads_manager_columns);

export const all_ads_manager_columns = atom(ads_manager_columns);

export const draft_ads_manager_columns = atom([
    {
        name: "",
        id: "checkbox",
        selected: true,
        order: 0,
    },
    {
        name: "",
        id: "actions",
        selected: true,
        order: 1,
    },
    {
        name: "Name",
        id: "name",
        selected: true,
        order: 2,
    },
    {
        name: "ID",
        id: "id",
        selected: true,
        order: 3,
    },
    {
        name: "Buying Type",
        id: "buying_type",
        selected: true,
        order: 4,
    },
    {
        name: "Objective",
        id: "objective",
        selected: true,
        order: 5,
    },
    {
        name: "CBO",
        id: "is_cbo",
        selected: true,
        order: 6,
    },
    {
        name: "Status",
        id: "effective_status",
        selected: true,
        order: 7,
    },
]);

export const selected_ads_manager_columns = atom([]);
export const account_assets_atom = atom({});
