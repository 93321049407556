import React from "react";
import { flip, lensPath, set } from "ramda";
import { map as lodashmap } from "lodash";
import { useAtom } from "jotai";
import { rule_atom } from "../state/rule";
import { expressions_state, predicates_atom, string_predicates_atom, id_predicates_atom } from "../state/expression";

const lomap = flip(lodashmap);

const predicate_map = {
    "< Less than": "lt",
    "> Greater than": "gt",
    "≤  Less than or equal": "lte",
    "≥  Greater than or equal": "gte",
    "= Equals": "equals",
    "⊆ Includes": "includes",
};

const DefaultPredicates = ({ expression }) => {
    const [rule, setRule] = useAtom(rule_atom);
    const [predicatesDropdown] = useAtom(predicates_atom);
    const [expressions, setExpressions] = useAtom(expressions_state);

    const onSelectPredicate = (predicate) => {
        setRule(set(lensPath([...expression.path, "predicate"]), { text: predicate.substring(2), value: predicate_map[predicate] }, rule));
        setExpressions({ ...expressions, dropdown_is_open: false, expression_id: null, dropdown_type: null });
    };

    return (
        <div className="predicate_container">
            {lomap(
                (value, key) => (
                    <div className="metric_field" key={key} onClick={() => onSelectPredicate(value)}>
                        {value}
                    </div>
                ),
                predicatesDropdown
            )}
        </div>
    );
};

const StringPredicates = ({ expression }) => {
    const [rule, setRule] = useAtom(rule_atom);
    const [predicatesDropdown] = useAtom(string_predicates_atom);
    const [expressions, setExpressions] = useAtom(expressions_state);

    const onSelectPredicate = (predicate) => {
        setRule(set(lensPath([...expression.path, "predicate"]), { text: predicate.substring(2), value: predicate_map[predicate] }, rule));
        setExpressions({ ...expressions, dropdown_is_open: false, expression_id: null, dropdown_type: null });
    };

    return (
        <div className="predicate_container">
            {lomap(
                (value, key) => (
                    <div className="metric_field" key={key} onClick={() => onSelectPredicate(value)}>
                        {value}
                    </div>
                ),
                predicatesDropdown
            )}
        </div>
    );
};

const IDPredicates = ({ expression }) => {
    const [rule, setRule] = useAtom(rule_atom);
    const [predicatesDropdown] = useAtom(id_predicates_atom);
    const [expressions, setExpressions] = useAtom(expressions_state);

    const onSelectPredicate = (predicate) => {
        setRule(set(lensPath([...expression.path, "predicate"]), { text: predicate.substring(2), value: predicate_map[predicate] }, rule));
        setExpressions({ ...expressions, dropdown_is_open: false, expression_id: null, dropdown_type: null });
    };

    return (
        <div className="predicate_container">
            {lomap(
                (value, key) => (
                    <div className="metric_field" key={key} onClick={() => onSelectPredicate(value)}>
                        {value}
                    </div>
                ),
                predicatesDropdown
            )}
        </div>
    );
};

const PredicateDropdown = ({ expression }) => {
    if (expression.metric.metric_type == "string") return <StringPredicates expression={expression} />;
    if (expression.metric.metric_type == "id") return <IDPredicates expression={expression} />;
    return <DefaultPredicates expression={expression} />;
};

export default PredicateDropdown;
