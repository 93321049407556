import React from "react";
import {
    CaretRightOutlined,
    PauseOutlined,
    MinusOutlined,
    CopyOutlined,
    NotificationOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    VerticalAlignMiddleOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";
import { capitalize, get, get as lodashget } from "lodash";

let loget = (path, obj) => get(obj, path);

const RuleActionUneditable = ({ rule }) => {
    let icon_map = {
        pause: PauseOutlined,
        start: CaretRightOutlined,
        delete: MinusOutlined,
        duplicate: CopyOutlined,
        notify: NotificationOutlined,
        decrease_budget: ArrowDownOutlined,
        increase_budget: ArrowUpOutlined,
        set_budget: VerticalAlignMiddleOutlined,
    };

    let action_value = loget(["action", "value"], rule);

    let is_budget_action = ["increase_budget", "decrease_budget", "set_budget"].includes(action_value);

    let Icon = icon_map[action_value];

    let rule_types = {
        amount: "$",
        percent: "%",
    };

    const SetBudget = () => {
        let budget_value = loget(["budget", "value"], rule);

        return (
            <div className="budget_params_container">
                <div className="budget_amount_container">
                    <input type="text" placeholder="Amount" value={budget_value} readOnly />
                </div>
            </div>
        );
    };

    const IncreaseBudget = () => {
        let budget_type = loget(["budget", "type"], rule);
        let budget_value = loget(["budget", "value"], rule);

        return (
            <div className="budget_params_container">
                <div className="budget_amount_container">
                    <input type="text" placeholder="Amount" value={budget_value} readOnly />
                </div>

                <div className="budget_type_dropdown_container">
                    <div className="budget_type_container">
                        <div className="value">{rule_types[budget_type]}</div>
                        <div className="icon">
                            <CaretDownOutlined />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const DecreaseBudget = () => {
        let budget_type = loget(["budget", "type"], rule);
        let budget_value = loget(["budget", "value"], rule);

        return (
            <div className="budget_params_container">
                <div className="budget_amount_container">
                    <input type="text" placeholder="Amount" value={budget_value} readOnly />
                </div>

                <div className="budget_type_dropdown_container">
                    <div className="budget_type_container">
                        <div className="value">{rule_types[budget_type]}</div>
                        <div className="icon">
                            <CaretDownOutlined />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    let budget_elements = {
        decrease_budget: DecreaseBudget,
        increase_budget: IncreaseBudget,
        set_budget: SetBudget,
    };

    let BudgetElement = budget_elements[action_value];

    return (
        <div className="action_container">
            <div className="icon_container">
                <Icon />
            </div>
            <div className="action_name_container">
                <div className="text">{capitalize(loget(["action", "text"], rule))}</div>
            </div>
            <div className="action_description">{is_budget_action && <BudgetElement />}</div>
        </div>
    );
};

export default RuleActionUneditable;
