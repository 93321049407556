import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { app_atom } from "../state/app";
import { user_atom, user_accounts_atom, facebook_accounts_atom } from "../state/auth";
import ScreenModal from "../components/ScreenModal";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { CaretDownOutlined, CloseCircleOutlined, LoadingOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Typography, Divider, Image, List, Radio, Checkbox, Button } from "antd";
import { map, flip, has, pipe, pick, includes, head, isEmpty, keys, sum } from "ramda";
import { map as lodashmap, keyBy, size, toLower } from "lodash";
import { db } from "../firebase";
import { getDocs, query, where, collection, getDoc, doc, onSnapshot, collectionGroup, setDoc, arrayUnion } from "firebase/firestore";
import { from, map as rxmap, zip } from "rxjs";
import { mod, matching, get, all } from "shades";
import Highlight from "react-highlight";
import copy from "copy-to-clipboard";
const { Title, Paragraph, Text, Link } = Typography;
const SelectAdAccountImg = require("../assets/select_ad_account.png");
const FbUrlParamaterImgOne = require("../assets/fb_url_paramater_one.png");
const onboarding_1_fb_2 = require("../assets/onboarding_1_fb_2.png");
const onboarding_1_fb_3 = require("../assets/onboarding_1_fb_3.png");
const onboarding_1_fb_4 = require("../assets/onboarding_1_fb_4.png");
const onboarding_1_fb_5 = require("../assets/onboarding_1_fb_5.png");
const onboarding_3_cf_1 = require("../assets/onboarding_3_cf_1.png");
const onboarding_4_shopify_1 = require("../assets/onboarding_4_shopify_1.png");
const onboarding_4_shopify_2 = require("../assets/onboarding_4_shopify_2.png");
const onboarding_4_shopify_3 = require("../assets/onboarding_4_shopify_3.png");
const onboarding_4_shopify_4 = require("../assets/onboarding_4_shopify_4.png");
const onboarding_5_gtm_1 = require("../assets/onboarding_5_gtm_1.png");
const onboarding_5_gtm_2 = require("../assets/onboarding_5_gtm_2.png");
const onboarding_5_gtm_3 = require("../assets/onboarding_5_gtm_3.png");
const onboarding_5_gtm_4 = require("../assets/onboarding_5_gtm_4.png");
const onboarding_5_gtm_5 = require("../assets/onboarding_5_gtm_5.png");
const onboarding_5_gtm_6 = require("../assets/onboarding_5_gtm_6.png");
const onboarding_5_gtm_7 = require("../assets/onboarding_5_gtm_7.png");
const onboarding_5_gtm_8 = require("../assets/onboarding_5_gtm_8.png");
const onboarding_6_elementor_1 = require("../assets/onboarding_6_elementor_1.png");
const onboarding_6_elementor_2 = require("../assets/onboarding_6_elementor_2.png");
const onboarding_6_elementor_3 = require("../assets/onboarding_6_elementor_3.png");
const onboarding_6_elementor_4 = require("../assets/onboarding_6_elementor_4.png");
const onboarding_7_thrive_thems_1 = require("../assets/onboarding_7_thrive_thems_1.png");
const onboarding_7_thrive_thems_2 = require("../assets/onboarding_7_thrive_thems_2.png");
const onboarding_7_thrive_thems_3 = require("../assets/onboarding_7_thrive_thems_3.png");
const onboarding_7_thrive_thems_4 = require("../assets/onboarding_7_thrive_thems_4.png");
const onboarding_7_thrive_thems_5 = require("../assets/onboarding_7_thrive_thems_5.png");
const onboarding_8_kartra_1 = require("../assets/onboarding_8_kartra_1.png");
const onboarding_8_kartra_2 = require("../assets/onboarding_8_kartra_2.png");
const onboarding_8_kartra_3 = require("../assets/onboarding_8_kartra_3.png");
const onboarding_8_kartra_4 = require("../assets/onboarding_8_kartra_4.png");
const onboarding_8_kartra_5 = require("../assets/onboarding_8_kartra_5.png");
const onboarding_9_keap_1 = require("../assets/onboarding_9_keap_1.png");
const onboarding_9_keap_2 = require("../assets/onboarding_9_keap_2.png");
const onboarding_9_keap_3 = require("../assets/onboarding_9_keap_3.png");
const onboarding_9_keap_4 = require("../assets/onboarding_9_keap_4.png");
const onboarding_9_keap_5 = require("../assets/onboarding_9_keap_5.png");
const onboarding_9_keap_6 = require("../assets/onboarding_9_keap_6.png");
const onboarding_10_shopify_1 = require("../assets/onboarding_10_shopify_1.png");
const onboarding_10_shopify_2 = require("../assets/onboarding_10_shopify_2.png");
const onboarding_10_shopify_3 = require("../assets/onboarding_10_shopify_3.png");
const onboarding_10_shopify_4 = require("../assets/onboarding_10_shopify_4.png");
const onboarding_10_shopify_5 = require("../assets/onboarding_10_shopify_5.png");
const onboarding_10_shopify_6 = require("../assets/onboarding_10_shopify_6.png");
const onboarding_11_clickfunnels_1 = require("../assets/onboarding_11_clickfunnels_1.png");
const onboarding_11_clickfunnels_2 = require("../assets/onboarding_11_clickfunnels_2.png");
const onboarding_11_clickfunnels_3 = require("../assets/onboarding_11_clickfunnels_3.png");
const onboarding_11_clickfunnels_4 = require("../assets/onboarding_11_clickfunnels_4.png");
const onboarding_12_woocommerce_1 = require("../assets/onboarding_12_woocommerce_1.png");
const onboarding_12_woocommerce_2 = require("../assets/onboarding_12_woocommerce_2.png");
const onboarding_12_woocommerce_3 = require("../assets/onboarding_12_woocommerce_3.png");
const onboarding_12_woocommerce_4 = require("../assets/onboarding_12_woocommerce_4.png");
const onboarding_12_woocommerce_5 = require("../assets/onboarding_12_woocommerce_5.png");
const onboarding_12_woocommerce_6 = require("../assets/onboarding_12_woocommerce_6.png");
const onboarding_12_woocommerce_7 = require("../assets/onboarding_12_woocommerce_7.png");
const onboarding_12_woocommerce_8 = require("../assets/onboarding_12_woocommerce_8.png");
const onboarding_13_kartra_1 = require("../assets/onboarding_13_kartra_1.png");
const onboarding_13_kartra_2 = require("../assets/onboarding_13_kartra_2.png");
const onboarding_13_kartra_3 = require("../assets/onboarding_13_kartra_3.png");
const onboarding_13_kartra_4 = require("../assets/onboarding_13_kartra_4.png");
const onboarding_13_kartra_5 = require("../assets/onboarding_13_kartra_5.png");
import styled from "styled-components";

const lomap = flip(lodashmap);
const lokeyby = flip(keyBy);

const common_paragraph_styles = {
    fontSize: 14,
    marginBottom: 10,
    marginTop: 10,
};

const common_list_styles = {
    fontSize: 14,
    marginBottom: 10,
    marginTop: 10,
};

const common_image_styles = {
    margin: 10,
    marginLeft: 0,
};

const LinkElm = styled.a``;

let tracking_code = (user_id) => `
    <script>
        (function roas(params) {
            var user_id  = params.user_id;
            localStorage.setItem("roas_user_id", user_id);
            fetch("https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js").then(function(res){return res.json()}).then(function(response){
                var downloadTokens  = response.downloadTokens;
                var head = document.head;
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js?alt=media&token=" + downloadTokens
                head.appendChild(script);
            })
        })({ user_id: "${user_id}" });
    </script>
`;

const TrackingCodeSnippetSection = () => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    const onCopySnippetToClipboard = (type) => {
        copy(tracking_code(user.uid));
    };

    return (
        <div className="tracking_code_container" style={{ width: 700 }}>
            <div className="title">ROAS Tracking Script</div>
            <div className="tracking_code">
                <Highlight language={"javascript"}>{tracking_code(user.uid)}</Highlight>
            </div>
            <div className="copy_code_snippet_to_clipboard_container" onClick={() => onCopySnippetToClipboard("tracking_code")}>
                Copy To Clipboard
            </div>
        </div>
    );
};

const ShoppingCartWebhook = ({ roas_shopping_cart }) => {
    console.log("ShoppingCartWebhook");
    console.log(roas_shopping_cart);

    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    const onCopySnippetToClipboard = () => {
        if (roas_shopping_cart == "") return;
        let url = `https://us-central1-roas-e44b8.cloudfunctions.net/app/${roas_shopping_cart}/${user.uid}`;
        copy(url);
    };

    if (roas_shopping_cart == "clickfunnels") {
        return (
            <div className="webhooks_container">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/clickfunnels/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "shopify") {
        return (
            <div className="webhooks_container">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/shopify/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    // if (userAccount.roas_shopping_cart == "kajabi") {
    //     return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/kajabi/${user.uid}`}</div>;
    // }

    if (roas_shopping_cart == "woocommerce") {
        return (
            <div className="webhooks_container">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/woocommerce/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "thrivecart") {
        return (
            <div className="webhooks_container">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/thrivecart/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "kartra") {
        return (
            <div className="webhooks_container">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/kartra/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "keap") {
        return (
            <div className="webhooks_container">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/keap/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "samcart") {
        return (
            <div className="webhooks_container">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/samcart/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    return <div className="webhooks_url_container">Select a shopping cart first to get your webhook url</div>;
};

const VideoCardElm = ({ title = "", modal_id = "", thumbnail_id = "", dna_script = false, webhook = false } = {}) => {
    console.log("VideoCardElm");
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);

    const onViewVideo = () => {
        console.log("onViewVideo");
        console.log("modal_id", modal_id);
        setApp({ ...app, modal_is_open: true, modal_id });
    };

    return (
        <div className="video_card_container" onClick={onViewVideo}>
            <div className="title">{title}</div>
            <div className="video_container">
                <img style={{ maxWidth: "100%" }} src={`https://cdn.loom.com/sessions/thumbnails/${thumbnail_id}-with-play.gif `}></img>
            </div>

            {dna_script && <TrackingCodeSnippetSection />}

            {webhook && (
                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={webhook} />
                </Paragraph>
            )}
        </div>
    );
};

const FacebookAccounts = () => {
    const [user] = useAtom(user_atom);
    const [fbAccounts, setFbAccounts] = useAtom(facebook_accounts_atom);
    const [filteredFbAccounts, setFilteredFbAccounts] = useState({});
    const [selectedFbAccount, setSelectedFbAccount] = useState("");
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    const save_ad_accounts = (ad_accounts) => {
        from(ad_accounts).subscribe((ad_account) => {
            let { account_id: fb_account_id, roas_user_id } = ad_account;
            let user_ids = arrayUnion(roas_user_id);
            from(setDoc(doc(db, "fb_ad_accounts", fb_account_id), { ...ad_account, user_ids }, { merge: true })).subscribe(() => {
                console.log(`saved ad account ${fb_account_id}`);
            });
        });
    };

    const get_fb_ad_accounts = () => {
        console.log("get_fb_ad_accounts");

        let user_fb_ref = query(collectionGroup(db, "integrations"), where("user_id", "==", user.uid), where("account_name", "==", "facebook"));
        from(getDocs(user_fb_ref))
            .pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(head)
            )
            .subscribe((fb_account_data) => {
                let { access_token, fb_account_id: fb_user_id, user_id: roas_user_id } = fb_account_data;

                let credentials = { access_token, fb_user_id, roas_user_id };

                var config = {
                    method: "get",
                    url: `https://us-central1-roas-e44b8.cloudfunctions.net/app/ad_accounts?access_token=${access_token}&fb_user_id=${fb_user_id}&roas_user_id=${roas_user_id}`,
                };

                from(axios(config))
                    .pipe(rxmap(get("data")), rxmap(mod(all)((ad_account) => ({ ...ad_account, ...credentials }))))
                    .subscribe((ad_accounts) => {
                        let roas_ad_amount_spent = pipe(
                            get(all, "amount_spent"),
                            mod(all)(Number),
                            mod(all)((value) => value / 100),
                            sum
                        )(ad_accounts);

                        update_user_intercom({ roas_has_facebook_ad_accounts: true, roas_ad_amount_spent });
                        update_user_db({ roas_has_facebook_ad_accounts: true, roas_ad_amount_spent });
                        setFbAccounts(ad_accounts);
                        save_ad_accounts(ad_accounts);
                    });
            });
    };

    useEffect(() => {
        let ad_accounts_query = query(collection(db, "fb_ad_accounts"), where("user_ids", "array-contains", user.uid));

        from(getDocs(ad_accounts_query))
            .pipe(
                rxmap((accounts) => accounts.docs.map((account) => account.data())),
                rxmap(pipe(lokeyby("account_id"))),
                rxmap(map(pick(["account_id", "name"]))),
                rxmap(map((value) => ({ ...value, selected: false })))
            )
            .subscribe((accounts) => {
                if (isEmpty(accounts)) {
                    get_fb_ad_accounts();
                } else {
                    update_user_intercom({ roas_has_facebook_ad_accounts: true });
                    update_user_db({ roas_has_ad_accounts: true });
                    setFbAccounts(accounts);
                }
            });
    }, []);

    useEffect(() => {
        if (size(fbAccounts) > 0) {
            setFilteredFbAccounts(fbAccounts);
        }
    }, [fbAccounts]);

    const save_ad_account_to_project = (ad_account_id) => {
        console.log("save_ad_account_to_project");

        let project_query = query(collection(db, "projects"), where("roas_user_id", "==", user.uid));
        let project_doc = from(getDocs(project_query)).pipe(
            rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
            rxmap(head)
        );

        project_doc.subscribe((project) => {
            let { project_id } = project;

            let project_ref = doc(db, "projects", project_id);
            setDoc(project_ref, { facebook_ad_account_id: ad_account_id, fb_ad_account_id: ad_account_id }, { merge: true });
        });
    };

    const update_user_db = (payload) => {
        let user_ref = doc(db, "accounts", user.uid);
        setDoc(user_ref, payload, { merge: true });
    };

    const update_user_intercom = (meta) => {
        console.log("update_user_intercom");
        if (user && !user.is_admin) {
            let intercom_defaults = {
                api_base: "https://api-iam.intercom.io",
                app_id: "wfjbdiuj",
                roas_email: user.roas_email,
                email: user.roas_email,
                roas_user_id: user.roas_user_id,
                user_id: user.roas_user_id,
            };

            window.Intercom("update", { ...intercom_defaults, ...meta });
        }
    };

    const onSelectFbAccount = (account_id) => {
        if (account_id == selectedFbAccount) {
            setSelectedFbAccount("");
        } else {
            save_ad_account_to_project(account_id);

            update_user_db({
                roas_has_selected_facebook_ad_account: true,
                roas_facebook_ad_account_id: account_id,
                roas_facebook_ad_account_id_selected_at: Number(moment().format("x")),
            });

            update_user_intercom({
                roas_has_selected_facebook_ad_account: true,
                roas_facebook_ad_account_id: account_id,
            });

            setSelectedFbAccount(account_id);
            setUserAccount({ ...userAccount, roas_has_selected_facebook_ad_account: true, roas_facebook_ad_account_id: account_id });
        }
    };

    const onFilterFbAccounts = ({ target: { value } }) => {
        setFilteredFbAccounts(pipe(get(matching({ name: pipe(toLower, includes(value)) })))(fbAccounts));
    };

    const AdAccounts = () => {
        return (
            <div className="facebook_accounts_list_container">
                {lomap(
                    (account) => (
                        <div className="account" key={account.account_id} onClick={() => onSelectFbAccount(account.account_id)}>
                            <div className="select">
                                <Checkbox
                                    onChange={() => onSelectFbAccount(account.account_id)}
                                    checked={account.account_id == selectedFbAccount}
                                ></Checkbox>
                            </div>
                            <div className="name">{account.name}</div>
                            <div className="name">{account.account_id}</div>
                        </div>
                    ),
                    filteredFbAccounts
                )}
            </div>
        );
    };

    const LoadingAdAccounts = () => {
        return (
            <div className="facebook_accounts_list_container loading_fb_accounts_container">
                <div className="loading_icon_container">
                    <div className="text">Loading</div>
                    <div className="icon">
                        <LoadingOutlined />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="carousel_view_container" id="facebook_accounts" key="facebook_accounts">
            <div className="facebook_accounts_container">
                <div className="facebook_accounts_search_filter_container">
                    <input type="text" placeholder="Search by account name" onChange={onFilterFbAccounts} />
                </div>

                {isEmpty(filteredFbAccounts) && <LoadingAdAccounts />}
                {!isEmpty(filteredFbAccounts) && <AdAccounts />}
            </div>
        </div>
    );
};

const ConnectToFacebookAccordion = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Title level={4} style={{ marginTop: 0 }}>
                    Part 1: Connect To Facebook.
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <Paragraph style={{ ...common_paragraph_styles }}>There are two steps in this process and they are both easy.</Paragraph>
                <Paragraph style={{ ...common_paragraph_styles }}>First, we’ll connect to your Facebook Business Manager.</Paragraph>
                <Paragraph style={{ ...common_paragraph_styles }}>Then, we’ll choose the Facebook ad account that you want to track.</Paragraph>

                <Accordion>
                    <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel1a-content" id="panel1a-header">
                        <Title level={5} style={{ marginTop: 0 }}>
                            Step 1: Connect To Facebook Business Manager:
                        </Title>
                    </AccordionSummary>
                    <AccordionDetails>
                        <VideoCardElm
                            title="Click here for a quick video walkthrough showing you how to connect your Business manager to ROAS."
                            modal_id={"connect_business_ads_manager"}
                            thumbnail_id={"8932485b652047f192f345d53ff6aaec"}
                            dna_script={false}
                            webhook={false}
                        />

                        <div className="connect_to_fb_container">
                            <a
                                className="connect_to_fb_button"
                                href={`https://roas-oauth-service.herokuapp.com/auth/facebook?user_id=${user.uid}`}
                                target="_blank"
                            >
                                <div className="text">Connect to Facebook</div>
                            </a>
                        </div>

                        <Paragraph style={{ ...common_paragraph_styles }}>Make sure that you click “Allow” on all the steps from Facebook </Paragraph>
                        <Paragraph style={{ ...common_paragraph_styles }}>(Note: this may request you login to your facebook account)</Paragraph>
                        <Paragraph style={{ ...common_paragraph_styles }}>
                            Once you have allowed ROAS app to connect, you will want to select the ad account.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                        <Title level={5} style={{ marginTop: 0 }}>
                            Step 2: Select Your Ad Account From Within Your Business Manager.
                        </Title>
                    </AccordionSummary>
                    <AccordionDetails style={{}}>
                        <Paragraph style={{ ...common_paragraph_styles }}>
                            Now you should see a list of all the accounts in your Business Manager. If you have multiple accounts, this part might
                            take a few minutes to load. (It’s Facebook being ...well ...being Facebook.)
                        </Paragraph>

                        <div style={{ marginBottom: 20 }}>
                            <FacebookAccounts />
                        </div>

                        <Paragraph style={{ ...common_paragraph_styles }}>
                            Be sure to select the proper account you want to track (only select one ad account per ROAS account), and click “Save”.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
            </AccordionDetails>
        </Accordion>
    );
};

const AddTrackingToYourAdsAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const adding_tracking_code_to_your_fb_aaccount_list_1 = [
        "1. Log into your Facebook ads manager.",
        "2. Select the campaigns, ad sets, and ads you want to edit.",
        "3. In the ads section we’re going to setup UTM parameters.",
        "4. Edit the ads that you will be, or are already running traffic to. You can use Facebook’s “Bulk Editing” feature by selecting multiple ads and clicking “edit”.",
        "5. Under the “Destination” area look for Build a URL parameter underneath Website URL.",
    ];
    const adding_tracking_code_to_your_fb_aaccount_list_2 = [
        "6. Click the “Build URL Parameter” link that’s right below the field where your website goes.",
    ];
    const adding_tracking_code_to_your_fb_aaccount_list_3 = ["7. Click the “Add Parameter” button as shown above."];
    const adding_tracking_code_to_your_fb_aaccount_list_4 = [
        "8. When you click on the “value” box on the right hand side, a drop down menu will appear:",
    ];
    const adding_tracking_code_to_your_fb_aaccount_list_5 = ["9. When it does, select {{ad.id}} as shown."];
    const adding_tracking_code_to_your_fb_aaccount_list_6 = [
        "10. Verify that you see the parameter in the URL preview as shown above, and then click “apply.",
    ];

    return (
        <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2bh-content" id="panel2bh-header">
                <Title level={4} style={{ marginTop: 0 }}>
                    Part 2: Add The Tracking To Your Ads.
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"add_tracking_to_your_ads_video"}
                    thumbnail_id={"4662986a0cc24f50923020f4e1c51ab1"}
                    dna_script={false}
                    webhook={false}
                />

                <Paragraph style={{ fontSize: 14 }}>Now we’re going to add one little snippet to the URLs in your Facebook ads.</Paragraph>
                <Paragraph style={{ fontSize: 14 }}>Here’s the snippet itself:</Paragraph>
                <Paragraph style={{ fontSize: 14 }}>
                    <Text code={true}>{`fb_id={{ad.id}}`}</Text>
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    You’re going to want to use this on ALL of the URLs in ALL of the ads you’re running traffic to so we can track them.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Here’s how to do it.</Paragraph>

                <List
                    size="large"
                    bordered
                    dataSource={adding_tracking_code_to_your_fb_aaccount_list_1}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Image width={500} style={{ ...common_image_styles }} src={FbUrlParamaterImgOne} />

                <List
                    style={{ marginBottom: 20 }}
                    size="large"
                    bordered
                    dataSource={adding_tracking_code_to_your_fb_aaccount_list_2}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>When you do, this will pop up:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_1_fb_2} />

                <List
                    style={{ marginBottom: 20 }}
                    size="large"
                    bordered
                    dataSource={adding_tracking_code_to_your_fb_aaccount_list_3}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>When you do, you’ll see options appear under a heading called “Custom Parameters”.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    On the left hand side, type <Text code={true}>{`fb_id`}</Text>.
                </Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_1_fb_3} />

                <List
                    style={{ marginBottom: 20 }}
                    size="large"
                    bordered
                    dataSource={adding_tracking_code_to_your_fb_aaccount_list_4}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_1_fb_4} />

                <List
                    style={{ marginBottom: 20 }}
                    size="large"
                    bordered
                    dataSource={adding_tracking_code_to_your_fb_aaccount_list_5}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>When you do this, notice how you’ll see the parameter in the URL Preview section:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_1_fb_5} />

                <List
                    style={{ marginBottom: 20 }}
                    size="large"
                    bordered
                    dataSource={adding_tracking_code_to_your_fb_aaccount_list_6}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>
                    Assuming you’ve done this to ALL ads you’re wanting to track, you’ve completed this step.
                </Paragraph>
            </AccordionDetails>
        </Accordion>
    );
};

const ClickFunnelsAccordion = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const clickfunnels_list_1 = [
        "1. Select your funnel you wish to track",
        "2. In the top right select the “Settings”",
        "3. Then paste your tracking code into the “head” section”",
        "4. Remember to scroll down to the bottom the the page and click SAVE.",
        "5. Please do this for ALL of the funnels you want to track in Clickfunnels.",
        "6. Here is your code again if you need it:",
    ];

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel1a-content" id="panel1a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Clickfunnels
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"clickfunnels_dna_tracking"}
                    thumbnail_id={"7b97363c8ea840da95d78407aaa78bfd"}
                    dna_script={true}
                    webhook={false}
                />

                <List
                    size="large"
                    bordered
                    dataSource={clickfunnels_list_1}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <TrackingCodeSnippetSection />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_3_cf_1} />
            </AccordionDetails>
        </Accordion>
    );
};

const ShopifyAccordion = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const shopify_list_1 = [
        "1. From the home screen, select “Online Store”",
        "2. From the dropdown menu, select “Themes”. (You probably won’t see “themes” until you click “Online Store”.)",
    ];

    const shopify_list_2 = [
        "3. When the “Themes” page loads, click on “Actions” button at the top right and then click on “Edit Code” from the drop down.",
    ];

    const shopify_list_3 = [
        "4. On the next page, you’ll see a bunch of code and stuff ...when you’re doing this. DON’T LET ALL THAT CODE FREAK YOU OUT! WE’RE JUST PASTING SOMETHING :-)",
        "5. First, at the top left, under the “Layout” icon, click on “Theme.liquid.”",
        `6. Next, look at the top part of the code that appears in the main bondy of the page.

            Right near the top, you’ll see a little snippet of code that says "<head>"
        `,
        `7. When you find it, put your cursor right at the end of it and press the “enter” key on your keyboard a few times.

            We’re just wanting to add some space so we can paste the tracking code here.
        `,
        `8. Then paste your tracking code into that space you created. (See below).`,
    ];

    const shopify_list_4 = ["9. Here is your tracking code in case you need it"];

    const shopify_list_5 = [`10. Don’t forget to click "SAVE"`];

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Shopify
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"shopify_dna_tracking"}
                    thumbnail_id={"6a4cfc8960ad40c0973f1398df725296"}
                    dna_script={true}
                    webhook={false}
                />

                <List
                    size="large"
                    bordered
                    dataSource={shopify_list_1}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_4_shopify_1} />

                <List
                    size="large"
                    bordered
                    dataSource={shopify_list_2}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_4_shopify_2} />

                <List
                    size="large"
                    bordered
                    dataSource={shopify_list_3}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_4_shopify_3} />

                <List
                    size="large"
                    bordered
                    dataSource={shopify_list_4}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <TrackingCodeSnippetSection />

                <List
                    size="large"
                    bordered
                    dataSource={shopify_list_5}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_4_shopify_4} />
            </AccordionDetails>
        </Accordion>
    );
};

const GtmAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const list_1 = ["1. First, log into your Google Tag Manager workspace.", "2. Then click on Tags."];

    const list_2 = ["3. Then click on “New”"];

    const list_3 = ["4. Now name the tag and select “Custom HTML” from the menu on the right."];

    const list_4 = [
        "5. When you select “Custom HTML”, a blank space will appear where you’ll be able to paste your code.",
        `6. Paste your code there (see screenshot below).`,
    ];

    const list_5 = [
        "6. Next, you’ll want to tell Google Tag manager what needs to happen in order for this tag to fire.",
        `7. We’ll do this by creating a “Trigger”.`,
        `8. Right below the space where you pasted your code, you’ll see the “Triggering” section.`,
        `9. Click the little pencil on the right so you can create a new trigger.`,
    ];

    const list_6 = [
        "10. When you do that, you’ll be taken to the “Triggers” screen.",
        `11. Click the plus sign in the top right to create a new trigger.`,
    ];

    const list_7 = [
        "12. Now you can create a new trigger.",
        `13. In the top right, name it “All Web Pages For ROAS”`,
        `14. Next, click “Page View” from the menu on the left.`,
    ];

    const list_8 = ["15. On the next screen, just make sure that “all page views” is selected.", `16. Then click “Save”.`];

    const list_9 = ["17. Be sure to save the trigger and the tag, and then be sure to PUBLISH your changes."];

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Google Tag Manager
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"gtm_dna_tracking"}
                    thumbnail_id={"a836b1d855f44994a28dd2597cf52fc7"}
                    dna_script={true}
                    webhook={false}
                />

                <List size="large" bordered dataSource={list_1} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_5_gtm_1} />

                <List size="large" bordered dataSource={list_2} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_5_gtm_2} />

                <List size="large" bordered dataSource={list_3} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_5_gtm_3} />

                <List size="large" bordered dataSource={list_4} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Paragraph style={{ ...common_paragraph_styles }}>Here’s your code in case you need it:</Paragraph>

                <TrackingCodeSnippetSection />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_5_gtm_4} />

                <List size="large" bordered dataSource={list_5} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_5_gtm_5} />

                <List size="large" bordered dataSource={list_6} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_5_gtm_6} />

                <List size="large" bordered dataSource={list_7} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_5_gtm_7} />

                <List size="large" bordered dataSource={list_8} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_5_gtm_8} />

                <List size="large" bordered dataSource={list_9} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />
            </AccordionDetails>
        </Accordion>
    );
};

const ElementorAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const list_1 = [
        "1. Log into your Wordpress admin.",
        "2. Find the Elementor menu in the main menu on the left.",
        "3. From the Elementor menu, select “Custom Code”",
    ];

    const list_2 = [
        "5. In the custom code interface, be sure you select “<head>” in the dropdown menu of the Location settings.",
        "6. Then paste your code.",
        "7. When you’re done, click PUBLISH.",
    ];

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Elementor
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"elementor_dna_tracking"}
                    thumbnail_id={"2f875dbe62a849ed8b2c77c58b05036d"}
                    dna_script={true}
                    webhook={false}
                />

                <Paragraph style={{ fontSize: 14 }}>
                    There are lots of page builders for Wordpress. many people use Elementor, so we included instructions for adding the code using
                    that system specifically.
                </Paragraph>

                <List size="large" bordered dataSource={list_1} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_6_elementor_1} />

                <Paragraph style={{ fontSize: 14 }}>At the Custom Code menu, select “add new custom code”.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_6_elementor_2} />

                <List size="large" bordered dataSource={list_2} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <TrackingCodeSnippetSection />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_6_elementor_3} />

                <Paragraph style={{ fontSize: 14 }}>At the next screen, make sure you select “Entire Site” as shown below.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_6_elementor_4} />

                <Paragraph style={{ fontSize: 14 }}>Be sure to click “Save” when you’re done.</Paragraph>
            </AccordionDetails>
        </Accordion>
    );
};

const ThriveThemesAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const list_1 = [
        "1. From your main Wordpress admin screen, select “Thrive Dashboard” from the menu.",
        "2. Then select the Thrive Dashboard AGAIN when the Thrive menu opens up. (I know, that is weird.)",
        "3. See screenshot:",
    ];

    const list_2 = [
        "4. Paste your script in the “script” area as shown below.",
        "5. Type “ROAS CODE” for the script label.",
        "6. In the dropdown menu for placement, select “Before </head>”.",
        "7. Then click “Continue”",
    ];

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Thrive Themes
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"thrive_dna_tracking"}
                    thumbnail_id={"2a4107d619fd470480be15e0c5833adb"}
                    dna_script={true}
                    webhook={false}
                />

                <Paragraph style={{ fontSize: 14 }}>
                    There are many page builders for Wordpress. Since ThriveThemes is so popular, we created a specific tutorial for it.
                </Paragraph>

                <List size="large" bordered dataSource={list_1} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_7_thrive_thems_1} />

                <Paragraph style={{ fontSize: 14 }}>Next, you’ll see a lot of menu options.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    Select “Analytics and Scripts” (Click the green “manage Scripts” button as shown below).
                </Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_7_thrive_thems_2} />

                <Paragraph style={{ fontSize: 14 }}>On the next screen, click “Add New” at the top right.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_7_thrive_thems_3} />

                <Paragraph style={{ fontSize: 14 }}>When you do, you’ll see a window appear like in the screenshot below.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>This is where you’ll paste your code (Copy from below):</Paragraph>

                <TrackingCodeSnippetSection />

                <Paragraph style={{ fontSize: 14 }}>Do this:</Paragraph>

                <List size="large" bordered dataSource={list_2} renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>} />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_7_thrive_thems_4} />

                <Paragraph style={{ fontSize: 14 }}>IMPORTANT:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Don’t forget to turn it ON (see below):</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_7_thrive_thems_5} />
            </AccordionDetails>
        </Accordion>
    );
};

const KartraAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Kartra
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"kartra_dna_tracking"}
                    thumbnail_id={"ffa1de1ef06a4237ba0690a289a62e87"}
                    dna_script={true}
                    webhook={false}
                />

                <Paragraph style={{ fontSize: 14 }}>
                    You want to make sure the tracking code is on EVERY page that you’re sending traffic to.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    First, navigate to “my Pages and find the specific page that you want to track. (Note - you’re going to want to do these steps for
                    ALL pages that you’re tracking).
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Next, click the three dots next to the page name as shown below:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_8_kartra_1} />

                <Paragraph style={{ fontSize: 14 }}>When you click the three buttons, a menu will appear. Select “Edit” from that menu.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_8_kartra_2} />

                <Paragraph style={{ fontSize: 14 }}>When you do that, ANOTHER menu will appear.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Click on “Edit” in that menu, too:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_8_kartra_3} />

                <Paragraph style={{ fontSize: 14 }}>At the next screen, select “Settings” from the menu on the left.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    When you do, another menu will expand and you’ll want to select “Tracking Code” from there.
                </Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_8_kartra_4} />

                <Paragraph style={{ fontSize: 14 }}>
                    Next, place your tracking code at the top left part of the page where it says “EMBED TRACKING CODE INTO THE {`<HEAD>`}.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Here is your code if you need it:</Paragraph>

                <TrackingCodeSnippetSection />

                <Paragraph style={{ fontSize: 14 }}>Hit “Apply” when you’re done.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Then “Publish LIVE”</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_8_kartra_5} />

                <Paragraph style={{ fontSize: 14 }}>
                    NOTE: Be sure to do this for ALL pages you’re sending traffic to (as well as all pages in your funnel such as upsell pages,
                    checkout pages, etc. Anywhere Kartra will allow you to paste this code, paste it.)
                </Paragraph>
            </AccordionDetails>
        </Accordion>
    );
};

const AddRoasTrackingCodeAccordion = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel3bh-content" id="panel3bh-header">
                <Title level={4} style={{ marginTop: 0 }}>
                    Part 3: Paste The ROAS Tracking Code ON ALL Of Your Web Pages.
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <Paragraph style={{ fontSize: 14 }}>
                    Now you should see a list of all the accounts in your Business Manager. If you have multiple accounts, this part might take a few
                    minutes to load. (It’s Facebook being ...well ...being Facebook.)
                </Paragraph>

                <TrackingCodeSnippetSection />

                <Paragraph style={{ fontSize: 14 }}>We’ve created instructions for installing it on the most popular platforms around.</Paragraph>

                <ClickFunnelsAccordion />
                <ShopifyAccordion />
                <GtmAccordion />
                <ElementorAccordion />
                <ThriveThemesAccordion />
                <KartraAccordion />
            </AccordionDetails>
        </Accordion>
    );
};

const KeapWebhookAccordion = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Keap/Infusionsoft.
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"keap_webhook"}
                    thumbnail_id={"e4ee7543f925458b95638e2fbb8d1070"}
                    dna_script={false}
                    webhook={false}
                />

                <Paragraph style={{ fontSize: 14 }}>
                    (Even if your order forms are hosted on Clickfunnels ...but you actually process the sales through the Infusionsoft Gateway, use
                    this.)
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>First, connect to Keap/Infusionsoft.</Paragraph>

                <div className="connect_to_fb_container">
                    <a
                        className="connect_to_fb_button"
                        href={`https://us-central1-roas-e44b8.cloudfunctions.net/oauth/auth/keap/?user_id=${user.uid}`}
                        target="_blank"
                    >
                        <div className="text">Click here to open the connection window in a new tab</div>
                    </a>
                </div>

                <Paragraph style={{ fontSize: 14 }}>Next select which Keap/Infusionsoft account you want to create and click “Allow”.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_9_keap_1} />

                <Paragraph style={{ fontSize: 14 }}>
                    If you’re processing orders through Keap/Infusionsoft ...but your actual order forms are powered by another provider such as
                    Clickfunnels, this is all you need to do.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    If you’re using Keap/Infusionsoft’s HOSTED ORDER FORMS, you’ll also need to do these steps:
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Log into your Keap account and select “E-Commerce Setup” from the main menu.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_9_keap_2} />

                <Paragraph style={{ fontSize: 14 }}>From the E-Commerce Setup menu, select “Order Forms”</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_9_keap_3} />

                <Paragraph style={{ fontSize: 14 }}>From the “order Forms” menu, select the order form you want to track.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>NOTE: You’ll want to do this for ALL order forms. </Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_9_keap_4} />

                <Paragraph style={{ fontSize: 14 }}>Next, select “HTML” areas from the top of that order form’s menu. </Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_9_keap_5} />

                <Paragraph style={{ fontSize: 14 }}>
                    Next, paste your tracking code in the “Custom Header” area and then click the blue “SAVE” button.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Here’s your tracking code in case you need it:</Paragraph>

                <TrackingCodeSnippetSection />

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_9_keap_6} />

                <Paragraph style={{ fontSize: 14 }}>Click “Save All” for good measure.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>You are now integrated with Infusionsoft/Keap</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    REMEMBER: You don’t need to paste the code on the order forms unless you’re actually using Infusionsoft’s HOSTED ORDER FORMS.
                </Paragraph>
            </AccordionDetails>
        </Accordion>
    );
};

const ShopifyWebhookAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Shopify.
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"shopify_webhook"}
                    thumbnail_id={"5d1fa2824e314d95b37246eed3202b21"}
                    dna_script={false}
                    webhook={"shopify"}
                />

                <Paragraph style={{ fontSize: 14 }}>First, click on “Settings” from the main menu. It’s kind of hidden down at the bottom:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_10_shopify_1} />

                <Paragraph style={{ fontSize: 14 }}>From the settings window, click on “Notifications” in the left hand menu.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_10_shopify_2} />

                <Paragraph style={{ fontSize: 14 }}>
                    On the “Notifications” screen, scroll all the way down to the bottom and click on “Create Webhook”
                </Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_10_shopify_3} />

                <Paragraph style={{ fontSize: 14 }}>IMPORTANT:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>We’re about to do the following step THREE times.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>This is super easy as long as you pay attention.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>First, here’s your Shopify Webhook:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"shopify"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Go ahead and copy that.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    Now, when you click on “Create Webhook” above, a little window like the one below will pop up.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    The first thing you want to do is select ”Order Creation” from the “Event” drop down menu like you see below.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Then paste your webhook into the URL field.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Leave the Webhook API version on Unstable. (Yes, that sounds weird but its fine).</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Then hit save. Just like below:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_10_shopify_4} />

                <Paragraph style={{ fontSize: 14 }}>SECOND TIME:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Click on “Create Webhook” again in the Notifications Menu.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>The little window will pop up again.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    The only thing that changes this time is what you selcect from the Events drop down menu.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Instead of choosing Order Creation, you want to choose “Order Payment”.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Then paste your webhook URL again. (Here it is in case you need it)</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"shopify"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>The format is JSON (it should already be selected like you see below).</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>...And the “Unstable” webhook API version will also be showing.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    So really all you’re doing is selecting a new event from the “Event” drop down menu, pasting your webhook, and pressing save.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Like so:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_10_shopify_5} />

                <Paragraph style={{ fontSize: 14 }}>THIRD TIME:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Click on “Create Webhook” again in the Notifications Menu.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>The little window will pop up again.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    The only thing that changes this time is what you selcect from the Events drop down menu.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Instead of choosing Order Payment, you want to choose “Order Update”.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Then paste your webhook URL again. (Here it is in case you need it)</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"shopify"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>The format is JSON (it should already be selected like you see below).</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>...And the “Unstable” webhook API version will also be showing.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    So really all you’re doing is selecting a new event from the “Event” drop down menu, pasting your webhook, and pressing save.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Like so:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_10_shopify_6} />

                <Paragraph style={{ fontSize: 14 }}>Now you’re fully connected to Shopify!</Paragraph>
            </AccordionDetails>
        </Accordion>
    );
};

const ClickfunnelsWebhookAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onViewVideo = (modal_id) => {
        setApp({ ...app, modal_is_open: true, modal_id });
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Clickfunnels.
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"clickfunnels_webhook"}
                    thumbnail_id={"d646eb20419d48188e0d868634a9865d"}
                    dna_script={false}
                    webhook={"clickfunnels"}
                />

                <Paragraph style={{ fontSize: 14 }}>If you use Clickfunnels order forms, you need to do this step.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>It’s really simple.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>First, understand that we need to do this for EACH FUNNEL you’re wanting to track.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>It takes roughly 20 seconds per funnel.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Select the funnel you want to track and click on “Settings”</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_11_clickfunnels_1} />

                <Paragraph style={{ fontSize: 14 }}>
                    Next, SCROLL DOWN toward the bottom of the page and click “Manage Your Funnel Webhooks”.
                </Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_11_clickfunnels_2} />

                <Paragraph style={{ fontSize: 14 }}>On the webhooks screen, click “New Webhook” at the top right.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_11_clickfunnels_3} />

                <Paragraph style={{ fontSize: 14 }}>On the next screen, simply paste your ROAS webhook in the URL field.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Here’s your webhook for ClickFunnels:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"clickfunnels"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>The “Event” field can be set to “All Events”.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>The “Version” field can be be set to “1”</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>The “Adapter” field can be set to “Attributes”.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    (These fields are usually set this way by default - just like in the screenshot below - so you really should only need to paste
                    your WebHook and then click the “Create Funnel Webhook” button.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Like so:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_11_clickfunnels_4} />

                <Paragraph style={{ fontSize: 14 }}>Be sure to do this for EVERY funnel you want to track.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    Also - when you’re done adding the webhook, you’ll be taken back to the “settngs” page for your funnel.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>ALWAYS CLICK “SAVE AND UPDATE SETTINGS” at the bottom :-)</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <LinkElm onClick={() => onViewVideo("clickfunnels_webhook")}>
                        If you’d like a video walkthrough of how to do it, watch this
                    </LinkElm>
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Also - in case you need it, here is your Clickfunnels Webhook</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"clickfunnels"} />
                </Paragraph>
            </AccordionDetails>
        </Accordion>
    );
};

const WoocommerceWebhookAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    let list_1 = [
        "1. The name of each webhook.",
        "2. The “Topic” of each webhook.",
        "3. We always want to make sure we turn it on by selecting “Active”.",
    ];

    let list_2 = [
        "1. Name it ROAS Order Created.",
        "2. Set it to Active in the “Status” Dropdown.",
        "3. Select “Order Created” from the “Topic” Dropdown.",
        "4. Paste your Webhook in the “Delivery URL” field.",
        "5. Click on Save Webhook.",
    ];

    let list_3 = [
        "1. Name it ROAS Order Updated.",
        "2. Set it to Active in the “Status” Dropdown.",
        "3. Select “Order Updated from the “Topic” Dropdown.",
        "4. Paste your Webhook in the “Delivery URL” field.",
        "5. Click on Save Webhook.",
    ];

    let list_4 = [
        "1. Name it ROAS Order Paid.",
        "2. Set it to Active in the “Status” Dropdown.",
        "3. Select “Order Paid from the “Topic” Dropdown.",
        "4. Paste your Webhook in the “Delivery URL” field.",
        "5. Click on Save Webhook.",
    ];

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Woocommerce.
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"woocommerce_webhook"}
                    thumbnail_id={"63647793eade4276aff8fe4c330d0abd"}
                    dna_script={false}
                    webhook={"woocommerce"}
                />

                <Paragraph style={{ fontSize: 14 }}>
                    ROAS can get your sales data directly from WooCommerce so you don’t have to rely on outdated stuff like cookies or pixels.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>To set it up, we just need to paste your WebHook three times.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    Here’s your WooCommerce Webhook. You’re going to need to copy/paste it during this simple process.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    Start in your wordpress admin panel by finding and clicking the WooCommerce link in the left hand menu.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Then click “Settings” from within the WooCommerce menu that drops down:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_12_woocommerce_1} />

                <Paragraph style={{ fontSize: 14 }}>Once you’re in the settings menu, click the “Advanced” tab:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_12_woocommerce_2} />

                <Paragraph style={{ fontSize: 14 }}>Once you’re in the “Advanced” tab, click “Webhooks”.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>It’s kind of hidden, so see the screenshot below:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_12_woocommerce_3} />

                <Paragraph style={{ fontSize: 14 }}>Once you’re in the “Webhooks” screen, click “Add Webhook”:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_12_woocommerce_4} />

                <Paragraph style={{ fontSize: 14 }}>When you do, you’ll see this screen.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>We’re going to add the webhook to this screen THREE times.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>(All we’re doing is pasting it.)</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_12_woocommerce_5} />

                <Paragraph style={{ fontSize: 14 }}>The only thing that will change is:</Paragraph>

                <List
                    style={{ ...common_list_styles }}
                    size="large"
                    bordered
                    dataSource={list_1}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>The “Topic” is simply a dropdown menu option ...and you can name it whatever you want.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Let’s get started.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>First, here’s your webhook:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>When you get to the webhook screen for your first webhook, do this:</Paragraph>

                <List
                    style={{ ...common_list_styles }}
                    size="large"
                    bordered
                    dataSource={list_2}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>Like so:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_12_woocommerce_6} />

                <Paragraph style={{ fontSize: 14 }}>Now we want to do this a SECOND time.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>All we’re changing is:</Paragraph>

                <List
                    style={{ ...common_list_styles }}
                    size="large"
                    bordered
                    dataSource={list_1}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>In case you need it, here’s your webhook:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>When you get to the webhook screen for your second webhook, do this:</Paragraph>

                <List
                    style={{ ...common_list_styles }}
                    size="large"
                    bordered
                    dataSource={list_3}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>Like so:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_12_woocommerce_7} />

                <Paragraph style={{ fontSize: 14 }}>Now we want to do this a THIRD (and final) time.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>All we’re changing is:</Paragraph>

                <List
                    style={{ ...common_list_styles }}
                    size="large"
                    bordered
                    dataSource={list_1}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>In case you need it, here’s your webhook:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>When you get to the webhook screen for your third webhook, do this:</Paragraph>

                <List
                    style={{ ...common_list_styles }}
                    size="large"
                    bordered
                    dataSource={list_4}
                    renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                />

                <Paragraph style={{ fontSize: 14 }}>Like so:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_12_woocommerce_8} />

                <Paragraph style={{ fontSize: 14 }}>That’s all there is to it!</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Sounds like a lot but it’s really just clicking a few buttons three times.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Once again, here’s your WooCommerce webhook in case you need it.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>
            </AccordionDetails>
        </Accordion>
    );
};

const KartraWebhookAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Kartra.
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"kartra_webhook"}
                    thumbnail_id={"bbdf9ba8e6924e52ac96df5bf07dffe1"}
                    dna_script={false}
                    webhook={"kartra"}
                />

                <Paragraph style={{ fontSize: 14 }}>Adding the webhook to Kartra is simple</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>From the main Kartra interfect, select “My Intergrations” from the menu on the left.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_13_kartra_1} />

                <Paragraph style={{ fontSize: 14 }}>On the next screen, click “My API” in the top menu.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_13_kartra_2} />

                <Paragraph style={{ fontSize: 14 }}>When you do, ANOTHER little menu will appear right at the top.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Click “My API” in that little menu.</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_13_kartra_3} />

                <Paragraph style={{ fontSize: 14 }}>
                    As soon as you do, you’ll see the screen change to what you see in the screenshot below.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    You want to click “CHANGE” on the right hand side of the “Outbound API” section like so:
                </Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_13_kartra_4} />

                <Paragraph style={{ fontSize: 14 }}>Once you click that, you’ll now have the option to paste you Webhook.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Here is your Karta webhook in case you need it:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"kartra"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Here’s what the next screen looks like:</Paragraph>

                <Image width={500} style={{ ...common_image_styles }} src={onboarding_13_kartra_5} />

                <Paragraph style={{ fontSize: 14 }}>First, make sure the “Yes, Activate Outbound API is checked.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>In the URL field, make sure you select “https” from the dropdown menu on the left.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    Next, paste your webhook in the URL field - but only paste everything AFTER the https:// part.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Here’s your webhook again if you need it:</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"kartra"} />
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Finally, we need to select what events make this webhook fire.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>This is simple.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    In the bottom row of dropdown menus, select “Customer Buys Product” in the menu on the left.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>In the menu to the right of that, select “And Product”.</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Then click “Add”</Paragraph>
            </AccordionDetails>
        </Accordion>
    );
};

const SamcartWebhookAccordion = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel2a-content" id="panel2a-header">
                <Title level={5} style={{ marginTop: 0 }}>
                    Samcart.
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"samcart_webhook"}
                    thumbnail_id={"058a6a2357e8494ea14504e4510dca93"}
                    dna_script={false}
                    webhook={"samcart"}
                />
            </AccordionDetails>
        </Accordion>
    );
};

const AddWebhookAccordion = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            <AccordionSummary expandIcon={<CaretDownOutlined />} aria-controls="panel3bh-content" id="panel3bh-header">
                <Title level={4} style={{ marginTop: 0 }}>
                    Part 4: Add The WebHook To Your Shopping Cart
                </Title>
            </AccordionSummary>
            <AccordionDetails>
                <Paragraph style={{ fontSize: 14 }}>When we say “Shopping Cart”, we just mean “The thing that powers your order forms.”</Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    Instead of relying on Pixels and other outdated tech that doesn’t work, ROAS tracks orders directly from your order forms ...so
                    there’s no depending on cookies or pixels for real tracking.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>
                    This little step takes a maximum of three minutes, but it makes it so that your tracking is VERY accurate.
                </Paragraph>

                <Paragraph style={{ fontSize: 14 }}>Select your Shopping Cart/Order Form Provider from the options below:</Paragraph>

                <KeapWebhookAccordion />
                <ShopifyWebhookAccordion />
                <ClickfunnelsWebhookAccordion />
                <WoocommerceWebhookAccordion />
                <KartraWebhookAccordion />
                <SamcartWebhookAccordion />
            </AccordionDetails>
        </Accordion>
    );
};

const OnboardingTwo = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);

    const onCloseModal = () => {
        setApp({ ...app, modal_is_open: false, modal_id: "" });
    };

    return (
        <div className="onboarding_two_container">
            <ScreenModal id="connect_business_ads_manager">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src={`https://www.loom.com/embed/8932485b652047f192f345d53ff6aaec`}
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="add_tracking_to_your_ads_video">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/4662986a0cc24f50923020f4e1c51ab1"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="clickfunnels_dna_tracking">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/7b97363c8ea840da95d78407aaa78bfd"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="shopify_dna_tracking">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/6a4cfc8960ad40c0973f1398df725296"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="gtm_dna_tracking">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/a836b1d855f44994a28dd2597cf52fc7"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="elementor_dna_tracking">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/2f875dbe62a849ed8b2c77c58b05036d"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="thrive_dna_tracking">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/2a4107d619fd470480be15e0c5833adb"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="kartra_dna_tracking">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/ffa1de1ef06a4237ba0690a289a62e87"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="samcart_dna_tracking">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/6470a69a15314487ae7d4c656f53a2be"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="keap_webhook">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/e4ee7543f925458b95638e2fbb8d1070"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="shopify_webhook">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/5d1fa2824e314d95b37246eed3202b21"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="clickfunnels_webhook">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/d646eb20419d48188e0d868634a9865d"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="woocommerce_webhook">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/63647793eade4276aff8fe4c330d0abd"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="kartra_webhook">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/bbdf9ba8e6924e52ac96df5bf07dffe1"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <ScreenModal id="samcart_webhook">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <iframe
                        src="https://www.loom.com/embed/058a6a2357e8494ea14504e4510dca93"
                        frameBorder="0"
                        webkitAllowFullscreen="true"
                        mozAllowFullscreen="true"
                        allowFullscreen="true"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "75%",
                            height: "75%",
                        }}
                    ></iframe>
                </div>
            </ScreenModal>

            <div className="onboarding_container">
                <div className="onboard_container">
                    <div className="welcome_container">
                        <Paragraph style={{ ...common_paragraph_styles }}>Welcome To Roas!</Paragraph>

                        <Paragraph style={{ ...common_paragraph_styles }}>This is where we’ll help you set everything up.</Paragraph>

                        <Paragraph style={{ ...common_paragraph_styles }}>
                            There are only 4 steps and this whole process should only take 10 minutes or so.
                        </Paragraph>
                    </div>

                    <div className="accordion_container">
                        <ConnectToFacebookAccordion />
                        <AddTrackingToYourAdsAccordion />
                        <AddRoasTrackingCodeAccordion />
                        <AddWebhookAccordion />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingTwo;
