import React from "react";
import { lensPath, set, dissocPath, view } from "ramda";
import { size, truncate, toNumber } from "lodash";
import { CaretDownOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { rule_atom } from "../state/rule";
import { expressions_state } from "../state/expression";
import TriggerDropdown from "./TriggerDropdown";
import TimeframeDropdown from "./TimeframeDropdown";
import PredicateDropdown from "./PredicateDropdown";

let Expression = ({ expression }) => {
    const [rule, setRule] = useAtom(rule_atom);
    const [expressions, setExpressions] = useAtom(expressions_state);

    const onDeleteExpression = () => {
        let condition_path = ["conditions", expression.condition_id];
        let expressions_path = [...condition_path, "expressions"];

        if (size(view(lensPath(expressions_path), rule)) == 1) {
            setRule(dissocPath(condition_path, rule));
        } else {
            setRule(dissocPath(expression.path, rule));
        }
    };

    const onDropdownClick = (dropdown_type) => {
        if (expressions.dropdown_is_open && expression.id == expressions.expression_id) {
            setExpressions({ ...expressions, dropdown_is_open: false, expression_id: null, dropdown_type: null });
        } else {
            setExpressions({ ...expressions, dropdown_is_open: true, expression_id: expression.id, dropdown_type });
        }
    };

    const onUpdateValue = (e) => {
        console.log("onUpdateValue");
        setRule(set(lensPath([...expression.path, "value"]), e.target.value, rule));
    };

    let isMetricDropdownOpen = expressions.dropdown_is_open && expressions.expression_id == expression.id && expressions.dropdown_type == "metric";
    let isTimeframeDropdownOpen =
        expressions.dropdown_is_open && expressions.expression_id == expression.id && expressions.dropdown_type == "timeframe";
    let isPredicateDropdownOpen =
        expressions.dropdown_is_open && expressions.expression_id == expression.id && expressions.dropdown_type == "predicate";

    return (
        <div className="expression_container">
            <div className="expression_metric">
                <div className="select" onClick={() => onDropdownClick("metric")}>
                    <div className="value">{truncate(expression.metric.text, { length: 18 })}</div>
                    <div className="icon">
                        <CaretDownOutlined />
                    </div>
                </div>
                <div className="dropdown">{isMetricDropdownOpen && <TriggerDropdown expression={expression} />}</div>
            </div>

            <div className="expression_timeframe">
                <div className="select" onClick={() => onDropdownClick("timeframe")}>
                    <div className="value">{truncate(expression.timeframe.text, { length: 18 })}</div>
                    <div className="icon">
                        <CaretDownOutlined />
                    </div>
                </div>
                <div className="dropdown">{isTimeframeDropdownOpen && <TimeframeDropdown expression={expression} />}</div>
            </div>

            <div className="expression_predicate">
                <div className="select" onClick={() => onDropdownClick("predicate")}>
                    <div className="value">{expression.predicate.text}</div>
                    <div className="icon">
                        <CaretDownOutlined />
                    </div>
                </div>
                <div className="dropdown">{isPredicateDropdownOpen && <PredicateDropdown expression={expression} />}</div>
            </div>

            <div className="expression_value">
                <input type="text" className="value" value={expression.value} onChange={onUpdateValue} />
            </div>

            <div className="expression_actions">
                <div className="delete">
                    <DeleteOutlined twoToneColor="#eb2f96" onClick={onDeleteExpression} />
                </div>
            </div>
        </div>
    );
};

export default Expression;
