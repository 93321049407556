import React, { useEffect, useRef, useState } from "react";
import { Select, Checkbox } from "antd";
import { defaultTo, pipe, reject, of, head } from "ramda";
import { domPipeLog, lomap } from "../../utilities/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const { io } = require("socket.io-client");
import styled from "styled-components";
import { useAtom } from "jotai";
import { user_atom } from "../state/auth";
import { size, capitalize } from "lodash";
import { from, map as rxmap } from "rxjs";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";
import { atom, useAtom } from "jotai";

const loading_atom = atom(false);

const OrderButton = styled.button`
    background-color: ${(props) => (props.canSubmit ? "#EB072A" : "#ccc")};
    cursor: ${(props) => (props.canSubmit ? "pointer" : "not-allowed")};
`;

const OrderElm = () => {
    const [user] = useAtom(user_atom);
    const formRef = useRef(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [canSubmit, setCanSubmit] = useState(false);
    const navigate = useNavigate();
    // var socket = io.connect("http://localhost:3000");
    var socket = io.connect("https://roas-main-services.herokuapp.com/");
    var [, setIsLoading] = useAtom(loading_atom);
    var [hasError, setHasError] = useState([]);

    socket.on("connect", function () {
        console.log("connectedio");

        socket.on("recurly_subscription_response", (data) => {
            console.log("recurly_subscription_response");
            console.log(data);
            let { status } = data;

            if (status == "200") {
                console.log("200");
                let doc_ref = doc(db, "accounts", user.uid);
                let payload = { roas_account_status: "active", payment_created_at: Number(moment().format("x")) };
                from(setDoc(doc_ref, payload, { merge: true })).subscribe(() => {
                    setIsLoading(false);
                    setTimeout(() => {
                        navigate("/ads-manager", { replace: true });
                    }, 500);
                });
            }
        });
    });

    const tokenHandler = async (err, token) => {
        if (err) {
            console.log("err");
            console.log(err);
            // handle error using err.code and err.fields
        } else {
            // handle success using token.id
            console.log("rtk");
            // console.log(token);
            let { id: token_id } = token;

            let roas_user_id = user.uid;

            let order_email = pipe(
                of,
                reject((value) => size(value) == 0),
                head,
                defaultTo(user.roas_email)
            )(email);

            let order_first_name = pipe(
                of,
                reject((value) => size(value) == 0),
                head,
                defaultTo(user.roas_first_name)
            )(firstName);

            let order_last_name = pipe(
                of,
                reject((value) => size(value) == 0),
                head,
                defaultTo(user.roas_last_name)
            )(lastName);

            let payload = {
                currency: "USD",
                account: {
                    code: roas_user_id,
                    billingInfo: {
                        tokenId: token_id,
                    },
                    email: order_email,
                },
                subscriptions: [{ planCode: "roas" }],
                // subscriptions: [{ planCode: "penisland" }],
                customer_notes: roas_user_id,
            };

            let meta = {
                roas_user_id,
            };

            socket.emit("recurly_create_subscription", { payload, meta });
        }
    };

    useEffect(() => {
        if (formRef.current) {
            recurly.configure("ewr1-2zrRUxS61PeC7THdn1ME2j");
            const elements = recurly.Elements();
            const cardElement = elements.CardElement();
            cardElement.attach("#recurly-elements");
        }
    }, [formRef]);

    const onSubmit = (event) => {
        event.preventDefault();

        recurly.token(formRef.current, tokenHandler);
        setTimeout(() => {
            setIsLoading(true);
        }, 500);
        // if (canSubmit) {
        // }
    };

    useEffect(() => {}, []);

    // const onConfirmClick = () => {
    //     console.log("onConfirmClick");
    //     setCanSubmit(!canSubmit);
    // };

    const features = [
        "Facebook automation tactics",
        "Custom automations",
        "Dynamic-conditions-based automation",
        "Autonomous Budget Optimizer",
        "Facebook bid control",
        "Facebook Ads Dashboard",
        "Audience segmentation",
        "Automated reporting",
        "ROAS Streams",
    ];

    return (
        <section className="bg-white flex flex-col w-full">
            <div className="px-10 py-5 xl:flex xl:items-center xl:justify-between">
                <div>
                    <h1 className="text-4xl font-extrabold sm:text-5xl sm:tracking-tight">
                        <span className="text-gray-900">Everything you need for </span>
                        <span className="text-red-600">$297 a month</span>
                    </h1>
                    <p className="mt-5 text-xl text-gray-500">Includes every feature we offer + ROAS Streams.</p>
                </div>
            </div>

            <div className="checkout_container flex flex-row">
                <div className="left_section w-[50%] bg-gray-50">
                    <ul role="list" className="divide-y divide-gray-200 divide-solid px-10 py-6">
                        {pipe(
                            lomap((feature, idx) => (
                                <li className="py-4 flex" key={idx}>
                                    <svg
                                        className="flex-shrink-0 h-6 w-6 text-green-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                                </li>
                            ))
                        )(features)}
                    </ul>
                </div>
                <div className="right_section w-[50%] ml-auto">
                    <form className="form_container" ref={formRef} onSubmit={onSubmit}>
                        <div className="container px-4 mx-auto">
                            <div className="px-6 pb-0 overflow-hidden bg-white rounded-t-md">
                                <div className="pb-2 border-b border-gray-100">
                                    <div className="flex flex-wrap items-center justify-between -m-2">
                                        <div className="w-full p-2">
                                            <h2 className="text-gray-900 text-lg font-semibold">Payment Details</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-6 border-b border-solid border-gray-100">
                                    <div className="w-full">
                                        <div className="flex flex-wrap -m-3">
                                            <div className="w-full md:flex-1 p-3">
                                                <div className="flex flex-wrap -m-3">
                                                    <div className="w-full p-3">
                                                        <p className="mb-1.5 font-medium text-base text-gray-800">First Name</p>

                                                        <input
                                                            name="first_name"
                                                            className="w-full px-4 py-2.5 text-base text-gray-900 font-normal outline-none focus:border-green-500 border border-gray-200 rounded-lg shadow-input"
                                                            type="text"
                                                            defaultValue={user.roas_first_name || firstName}
                                                            placeholder="john"
                                                            onChange={(event) => setFirstName(event.target.value)}
                                                            data-recurly="first_name"
                                                        />
                                                    </div>
                                                    <div className="w-full p-3">
                                                        <p className="mb-1.5 font-medium text-base text-gray-800">Last Name</p>

                                                        <input
                                                            name="last_name"
                                                            className="w-full px-4 py-2.5 text-base text-gray-900 font-normal outline-none focus:border-green-500 border border-gray-200 rounded-lg shadow-input"
                                                            type="text"
                                                            placeholder="doe"
                                                            defaultValue={user.roas_last_name || lastName}
                                                            onChange={(event) => setLastName(event.target.value)}
                                                            data-recurly="last_name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-6 border-b border-solid border-gray-100">
                                    <div className="w-full">
                                        <div className="flex flex-wrap -m-3">
                                            <div className="w-full  p-3">
                                                <p className="text-sm text-gray-800 font-semibold">Email Address</p>
                                            </div>
                                            <div className="w-full md:flex-1 p-3">
                                                <input
                                                    className="w-full px-4 py-2.5 text-base text-gray-900 font-normal outline-none focus:border-green-500 border border-gray-200 rounded-lg shadow-input"
                                                    type="text"
                                                    placeholder="johndoe@roas.org"
                                                    defaultValue={user.roas_email || email}
                                                    onChange={(event) => setEmail(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-6 border-solid border-b border-gray-100">
                                    <div className="w-full">
                                        <div className="flex flex-wrap -m-3 mb-2">
                                            <div className="w-full md:flex-1 p-3">
                                                <p className="mb-1.5 font-medium text-base text-gray-800">Card Number</p>
                                                <div className="input_container card">
                                                    <div id="recurly-elements"></div>
                                                    <input type="hidden" name="recurly-token" data-recurly="token"></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6 bg-white bg-opacity-60 rounded-b-md">
                                <div className="w-full">
                                    <div className="flex flex-wrap justify-end -m-1.5">
                                        <div className="w-full p-1.5">
                                            <button className="flex flex-wrap justify-center w-full px-4 py-2 bg-red-500 hover:bg-red-600 font-medium text-sm text-white border border-red-500 rounded-md shadow-button h-[40px]">
                                                <p>Continue using ROAS</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

const Order = () => {
    var [isLoading] = useAtom(loading_atom);

    return (
        <div className="order_view">
            <div className={`${isLoading ? "visible" : "invisible"} loading_container absolute inset-0 bg-white `}>
                <div className="text">Processing Payment</div>
                <div className="icon">
                    <LoadingOutlined />
                </div>
            </div>
            <div className="header_section_container">
                <div className="_header_container flex flex-col h-full items-center justify-center">
                    <div className="logo_container">
                        <p style={{ fontSize: "30px" }}>
                            <span style={{ fontWeight: "bold" }}>ROAS</span>
                            <span style={{ fontWeight: "bold", color: "#eb072a" }}>.org</span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="main_section_container">
                <OrderElm />
            </div>
        </div>
    );
};

export default Order;
