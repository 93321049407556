import React from "react";
import { useAtom } from "jotai";
import { rule_state, rule_atom } from "../state/rule";
import ScopesDropdown from "./ScopesDropdown";
import { useParams } from "react-router-dom";
import { capitalize, get as lodashget } from "lodash";
import { flip, path, defaultTo } from "ramda";

const loget = flip(lodashget);

const RuleScope = () => {
    const [rule, setRule] = useAtom(rule_atom);
    const [ruleState, setRuleState] = useAtom(rule_state);
    let { rule_id } = useParams();

    const onDropdownClick = () => {
        if (ruleState.scope_dropdown_is_open) {
            setRuleState({ ...ruleState, scope_dropdown_is_open: false });
        } else {
            setRuleState({ ...ruleState, scope_dropdown_is_open: true });
        }
    };

    let isScopeDropdownOpen = ruleState.scope_dropdown_is_open;

    let icon_map = {
        campaigns: "C",
        adsets: "A",
        ads: "a",
    };

    let scope = defaultTo("campaigns", path(["scope"], rule));

    let Icon = icon_map[scope];

    return (
        <div className="action_container" onClick={onDropdownClick}>
            <div className="icon_container">{Icon}</div>
            <div className="scope_description_container">
                <div className="text">{capitalize(scope)}</div>
            </div>

            {isScopeDropdownOpen && <ScopesDropdown />}
        </div>
    );
};

export default RuleScope;
