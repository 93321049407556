import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Input, Select, InputNumber, DatePicker, TimePicker, Checkbox, Button, Tooltip } from "antd";
const { TextArea } = Input;
const { Option } = Select;
import {
    PlayCircleOutlined,
    FolderOpenOutlined,
    AppstoreOutlined,
    FileOutlined,
    FileImageOutlined,
    VideoCameraOutlined,
    PlusOutlined,
    EllipsisOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { lohasin, lokeyby, lomap, lotruncate } from "../../utilities/helpers";
import moment from "moment";
// import imageToBase64 from "image-to-base64";
import { ad_launcher_left_nav_atom, ad_launcher_asset_atom, ad_launcher_view_state_atom } from "../state/ad_launcher";
import { get } from "shades";
import { pipe, head, zip, prop, values, omit } from "ramda";
import { db, httpsCallable, functions } from "../firebase";
import { getDocs, where, query, collectionGroup, collection, setDoc, doc, arrayUnion } from "firebase/firestore";
import { from, map as rxmap, zip, concatMap } from "rxjs";
import { matching, get, mod } from "shades";
import { isEmpty } from "lodash";
import { user_atom } from "../state/auth";
const { getCodeList } = require("country-list");
var languages = require("language-list")();
import ImageUploading from "react-images-uploading";

const fbapi = httpsCallable(functions, "fb_api");

const CampaignAuctionOptions = () => {
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);

    const onSelectObjective = (objective) => {
        setAdLauncher({ ...adLauncher, objective });
    };

    return (
        <div className="content">
            <div className="objective_container">
                <div className="tite">Awareness</div>
                <div className="options">
                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="BRAND_AWARENESS"
                                defaultChecked={adLauncher.objective == "BRAND_AWARENESS"}
                                onClick={() => onSelectObjective("BRAND_AWARENESS")}
                            />
                        </div>
                        <div className="text">Brand Awareness</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="REACH"
                                defaultChecked={adLauncher.objective == "REACH"}
                                onClick={() => onSelectObjective("REACH")}
                            />
                        </div>
                        <div className="text">Reach</div>
                    </div>
                </div>
            </div>
            <div className="objective_container">
                <div className="tite">Consideration</div>
                <div className="options">
                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="OUTCOME_TRAFFIC"
                                defaultChecked={adLauncher.objective == "OUTCOME_TRAFFIC"}
                                onClick={() => onSelectObjective("OUTCOME_TRAFFIC")}
                            />
                        </div>
                        <div className="text">Traffic</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="POST_ENGAGEMENT"
                                defaultChecked={adLauncher.objective == "POST_ENGAGEMENT"}
                                onClick={() => onSelectObjective("POST_ENGAGEMENT")}
                            />
                        </div>
                        <div className="text">Engagement</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="APP_INSTALLS"
                                defaultChecked={adLauncher.objective == "APP_INSTALLS"}
                                onClick={() => onSelectObjective("APP_INSTALLS")}
                            />
                        </div>
                        <div className="text">App Installs</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="VIDEO_VIEWS"
                                defaultChecked={adLauncher.objective == "VIDEO_VIEWS"}
                                onClick={() => onSelectObjective("VIDEO_VIEWS")}
                            />
                        </div>
                        <div className="text">Video Views</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="LEAD_GENERATION"
                                defaultChecked={adLauncher.objective == "LEAD_GENERATION"}
                                onClick={() => onSelectObjective("LEAD_GENERATION")}
                            />
                        </div>
                        <div className="text">Lead Generation</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="MESSAGES"
                                defaultChecked={adLauncher.objective == "MESSAGES"}
                                onClick={() => onSelectObjective("MESSAGES")}
                            />
                        </div>
                        <div className="text">Messages</div>
                    </div>
                </div>
            </div>
            <div className="objective_container">
                <div className="tite">Conversion</div>
                <div className="options">
                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="CONVERSIONS"
                                defaultChecked={adLauncher.objective == "CONVERSIONS"}
                                onClick={() => onSelectObjective("CONVERSIONS")}
                            />
                        </div>
                        <div className="text">Conversions</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="PRODUCT_CATALOG_SALES"
                                onClick={() => onSelectObjective("PRODUCT_CATALOG_SALES")}
                                defaultChecked={adLauncher.objective == "PRODUCT_CATALOG_SALES"}
                            />
                        </div>
                        <div className="text">Catalog sales</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input
                                type="radio"
                                name="objective"
                                value="STORE_VISITS"
                                onClick={() => onSelectObjective("STORE_VISITS")}
                                defaultChecked={adLauncher.objective == "STORE_VISITS"}
                            />
                        </div>
                        <div className="text">Store traffic</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CampaignReachAndFrequencyOptions = () => {
    return (
        <div className="content">
            <div className="objective_container">
                <div className="tite">Awareness</div>
                <div className="options">
                    <div className="option_row">
                        <div className="radio">
                            <input type="radio" name="objective" value="awareness" />
                        </div>
                        <div className="text">Brand Awareness</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input type="radio" name="objective" value="reach" />
                        </div>
                        <div className="text">Reach</div>
                    </div>
                </div>
            </div>
            <div className="objective_container">
                <div className="tite">Consideration</div>
                <div className="options">
                    <div className="option_row">
                        <div className="radio">
                            <input type="radio" name="objective" value="traffic" />
                        </div>
                        <div className="text">Traffic</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input type="radio" name="objective" value="engagement" />
                        </div>
                        <div className="text">Engagement</div>
                    </div>

                    <div className="option_row">
                        <div className="radio">
                            <input type="radio" name="objective" value="video_views" />
                        </div>
                        <div className="text">Video Views</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CampaignObjectives = () => {
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);

    return (
        <div className="campaign_objective_container">
            {adLauncher.buying_type == "AUCTION" && <CampaignAuctionOptions />}
            {adLauncher.buying_type == "RESERVED" && <CampaignReachAndFrequencyOptions />}
        </div>
    );
};

const CampaignBudgetOptimizationOptions = () => {
    return (
        <div className="campaign_budget_settings_container">
            <div className="content_container">
                <div className="content_header">
                    <div className="title">Budget</div>
                </div>
                <div className="content_body_container">
                    <div className="budget_type">
                        <Select defaultValue="daily" size="large" className="select_container">
                            <Option value="daily">Daily Budget</Option>
                            <Option value="lifetime_budget">Lifetime Budget</Option>
                        </Select>
                    </div>

                    <div className="budget_amount">
                        <InputNumber min={1} defaultValue={1} size="large" />
                    </div>
                </div>
            </div>

            <div className="content_container">
                <div className="content_header">
                    <div className="title">Campaign bid strategy</div>
                </div>

                <div className="select_container">
                    <Select defaultValue="highest_value_or_lowest_cost" size="large">
                        <Option value="highest_value_or_lowest_cost">Highest value or lowest cost</Option>
                        <Option value="cost_cap">Cost cap</Option>
                        <Option value="minimum_roas">Minimum ROAS</Option>
                    </Select>
                </div>
            </div>
        </div>
    );
};

const CreateCampaignFooterActions = () => {
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);
    const [user, setUser] = useAtom(user_atom);
    const { fb_ad_account_id, access_token } = user;

    const onCreateAndPublishCampaign = () => {
        console.log("onCreateAndPublishCampaign");

        let create_campaign_payload = {
            campaign: {
                path: ["campaign"],
                params: {
                    action: "create",
                    name: adLauncher.asset.campaign_name,
                    buying_type: "AUCTION",
                    objective: "CONVERSIONS",
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                    // daily_budget: 3500,
                    // bid_strategy: "LOWEST_COST_WITHOUT_CAP",
                    // pacing_type: ["standard"],
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: create_campaign_payload })).subscribe((value) => {
            console.log("onCreateAndPublishCampaignResponse");
            console.log(value);
        });
    };

    const onCreateCampaign = () => {
        console.log("onCreateCampaign");

        let create_campaign_payload = {
            campaign: {
                path: ["campaign"],
                params: {
                    action: "create",
                    name: adLauncher.campaign_name,
                    buying_type: "AUCTION",
                    objective: "CONVERSIONS",
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                    // daily_budget: 3500,
                    // bid_strategy: "LOWEST_COST_WITHOUT_CAP",
                    // pacing_type: ["standard"],
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: create_campaign_payload }))
            .pipe(
                rxmap(pipe(prop("data"))),
                rxmap(head),
                concatMap((campaign) => {
                    let get_campaign_payload = {
                        campaign: {
                            path: ["campaign"],
                            params: {
                                action: "get",
                                campaign_id: campaign.id,
                                time_range: {
                                    since: moment().format("YYYY-MM-DD"),
                                    until: moment().format("YYYY-MM-DD"),
                                },
                                fields: [],
                            },
                        },
                    };

                    return from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "get", payload: get_campaign_payload }));
                }),
                rxmap(get("data", "campaign")),
                rxmap((asset) => ({
                    ...asset,
                    type: "campaign",
                    is_cbo: asset.daily_budget ? true : false,
                    user_id: user.uid,
                    fb_ad_account_id,
                    account_id: asset.account_id,
                    asset_id: asset.campaign_id,
                    asset_name: asset.name,
                    campaign_id: asset.campaign_id,
                    campaign_name: asset.name,
                    labels: { status: "draft" },
                }))
            )
            .subscribe((campaign) => {
                let { campaign_id } = campaign;

                let payload = {
                    ...campaign,
                    user_ids: arrayUnion(user.uid),
                    date: arrayUnion(moment().format("YYYY-MM-DD")),
                };

                let doc_ref = doc(db, "campaigns", campaign_id, "asset", campaign_id);

                setDoc(doc_ref, payload, { merge: true }).then(() => {
                    console.log(`campaign ${campaign_id} saved`);
                    console.log(campaign);
                });
            });
    };

    return (
        <div className="footer_actions_container">
            <div className="create_campaign_button">
                <Button type="default" size="large" onClick={onCreateAndPublishCampaign}>
                    Create & Publish Campaign
                </Button>
            </div>
            <div className="create_campaign_button">
                <Button type="primary" size="large" onClick={onCreateCampaign}>
                    Create Campaign
                </Button>
            </div>
        </div>
    );
};

const SaveCampaignFooterActions = () => {
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);
    const [user, setUser] = useAtom(user_atom);
    const { fb_ad_account_id, access_token } = user;

    const onUpdateAndPublishCampaign = () => {
        console.log("onCreateAndPublishCampaign");

        let create_campaign_payload = {
            campaign: {
                path: ["campaign"],
                params: {
                    action: "create",
                    name: adLauncher.asset.campaign_name,
                    buying_type: "AUCTION",
                    objective: "CONVERSIONS",
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                    // daily_budget: 3500,
                    // bid_strategy: "LOWEST_COST_WITHOUT_CAP",
                    // pacing_type: ["standard"],
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: create_campaign_payload })).subscribe((value) => {
            console.log("onCreateAndPublishCampaignResponse");
            console.log(value);
        });
    };

    const onUpdateCampaign = () => {
        console.log("onUpdateCampaign");

        let update_campaign_payload = {
            campaign: {
                path: ["campaign"],
                params: {
                    action: "update",
                    campaign_id: adLauncher.campaign_id,
                    name: adLauncher.campaign_name,
                    objective: adLauncher.objective,
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: update_campaign_payload }))
            .pipe(rxmap(pipe(prop("data"))), rxmap(head))
            .subscribe(() => {
                let payload = {
                    campaign_id: adLauncher.campaign_id,
                    name: adLauncher.campaign_name,
                    objective: adLauncher.objective,
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                    date: arrayUnion(moment().format("YYYY-MM-DD")),
                };

                let doc_ref = doc(db, "campaigns", adLauncher.campaign_id, "asset", adLauncher.campaign_id);

                setDoc(doc_ref, payload, { merge: true }).then(() => {
                    console.log(`campaign ${adLauncher.campaign_id} updated`);
                });
            });
    };

    return (
        <div className="footer_actions_container">
            <div className="create_campaign_button">
                <Button type="default" size="large" onClick={onUpdateAndPublishCampaign}>
                    Update & Publish Campaign
                </Button>
            </div>
            <div className="create_campaign_button">
                <Button type="primary" size="large" onClick={onUpdateCampaign}>
                    Update Campaign
                </Button>
            </div>
        </div>
    );
};

const CampaignSettings = () => {
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);
    const [user, setUser] = useAtom(user_atom);

    useEffect(() => {
        if (adLauncher.campaign_id !== "") {
            from(getDocs(query(collectionGroup(db, "asset"), where("campaign_id", "==", adLauncher.campaign_id), where("type", "==", "campaign"))))
                .pipe(
                    rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                    rxmap(head)
                )
                .subscribe((campaign) => {
                    let asset = { ...campaign, budget_type: campaign.is_cbo ? "cbo" : "abo" };
                    setAdLauncher({ ...adLauncher, ...asset });
                });
        }
    }, [adLauncher.campaign_id]);

    const onSelectBuyingType = (buying_type) => {
        setAdLauncher({ ...adLauncher, buying_type });
    };

    const onSelectBudgetType = (budget_type) => {
        setAdLauncher({ ...adLauncher, budget_type, is_cbo: budget_type == "cbo", is_abo: budget_type == "abo" });
    };

    const onSetCampaignName = ({ target: { value } }) => {
        setAdLauncher(mod("campaign_name")((prev) => value)(adLauncher));
    };

    return (
        <div className="ad_creation_container">
            <div className="content">
                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Campaign Name</div>
                    </div>
                    <div className="content">
                        <div className="asset_name_container">
                            <Input size="large" placeholder="Name" value={adLauncher.campaign_name} onChange={onSetCampaignName} />
                        </div>
                    </div>
                </div>

                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Buying Type</div>
                        <div className="header_action">
                            <PlayCircleOutlined />
                        </div>
                    </div>
                    <div className="content">
                        <div className="budget_type_container">
                            <Select value={adLauncher.buying_type} size="large" onChange={onSelectBuyingType}>
                                <Option value="AUCTION">Auction</Option>
                                <Option value="RESERVED">Reach & Frequency</Option>
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Choose Objective</div>
                        <div className="header_action">
                            <PlayCircleOutlined />
                        </div>
                    </div>

                    <CampaignObjectives />
                </div>

                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Budget Optimization</div>
                        <div className="header_action">
                            <PlayCircleOutlined />
                        </div>
                    </div>

                    <div className="content">
                        <div className="budget_type_container">
                            <Select defaultValue="abo" value={adLauncher.budget_type} size="large" onChange={onSelectBudgetType}>
                                <Option value="cbo">Campaign Budget Optimization</Option>
                                <Option value="abo">Adset Budget Optimization</Option>
                            </Select>
                        </div>
                    </div>

                    {adLauncher.budget_type == "cbo" && <CampaignBudgetOptimizationOptions />}
                </div>
            </div>
            <div className="footer">
                {adLauncher.campaign_id == "" && <CreateCampaignFooterActions />}
                {adLauncher.campaign_id !== "" && <SaveCampaignFooterActions />}
            </div>
        </div>
    );
};

const CreateAdsetFooterActions = () => {
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);
    const [user, setUser] = useAtom(user_atom);
    const { fb_ad_account_id, access_token } = user;
    const [leftNav, setLeftNav] = useAtom(ad_launcher_left_nav_atom);

    const onCreateAndPublishCampaign = () => {
        console.log("onCreateAndPublishCampaign");

        let create_campaign_payload = {
            campaign: {
                path: ["campaign"],
                params: {
                    action: "create",
                    name: adLauncher.asset.campaign_name,
                    buying_type: "AUCTION",
                    objective: "CONVERSIONS",
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                    // daily_budget: 3500,
                    // bid_strategy: "LOWEST_COST_WITHOUT_CAP",
                    // pacing_type: ["standard"],
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: create_campaign_payload })).subscribe((value) => {
            console.log("onCreateAndPublishCampaignResponse");
            console.log(value);
        });
    };

    const onCreateAdset = () => {
        const create_adset_payload = {
            adset: {
                path: ["adset"],
                params: {
                    action: "create",
                    campaign_id: adLauncher.campaign_id,
                    status: "PAUSED",
                    name: adLauncher.name,
                    optimization_goal: "REACH",
                    billing_event: "IMPRESSIONS",
                    bid_amount: 2,
                    daily_budget: 1000,
                    targeting: { geo_locations: { countries: ["VE"] }, interests: [{ id: 6003504016795, name: "Dunkin' Donuts" }] },
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: create_adset_payload }))
            .pipe(
                rxmap(pipe(prop("data"))),
                rxmap(head),
                concatMap((adset) => {
                    let get_adset_payload = {
                        adset: {
                            path: ["adset"],
                            params: {
                                action: "get",
                                adset_id: adset.id,
                                time_range: {
                                    since: moment().format("YYYY-MM-DD"),
                                    until: moment().format("YYYY-MM-DD"),
                                },
                                fields: [],
                            },
                        },
                    };

                    return from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: get_adset_payload }));
                }),
                rxmap(get("data", 0, "data")),
                rxmap((asset) => ({
                    ...asset,
                    type: "adset",
                    is_abo: asset.daily_budget ? true : false,
                    user_id: user.uid,
                    fb_ad_account_id,
                    account_id: asset.account_id,
                    asset_id: asset.adset_id,
                    asset_name: asset.name,
                    campaign_id: adLauncher.campaign_id,
                    adset_id: asset.adset_id,
                    adset_name: asset.name,
                    labels: { status: "draft" },
                })),
                concatMap((adset) => {
                    let get_campaign_payload = {
                        campaign: {
                            path: ["campaign"],
                            params: {
                                action: "get",
                                campaign_id: adset.campaign_id,
                                time_range: {
                                    since: moment().format("YYYY-MM-DD"),
                                    until: moment().format("YYYY-MM-DD"),
                                },
                                fields: [],
                            },
                        },
                    };

                    return from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: get_campaign_payload })).pipe(
                        rxmap(get("data", 0, "data")),
                        rxmap((campaign) => {
                            let { name: campaign_name } = campaign;
                            return { ...adset, campaign_name };
                        })
                    );
                })
            )
            .subscribe((adset) => {
                let { adset_id } = adset;

                let payload = {
                    ...adset,
                    user_ids: arrayUnion(user.uid),
                    date: arrayUnion(moment().format("YYYY-MM-DD")),
                };

                let doc_ref = doc(db, "adset", adset_id, "asset", adset_id);

                setDoc(doc_ref, payload, { merge: true }).then(() => {
                    console.log(`adset ${adset_id} saved`);
                    // console.log(adset);

                    setLeftNav(pipe(mod("adsets")((prev) => ({ ...prev, [adset_id]: adset })))(leftNav));
                });
            });
    };

    return (
        <div className="footer_actions_container">
            <div className="create_campaign_button">
                <Button type="default" size="large" onClick={onCreateAndPublishCampaign}>
                    Create & Publish Ad Setdd
                </Button>
            </div>
            <div className="create_campaign_button">
                <Button type="primary" size="large" onClick={onCreateAdset}>
                    Create Ad Set
                </Button>
            </div>
        </div>
    );
};

const SaveAdsetFooterActions = () => {
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);
    const [user, setUser] = useAtom(user_atom);
    const { fb_ad_account_id, access_token } = user;

    const onUpdateAndPublishCampaign = () => {
        console.log("onCreateAndPublishCampaign");

        let create_campaign_payload = {
            campaign: {
                path: ["campaign"],
                params: {
                    action: "create",
                    name: adLauncher.asset.campaign_name,
                    buying_type: "AUCTION",
                    objective: "CONVERSIONS",
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                    // daily_budget: 3500,
                    // bid_strategy: "LOWEST_COST_WITHOUT_CAP",
                    // pacing_type: ["standard"],
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: create_campaign_payload })).subscribe((value) => {
            console.log("onCreateAndPublishCampaignResponse");
            console.log(value);
        });
    };

    const onUpdateCampaign = () => {
        console.log("onUpdateCampaign");

        let update_campaign_payload = {
            campaign: {
                path: ["campaign"],
                params: {
                    action: "update",
                    campaign_id: adLauncher.campaign_id,
                    name: adLauncher.campaign_name,
                    objective: adLauncher.objective,
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: update_campaign_payload }))
            .pipe(rxmap(pipe(prop("data"))), rxmap(head))
            .subscribe(() => {
                let payload = {
                    campaign_id: adLauncher.campaign_id,
                    name: adLauncher.campaign_name,
                    objective: adLauncher.objective,
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                    date: arrayUnion(moment().format("YYYY-MM-DD")),
                };

                let doc_ref = doc(db, "campaigns", adLauncher.campaign_id, "asset", adLauncher.campaign_id);

                setDoc(doc_ref, payload, { merge: true }).then(() => {
                    console.log(`campaign ${adLauncher.campaign_id} updated`);
                });
            });
    };

    return (
        <div className="footer_actions_container">
            <div className="create_campaign_button">
                <Button type="default" size="large" onClick={onUpdateAndPublishCampaign}>
                    Update & Publish Ad Set
                </Button>
            </div>
            <div className="create_campaign_button">
                <Button type="primary" size="large" onClick={onUpdateCampaign}>
                    Update Ad Set
                </Button>
            </div>
        </div>
    );
};

const CreateAdFooterActions = () => {
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);
    const [user, setUser] = useAtom(user_atom);
    const { fb_ad_account_id, access_token } = user;
    const [leftNav, setLeftNav] = useAtom(ad_launcher_left_nav_atom);

    const onCreateAndPublishCampaign = () => {
        console.log("onCreateAndPublishCampaign");

        let create_campaign_payload = {
            campaign: {
                path: ["campaign"],
                params: {
                    action: "create",
                    name: adLauncher.asset.campaign_name,
                    buying_type: "AUCTION",
                    objective: "CONVERSIONS",
                    status: "PAUSED",
                    special_ad_categories: ["NONE"],
                    // daily_budget: 3500,
                    // bid_strategy: "LOWEST_COST_WITHOUT_CAP",
                    // pacing_type: ["standard"],
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: create_campaign_payload })).subscribe((value) => {
            console.log("onCreateAndPublishCampaignResponse");
            console.log(value);
        });
    };

    const onCreateAd = () => {
        console.log("onCreateAd");

        let create_ad_payload = {
            ad: {
                path: ["ad"],
                params: {
                    action: "create",
                    name: adLauncher.name,
                    adset_id: "23850723472650006",
                    creative: {
                        creative_id: "23850724389480006",
                    },
                    tracking_specs: [
                        {
                            "action.type": ["offsite_conversion"],
                            fb_pixel: ["598156393592577"],
                        },
                    ],
                    status: "PAUSED",
                },
            },
        };

        from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: create_ad_payload }))
            .pipe(
                rxmap(pipe(prop("data"))),
                rxmap(head),
                concatMap((ad) => {
                    let get_ad_payload = {
                        ad: {
                            path: ["ad"],
                            params: {
                                action: "get",
                                ad_id: ad.id,
                                time_range: {
                                    since: moment().format("YYYY-MM-DD"),
                                    until: moment().format("YYYY-MM-DD"),
                                },
                                fields: [],
                            },
                        },
                    };

                    return from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: get_ad_payload }));
                }),
                rxmap(get("data", 0, "data")),
                rxmap((asset) => ({
                    ...asset,
                    user_id: user.uid,
                    type: "ad",
                    fb_ad_account_id,
                    account_id: asset.account_id,
                    asset_id: asset.ad_id,
                    asset_name: asset.name,
                    campaign_id: asset.campaign_id,
                    adset_id: asset.adset_id,
                    ad_id: asset.ad_id,
                    ad_name: asset.name,
                    labels: { status: "draft" },
                })),
                concatMap((ad) => {
                    let get_campaign_payload = {
                        campaign: {
                            path: ["campaign"],
                            params: {
                                action: "get",
                                campaign_id: ad.campaign_id,
                                time_range: {
                                    since: moment().format("YYYY-MM-DD"),
                                    until: moment().format("YYYY-MM-DD"),
                                },
                                fields: [],
                            },
                        },
                    };

                    let get_adset_payload = {
                        adset: {
                            path: ["adset"],
                            params: {
                                action: "get",
                                adset_id: ad.adset_id,
                                time_range: {
                                    since: moment().format("YYYY-MM-DD"),
                                    until: moment().format("YYYY-MM-DD"),
                                },
                                fields: [],
                            },
                        },
                    };

                    let campaign = from(
                        fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: get_campaign_payload })
                    ).pipe(
                        rxmap(get("data", 0, "data")),
                        rxmap((campaign) => {
                            let { name: campaign_name } = campaign;
                            return campaign_name;
                        })
                    );

                    let adset = from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: get_adset_payload })).pipe(
                        rxmap(get("data", 0, "data")),
                        rxmap((adset) => {
                            let { name: adset_name } = adset;
                            return adset_name;
                        })
                    );

                    return zip([campaign, adset]).pipe(rxmap(([campaign_name, adset_name]) => ({ ...ad, campaign_name, adset_name })));
                })
            )
            .subscribe((ad) => {
                console.log("ad");
                // console.log(ad);
                let { ad_id, adset_id } = ad;

                let payload = {
                    ...ad,
                    user_ids: arrayUnion(user.uid),
                    date: arrayUnion(moment().format("YYYY-MM-DD")),
                };

                let doc_ref = doc(db, "ad", ad_id, "asset", ad_id);

                setDoc(doc_ref, payload, { merge: true }).then(() => {
                    console.log(`ad ${ad_id} saved`);
                    // console.log(adset);

                    setLeftNav(pipe(mod("adsets", adset_id, "ads")((prev) => ({ ...prev, [ad_id]: ad })))(leftNav));
                });
            });
    };

    return (
        <div className="footer_actions_container">
            <div className="create_campaign_button">
                <Button type="default" size="large" onClick={onCreateAndPublishCampaign}>
                    Create & Publish Ad
                </Button>
            </div>
            <div className="create_campaign_button">
                <Button type="primary" size="large" onClick={onCreateAd}>
                    Create Ad
                </Button>
            </div>
        </div>
    );
};

const AdsetSettings = () => {
    const [adLauncherViewState, setAdLauncherViewState] = useAtom(ad_launcher_view_state_atom);
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);
    const [user, setUser] = useAtom(user_atom);
    const { fb_ad_account_id, access_token } = user;
    const [businessPixels, setBusinessPixels] = useState([]);
    const [interests, setInterests] = useState([]);

    useEffect(() => {
        let interests_query = query(collectionGroup(db, "interests"), where("user_id", "==", user.uid));

        from(getDocs(interests_query))
            .pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(lokeyby("interest_id"))
            )
            .subscribe((interests_db_results) => {
                setInterests({ ...interests, ...interests_db_results });
            });
    }, []);

    useEffect(() => {
        if (adLauncherViewState.view_id == "adset" && fb_ad_account_id && access_token) {
            const business_id_payload = {
                business: {
                    path: ["business"],
                    params: {
                        action: "get",
                    },
                },
            };

            const business_pixels_payload = {
                business: {
                    path: ["business"],
                    params: {
                        action: "owned_pixels",
                        fields: ["name", "id"],
                    },
                },
            };

            from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: business_id_payload }))
                .pipe(
                    rxmap(pipe(prop("data"))),
                    rxmap(head),
                    concatMap((business) => {
                        let { id: business_id } = business;

                        return from(
                            fbapi({
                                credentials: { fb_ad_account_id, access_token },
                                method: "post",
                                payload: pipe(mod("business", "params")((prev) => ({ ...prev, business_id })))(business_pixels_payload),
                            })
                        );
                    }),
                    rxmap(pipe(prop("data"))),
                    rxmap(head)
                )
                .subscribe((result) => {
                    setBusinessPixels(result);
                });
        }
    }, [adLauncherViewState.view_id, user.fb_ad_account_id, user.access_token]);

    const onSetAdsetName = ({ target: { value } }) => {
        setAdLauncher({ ...adLauncher, name: value });
    };

    return (
        <div className="ad_creation_container">
            <div className="content">
                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Adset Name</div>
                    </div>
                    <div className="content">
                        <div className="asset_name_container">
                            <Input size="large" placeholder="Name" onChange={onSetAdsetName} value={adLauncher.name} />
                        </div>
                    </div>
                </div>

                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Conversion & Optimization</div>
                        <div className="header_action">
                            <PlayCircleOutlined />
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Pixel</div>
                        </div>
                        <div className="content_body_container">
                            <Select size="large" className="select_container" style={{ width: "100%" }}>
                                {lomap(
                                    (pixel) => (
                                        <Option value={pixel.id} key={pixel.id}>
                                            {pixel.name}
                                        </Option>
                                    ),
                                    businessPixels
                                )}
                            </Select>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Conversion Event</div>
                        </div>
                        <div className="content_body_container">
                            <Select defaultValue="IMPRESSIONS" size="large" className="select_container" style={{ width: "100%" }}>
                                <Option value="APP_INSTALLS">App Installs</Option>
                                <Option value="CLICKS">Clicks</Option>
                                <Option value="IMPRESSIONS">Impressions</Option>
                                <Option value="LINK_CLICKS">Link Clicks</Option>
                                <Option value="OFFER_CLAIMS">Offer Claims</Option>
                                <Option value="PAGE_LIKES">Page Likes</Option>
                                <Option value="POST_ENGAGEMENT">Post Engagement</Option>
                                <Option value="VIDEO_VIEWS">Video Views</Option>
                                <Option value="THRUPLAY">Thruplay</Option>
                            </Select>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Optimization For Ad Delivery</div>
                        </div>
                        <div className="content_body_container">
                            <Select defaultValue="IMPRESSIONS" size="large" className="select_container" style={{ width: "100%" }}>
                                <Option value="NONE">None</Option>
                                <Option value="APP_INSTALLS">App Installs</Option>
                                <Option value="AD_RECALL_LIFT">Ad Recall Lift</Option>
                                <Option value="CLICKS">Clicks</Option>
                                <Option value="ENGAGED_USERS">Engaged Users</Option>
                                <Option value="EVENT_RESPONSES">Event Responses</Option>
                                <Option value="IMPRESSIONS">Impressions</Option>
                                <Option value="LEAD_GENERATION">Lead Generation</Option>
                                <Option value="QUALITY_LEAD">Quality Leads</Option>
                                <Option value="LINK_CLICKS">Link Clicks</Option>
                                <Option value="OFFSITE_CONVERSIONS">Offsite Conversiosn</Option>
                                <Option value="PAGE_LIKES">Page Likes</Option>
                                <Option value="POST_ENGAGEMENT">Post Engagement</Option>
                                <Option value="QUALITY_CALL">Quality Call</Option>
                                <Option value="REACH">Reach</Option>
                                <Option value="LANDING_PAGE_VIEWS">Landing Page Views</Option>
                                <Option value="VISIT_INSTAGRAM_PROFILE">Visit Instagram Profile</Option>
                                <Option value="VALUE">Value</Option>
                                <Option value="THRUPLAY">Thruplay</Option>
                                <Option value="DERIVED_EVENTS">Derived Events</Option>
                                <Option value="APP_INSTALLS_AND_OFFSITE_CONVERSIONS">App Installs And Offiste Conversions</Option>
                                <Option value="CONVERSATIONS">Conversations</Option>
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Audience</div>
                        <div className="header_action">
                            <PlayCircleOutlined />
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Custom Audiences</div>
                        </div>
                        <div className="content_body_container">
                            <Select size="large" className="select_container" style={{ width: "100%" }}>
                                {lomap(
                                    (interest) => (
                                        <Option value={interest.interest_id} key={interest.interest_id}>
                                            {interest.interest_name}
                                        </Option>
                                    ),
                                    interests
                                )}
                            </Select>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Locations</div>
                        </div>
                        <div className="content_body_container">
                            <Select size="large" className="select_container" style={{ width: "100%" }}>
                                {lomap(
                                    (country, country_key) => (
                                        <Option value={country_key} key={country_key}>
                                            {country}
                                        </Option>
                                    ),
                                    getCodeList()
                                )}
                            </Select>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Gender</div>
                        </div>
                        <div className="gender_container">
                            <div className="option">
                                <div className="radio">
                                    <input type="radio" name="gender" value="all" />
                                </div>
                                <div className="text">All</div>
                            </div>
                            <div className="option">
                                <div className="radio">
                                    <input type="radio" name="gender" value="men" />
                                </div>
                                <div className="text">Men</div>
                            </div>
                            <div className="option">
                                <div className="radio">
                                    <input type="radio" name="gender" value="women" />
                                </div>
                                <div className="text">Women</div>
                            </div>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Age</div>
                        </div>
                        <div className="content_body_container">
                            <div className="age_input_container">
                                <InputNumber min={1} max={120} defaultValue={18} />
                            </div>
                            <div className="age_divider_container">-</div>
                            <div className="age_input_container">
                                <InputNumber min={1} max={120} defaultValue={35} />
                            </div>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Language</div>
                        </div>
                        <div className="content_body_container">
                            <Select size="large" className="select_container" style={{ width: "100%" }}>
                                {lomap(
                                    (language) => (
                                        <Option value={language.code} key={language.code}>
                                            {language.language}
                                        </Option>
                                    ),
                                    languages.getData()
                                )}
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Budget & Schedule</div>
                        <div className="header_action">
                            <PlayCircleOutlined />
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Budget</div>
                        </div>
                        <div className="content_body_container">
                            <div className="budget_type">
                                <Select defaultValue="daily" size="large" className="select_container">
                                    <Option value="daily">Daily Budget</Option>
                                    <Option value="lifetime_budget">Lifetime Budget</Option>
                                </Select>
                            </div>

                            <div className="budget_amount">
                                <InputNumber min={1} defaultValue={1} size="large" />
                            </div>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Start Date</div>
                        </div>
                        <div className="content_body_container">
                            <div className="calendar_date_container">
                                <DatePicker size="large" />
                            </div>
                            <div className="age_divider_container">-</div>
                            <div className="age_input_container">
                                <TimePicker defaultOpenValue={moment("00:00:00", "HH:mm:ss")} size="large" />
                            </div>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">End Date</div>
                        </div>
                        <div className="content_body_container">
                            <div className="set_end_date_container">
                                <Checkbox>Set an end date</Checkbox>
                            </div>
                        </div>
                        <div className="content_body_container">
                            <div className="calendar_date_container">
                                <DatePicker size="large" />
                            </div>

                            <div className="age_divider_container">-</div>

                            <div className="age_input_container">
                                <TimePicker defaultOpenValue={moment("00:00:00", "HH:mm:ss")} size="large" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                {!adLauncher.adset_id && <CreateAdsetFooterActions />}
                {adLauncher.adset_id && <SaveAdsetFooterActions />}
            </div>
        </div>
    );
};

const AdSettings = () => {
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);
    const [adLauncherViewState, setAdLauncherViewState] = useAtom(ad_launcher_view_state_atom);
    const [user, setUser] = useAtom(user_atom);
    const [creative, setCreative] = useState([]);
    const [trackingPixels, setTrackingPixels] = useState([]);
    const maxNumber = 1;
    const { fb_ad_account_id, access_token } = user;

    useEffect(() => {
        if (adLauncherViewState.view_id == "ad" && fb_ad_account_id && access_token) {
            const ad_pixels_payload = {
                business: {
                    path: ["business"],
                    params: {
                        action: "ad_pixels",
                        fields: ["name", "id"],
                        business_id: "844295895616999",
                    },
                },
            };

            from(fbapi({ credentials: { fb_ad_account_id, access_token }, method: "post", payload: ad_pixels_payload }))
                .pipe(rxmap(get("data", 0, "data")))
                .subscribe((pixels) => {
                    setTrackingPixels(pixels);
                });
        }
    }, [adLauncherViewState.view_id, user.fb_ad_account_id, user.access_token]);

    const onChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setCreative(imageList);
    };

    const onSetName = ({ target: { value: name } }) => {
        setAdLauncher({ ...adLauncher, name });
    };

    const onSetPrimaryText = ({ target: { value: text } }) => {
        console.log("onSetPrimaryText");
        console.log(text);
    };

    const onSetHeadline = ({ target: { value: text } }) => {
        console.log("onSetHeadline");
        console.log(text);
    };

    const onSetDestinationURL = ({ target: { value: url } }) => {
        console.log("onSetDestinationURL");
        console.log(url);
    };

    const onSetDisplayLink = ({ target: { value: text } }) => {
        console.log("onSetDisplayLink");
        console.log(text);
    };

    const onSetURLParamaters = ({ target: { value: paramaters } }) => {
        console.log("onSetURLParamaters");
        console.log(paramaters);
    };

    return (
        <div className="ad_creation_container">
            <div className="content">
                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Ad Name</div>
                    </div>
                    <div className="content">
                        <div className="asset_name_container">
                            <Input size="large" placeholder="Name" value={adLauncher.ad_name} onChange={onSetName} />
                        </div>
                    </div>
                </div>

                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Ad Creative</div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Media</div>
                        </div>
                        <div className="content_body_container">
                            <ImageUploading multiple value={creative} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                                {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                                    <div className="media_container">
                                        <div className="creative_action_buttons">
                                            {isEmpty(imageList) && (
                                                <div className="action" onClick={onImageUpload} {...dragProps}>
                                                    <div className="icon">
                                                        <FileImageOutlined />
                                                    </div>
                                                    <div className="text">Add Image</div>
                                                </div>
                                            )}

                                            {!isEmpty(imageList) && (
                                                <div className="action" onClick={() => onImageUpdate(0)}>
                                                    <div className="icon">
                                                        <FileImageOutlined />
                                                    </div>
                                                    <div className="text">Update Image</div>
                                                </div>
                                            )}

                                            <div className="action" onClick={onImageRemoveAll}>
                                                <div className="icon">
                                                    <DeleteOutlined />
                                                </div>
                                                <div className="text">Remove</div>
                                            </div>
                                        </div>

                                        <div className="creative_container">
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image.data_url} alt="" style={{ width: "300px" }} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </ImageUploading>
                            {/* 
                            <div className="add_media_button_container">
                                <div className="icon">
                                    <VideoCameraOutlined />
                                </div>
                                <div className="text">Add Video</div>
                            </div> */}
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Headline</div>
                        </div>
                        <div className="content_body_container">
                            <Input placeholder="" size="large" onChange={onSetHeadline} />
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Primary Text</div>
                        </div>
                        <div className="content_body_container">
                            <TextArea rows={4} onChange={onSetPrimaryText} />
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Call to Action</div>
                        </div>
                        <div className="content_body_container">
                            <Select size="large" className="select_container" style={{ width: "100%" }}>
                                <Option value="NO_BUTTON">No Button</Option>
                                <Option value="PLAY_GAME">Play Game</Option>
                                <Option value="REQUEST_TIME">Request Time</Option>
                                <Option value="SEE_MENU">See Menu</Option>
                                <Option value="SHOP_NOW">Shop Now</Option>
                                <Option value="SIGN_UP">Sign Up</Option>
                                <Option value="SUBSCRIBE">Subscribe</Option>
                                <Option value="WATCH_MORE">Watch More</Option>
                                <Option value="GET_SHOWTIMES">Get Showtimes</Option>
                                <Option value="APPLY_NOW">Apply Now</Option>
                                <Option value="BOOK_NOW">Book Now</Option>
                                <Option value="CONTACT_US">Contact Us</Option>
                                <Option value="DONATE_NOW">Donate Now</Option>
                                <Option value="DOWNLOAD">Download</Option>
                                <Option value="GET_OFFER">Get Offer</Option>
                                <Option value="GET_QUOTE">Get Quote</Option>
                                <Option value="LEARN_MORE">Learn More</Option>
                                <Option value="LISTEN_NOW">Listen Now</Option>
                                <Option value="ORDER_NOW">Order Now</Option>
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Destination</div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Website URL</div>
                        </div>
                        <div className="content_body_container">
                            <Input placeholder="" size="large" onChange={onSetDestinationURL} />
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">URL Paramaters</div>
                        </div>
                        <div className="content_body_container">
                            <Input placeholder="" size="large" onChange={onSetURLParamaters} />
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Display Link</div>
                        </div>
                        <div className="content_body_container">
                            <Input placeholder="" size="large" onChange={onSetDisplayLink} />
                        </div>
                    </div>
                </div>

                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Tracking</div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Pixels</div>
                        </div>
                        <div className="content_body_container">
                            <Select size="large" className="select_container" style={{ width: "100%" }}>
                                {lomap(
                                    (pixel) => (
                                        <Option value={pixel.id} key={pixel.id}>
                                            {pixel.name}
                                        </Option>
                                    ),
                                    trackingPixels
                                )}
                            </Select>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Tags</div>
                        </div>
                        <div className="content_body_container">
                            <Input placeholder="" size="large" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                {!adLauncher.ad_id && <CreateAdFooterActions />}
                {adLauncher.ad_id && <CreateAdFooterActions />}
            </div>
        </div>
    );
};

const PublishSettings = () => {
    return (
        <div className="ad_creation_container">
            <div className="content">
                <div className="ad_creation_content_container">
                    <div className="header">
                        <div className="title">Budget & Schedule</div>
                        <div className="header_action">
                            <PlayCircleOutlined />
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Budget</div>
                        </div>
                        <div className="content_body_container">
                            <div className="budget_type">
                                <Select defaultValue="daily" size="large" className="select_container">
                                    <Option value="daily">Daily Budget</Option>
                                    <Option value="lifetime_budget">Lifetime Budget</Option>
                                </Select>
                            </div>

                            <div className="budget_amount">
                                <InputNumber min={1} defaultValue={1} size="large" />
                            </div>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">Start Date</div>
                        </div>
                        <div className="content_body_container">
                            <div className="calendar_date_container">
                                <DatePicker size="large" />
                            </div>
                            <div className="age_divider_container">-</div>
                            <div className="age_input_container">
                                <TimePicker defaultOpenValue={moment("00:00:00", "HH:mm:ss")} size="large" />
                            </div>
                        </div>
                    </div>

                    <div className="content_container">
                        <div className="content_header">
                            <div className="title">End Date</div>
                        </div>
                        <div className="content_body_container">
                            <div className="set_end_date_container">
                                <Checkbox>Set an end date</Checkbox>
                            </div>
                        </div>
                        <div className="content_body_container">
                            <div className="calendar_date_container">
                                <DatePicker size="large" />
                            </div>

                            <div className="age_divider_container">-</div>

                            <div className="age_input_container">
                                <TimePicker defaultOpenValue={moment("00:00:00", "HH:mm:ss")} size="large" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AdLauncherLefNav = () => {
    const [leftNav, setLeftNav] = useAtom(ad_launcher_left_nav_atom);
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);
    const [adLauncherViewState, setAdLauncherViewState] = useAtom(ad_launcher_view_state_atom);

    useEffect(() => {
        if (adLauncher.campaign_id !== "") {
            let campaign = from(
                getDocs(query(collectionGroup(db, "asset"), where("campaign_id", "==", adLauncher.campaign_id), where("type", "==", "campaign")))
            ).pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(head)
            );

            let adsets = from(
                getDocs(query(collectionGroup(db, "asset"), where("campaign_id", "==", adLauncher.campaign_id), where("type", "==", "adset")))
            ).pipe(rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())));

            let ads = from(
                getDocs(query(collectionGroup(db, "asset"), where("campaign_id", "==", adLauncher.campaign_id), where("type", "==", "ad")))
            ).pipe(rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())));

            zip([campaign, adsets, ads])
                .pipe(
                    rxmap(([campaign, adsets, ads]) => {
                        let adsets_payload = pipe(
                            lomap((adset) => {
                                let adset_ads = pipe(get(matching({ adset_id: adset.asset_id })), lokeyby("asset_id"))(ads);
                                return { ...adset, ads: adset_ads };
                            }),
                            lokeyby("asset_id")
                        )(adsets);

                        return { ...campaign, adsets: adsets_payload };
                    })
                )
                .subscribe((assets) => {
                    setLeftNav({ ...leftNav, ...assets });
                });
        }
    }, [adLauncher.campaign_id]);

    const onSelectAd = (adset_id, ad_id) => {
        let ad = pipe(get("adsets", adset_id, "ads", matching({ asset_id: ad_id })), values, head)(leftNav);
        setAdLauncher({ ...ad });
        setAdLauncherViewState({ ...adLauncherViewState, view_id: "ad" });
    };

    const onSelectAdset = (adset_id) => {
        let adset = pipe(get("adsets", matching({ asset_id: adset_id })), values, head)(leftNav);
        setAdLauncher({ ...adLauncher, ...adset });
        setAdLauncherViewState({ ...adLauncherViewState, view_id: "adset" });
    };

    const onSelectCampaign = () => {
        setAdLauncher(pipe(omit(["adsets"]))(leftNav));
        setAdLauncherViewState({ ...adLauncherViewState, view_id: "campaign" });
    };

    if (isEmpty(leftNav)) {
        return (
            <div className="ads_left_nav">
                <div className="left_nav_assets_container">
                    <div className="ad_launcher_nav_campaign_container" key="new_campaign">
                        <div className="campaign_asset_header">
                            <div className="icon">
                                <FolderOpenOutlined />
                            </div>
                            <div className="title">
                                <Tooltip title={leftNav.campaign_name}>{lotruncate({ length: "20" }, leftNav.campaign_name)}</Tooltip>
                            </div>
                            <div className="nav_asset_actions">
                                <div className="action_container">
                                    <PlusOutlined />
                                </div>
                                <div className="action_container">
                                    <EllipsisOutlined />
                                </div>
                            </div>
                        </div>
                        <div className="ad_launcher_nav_adsets_container">
                            <div className="new_adset_container">
                                <div className="icon">
                                    <PlusOutlined />
                                </div>
                                <div className="text">New Adset</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="ads_left_nav">
            <div className="left_nav_assets_container">
                <div className="ad_launcher_nav_campaign_container" key={leftNav.campaign_id}>
                    <div className="campaign_asset_header" onClick={onSelectCampaign}>
                        <div className="icon">
                            <FolderOpenOutlined />
                        </div>
                        <div className="title">
                            <Tooltip title={adLauncherViewState.view_id == "campaign" ? adLauncher.campaign_name : leftNav.campaign_name}>
                                {lotruncate(
                                    { length: "20" },
                                    adLauncherViewState.view_id == "campaign" ? adLauncher.campaign_name : leftNav.campaign_name
                                )}
                            </Tooltip>
                        </div>
                        <div className="nav_asset_actions">
                            <div className="action_container">
                                <PlusOutlined />
                            </div>
                            <div className="action_container">
                                <EllipsisOutlined />
                            </div>
                        </div>
                    </div>
                    <div className="ad_launcher_nav_adsets_container">
                        {lomap(
                            (adset) => (
                                <div className="ad_launcher_nav_adset_container" key={adset.asset_id}>
                                    <div className="adset_asset_header" onClick={() => onSelectAdset(adset.asset_id)}>
                                        <div className="icon">
                                            <AppstoreOutlined />
                                        </div>
                                        <div className="title">
                                            <Tooltip title={adset.name}>{lotruncate({ length: "25" }, adset.name)}</Tooltip>
                                        </div>
                                        <div className="nav_asset_actions">
                                            <div className="action_container">
                                                <PlusOutlined />
                                            </div>
                                            <div className="action_container">
                                                <EllipsisOutlined />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ad_launcher_nav_ads_container">
                                        {lomap(
                                            (ad) => (
                                                <div
                                                    className="ad_launcher_nav_ad_container"
                                                    key={ad.asset_id}
                                                    onClick={() => onSelectAd(ad.adset_id, ad.asset_id)}
                                                >
                                                    <div className="ad_asset">
                                                        <div className="icon">
                                                            <FileOutlined />
                                                        </div>
                                                        <div className="title">
                                                            <Tooltip title={ad.name}>{lotruncate({ length: "25" }, ad.name)}</Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                            adset.ads
                                        )}
                                    </div>
                                </div>
                            ),
                            leftNav.adsets
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const AdCreation = () => {
    const [user, setUser] = useAtom(user_atom);
    const [adLauncherViewState, setAdLauncherViewState] = useAtom(ad_launcher_view_state_atom);

    useEffect(() => {
        if (!user.fb_ad_account_id) {
            let project_query = query(collectionGroup(db, "project_accounts"), where("roas_user_id", "==", user.uid));
            let project = from(getDocs(project_query)).pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(head)
            );

            let facebook_account_query = query(
                collectionGroup(db, "integrations"),
                where("user_id", "==", user.uid),
                where("account_name", "==", "facebook")
            );

            let fb_account = from(getDocs(facebook_account_query)).pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(head)
            );

            zip([fb_account, project])
                .pipe(rxmap(([fb_account, project]) => ({ ...fb_account, ...project })))
                .subscribe((project) => {
                    let { fb_ad_account_id, facebook_account_id, project_id, project_account_id, access_token } = project;
                    setUser({ ...user, fb_ad_account_id, facebook_account_id, project_id, project_account_id, access_token });
                });
        }
    }, []);

    return (
        <div className="ad_launcher_view">
            <div className="view_header">
                <div className="title">Ads Launcher</div>
            </div>

            <div className="ad_launcher_content">
                <AdLauncherLefNav />
                {adLauncherViewState.view_id == "campaign" && <CampaignSettings />}
                {adLauncherViewState.view_id == "adset" && <AdsetSettings />}
                {adLauncherViewState.view_id == "ad" && <AdSettings />}
                {/* <AdsetSettings /> */}
                {/* <AdSettings /> */}
                {/* <PublishSettings /> */}
            </div>
        </div>
    );
};

export default AdCreation;
