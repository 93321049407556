import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { db } from "../firebase";
import { getDocs, query, collection, collectionGroup, query, where, getDocs } from "firebase/firestore";
import RuleUneditable from "../components/RuleUneditable";
import { head, flip } from "ramda";
import { map as lodashmap, sortBy } from "lodash";
import { loorderby } from "../../utilities/helpers";

let losort = flip(sortBy);

const lomap = flip(lodashmap);

const Log = ({ rule, log }) => {
    return (
        <div className="log_container">
            <div className="item_info_container">
                <div className="item_name">{log.name}</div>
                <div className="item_id">
                    <span>ID: </span> {log.item_id}
                </div>
                <div className="campaign_name">
                    <span>Campaign:</span> {log.campaign_id}
                </div>
            </div>

            <div className="rule_info_container">
                <RuleUneditable rule={rule} />
            </div>
        </div>
    );
};

const Logs = () => {
    const [rule, setRule] = useState();
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        getDocs(query(collection(db, "rules"))).then((response) => {
            let rules = response.docs.map((doc) => ({ ...doc.data(), path: doc.id }));
            setRule(head(rules));
        });
    }, []);

    useEffect(() => {
        if (rule) {
            getDocs(collection(db, "rules", rule.id, "logs")).then((data) => {
                let logs = data.docs.map((doc) => ({ ...doc.data(), path: doc.id }));
                setLogs(losort(["automation_created_at_timestamp"], logs));
            });
        }
    }, [rule]);

    if (!rule) return <div></div>;

    return (
        <div className="logs_view">
            <div className="logs_container">
                {lomap(
                    (log, key) => (
                        <Log rule={rule} log={log} key={key} />
                    ),
                    logs
                )}
            </div>
        </div>
    );
};

export default Logs;
