import React from "react";
import { Input } from "antd";
import { SearchOutlined, SaveOutlined, LoadingOutlined, BarChartOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { advertisers_view_state_atom, advertisers_atom } from "../state/advertisers";
import { app_atom } from "../state/app";
import { db, httpsCallable, functions } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { lomap, losortby, lotruncate, toFixed, pipeLog } from "../../utilities/helpers";
import { from, map as rxmap } from "rxjs";
import { prop, defaultTo, pipe, head, pick, reverse, values, mergeDeepRight } from "ramda";
import { truncate, take } from "lodash";
import { get, matching } from "shades";
import ScreenModal from "../components/ScreenModal";
import AudiencesTopNav from "../components/AudiencesTopNav";
import { user_atom } from "../state/auth";
import AdvertiserInsights from "../components/AdvertiserInsights";
import { loreduce } from "../../utilities/helpers";
import moment from "moment";

const interests = httpsCallable(functions, "interests");
const suggestions = httpsCallable(functions, "suggestions");
const advertisersFn = httpsCallable(functions, "advertisers");

const Loading = () => {
    return (
        <div className="loading_view">
            <div className="loading_container">
                <LoadingOutlined />
            </div>
        </div>
    );
};

const Advertisers = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);
    const [advertisersViewState, setAdvertisersViewState] = useAtom(advertisers_view_state_atom);
    const [advertisers, setAdvertisers] = useAtom(advertisers_atom);

    const onSetQuery = ({ target: { value: q } }) => {
        if (q) {
            setAdvertisersViewState({ ...advertisersViewState, q });
        }
    };

    const onSearch = () => {
        // setAdvertisersViewState({ ...advertisersViewState, is_loading: true });

        console.log(advertisersViewState);

        from(advertisersFn({ q: advertisersViewState.q }))
            .pipe(rxmap(prop("data")), rxmap(loreduce((prev, curr) => mergeDeepRight(prev, curr))))
            .subscribe((data) => {
                console.log("data");
                console.log(data);
                setAdvertisersViewState({ ...advertisersViewState, is_loading: false });
                setAdvertisers({ ...advertisers, ...data });
            });
    };

    const onSelectAdvertiser = (advertiser_id) => {
        console.log("onSelectAdvertiser");
        console.log(advertisers);
        let advertiser = pipe(get(matching({ page_id: advertiser_id })))(advertisers);
        setAdvertisersViewState({ ...advertisersViewState, selected_advertiser_id: advertiser_id, selected_advertiser: advertiser });
        setApp({ ...app, modal_is_open: true, modal_id: "advertiser_insights" });
    };

    const onSaveInterest = (interest_id) => {
        let interest = pipe(get(matching({ interest_id })), values, head)(advertisers);

        let payload = {
            ...interest,
            user_id: user.uid,
        };

        // let doc_ref = doc(db, "advertisers", user.uid, "advertisers", interest_id);
        // setDoc(doc_ref, payload, { merge: true }).then(() => {
        //     console.log(`saved interest ${interest_id}`);
        // });
    };

    return (
        <div className="audiences_view">
            {advertisersViewState.is_loading && <Loading />}
            <ScreenModal id="advertiser_insights">
                <AdvertiserInsights />
            </ScreenModal>
            <AudiencesTopNav />

            <div className="audiences_finder_container">
                <div className="search_header_container">
                    <div className="search_container">
                        <div className="search_bar">
                            <Input size="large" onChange={onSetQuery} />
                        </div>

                        <div className="search_icon">
                            <SearchOutlined onClick={onSearch} />
                        </div>
                    </div>
                </div>

                <div className="audience_table_container">
                    <div className="table_container">
                        <div className="audience_table_tabs">
                            <div className="tab_cell">Name</div>
                            <div className="tab_cell">Likes</div>
                            <div className="tab_cell">Ads</div>
                            <div className="tab_cell">Duration</div>
                            <div className="tab_cell">Related Products</div>
                            <div className="tab_cell"></div>
                        </div>
                        <div className="table_rows">
                            {lomap(
                                (interest, advertiser_id) => (
                                    <div className="advertiser_row" key={advertiser_id}>
                                        <div className="advertiser_cell name_audience_cell" onClick={() => onSelectAdvertiser(advertiser_id)}>
                                            <div className="logo">
                                                <img src={interest.logo_url} style={{ width: "40px" }} />
                                            </div>
                                            <div className="name">{interest.page_name}</div>
                                        </div>
                                        <div className="advertiser_cell">
                                            {pipe(prop("likes"), Number, (value) => value.toLocaleString())(interest)}
                                        </div>
                                        <div className="advertiser_cell">
                                            {pipe(prop("ads"), Number, (value) => value.toLocaleString())(interest)}
                                        </div>
                                        <div className="advertiser_cell">
                                            <div className="duration_column">
                                                <div className="duration_row">
                                                    {pipe(prop("first_seen_min"), (value) => moment(value).format("YYYY-MM-DD"))(interest)}
                                                </div>
                                                <div className="duration_row">
                                                    {pipe(prop("first_seen_max"), (value) => moment(value).format("YYYY-MM-DD"))(interest)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="advertiser_cell">
                                            <div className="related">
                                                {pipe(
                                                    prop("related"),
                                                    values,
                                                    (value) => take(value, 3),
                                                    lomap((product, idx) => (
                                                        <div key={idx} className="related_product_column">
                                                            <div className="logo">
                                                                <img src={pipe(prop("logo_url"))(product)} style={{ width: "20px" }} />
                                                            </div>
                                                            <div className="name">
                                                                {pipe(prop("full_name"), (value) => truncate(value, { length: 20 }))(product)}
                                                            </div>
                                                        </div>
                                                    ))
                                                )(interest)}
                                            </div>
                                        </div>
                                        <div className="actions">
                                            <div className="stats" onClick={() => onSelectAdvertiser(advertiser_id)}>
                                                <BarChartOutlined />
                                            </div>

                                            <div className="save">
                                                <SaveOutlined className="icon" onClick={() => onSaveInterest(advertiser_id)} />
                                            </div>
                                        </div>
                                    </div>
                                ),
                                advertisers
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Advertisers;
