import React from "react";
import { flip } from "ramda";
import { map as lodashmap, size } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import uniqid from "uniqid";
import ExpressionsUneditable from "./ExpressionsUneditable";

const lomap = flip(lodashmap);

const ConditionsContainer = styled.div`
    grid-template-columns: ${({ numOfConditions }) => (numOfConditions > 1 ? "30px auto" : "auto")};
`;

const ConditionsGrid = styled.div`
    grid-column: ${({ numOfConditions }) => (numOfConditions > 1 ? "2/2" : "1/1")};
`;

const ConditionsUneditable = ({ rule }) => {
    const numOfConditions = size(rule.conditions);

    const AddGroup = () => (
        <div className="add_or_condition">
            <div className="button">
                <div className="icon">
                    <PlusOutlined />
                </div>
                <div className="text">Group</div>
            </div>
        </div>
    );

    return (
        <ConditionsContainer className="conditions_container" numOfConditions={numOfConditions}>
            {numOfConditions > 1 && (
                <div className="expressions_operand_bar">
                    <div className="background">
                        <div className="text">OR</div>
                    </div>
                </div>
            )}

            <ConditionsGrid className="conditions_grid" numOfConditions={numOfConditions}>
                {lomap(
                    (condition, key) => (
                        <ExpressionsUneditable key={key} condition={condition} rule={rule} />
                    ),
                    rule.conditions
                )}
            </ConditionsGrid>
        </ConditionsContainer>
    );
};

export default ConditionsUneditable;
