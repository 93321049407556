import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { pipe, head, defaultTo } from "ramda";
import { matching, get, mod, all } from "shades";
const { lomap, domPipeLog: pipeLog } = require("../../utilities/helpers");

const SelectOption = ({ option, setSelectIsVisible, onSelectCallback, selected_id }) => {
    const [isHoverOver, setIsHoverOver] = useState(false);

    const onSelect = () => {
        setSelectIsVisible(false);
        onSelectCallback(option);
    };

    return (
        <li
            onMouseEnter={() => setIsHoverOver(true)}
            onMouseLeave={() => setIsHoverOver(false)}
            onClick={onSelect}
            className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-indigo-600 hover:cursor-pointer"
            id="listbox-option-0"
            role="option"
        >
            <span className="font-normal block truncate">{option.name}</span>

            {option.id == selected_id && (
                <span className={`absolute inset-y-0 right-0 flex items-center pr-4 ${isHoverOver ? "text-white" : "text-indigo-600"}`}>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </span>
            )}
        </li>
    );
};

const FacebookPixelSelect = ({ selected_id, onSelectCallback, options }) => {
    const [selectIsVisible, setSelectIsVisible] = useState(false);

    return (
        <div className="mt-1 relative ">
            <button
                type="button"
                className="bg-white relative w-full border border-gray-300 border-solid rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm cursor-pointer"
                aria-haspopup="listbox"
                aria-expanded="false"
                aria-labelledby="listbox-label"
                onClick={() => setSelectIsVisible(!selectIsVisible)}
            >
                <span className="block truncate">
                    {pipe(
                        get(matching({ id: selected_id })),
                        head,
                        defaultTo(pipe(get(matching({ id: "none" })), head)(options)),
                        get("name")
                    )(options)}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                        className="h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </span>
            </button>

            <ul
                className={`absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${
                    selectIsVisible ? "opacity-100 block" : "opacity-0 hidden"
                }`}
                tabIndex="-1"
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3"
            >
                {pipe(
                    lomap((option, idx) => (
                        <SelectOption
                            option={option}
                            key={idx}
                            setSelectIsVisible={setSelectIsVisible}
                            onSelectCallback={onSelectCallback}
                            selected_id={selected_id}
                        />
                    ))
                )(options)}
            </ul>
        </div>
    );
};

export default FacebookPixelSelect;
