import React from "react";
import {
    CaretRightOutlined,
    PauseOutlined,
    MinusOutlined,
    CopyOutlined,
    NotificationOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    VerticalAlignMiddleOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";
import { useAtom } from "jotai";
import { rule_state, rule_atom } from "../state/rule";
import ActionsDropdown from "./ActionsDropdown";
import BudgetTypeDropdown from "./BudgetTypeDropdown";
import { useParams } from "react-router-dom";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { defaultTo, path } from "ramda";

let rule_types = {
    amount: "$",
    percent: "%",
};

const SetBudget = () => {
    const [rule, setRule] = useAtom(rule_atom);

    const onChangeBudgetValue = ({ target: { value } }) => {
        console.log("onChangeBudgetValue");
        setRule({ ...rule, budget: { ...rule.budget, value } });
    };

    return (
        <div className="budget_params_container" key="setbudget">
            <div className="budget_amount_container">
                <input type="text" placeholder="Amount" value={rule.budget.value} onChange={onChangeBudgetValue} />
            </div>
        </div>
    );
};

const IncreaseBudget = () => {
    const [rule, setRule] = useAtom(rule_atom);
    const [ruleState, setRuleState] = useAtom(rule_state);

    const onChangeBudgetValue = ({ target: { value } }) => {
        console.log("onChangeBudgetValue");
        setRule({ ...rule, budget: { ...rule.budget, value } });
    };

    const onBudgetDropdownClick = () => {
        if (ruleState.budget_types_dropdown_is_open) {
            setRuleState({ ...ruleState, budget_types_dropdown_is_open: false, dropdown_is_open: false, scope_dropdown_is_open: false });
        } else {
            setRuleState({ ...ruleState, budget_types_dropdown_is_open: true, dropdown_is_open: false, scope_dropdown_is_open: false });
        }
    };

    return (
        <div className="budget_params_container">
            <div className="budget_amount_container">
                <input type="text" placeholder="Amount" value={rule.budget.value} onChange={onChangeBudgetValue} />
            </div>

            <div className="budget_type_dropdown_container" onClick={onBudgetDropdownClick}>
                <div className="budget_type_container">
                    <div className="value">{rule_types[rule.budget.type]}</div>
                    <div className="icon">
                        <CaretDownOutlined />
                    </div>
                </div>

                {ruleState.budget_types_dropdown_is_open && <BudgetTypeDropdown />}
            </div>
        </div>
    );
};

const DecreaseBudget = () => {
    const [rule, setRule] = useAtom(rule_atom);
    const [ruleState, setRuleState] = useAtom(rule_state);

    const onChangeBudgetValue = ({ target: { value } }) => {
        console.log("onChangeBudgetValue");
        setRule({ ...rule, budget: { ...rule.budget, value } });
    };

    const onBudgetDropdownClick = () => {
        if (ruleState.budget_types_dropdown_is_open) {
            setRuleState({ ...ruleState, budget_types_dropdown_is_open: false, dropdown_is_open: false, scope_dropdown_is_open: false });
        } else {
            setRuleState({ ...ruleState, budget_types_dropdown_is_open: true, dropdown_is_open: false, scope_dropdown_is_open: false });
        }
    };

    return (
        <div className="budget_params_container">
            <div className="budget_amount_container">
                <input type="text" placeholder="Amount" value={rule.budget.value} onChange={onChangeBudgetValue} />
            </div>

            <div className="budget_type_dropdown_container" onClick={onBudgetDropdownClick}>
                <div className="budget_type_container">
                    <div className="value">{rule_types[rule.budget.type]}</div>
                    <div className="icon">
                        <CaretDownOutlined />
                    </div>
                </div>

                {ruleState.budget_types_dropdown_is_open && <BudgetTypeDropdown />}
            </div>
        </div>
    );
};

const RuleAction = () => {
    const [rule, setRule] = useAtom(rule_atom);
    const [ruleState, setRuleState] = useAtom(rule_state);

    const rule_action = defaultTo("", path(["action", "value"], rule));
    const rule_text = defaultTo("", path(["action", "text"], rule));

    const onDropdownClick = () => {
        if (ruleState.dropdown_is_open) {
            setRuleState({ ...ruleState, dropdown_is_open: false, scope_dropdown_is_open: false, budget_types_dropdown_is_open: false });
        } else {
            setRuleState({ ...ruleState, dropdown_is_open: true, scope_dropdown_is_open: false, budget_types_dropdown_is_open: false });
        }
    };

    let isActionDropdownOpen = ruleState.dropdown_is_open;

    let icon_map = {
        pause: PauseOutlined,
        start: CaretRightOutlined,
        delete: MinusOutlined,
        duplicate: CopyOutlined,
        notify: NotificationOutlined,
        decrease_budget: ArrowDownOutlined,
        increase_budget: ArrowUpOutlined,
        set_budget: VerticalAlignMiddleOutlined,
    };

    let is_budget_action = ["increase_budget", "decrease_budget", "set_budget"].includes(rule_action);

    let Icon = icon_map[rule_action];

    let budget_elements = {
        decrease_budget: DecreaseBudget,
        increase_budget: IncreaseBudget,
        set_budget: SetBudget,
    };

    let BudgetElement = budget_elements[rule_action];

    return (
        <div className="action_container">
            <div className="icon_container" onClick={onDropdownClick}>
                <Icon />
            </div>
            <div className="action_name_container" onClick={onDropdownClick}>
                <div className="text">{capitalize(rule_text)}</div>
            </div>
            <div className="action_description">{is_budget_action && <BudgetElement rule={rule} />}</div>

            {isActionDropdownOpen && <ActionsDropdown />}
        </div>
    );
};

export default RuleAction;
