import React from "react";
import { flip } from "ramda";
import { map as lodashmap } from "lodash";
import { useAtom } from "jotai";
import { scopes_atom, rule_atom } from "../state/rule";
import { ads_manager_state_atom } from "../state/ads_manager";

const lomap = flip(lodashmap);

const ScopesDropdown = () => {
    const [rule, setRule] = useAtom(rule_atom);
    const [scopesDropdown] = useAtom(scopes_atom);
    const [adsManagerState, setAdsManagerState] = useAtom(ads_manager_state_atom);

    const onSelectScope = (key) => {
        setRule({ ...rule, scope: key });
        setAdsManagerState({ ...adsManagerState, selected_tab: key });
    };

    return (
        <div className="actions_dropdown">
            {lomap(
                (value, key) => (
                    <div className="metric_field" key={key} onClick={() => onSelectScope(key)}>
                        {value}
                    </div>
                ),
                scopesDropdown
            )}
        </div>
    );
};

export default ScopesDropdown;
