import React from "React";
import { app_atom } from "../state/app";
import { useAtom } from "jotai";
import Modal from "react-modal";

const ScreenModal = (props) => {
    const [app, setApp] = useAtom(app_atom);

    if (props.id !== app.modal_id) return <div></div>;

    return (
        <div className="modal_container">
            <Modal
                isOpen={app.modal_is_open}
                style={{
                    content: {
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        border: "none",
                        borderRadius: "0px",
                    },
                }}
            >
                <div className="screen_modal_content">{props.children}</div>
            </Modal>
        </div>
    );
};

export default ScreenModal;
