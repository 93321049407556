import React, { useRef, useEffect, useState } from "react";
import { flip } from "ramda";
import { map as lodashmap } from "lodash";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { user_atom } from "../state/auth";

const lomap = flip(lodashmap);

const integrations = {
    javascript: {
        name: "Javascript",
        integrations: [
            {
                logo_img: "https://seeklogo.com/images/J/javascript-logo-8892AEFCAC-seeklogo.com.png",
                name: "Javascript",
            },
        ],
    },
    facebook: {
        name: "Facebook",
        integrations: [
            {
                logo_img: "https://logodownload.org/wp-content/uploads/2014/09/facebook-logo-1-2.png",
                name: "Facebook",
            },
            // {
            //     logo_img: "https://logodownload.org/wp-content/uploads/2014/09/facebook-logo-1-2.png",
            //     name: "Facebook  Insights",
            // },
            // {
            //     logo_img: "https://assets.stickpng.com/images/580b57fcd9996e24bc43c521.png",
            //     name: "Instagram",
            // },
        ],
    },
    google: {
        name: "Google",
        integrations: [
            {
                logo_img: "https://logowik.com/content/uploads/images/google-ads2666.jpg",
                name: "Google  Ads",
            },
            {
                logo_img: "https://logos-world.net/wp-content/uploads/2021/02/Google-Analytics-Logo.png",
                name: "Google  Analytics",
            },
            {
                logo_img: "https://www.iconpacks.net/icons/2/free-youtube-logo-icon-2431-thumb.png",
                name: "Youtube",
            },
        ],
    },
    keap: {
        name: "Keap",
        integrations: [
            {
                logo_img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Logo_of_Keap_Company.svg/1200px-Logo_of_Keap_Company.svg.png",
                name: "Keap",
            },
        ],
    },
    stripe: {
        name: "Stripe",
        integrations: [
            {
                logo_img: "https://montekado.com/wp-content/uploads/2020/11/1280px-Stripe_Logo_revised_2016.svg.png",
                name: "Stripe",
            },
        ],
    },
    clickfunnels: {
        name: "Click Funnels",
        integrations: [
            {
                logo_img: "https://cdn.worldvectorlogo.com/logos/click-funnels.svg",
                name: "Click Funnels",
            },
        ],
    },
    shopify: {
        name: "Shopify",
        integrations: [
            {
                logo_img: "https://www.thesoftwarereport.com/wp-content/uploads/2021/07/Shopify.jpg",
                name: "Shopify",
            },
        ],
    },
    snapchat: {
        name: "Snapchat",
        integrations: [
            {
                logo_img: "https://assets.dryicons.com/uploads/icon/svg/8361/f80c84be-cee0-4dd1-812b-5894b06a8db7.svg",
                name: "Snapchat",
            },
        ],
    },
    tiktok: {
        name: "TikTok",
        integrations: [
            {
                logo_img: "https://cliply.co/wp-content/uploads/2021/02/372102690_TIKTOK_LOGO_1080.png",
                name: "TikTok",
            },
        ],
    },
};

const Integrations = () => {
    const [user, setUser] = useAtom(user_atom);

    return (
        <div className="integrations_view">
            <div className="nav_container">
                <div className="nav">
                    <div className="nav_item">Facebook</div>
                    <div className="nav_item">Google</div>
                    <div className="nav_item">Keap</div>
                    <div className="nav_item">Stripe</div>
                    <div className="nav_item">Click Funnels</div>
                    <div className="nav_item">Shopify</div>
                    <div className="nav_item">SnapChat</div>
                    <div className="nav_item">TikTok</div>
                </div>
            </div>
            <div className="integrations_container">
                {lomap(
                    (group, idx) => (
                        <div className="integrations_group_container" key={idx}>
                            <div className="group_name">{group.name}</div>
                            <div className="integrations">
                                {lomap(
                                    (integration, idx) => (
                                        <div className="integration_container" key={idx}>
                                            <div className="logo">
                                                <img src={integration.logo_img} />
                                            </div>
                                            <div className="name">{integration.name}</div>

                                            {integration.name == "Javascript" && (
                                                <Link to={"/tracking-code"} className="status">
                                                    Connect
                                                </Link>
                                            )}

                                            {integration.name == "Facebook" && (
                                                <div className="actions">
                                                    <div className="action_container">
                                                        <a
                                                            href={`https://us-central1-roas-e44b8.cloudfunctions.net/app/auth/facebook?user_id=${user.uid}`}
                                                            className="status"
                                                        >
                                                            Connect
                                                        </a>
                                                    </div>
                                                    <div className="action_container">
                                                        <Link to="/ad-accounts" className="accounts">
                                                            Ad Accounts
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}

                                            {integration.name == "Keap" && (
                                                <a
                                                    href={`https://us-central1-roas-e44b8.cloudfunctions.net/oauth/auth/keap/?user_id=${user.uid}`}
                                                    className="status"
                                                >
                                                    Connect
                                                </a>
                                            )}

                                            {integration.name == "Stripe" && (
                                                <a
                                                    href={`https://us-central1-roas-e44b8.cloudfunctions.net/app/auth/stripe/?user_id=${user.uid}`}
                                                    className="status"
                                                >
                                                    Connect
                                                </a>
                                            )}

                                            {integration.name == "Shopify" && (
                                                <Link to={"/shopify-settings"} className="status">
                                                    Connect
                                                </Link>
                                            )}

                                            {!["Keap", "Facebook", "Stripe", "Shopify", "Javascript"].includes(integration.name) && (
                                                <div className="status">Connect</div>
                                            )}
                                        </div>
                                    ),
                                    group.integrations
                                )}
                            </div>
                        </div>
                    ),
                    integrations
                )}
            </div>
        </div>
    );
};

export default Integrations;
