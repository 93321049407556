import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    SettingOutlined,
    PartitionOutlined,
    DashboardOutlined,
    AppstoreOutlined,
    CloseOutlined,
    TeamOutlined,
    DollarCircleOutlined,
    VideoCameraOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    FlagOutlined,
} from "@ant-design/icons";
import { useAtom } from "jotai";
import { app_atom } from "../state/app";
import { user_atom } from "../state/auth";
import { auth } from "../firebase";
import { size } from "lodash";

const LeftNavTwo = () => {
    return (
        <div class="flex flex-col min-w-[200px] border-r border-solid border-gray-200 bg-white overflow-y-auto h-full">
            <div class="flex-grow flex flex-col">
                <nav class="flex-1 bg-white space-y-1" aria-label="Sidebar">
                    <a href="#" class="bg-blue-50 border-blue-600 text-blue-600 group flex items-center px-3 py-2 text-sm font-medium border-l-4">
                        <svg
                            class="text-blue-500 mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                            />
                        </svg>
                        Dashboard
                    </a>

                    <a
                        href="#"
                        class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4"
                    >
                        <svg
                            class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                        </svg>
                        Team
                    </a>

                    <a
                        href="#"
                        class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4"
                    >
                        <svg
                            class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                            />
                        </svg>
                        Projects
                    </a>

                    <a
                        href="#"
                        class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4"
                    >
                        <svg
                            class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                            />
                        </svg>
                        Calendar
                    </a>

                    <a
                        href="#"
                        class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4"
                    >
                        <svg
                            class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                            />
                        </svg>
                        Documents
                    </a>

                    <a
                        href="#"
                        class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4"
                    >
                        <svg
                            class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                            />
                        </svg>
                        Reports
                    </a>
                </nav>
            </div>
        </div>
    );
};

const LeftNavOne = () => {
    const [app, setApp] = useAtom(app_atom);
    const [user, setUser] = useAtom(user_atom);
    let navigate = useNavigate();

    const onRetractLeftNavBar = () => {
        setApp({ ...app, left_nav_is_open: false, settings_nav_is_open: false });
    };

    const onSignOut = () =>
        auth.signOut().then(() => {
            setApp({ ...app, settings_nav_is_open: false });
            setUser({ uid: null, email: "" });
            navigate("/", { replace: true });
        });

    const navigateTo = (path) => {
        setApp({ ...app, settings_nav_is_open: false });
        navigate(path, { replace: true });
    };

    const onToggleSettingNav = () => {
        setApp({ ...app, settings_nav_is_open: !app.settings_nav_is_open });
    };
    const onCloseSettingsNav = () => setApp({ ...app, settings_nav_is_open: false });

    let is_setup_complete =
        user.roas_has_facebook_connected &&
        user.roas_has_selected_facebook_ad_account &&
        size(user.roas_shopping_cart) > 0 &&
        user.roas_dna_script_installed &&
        user.roas_shopping_cart_webhook_installed;

    return (
        <div className="open_left_nav bg-white">
            <div className="header_nav">
                <Link to={`/onboarding`} className="nav_item">
                    <div className="icon">
                        <FlagOutlined />
                    </div>
                    <div className="text">Onboarding</div>
                </Link>

                <Link to="/ads-manager" className="nav_item">
                    <div className="icon">
                        <DashboardOutlined />
                    </div>
                    <div className="text">Ads Manager</div>
                </Link>

                <Link to="/rules" className="nav_item">
                    <div className="icon">
                        <PartitionOutlined />
                    </div>
                    <div className="text">Automations</div>
                </Link>

                <a href="https://roas.org/automations" target="_blank" className="nav_item">
                    <div className="icon">
                        <VideoCameraOutlined />
                    </div>
                    <div className="text">Automation Tutorials</div>
                </a>

                <Link to="/projects" className="nav_item">
                    <div className="icon">
                        <AppstoreOutlined />
                    </div>
                    <div className="text">Projects</div>
                </Link>

                <Link to={`/order/${user.uid}`} className="nav_item">
                    <div className="icon">
                        <DollarCircleOutlined />
                    </div>
                    <div className="text">Order</div>
                </Link>

                {user.is_admin && (
                    <Link to="/user-accounts" className="nav_item">
                        <div className="icon">
                            <TeamOutlined />
                        </div>
                        <div className="text">Accounts</div>
                    </Link>
                )}

                {user.is_admin && (
                    <Link to={`/users`} className="nav_item">
                        <div className="icon">
                            <TeamOutlined />
                        </div>
                        <div className="text">Users</div>
                    </Link>
                )}

                <div className="account_status_container">
                    <div className="content_container">
                        <div className="account_status_header">Account Status</div>

                        <div className="status_container">
                            <div className="icon">{user.roas_has_facebook_connected ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</div>
                            <div className="label">Facebook Connected</div>
                            <div className="action">{user.roas_has_facebook_connected ? "" : <div>Fix</div>}</div>
                        </div>
                        <div className="status_container">
                            <div className="icon">
                                {user.roas_has_selected_facebook_ad_account ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                            </div>
                            <div className="label">Ad Account Selected</div>
                            <div className="action">{user.roas_has_selected_facebook_ad_account ? "" : <div>Fix</div>}</div>
                        </div>
                        <div className="status_container">
                            <div className="icon">{user.roas_shopping_cart ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</div>
                            <div className="label">Shopping Cart Selected</div>
                            <div className="action">{user.roas_shopping_cart ? "" : <div>Fix</div>}</div>
                        </div>
                        <div className="status_container">
                            <div className="icon">{user.roas_dna_script_installed ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</div>
                            <div className="label">Tracking Script Installed</div>
                            <div className="action">{user.roas_dna_script_installed ? "" : <div>Fix</div>}</div>
                        </div>

                        <div className="status_container">
                            <div className="icon">
                                {user.roas_shopping_cart_webhook_installed ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                            </div>
                            <div className="label">Webhook Installed</div>
                            <div className="action">{user.roas_shopping_cart_webhook_installed ? "" : <div>Fix</div>}</div>
                        </div>
                        {!is_setup_complete && (
                            <div className="schedule_a_call_container">
                                <div className="text_row" style={{ fontWeight: "bold" }}>
                                    Want us to do this FOR YOU?
                                </div>
                                <div className="text_row">Click here to pick a time that works and we'll take care of it together.</div>
                                <div className="text_row">This is NOT a sales call 😊</div>
                                <a href="https://calendly.com/roas-onboarding/roas-onboarding" target="_blank" className="cta">
                                    Let's do it!
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="footer_nav">
                {app.settings_nav_is_open && (
                    <div className="settings_select">
                        <div className="close" onClick={onCloseSettingsNav}>
                            <CloseOutlined />
                        </div>

                        {user.is_admin && (
                            <div className="setting_nav_item" onClick={() => navigateTo("/integrations")}>
                                <div className="icon">
                                    <AppstoreOutlined />
                                </div>
                                <div className="text">Accounts</div>
                            </div>
                        )}

                        <Link to={"/onboarding"}>
                            <div className="setting_nav_item">
                                <div className="icon">
                                    <AppstoreOutlined />
                                </div>
                                <div className="text">Integrations</div>
                            </div>
                        </Link>

                        <div className="setting_nav_item" onClick={onSignOut}>
                            <div className="icon">
                                <SettingOutlined />
                            </div>
                            <div className="text">Log Out</div>
                        </div>
                    </div>
                )}

                <div className="nav_item" onClick={onToggleSettingNav}>
                    <div className="icon">
                        <SettingOutlined />
                    </div>
                    <div className="text">Settings</div>
                </div>
            </div>
        </div>
    );
};

const OpenLeftNav = () => {
    return <LeftNavOne />;
};

const LeftNav = () => {
    return <OpenLeftNav />;
};

export default LeftNav;
