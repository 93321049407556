import React, { useState } from "react";
import { useAtom } from "jotai";
import { accounts_filters_atom, accounts_atom } from "./accounts.js";
import { Input } from "antd";
import { PlusOutlined, CloseOutlined, CaretDownOutlined, DeleteOutlined } from "@ant-design/icons";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("d67b1755a5322afc68ba2e1a98fc7986Tz00NTAxNSxFPTE2ODU4MDU2NjQyNDQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");
import "./styles.scss";
import { lomap } from "../../../../utilities/helpers";
import { from, map as rxmap } from "rxjs";
import { pipe, head, reject } from "ramda";
import { isEmpty, size } from "lodash";
import { get, matching, mod, all } from "shades";
import axios from "axios";
var qs = require("qs");

const columns = [
    { headerName: "Email", field: "roas_email", width: 250 },
    { headerName: "User ID", field: "roas_user_id", width: 150 },
    { headerName: "First Name", field: "roas_first_name", width: 150 },
    { headerName: "Last Name", field: "roas_last_name", width: 150 },
    { headerName: "Spend", field: "roas_ad_amount_spent", width: 150 },
];

const accounts_filters = {
    roas_user_id: {
        field: { value: "roas_user_id", type: "string", hr_value: "user id" },
        operator: { name: "is", value: "$eq", type: "string" },
        value: "",
    },
    roas_email: {
        field: { value: "roas_email", type: "string", hr_value: "email" },
        operator: { name: "is", value: "$eq", type: "string" },
        value: "",
    },
    roas_ad_amount_spent: {
        field: { value: "roas_ad_amount_spent", type: "number", hr_value: "spend" },
        operator: { name: "is", value: "$eq", type: "number" },
        value: 0,
    },
};

const operator_dropdowns = {
    string: [
        { name: "is", value: "$eq" },
        { name: "is_not", value: "$ne" },
        { name: "contains", value: "$regex" },
    ],
    number: [
        { name: "is", value: "$eq", type: "number" },
        { name: "greater than", value: "$gt", type: "number" },
        { name: "less than", value: "$lt", type: "number" },
    ],
};

const EmptyFilters = ({ onAddNewFilter }) => {
    return (
        <div className="flex flex-row w-full h-full items-center justify-center cursor-pointer min-h-[100px]" onClick={onAddNewFilter}>
            <div className="icon flex flex-col items-center justify-center mr-[10px]">
                <PlusOutlined />
            </div>
            <div className="texst">Filter</div>
        </div>
    );
};

const FieldSelect = ({ filter, filters, onUpdateFilter, filterDropdown, setFilterDropdown }) => {
    const onSelectFilter = (selected_filter) => {
        setFilterDropdown({ filter_id: null, dropdown_type: null, is_open: false });

        onUpdateFilter({
            value: selected_filter.field.value,
            id: filter.id,
        });
    };

    const onFilterDropdownClick = () => {
        if (filter.id == filterDropdown.filter_id && filterDropdown.dropdown_type == "field") {
            setFilterDropdown({ filter_id: filter.id, dropdown_type: "field", is_open: !filterDropdown.is_open });
        } else {
            setFilterDropdown({ filter_id: filter.id, dropdown_type: "field", is_open: true });
        }
    };

    const is_dropdown_open = filterDropdown.filter_id == filter.id && filterDropdown.dropdown_type == "field" && filterDropdown.is_open == true;

    return (
        <div className="filters_select_container relative col-start-1">
            <div className="relative flex flex-col h-[30px] bg-white border-[1px] border-solid border-slate-100 w-[150px] rounded">
                <div className="flex flex-row items-center justify-between h-full w-full cursor-pointer px-3" onClick={onFilterDropdownClick}>
                    <div className="name">{filter.field.hr_value}</div>
                    <div className="icon">
                        <CaretDownOutlined />
                    </div>
                </div>
            </div>
            {is_dropdown_open && (
                <div className="filters_dropdown_container absolute top-[35px] flex flex-col bg-white w-[305px] shadow rounded min-h-[60px] z-10">
                    {lomap(
                        (filter, key) => (
                            <div
                                className="flex flex-row items-center px-3 rounded hover:bg-blue-500 hover:text-white hover:cursor-pointer w-full h-[30px] border-b-[1px] border-solid border-slate-100"
                                key={key}
                                onClick={() => onSelectFilter(filter)}
                            >
                                <div className="name">{filter.field.hr_value}</div>
                            </div>
                        ),
                        filters
                    )}
                </div>
            )}
        </div>
    );
};

const OperatorsSelect = ({ filter, onUpdateOperator, filterDropdown, setFilterDropdown }) => {
    const onSelectOperator = (selected) => {
        setFilterDropdown({ filter_id: null, dropdown_type: null, is_open: false });
        onUpdateOperator({
            prop: "operator",
            value: selected,
            id: filter.id,
        });
    };

    const onFilterDropdownClick = () => {
        if (filter.id == filterDropdown.filter_id && filterDropdown.dropdown_type == "operator") {
            setFilterDropdown({ filter_id: filter.id, dropdown_type: "operator", is_open: !filterDropdown.is_open });
        } else {
            setFilterDropdown({ filter_id: filter.id, dropdown_type: "operator", is_open: true });
        }
    };

    const is_dropdown_open = filterDropdown.filter_id == filter.id && filterDropdown.dropdown_type == "operator" && filterDropdown.is_open == true;
    let operators = operator_dropdowns[filter.field.type];

    return (
        <div className="operators_select_container relative col-start-2">
            <div className="relative flex flex-col h-[30px] bg-white border-[1px] border-solid border-slate-100 w-[150px] rounded">
                <div className="flex flex-row items-center justify-between h-full w-full cursor-pointer px-3" onClick={onFilterDropdownClick}>
                    <div className="name">{filter.operator.name}</div>
                    <div className="icon">
                        <CaretDownOutlined />
                    </div>
                </div>
            </div>
            {is_dropdown_open && (
                <div className="operators_dropdown_container absolute top-[35px] flex flex-col bg-white w-[150px] shadow rounded min-h-[60px] z-10">
                    {lomap(
                        (operator, idx) => (
                            <div
                                className="flex flex-row items-center px-3 rounded hover:bg-blue-500 hover:text-white hover:cursor-pointer w-full h-[30px] border-b-[1px] border-solid border-slate-100"
                                key={idx}
                                onClick={() => onSelectOperator(operator)}
                            >
                                <div className="name">{operator.name}</div>
                            </div>
                        ),
                        operators
                    )}
                </div>
            )}
        </div>
    );
};

const FilterValue = ({ onUpdateValue, filter }) => {
    const onFilterValueChange = ({ target: { value } }) => {
        onUpdateValue({
            id: filter.id,
            value,
        });
    };

    return (
        <div className="filter_value_container col-start-3">
            <div className="relative flex flex-col h-[30px] bg-white border-[1px] border-solid border-slate-100 w-[150px] rounded">
                <Input className="border-none" placeholder="" onChange={onFilterValueChange} value={filter.value} />
            </div>
        </div>
    );
};

const DeleteFilter = ({ filter, onDeleteFilter }) => {
    const onDeleteFilterClick = () => {
        onDeleteFilter(filter);
    };

    return (
        <div className="delete_filter_container col-start-4 cursor-pointer flex flex-col items-center justify-center" onClick={onDeleteFilterClick}>
            <DeleteOutlined />
        </div>
    );
};

const FiltersFooter = ({ onAddNewFilter, setIsFiltersModalOpen, applyFiltersCallback }) => {
    const [filters] = useAtom(accounts_filters_atom);

    const onApplyFilters = () => {
        setIsFiltersModalOpen(false);
        applyFiltersCallback(filters);
    };

    return (
        <div className="filters_footer_container mx-[10px] h-[40px] flex flex-row items-center justify-between">
            <div className="left">
                <div className="action flex flex-row items-center cursor-pointer text-xs" onClick={onAddNewFilter}>
                    <div className="icon flex flex-col items-center justify-center mr-[10px]">
                        <PlusOutlined />
                    </div>
                    <div className="text">Add Filter</div>
                </div>
            </div>
            <div className="right">
                <button
                    type="button"
                    className="flex flex-col justify-center items-center w-[75px] py-[3px] border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={onApplyFilters}
                >
                    Apply
                </button>
            </div>
        </div>
    );
};

const Filters = ({ applyFiltersCallback, setIsFiltersModalOpen }) => {
    const [filters, setFilters] = useAtom(accounts_filters_atom);
    const [filterDropdown, setFilterDropdown] = useState({ filter_id: null, dropdown_type: null, is_open: false });

    const onAddNewFilter = () => {
        let new_filter_default = {
            field: { type: "string", value: "" },
            operator: { type: "equals", value: "=" },
            value: "",
            id: size(filters) + 1,
        };

        setFilters([...filters, new_filter_default]);
    };

    const onUpdateFilter = (update) => {
        setFilters(pipe(mod(matching({ id: update.id }))((value) => ({ ...accounts_filters[update.value], id: update.id })))(filters));
    };

    const onUpdateOperator = (update) => {
        setFilters(pipe(mod(matching({ id: update.id }), update.prop)((value) => update.value))(filters));
    };

    const onUpdateValue = (update) => {
        setFilters(pipe(mod(matching({ id: update.id }), "value")((curr) => update.value))(filters));
    };

    const onDeleteFilter = (filter) => {
        setFilters(pipe(reject((value) => value.id == filter.id))(filters));
    };

    if (isEmpty(filters)) return <EmptyFilters onAddNewFilter={onAddNewFilter} />;

    return (
        <div className="filters_container flex flex-col">
            <div className="filters flex flex-col p-[10px]">
                {lomap(
                    (filter) => (
                        <div className="filter_container grid grid-cols-[auto auto auto 20px] gap-[5px] mb-[10px]" key={filter.id}>
                            <FieldSelect
                                filters={accounts_filters}
                                onUpdateFilter={onUpdateFilter}
                                filter={filter}
                                filterDropdown={filterDropdown}
                                setFilterDropdown={setFilterDropdown}
                            />
                            <OperatorsSelect
                                onUpdateOperator={onUpdateOperator}
                                filter={filter}
                                filterDropdown={filterDropdown}
                                setFilterDropdown={setFilterDropdown}
                            />
                            <FilterValue onUpdateValue={onUpdateValue} filter={filter} />
                            <DeleteFilter filter={filter} onDeleteFilter={onDeleteFilter} />
                        </div>
                    ),
                    filters
                )}
            </div>
            <FiltersFooter
                onAddNewFilter={onAddNewFilter}
                applyFiltersCallback={applyFiltersCallback}
                setIsFiltersModalOpen={setIsFiltersModalOpen}
            />
        </div>
    );
};

const FiltersContainer = ({ setIsFiltersModalOpen, applyFiltersCallback }) => {
    const onCloseFiltersModalClick = () => setIsFiltersModalOpen(false);
    return (
        <div className="flex flex-col w-[500px] z-[2] absolute top-[55px] bg-white rounded shadow">
            <div className="flex flex-row justify-between p-[10px] border-b-[1px] border-solid border-slate-100">
                <div className="title">Filters</div>
                <div className="close cursor-pointer" onClick={onCloseFiltersModalClick}>
                    <CloseOutlined />
                </div>
            </div>
            <Filters applyFiltersCallback={applyFiltersCallback} setIsFiltersModalOpen={setIsFiltersModalOpen} />
        </div>
    );
};

const TableFiltersAction = ({ applyFiltersCallback }) => {
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(true);

    const onTogglefiltersModal = () => setIsFiltersModalOpen(!isFiltersModalOpen);

    return (
        <div className="table_action_container flex flex-col h-full justify-center w-[150px] relative">
            <div className="action flex flex-row h-full items-center cursor-pointer text-blue-600 text-sm" onClick={onTogglefiltersModal}>
                <div className="icon flex flex-col items-center justify-center mr-[10px]">
                    <PlusOutlined />
                </div>
                <div className="text">Add Filter</div>
            </div>
            {isFiltersModalOpen && <FiltersContainer applyFiltersCallback={applyFiltersCallback} setIsFiltersModalOpen={setIsFiltersModalOpen} />}
        </div>
    );
};

const Accounts = () => {
    const [accounts, setAccounts] = useAtom(accounts_atom);

    const applyFiltersCallback = (filters) => {
        let payload = {
            filters,
        };

        let url = "http://localhost:3000/users";

        from(
            axios.get(url, {
                params: payload,
                paramsSerializer: (params) => qs.stringify(params),
            })
        )
            .pipe(rxmap(get("data")))
            .subscribe((response) => {
                console.log("accounts");
                console.log(response);
                setAccounts(pipe(mod(all)((account) => ({ ...account, id: account.doc_id })))(response));
            });
    };

    return (
        <div className="accounts_view_container flex flex-col items-center justify-center w-full h-full">
            <div className="accounts_table_container flex flex-col w-[calc(100%-40px)] h-[calc(100%-40px)]">
                <div className="actions_container table_actions_container flex flex-col h-[60px] w-full">
                    <div className="left_table_actions_container flex flex-col h-full">
                        <TableFiltersAction applyFiltersCallback={applyFiltersCallback} />
                    </div>
                </div>
                <div className="data_grid_container flex flex-col h-full w-full">
                    <DataGridPro
                        rows={accounts}
                        columns={columns}
                        disableColumnFilter={true}
                        disableChildrenFiltering={true}
                        disableColumnMenu={true}
                        rowHeight={40}
                    />
                </div>
            </div>
        </div>
    );
};

export default Accounts;
