import React from "react";
import { CheckCircleTwoTone, CheckOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const Home = () => {
    let navigate = useNavigate();
    const onSignUpClick = () => {
        navigate("/signup");
    };

    return (
        <div className="home_view">
            <div className="header_section_container">
                <div className="header_container">
                    <div className="header_content_container">
                        <div className="logo">
                            <p style={{ fontSize: "30px" }}>
                                <span style={{ fontWeight: "bold" }}>ROAS</span>
                                <span style={{ fontWeight: "bold", color: "#eb072a" }}>.org</span>
                            </p>
                        </div>

                        <div className="actions">
                            <div className="action_item try_it_button" onClick={onSignUpClick}>
                                <div className="header_try_it_button_container">
                                    <div className="text">Try It Free</div>
                                    <div className="bubble_container">
                                        <div className="icon_container">
                                            <div className="icon">
                                                <ArrowRightOutlined />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home_section_container">
                <div className="home_container">
                    <div className="content_section_outer_container" style={{ padding: "0px" }}>
                        <div className="headline_container">
                            <div style={{ fontSize: "47px", marginBottom: "10px" }}>
                                <p>
                                    Now You Can Know Exactly How Much Your Ads Are Making... And{" "}
                                    <span style={{ color: "#EB072A" }}>Automatically Scale</span> What's Working
                                </p>
                            </div>

                            <div>(While Turning Off What Isn't)</div>
                        </div>
                    </div>

                    <div className="main_video_container">
                        <div className="video">
                            <iframe
                                class="embedly-embed"
                                src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Ffast.wistia.net%2Fembed%2Fiframe%2Fpjasuoc9s8&amp;display_name=Wistia%2C+Inc.&amp;url=https%3A%2F%2Ffrankkern.wistia.com%2Fmedias%2Fpjasuoc9s8&amp;image=https%3A%2F%2Fembed-ssl.wistia.com%2Fdeliveries%2F3609cd8f2bd07885c0305c3553adf15c.jpg%3Fimage_crop_resized%3D960x540&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=wistia"
                                scrolling="no"
                                allowfullscreen=""
                                title="ROASAppApril2022Pitch"
                                width="854px"
                                height="480px"
                            ></iframe>
                        </div>
                    </div>

                    <div className="get_started_section" onClick={onSignUpClick}>
                        <div className="headline">Get started now</div>
                        <div className="subheadline">No credit card required</div>
                        <div className="cta">
                            <div className="cta_try_it_button_container">
                                <div className="text">Try It Free</div>
                                <div className="bubble_container">
                                    <div className="icon_container">
                                        <div className="icon">
                                            <ArrowRightOutlined />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="risk_reversals">
                            <div className="item">
                                <div className="icon">
                                    <CheckOutlined />
                                </div>
                                <div className="text">7-day free trial</div>
                            </div>
                            <div className="item">
                                <div className="icon">
                                    <CheckOutlined />
                                </div>
                                <div className="text">No credit card required</div>
                            </div>
                            <div className="item">
                                <div className="icon">
                                    <CheckOutlined />
                                </div>
                                <div className="text">Cancel anytime</div>
                            </div>
                        </div>
                    </div>

                    <div className="content_section_outer_container" style={{ backgroundColor: "#fff" }}>
                        <div className="content_section_container">
                            <div className="headline">
                                <div>1. Actually know what's bringing in revenue (and what isn't)</div>
                            </div>
                            <div className="content">
                                <div className="text_section">
                                    A team of Phds, engineers, and data scientists spent 183 consecutive days (and nights) creating our DNA Tracking
                                    Engine.
                                </div>

                                <div className="text_section">It cost us just under $600,000 (and counting) to build.</div>

                                <div className="text_section">The net effect?</div>

                                <div className="text_section">
                                    Simple: You'll be able to track sales from your Social ads ...even if someone clicks on your ad today, waits a
                                    while, and then buys a few days later ...from a different device in a different location.
                                </div>

                                <div className="text_section">Even if they have cookie-blockers.</div>

                                <div className="text_section">Even if they're in "incognito" mode. It doesn't matter. </div>

                                <div className="text_section" style={{ fontWeight: "bold" }}>
                                    EVERYTHING IS TRACKED
                                </div>

                                <div className="text_section">ROAS doesn't depend on Pixels, cookies, or anything like that.</div>

                                <div className="text_section">
                                    <p>
                                        {" "}
                                        We integrate directly with your payment system.{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            And this means that we can track every single purchase someone makes ...even if its six months from now.
                                        </span>
                                    </p>
                                </div>

                                <div className="text_section">
                                    <p>
                                        {" "}
                                        Even if you have a complex sales process with multiple cross-sells, upsells, or order bumps{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            ...you'll know exactly how much revenue your ads are bringing you ...down to the penny.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content_section_outer_container" style={{ backgroundColor: "#FBFBFB", position: "relative" }}>
                        <div className="wave" style={{ backgroundColor: "#FBFBFB" }}></div>
                        <div className="content_section_container" style={{ zIndex: 1 }}>
                            <div className="headline">
                                <div>2. Automatically Scale What's Working (And Stop What Isn't)</div>
                            </div>
                            <div className="content">
                                <div className="text_section">
                                    Say goodbye to agencies, media buyers, "ad coaches", and the inconsistent results (and high fees) that come with
                                    them.
                                </div>

                                <div className="text_section">
                                    <p>
                                        ROAS simply makes them obsolete by{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            automatically scaling the ads that work, and trimming the ones that don't.
                                        </span>
                                    </p>
                                </div>

                                <div className="text_section">
                                    <p>
                                        We do this by allowing you to create simple rules that do exactly what an expensive agency or employee would
                                        do ...except <span style={{ fontWeight: "bold" }}>ROAS works for you 24/7 and never makes mistakes.</span>
                                    </p>
                                </div>

                                <div className="text_section">
                                    (And it's actually tracking your REAL sales as opposed to making the "educated guesses" that come from FB's pixels
                                    and other tracking systems.)
                                </div>

                                <div className="text_section">
                                    The concept is simple: Now that you're actually tracking the TRUE amount of revenue your ads are bringing you, you
                                    can simply tell ROAS how to manage everything ...and it will.
                                </div>

                                <div className="text_section" style={{ fontWeight: "bold" }}>
                                    Flawlessly
                                </div>

                                <div className="text_section">
                                    For example, let's say that you want to take your ads that are working ...and scale them up. In this example,
                                    let's say that every ad that's bringing in over 2X ad spend for the day is "good"
                                </div>

                                <div className="text_section" style={{ fontWeight: "bold" }}>
                                    No problem
                                </div>

                                <div className="text_section">
                                    Just click a few buttons and ROAS will look at all your campaigns ...find the ones that match your rules (in this
                                    case - making more than 2X what you spend) ...and it'll scale them by as much as you want ...as often as you want.
                                </div>

                                <div className="text_section" style={{ fontWeight: "bold" }}>
                                    Never loose money
                                </div>

                                <div className="text_section">
                                    For example, let's say you don't want to run any ads that are bringing in less than 1.2X as much as you spend.
                                </div>

                                <div className="text_section">
                                    No problem. ROAS will check on everything, and (according to what you want it to do) ...either pause the ads that
                                    aren't performing or reduce their spend.
                                </div>

                                <div className="text_section" style={{ fontWeight: "bold" }}>
                                    Scale, Pause, Reduce
                                </div>

                                <div className="text_section">
                                    And you can scale, pause, or reduce spend based on all number of important things like cost per lead, cost per
                                    sale, or return on ad spend.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content_section_outer_container" style={{ backgroundColor: "#FBFBFB" }}>
                        <div className="headline_content_section" style={{ fontSize: 25, zIndex: 1 }}>
                            It's like having the best media buyer in the world working for you 24/7.
                        </div>
                    </div>
                    <div className="content_section_outer_container" style={{ backgroundColor: "#F6F6F6", position: "relative" }}>
                        <div className="wave" style={{ backgroundColor: "#F6F6F6" }}></div>
                        <div className="headline_content_section" style={{ fontSize: 60, zIndex: 1 }}>
                            What It Costs
                        </div>
                    </div>
                    <div className="content_section_outer_container" style={{ backgroundColor: "#F6F6F6" }}>
                        <div className="content_section_container" style={{ zIndex: 1 }}>
                            <div className="content">
                                <div className="text_section" style={{ fontWeight: "bold" }}>
                                    You can try ROAS for free for a full week. NO CREDIT CARD NEEDED
                                </div>

                                <div className="text_section">
                                    <p>
                                        Setup takes about 5 minutes (literally) and once everything's been populated into the system,{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            you'll start seeing accurate stats and you'll be able to use the automations.
                                        </span>
                                    </p>
                                </div>

                                <div className="text_section">
                                    <p>
                                        Creating the automations is extremely easy as well, so it's safe to say that{" "}
                                        <span style={{ fontWeight: "bold" }}>you can start seeing the benefits of using it TODAY.</span>
                                    </p>
                                </div>

                                <div className="text_section">
                                    We think that once the trial is up, you'll be hooked (in the good way) and you'll want to use it forever.
                                </div>

                                <div className="text_section">If you do, it's $297 per month.</div>

                                <div className="text_section" style={{ fontWeight: "bold" }}>
                                    That's it.
                                </div>

                                <div className="text_section">
                                    <p>We don't charge you more based on how much you spend or how much you use it, either.</p>
                                </div>

                                <div className="text_section">
                                    <p>
                                        If you do the math,{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            it's like getting a full-time ad expert running everything for you 24/7 ...for 41 cents an hour.
                                        </span>
                                    </p>
                                </div>

                                <div className="text_section">
                                    We're actually worried that the low cost might put people off. Which is why we might raise prices in the future.
                                    But that would be for new users ...not you.
                                </div>

                                <div className="text_section">
                                    But even if we did charge more, at the end of the day it should actually cost you ...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content_section_outer_container" style={{ backgroundColor: "#FBFBFB" }}>
                        <div className="headline_content_section" style={{ fontSize: 60 }}>
                            NOTHING
                        </div>
                    </div>
                    <div className="content_section_outer_container" style={{ backgroundColor: "#FBFBFB" }}>
                        <div className="content_section_container">
                            <div className="content">
                                <div className="text_section">
                                    <p>
                                        And the reason why is because{" "}
                                        <span style={{ fontWeight: "bold" }}>ROAS should both make you (and save you) far more</span> than the already
                                        low expense.
                                    </p>
                                </div>

                                <div className="text_section">But please don't take our word for it.</div>

                                <div className="text_section" style={{ fontWeight: "bold" }}>
                                    Instead, try it for free. No credit card needed.
                                </div>

                                <div className="text_section">
                                    <p>
                                        You'll have{" "}
                                        <span style={{ fontWeight: "bold" }}>full access to the entire power of ROAS for a full week</span>, which is
                                        plenty of time for you to see if this is valuable or not.
                                    </p>
                                </div>

                                <div className="text_section">
                                    If you like it, you simply sign up as a paying user and then keep using it as long as you want. There are no
                                    contracts or commitments on your end. You can cancel any time.
                                </div>

                                <div className="text_section">
                                    <p>
                                        And{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            if you don't see the value, you're not out anything because it's free to try it.
                                        </span>{" "}
                                        You don't even have to cancel the account because we'll do it for you automatically when the 7 days is over.
                                    </p>
                                </div>

                                <div className="text_section">So you literally have nothing to risk at all.</div>
                            </div>
                        </div>
                    </div>
                    <div className="content_section_outer_container" style={{ backgroundColor: "#F6F6F6", position: "relative" }}>
                        <div className="wave" style={{ backgroundColor: "#F6F6F6" }}></div>
                        <div className="headline_content_section" style={{ fontSize: 60, zIndex: 1 }}>
                            How To Get Started
                        </div>
                    </div>
                    <div className="content_section_outer_container" style={{ backgroundColor: "#F6F6F6" }}>
                        <div className="content_section_container">
                            <div className="content">
                                <div className="text_section">The first step is to create your account. That's free to do.</div>

                                <div className="text_section" style={{ fontWeight: "bold" }}>
                                    We don't even ask you for a credit card.
                                </div>

                                <div className="text_section">Once you do that, setting it up is easy.</div>

                                <div className="text_section">
                                    <div className="steps_section">
                                        <div className="icon">
                                            <CheckOutlined />
                                        </div>
                                        <div className="text">
                                            Connect To Your Ad Account. (This takes a matter of seconds. You just click two buttons.)
                                        </div>
                                    </div>
                                </div>

                                <div className="text_section">
                                    <div className="steps_section">
                                        <div className="icon">
                                            <CheckOutlined />
                                        </div>
                                        <div className="text">
                                            Connect To Your Website And Shopping Cart. (This takes about two minutes. You're just pasting our tracking
                                            script into the headers on your pages and then pasting on link into a special section of your shopping
                                            cart or payment processor.)
                                        </div>
                                    </div>
                                </div>

                                <div className="text_section">
                                    <div className="steps_section">
                                        <div className="icon">
                                            <CheckOutlined />
                                        </div>
                                        <div className="text">
                                            Add our tracking code to your Social ads. (This takes at most, two minutes. You can update all of your ads
                                            at once ...with the press of three buttons.)
                                        </div>
                                    </div>
                                </div>

                                <div className="text_section">That's it!</div>

                                <div className="text_section">Once everything is connected and synched up, you'll be good to go!</div>

                                <div className="text_section">
                                    Every sale from here on out will be tracked perfectly and you can completely automate your entire account so that
                                    it's running better than it would if you were paying a full time pro to do it for you.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="get_started_section" style={{ margin: "30px" }} onClick={onSignUpClick}>
                        <div className="headline">Get started now</div>
                        <div className="subheadline">No credit card required</div>
                        <div className="cta">
                            <div className="cta_try_it_button_container">
                                <div className="text">Try It Free</div>
                                <div className="bubble_container">
                                    <div className="icon_container">
                                        <div className="icon">
                                            <ArrowRightOutlined />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="risk_reversals">
                            <div className="item">
                                <div className="icon">
                                    <CheckOutlined />
                                </div>
                                <div className="text">7-day free trial</div>
                            </div>
                            <div className="item">
                                <div className="icon">
                                    <CheckOutlined />
                                </div>
                                <div className="text">No credit card required</div>
                            </div>
                            <div className="item">
                                <div className="icon">
                                    <CheckOutlined />
                                </div>
                                <div className="text">Cancel anytime</div>
                            </div>
                        </div>
                    </div>

                    <Footer styles={{ position: "relative", marginTop: "-10px", paddingBottom: "20px" }} />
                </div>
            </div>
        </div>
    );
};

export default Home;
