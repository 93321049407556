export const TREE_DATA_STRATEGY = 'tree-data';
/**
 * A node is visible if one of the following criteria is met:
 * - One of its children is passing the filter
 * - It is passing the filter
 */

export const filterRowTreeFromTreeData = params => {
  const {
    rowTree,
    disableChildrenFiltering,
    isRowMatchingFilters
  } = params;
  const visibleRowsLookup = {};
  const filteredRowsLookup = {};
  const filteredDescendantCountLookup = {};

  const filterTreeNode = (node, isParentMatchingFilters, areAncestorsExpanded) => {
    var _node$children;

    const shouldSkipFilters = disableChildrenFiltering && node.depth > 0;
    let isMatchingFilters;

    if (shouldSkipFilters) {
      isMatchingFilters = null;
    } else if (!isRowMatchingFilters) {
      isMatchingFilters = true;
    } else {
      isMatchingFilters = isRowMatchingFilters(node.id);
    }

    let filteredDescendantCount = 0;
    (_node$children = node.children) == null ? void 0 : _node$children.forEach(childId => {
      var _isMatchingFilters;

      const childNode = rowTree[childId];
      const childSubTreeSize = filterTreeNode(childNode, (_isMatchingFilters = isMatchingFilters) != null ? _isMatchingFilters : isParentMatchingFilters, areAncestorsExpanded && !!node.childrenExpanded);
      filteredDescendantCount += childSubTreeSize;
    });
    let shouldPassFilters;

    switch (isMatchingFilters) {
      case true:
        {
          shouldPassFilters = true;
          break;
        }

      case false:
        {
          shouldPassFilters = filteredDescendantCount > 0;
          break;
        }

      default:
        {
          shouldPassFilters = isParentMatchingFilters;
          break;
        }
    }

    visibleRowsLookup[node.id] = shouldPassFilters && areAncestorsExpanded;
    filteredRowsLookup[node.id] = shouldPassFilters;

    if (!shouldPassFilters) {
      return 0;
    }

    filteredDescendantCountLookup[node.id] = filteredDescendantCount;
    return filteredDescendantCount + 1;
  };

  const nodes = Object.values(rowTree);

  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];

    if (node.depth === 0) {
      filterTreeNode(node, true, true);
    }
  }

  return {
    visibleRowsLookup,
    filteredRowsLookup,
    filteredDescendantCountLookup
  };
};