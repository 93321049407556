import { atom } from "jotai";
import { groupBy, prop, pipe, flip } from "ramda";
import { map as lodashmap } from "lodash";

const lomap = flip(lodashmap);

export const expressions_state = atom({
    scope: "ad",
    expression_id: null,
    dropdown_is_open: false,
    dropdown_type: null,
});

export const triggers_default = [
    {
        adset: true,
        campaign: true,
        account: true,
        category: "most_common",
        text: "Spend",
        value: "roasspend",
        description: "",
    },
    {
        adset: true,
        campaign: true,
        account: true,
        category: "most_common",
        text: "ROAS",
        value: "roas",
        description: "",
    },
    {
        adset: true,
        campaign: true,
        account: true,
        category: "most_common",
        text: "Unique Customers",
        value: "roascustomers",
        description: "",
    },
    {
        adset: true,
        campaign: true,
        account: true,
        category: "most_common",
        text: "Cost Per Unique Customer",
        value: "roascostpercustomer",
        description: "",
    },
    {
        adset: true,
        campaign: true,
        account: true,
        category: "most_common",
        text: "Leads",
        value: "roasleads",
        description: "",
    },
    {
        adset: true,
        campaign: true,
        account: true,
        category: "most_common",
        text: "Cost Per Lead",
        value: "roascostperlead",
        description: "",
    },
    {
        adset: true,
        campaign: true,
        account: true,
        category: "most_common",
        text: "Name",
        value: "asset_name",
        description: "",
        metric_type: "string",
    },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "ID",
    //     value: "asset_id",
    //     description: "",
    //     metric_type: "id",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Margin",
    //     value: "roasmargin",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Leads",
    //     value: "roasleads",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Sales",
    //     value: "roassales",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Website adds to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Active ads in ad set",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Active ad sets in campaign",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Active campaigns in account",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Ads in ad set",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Ad sets in campaign",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Daily budget",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Campaigns in account",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Hours since creation",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Maximum daily spend limit",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Maximum lifetime spend limit",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Minimum daily spend target",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Minimum lifetime spend target",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Cost per website purchase",
    //     value: "roascostpersale",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Website purchase ROAS",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Time less than",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "most_common",
    //     text: "Time greater than",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "general",
    //     text: "CPM",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "general",
    //     text: "Cost per 1000 people reached",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "general",
    //     text: "Bid amount",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "general",
    //     text: "Remaining budget",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "general",
    //     text: "Lifetime budget",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "general",
    //     text: "Frequency",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "general",
    //     text: "Impressions",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "general",
    //     text: "Reach",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Website leads value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Website purchase value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Landing page views",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Website adds payment info",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Website adds to wishlist",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Website registrations completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Website checkouts initiated",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Website content views",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Average website purchase value",
    //     value: "roasaverageordervalue",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Website trials started",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per website trial started",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per landing page view",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per unique landing page view",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per unique website add to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per unique website purchase",
    //     value: "roascostpersale",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per website add of payment info",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per website add to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per website add to wishlist",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per website registration completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per website checkout initiated",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per website lead",
    //     value: "roascostperlead",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per website content view",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Unique landing page views",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Unique website adds to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Unique website purchases",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Unique website content views",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per unique website content view",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Unique website adds of payment info",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per unique website add of payment info",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Unique website adds of payment info",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Unique website registrations completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "website_standard_events",
    //     text: "Cost per unique website registration completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Achievements unlocked value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app sessions value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Levels completed value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app purchase value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app purchase value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Ratings submitted value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Tutorials completed value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Achievements unlocked",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app sessions",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app adds to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app registrations completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app D2 retention",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app D7 retention",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app checkouts initiated",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Levels completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app purchases",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Ratings submitted",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Tutorials completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Game plays",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app installs",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per achievement unlocked",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per mobile app add to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per mobile app registration completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per game play",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per unique achievement unlocked",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per unique session",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per unique level completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per unique rating submitted",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per unique tutorial completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per unique mobile app add of payment info",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Cost per mobile app install",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Mobile app purchase ROAS",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Unique achievements unlocked",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Unique sessions",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Unique levels completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Unique ratings submitted",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Unique tutorials completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "mobile_app_standard_events",
    //     text: "Unique mobile app adds of payment info",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Adds of payment info value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Adds of wishlist value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Leads value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Adds to cart value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Registrations completed value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cehckouts initiated value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Purchases value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Searches value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Credit spends value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Content views value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Adds of payment info",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Adds to wishlist",
    //     description: "",
    // },

    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Adds to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Registrations completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Checkouts initiated",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Purchases",
    //     value: "roassales",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Searches",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Credit spends",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Content views",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Post saves",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Contacts value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Customize product conversion value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Donations value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Location searches value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Schedule value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Trials started value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Applications submitted value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Subscriptions value",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Contacts",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Products customized",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Donations",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Location searches",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Appointments scheduled",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Trials started",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Applications submitted",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Subscriptions",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per add of pyament info",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per add to wishlist",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per contact",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per product customized",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per donation",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per location find",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per appointments scheduled",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per trial started",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per application submitted",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per subscription",
    //     description: "",
    // },

    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per on-Facebook lead",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per add to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per registration completed",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per checkout initiated",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per purchase",
    //     value: "roascostpersale",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per search",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per credit spend",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per content view",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per post save",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per unique add to wishlist",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per unique add to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per unique checkout initiated",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Cost per unique purchase",
    //     value: "roascostpersale",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Unique adds to wishlist",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Unique adds to cart",
    //     description: "",
    // },
    // {
    //     adset: true,
    //     campaign: true,
    //     account: true,
    //     category: "standard_events_total",
    //     text: "Unique checkouts initiated",
    //     description: "",
    // },
];

export const triggers_default_atom = atom(groupBy(prop("category"), triggers_default));
export const filtered_triggers_atom = atom(triggers_default);
export const materialized_triggers_atom = atom((get) => {
    let triggers = get(filtered_triggers_atom);
    return groupBy(prop("category"), triggers);
});

export const timeframes_default = [
    {
        category: "most_common",
        text: "Today",
        args: {
            unit: "days",
            value: 0,
            include_today: true,
        },
    },
    {
        category: "most_common",
        text: "Yesterday",
        args: {
            unit: "days",
            value: 1,
            include_today: false,
        },
    },
    {
        category: "most_common",
        text: "Today & yesterday",
        args: {
            unit: "days",
            value: 1,
            include_today: true,
        },
    },
    {
        category: "most_common",
        text: "Last 3 days (incl. today)",
        args: {
            unit: "days",
            value: 3,
            include_today: true,
        },
    },
    {
        category: "most_common",
        text: "Last 3 days",
        args: {
            unit: "days",
            value: 0,
            include_today: false,
        },
    },
    {
        category: "most_common",
        text: "Last 7 days (Incl. today)",
        args: {
            unit: "days",
            value: 7,
            include_today: true,
        },
    },
    {
        category: "most_common",
        text: "Last 7 days",
        args: {
            unit: "days",
            value: 7,
            include_today: false,
        },
    },
    // {
    //     category: "most_common",
    //     text: "This month",
    //     args: {
    //         unit: 'days',
    //         value: 30,
    //         include_today: false
    //     }
    // },
    // {
    //     category: "most_common",
    //     text: "Last month",
    //     description: "",
    // },
    // {
    //     category: "most_common",
    //     text: "Maximum",
    //     description: "",
    // },
    {
        category: "days",
        text: "Last 2 days",
        args: {
            unit: "days",
            value: 2,
            include_today: false,
        },
    },
    {
        category: "days",
        text: "Last 14 days",
        args: {
            unit: "days",
            value: 14,
            include_today: false,
        },
    },
    {
        category: "days",
        text: "Last 28 days",
        args: {
            unit: "days",
            value: 28,
            include_today: false,
        },
    },
    {
        category: "days",
        text: "Last 30 days",
        args: {
            unit: "days",
            value: 30,
            include_today: false,
        },
    },
    {
        category: "days",
        text: "Last 90 days",
        description: "",
        args: {
            unit: "days",
            value: 90,
            include_today: false,
        },
    },
    {
        category: "including_today",
        text: "Last 14 days (Incl. today)",
        args: {
            unit: "days",
            value: 14,
            include_today: true,
        },
    },
    {
        category: "including_today",
        text: "Last 28 days (Incl. today)",
        args: {
            unit: "days",
            value: 28,
            include_today: true,
        },
    },
    {
        category: "including_today",
        text: "Last 30 days (Incl. today)",
        args: {
            unit: "days",
            value: 30,
            include_today: true,
        },
    },
    {
        category: "including_today",
        text: "Last 90 days (Incl. today)",
        args: {
            unit: "days",
            value: 90,
            include_today: true,
        },
    },
    // {
    //     category: "hours",
    //     text: "Hour before previous hour",
    //     args: {
    //         unit: 'days',
    //         value: 0,
    //         include_today: true
    //     }
    // },
    // {
    //     category: "hours",
    //     text: "Previous hour",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Current hour",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 2 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 3 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 4 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 5 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 6 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 7 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 8 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 9 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 10 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 11 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 12 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 13 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 14 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 15 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 16 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 17 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 18 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 19 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 20 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 21 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 22 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 23 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "hours",
    //     text: "Last 24 hours (Incl. current hour)",
    //     description: "",
    // },
    // {
    //     category: "weeks",
    //     text: "This week (Mon to Today)",
    //     args: {
    //         unit: 'days',
    //         value: 7,
    //         include_today: true
    //     }
    // },
    // {
    //     category: "weeks",
    //     text: "This week (Sun to Today)",
    //     description: "",
    // },
    // {
    //     category: "weeks",
    //     text: "Last week (Mon to Sun)",
    //     description: "",
    // },
    // {
    //     category: "weeks",
    //     text: "Last week (Sun to Sat)",
    //     description: "",
    // },
];

export const timeframes_default_atom = atom(groupBy(prop("category"), timeframes_default));
export const filtered_timeframes_atom = atom(timeframes_default);
export const materialized_timeframes_atom = atom((get) => {
    let timeframes = get(filtered_timeframes_atom);
    return groupBy(prop("category"), timeframes);
});

let predicates = {
    less_than: "< Less than",
    greater_than: "> Greater than",
    lte: "≤  Less than or equal",
    gte: "≥  Greater than or equal",
    equals: "= Equals",
};

export const predicates_atom = atom(predicates);

let string_predicates = {
    equals: "= Equals",
    includes: "⊆ Includes",
};

export const string_predicates_atom = atom(string_predicates);

let id_predicates = {
    equals: "= Equals",
};

export const id_predicates_atom = atom(id_predicates);
