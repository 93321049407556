import { atom } from "jotai";
import { defaultTo, pipe } from "ramda";
import { lofilter } from "../../utilities/helpers";
import { get as view } from "shades";

export const user_atom = atom({ uid: null, email: "", roas_has_watched_initial_video: false, roas_has_watched_onboard_video: false });
export const accounts_atom = atom({
    roas_has_facebook_connected: false,
    roas_has_facebook_ad_accounts: false,
    roas_has_selected_facebook_ad_account: false,
    roas_facebook_ad_account_id: null,
    roas_has_initiated_adding_ad_id_paramater_on_ads: false,
    roas_has_ad_id_paramater_on_ads: false,
    roas_has_facebook_ad_account_data: false,

    roas_has_selected_payment_processor: false,
    roas_has_initiated_payment_processor_connection: false,
    roas_has_payment_processor_connected: false,
    roas_payment_processor: "",

    roas_has_selected_shopping_cart: false,
    roas_has_initiated_shopping_cart_connection: false,
    roas_has_shopping_cart_connected: false,
    roas_shopping_cart: "",

    roas_has_dna_snippet_connected: false,

    roas_onboard_step: "",
    roas_has_onboarded: false,
});

export const facebook_accounts_atom = atom({});
export const accounts_search_filter_atom = atom("");

export const users_accounts_atom = atom([]);
export const accounts_table_atom = atom((get) => {
    let accounts = get(users_accounts_atom);
    let search_filter = get(accounts_search_filter_atom);

    if (search_filter !== "") {
        return pipe(
            lofilter((value) => {
                let roas_user_name = pipe(view("roas_user_name"), defaultTo(""))(value);
                let roas_email = pipe(view("roas_email"), defaultTo(""))(value);
                let roas_user_id = pipe(view("roas_user_id"), defaultTo(""))(value);

                return (
                    roas_user_name == search_filter ||
                    roas_email == search_filter ||
                    roas_user_id == search_filter ||
                    roas_user_name.includes(search_filter) ||
                    roas_email.includes(search_filter) ||
                    roas_user_id.includes(search_filter)
                );
            })
        )(accounts);
    }

    return accounts;
});

// ADMIN

export const user_accounts_atom = atom({});
