import { atom } from "jotai";

let sample_interest = {
    6003712761753: {
        interest_id: "6003712761753",
        interest_name: "Starbucks",
        audience_size: "16600000",
        path: "Interests>Additional Interests",
        category: "-",
        topic: "Business and industry",
        country: "US",
        has_detail_data: true,
        number: 1,
        gender: [
            {
                value: 20.7,
                name: "Male",
            },
            {
                value: 79.3,
                name: "Female",
            },
        ],
        gender_age: [
            {
                product: "18-24",
                Female: 6.8,
                Male: 1.9,
                percentage_total: 8.7,
            },
            {
                product: "25-34",
                Female: 18.2,
                Male: 5.3,
                percentage_total: 23.5,
            },
            {
                product: "35-44",
                Female: 16.4,
                Male: 4.1,
                percentage_total: 20.5,
            },
            {
                product: "45-54",
                Female: 13,
                Male: 3,
                percentage_total: 16,
            },
            {
                product: "55-64",
                Female: 11.9,
                Male: 3.1,
                percentage_total: 15,
            },
            {
                product: "65+",
                Female: 13,
                Male: 3.3,
                percentage_total: 16.3,
            },
        ],
        regions: {
            Indianapolis: 0.41,
            "San Diego": 0.45,
            Dallas: 0.47,
            Philadelphia: 0.51,
            Phoenix: 0.61,
            "San Antonio (Texas)": 0.78,
            Chicago: 1,
            Houston: 1.03,
            "Los Angeles": 1.06,
            "New York": 1.96,
        },
    },
};

export const audience_view_state_atom = atom({
    q: "",
    modal_is_open: true,
    selected_interest_id: "",
    // selected_interest: {},
    selected_interest: sample_interest,
    is_loading: false,
});

export const interest_atom = atom({});
export const interests_atom = atom({});
