import React, { useEffect, useState } from "react";
import { user_atom } from "../state/auth";
import { project_atom } from "../state/projects";
import { useAtom } from "jotai";
import { db } from "../firebase";
import { doc, query, collectionGroup, where, getDocs, getDoc, collection, setDoc } from "firebase/firestore";
import { map as rxmap, from, zip } from "rxjs";
import { head, pipe } from "ramda";
import ProjectNav from "../components/ProjectNav";
import FacebookAdAccounts from "../components/FacebookAdAccounts";
import { useParams } from "react-router-dom";
import FacebookPixelSelect from "../components/FacebookPixelSelect";
import { capitalize } from "lodash";
import { louniqby } from "../../utilities/helpers";
let server_url = "https://roas-main-services.herokuapp.com";
// let server_url = "http://localhost:3000";

const FacebookAdAccountDetails = () => {
    const [user, setUser] = useAtom(user_atom);
    const [project, setProject] = useAtom(project_atom);
    const [facebookPixels, setFacebookPixels] = useState([{ id: "none", name: "Select a pixel!" }]);
    let { project_id } = useParams();

    useEffect(() => {
        console.log("fetching pixels");
        let pixel_url = `${server_url}/get_facebook_pixels?roas_user_id=${user.uid}`;
        var requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
        };

        fetch(pixel_url, requestOptions)
            .then((response) => response.json())
            .then((pixels) => setFacebookPixels(pipe(louniqby("id"))([...facebookPixels, ...pixels])))
            .catch((error) => console.log("error", error));
    }, []);

    const onEditSelectedAdAccount = () => setProject({ ...project, is_selecting_ad_account: !project.is_selecting_ad_account });

    const onSelectPixel = (option) => {
        let { id: pixel_id } = option;
        from(setDoc(doc(db, "projects", project_id), { pixel_id }, { merge: true })).subscribe(() => {
            console.log("saved");
            setProject({ ...project, pixel_id });
        });
    };

    return (
        <div className="facebook_account_details mt-10 divide-y divide-gray-200 divide-solid">
            <div className="space-y-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Facebook Account</h3>
            </div>
            <div className="mt-6">
                <dl className="divide-y divide-gray-200 divide-solid">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">{project.email || user.roas_user_email}</span>
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                        <dt className="text-sm font-medium text-gray-500">Facebook Account ID</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">{project.fb_account_id}</span>
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                        <dt className="text-sm font-medium text-gray-500">Ad Account ID</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">{project.account_id}</span>
                            <span className="ml-4 flex-shrink-0">
                                <button
                                    type="button"
                                    className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none"
                                    onClick={onEditSelectedAdAccount}
                                >
                                    {project.is_selecting_ad_account ? "Cancel" : "Edit"}
                                </button>
                            </span>
                        </dd>
                    </div>
                    {project.is_selecting_ad_account && <FacebookAdAccounts />}
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                        <dt className="text-sm font-medium text-gray-500">Facebook Account ID</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                                <FacebookPixelSelect options={facebookPixels} onSelectCallback={onSelectPixel} selected_id={project.pixel_id} />
                            </span>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
};

const ProjectAdAccount = () => {
    const [user] = useAtom(user_atom);
    const [project, setProject] = useAtom(project_atom);
    let { project_id } = useParams();

    useEffect(() => {
        let facebook_account_query = from(
            getDocs(query(collectionGroup(db, "integrations"), where("account_name", "==", "facebook"), where("user_id", "==", user.uid)))
        ).pipe(
            rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
            rxmap(head)
        );

        let project_query = from(getDocs(query(collection(db, "projects"), where("project_id", "==", project_id)))).pipe(
            rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
            rxmap(head)
        );

        zip([facebook_account_query, project_query]).subscribe(([facebook_account_data, project_data]) => {
            let { fb_ad_account_id } = project_data;

            let ad_account_query = from(getDocs(query(collection(db, "fb_ad_accounts"), where("account_id", "==", fb_ad_account_id)))).pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(head)
            );

            ad_account_query.subscribe((ad_account) => {
                setProject({ ...project, ...ad_account, ...project_data, ...facebook_account_data });
            });
        });
    }, []);

    return (
        <div className="project_container overflow-scroll">
            <main className="flex-1">
                <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
                    <div className="pt-10 pb-5">
                        <div className="px-4 sm:px-6 md:px-0">
                            <h1 className="text-3xl font-extrabold text-gray-900">Account Settings</h1>
                        </div>
                        <div className="px-4 sm:px-6 md:px-0">
                            <div className="py-6">
                                <ProjectNav selected_tab={"ad_account"} />
                                <FacebookAdAccountDetails />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ProjectAdAccount;
