const util = require("util");
const { flip, curry, pipe, ifElse, always, reject, anyPass, map, when, is, split, fromPairs, identity } = require("ramda");
const {
    map: lodashmap,
    sumBy: lodashSumBy,
    groupBy: lodashGroupBy,
    filter: lodashFilter,
    uniqBy: lodashUniqBy,
    reduce: lodashReduce,
    keyBy: lodashKeyBy,
    has: lodashhas,
    sortBy: lodashsortby,
    get: lodashget,
    hasIn: lodashhasin,
    isNil,
    isEmpty,
    chunk: lodashchunk,
    truncate,
    orderBy: lodashorderby,
} = require("lodash");
const validator = require("email-validator");
const { isIPv4, isIPv6 } = require("net");

const isNullString = (string) => string == "null";
const clean = (o) => pipe(ifElse(isNil, always(null), pipe(reject(anyPass([isNil, isEmpty, isNullString])), map(when(is(Object), clean)))))(o);
const loget = curry((path, obj) => lodashget(obj, path));
const lohasin = flip(lodashhasin);

const removeFirstCharacterFromString = (string) => string.substring(1);
const urlToParams = split("&");
const paramsToPairs = map(split("="));
const urlToSearchParams = pipe(removeFirstCharacterFromString, urlToParams, paramsToPairs, fromPairs);
const toSearchParams = ifElse(isEmpty, identity, urlToSearchParams);
const loorderby = curry((path, direction, data) => lodashorderby(data, path, direction));

exports.lomap = flip(lodashmap);
exports.lohas = flip(lodashhas);
exports.losumby = flip(lodashSumBy);
exports.logroupby = flip(lodashGroupBy);
exports.lofilter = flip(lodashFilter);
exports.louniqby = flip(lodashUniqBy);
exports.loreduce = curry((reducerFn, data) => lodashReduce(data, reducerFn));
exports.lokeyby = flip(lodashKeyBy);
// exports.losortby = flip(lodashsortby);
exports.losortby = curry((props, data) => lodashsortby(data, props));
exports.isNullString = (string) => string == "null";
exports.clean = clean;
exports.loget = loget;
exports.lohasin = lohasin;
exports.lochunk = flip(lodashchunk);
exports.toSearchParams = toSearchParams;
exports.lotruncate = flip(truncate);
exports.loorderby = loorderby;

exports.toFixed = curry((fixed, num) => {
    if (!num) return 0;
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
});

exports.pipeLog = (value) => {
    console.log("pipeLog");
    console.log(util.inspect(value, false, null, true));
    return value;
};

exports.domPipeLog = (value) => {
    console.log("pipeLog");
    console.log(value);
    return value;
};

exports.isEmail = validator.validate;
exports.isIPv4 = isIPv4;
exports.isIPv6 = isIPv6;
