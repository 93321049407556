import React from "react";
import { truncate } from "lodash";

let ExpressionUneditable = ({ expression }) => {
    return (
        <div className="expression_container">
            <div className="expression_metric">
                <div className="select">
                    <div className="value">{truncate(expression.metric.text, { length: 18 })}</div>
                </div>
            </div>

            <div className="expression_timeframe">
                <div className="select">
                    <div className="value">{truncate(expression.timeframe, { length: 18 })}</div>
                </div>
            </div>

            <div className="expression_predicate">
                <div className="select">
                    <div className="value">{expression.predicate.text}</div>
                </div>
            </div>

            <div className="expression_value">
                <input type="text" className="value" value={expression.value} readOnly />
            </div>
        </div>
    );
};

export default ExpressionUneditable;
