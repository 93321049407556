import React from "react";
import { useAtom } from "jotai";
import { user_atom } from "../state/auth";
import Highlight from "react-highlight";
import copy from "copy-to-clipboard";

let tracking_code_with_tags = (user_id) => `
    <script>
        (function roas(params) {
            var user_id  = params.user_id;
            localStorage.setItem("roas_user_id", user_id);
            fetch("https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js").then(function(res){return res.json()}).then(function(response){
                var downloadTokens  = response.downloadTokens;
                var head = document.head;
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js?alt=media&token=" + downloadTokens
                head.appendChild(script);
            })
        })({ user_id: "${user_id}" });
    </script>
`;

let tracking_code_without_tags = (user_id) => `    
    (function roas(params) {
        var user_id  = params.user_id;
        localStorage.setItem("roas_user_id", user_id);
        fetch("https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js").then(function(res){return res.json()}).then(function(response){
            var downloadTokens  = response.downloadTokens;
            var head = document.head;
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js?alt=media&token=" + downloadTokens
            head.appendChild(script);
        })
    })({ user_id: "${user_id}" });
`;

const TrackingCodeSnippetSection = ({ selected_id }) => {
    const [user] = useAtom(user_atom);

    const onCopySnippetToClipboard = (type) => {
        copy(tracking_code(user.uid));
    };

    return (
        <div className="tracking_code_container text-xs overflow-x-scroll">
            <div className="title">ROAS Tracking Script</div>
            <div className="tracking_code overflow-x-scroll">
                {selected_id !== "kartra" && <Highlight language={"javascript"}>{tracking_code_with_tags(user.uid)}</Highlight>}
                {selected_id == "kartra" && <Highlight language={"javascript"}>{tracking_code_without_tags(user.uid)}</Highlight>}
            </div>
            <div className="copy_code_snippet_to_clipboard_container" onClick={() => onCopySnippetToClipboard("tracking_code")}>
                Copy To Clipboard
            </div>
        </div>
    );
};

export default TrackingCodeSnippetSection;
