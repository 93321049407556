import React, { useEffect, useState } from "react";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useAtom, atom } from "jotai";
import { user_atom } from "../state/auth";
import {
    rule_atom,
    schedule_atom,
    schedule_state,
    attribution_atom,
    attribution_state,
    rule_assets_atom,
    rule_logs_atom,
    filted_rule_logs_atom,
} from "../state/rule";
import TextareaAutosize from "react-autosize-textarea";
import Conditions from "../components/Conditions";
import RuleAction from "../components/RuleAction";
import RuleScope from "../components/RuleScope";
import { setDoc, doc, getDoc, getDocs, collection, query, where, orderBy, limit, collectionGroup, startAfter, startAt } from "firebase/firestore";
import { db } from "../firebase";
import { getDoc, setDoc, doc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { from, zip } from "rxjs";
import { map as rxmap } from "rxjs/operators";
import { get, all, mod, matching } from "shades";
import { capitalize, map as lodashmap, keyBy as lodashkeyby, groupBy as lodashgroupby, isEmpty, size, sortBy as lodashsortby } from "lodash";
import { pipe, map, pick, path, head, values, flip, path, defaultTo, mergeDeepRight, keys, filter, last } from "ramda";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import moment from "moment";
import pluralize from "pluralize";
import styled from "styled-components";
import { toFixed } from "../../utilities/helpers";
import { get, all, mod, matching } from "shades";

const lomap = flip(lodashmap);
const lokeyby = flip(lodashkeyby);
const logroupby = flip(lodashgroupby);
const losort = flip(lodashsortby);

const pipeLog = (value) => {
    console.log(value);
    return value;
};

let sort_by_key = (data) => {
    let sorted = {};

    pipe(
        keys,
        lodashsortby,
        map((key) => (sorted[key] = data[key]))
    )(data);

    return sorted;
};

const ScheduleDropdown = () => {
    const [scheduleDropdown] = useAtom(schedule_atom);
    const [schedule, setSchedule] = useAtom(schedule_state);
    const [rule, setRule] = useAtom(rule_atom);

    const onSelectTime = (amount, unit) => {
        console.log("onSelectAction");
        console.log(amount, unit);
        setRule({ ...rule, schedule: { amount, unit } });
        setSchedule({ ...schedule, dropdown_is_open: false });
    };

    return (
        <div className="schedule_dropdown">
            {lomap(
                ({ amount, unit }, key) => (
                    <div className="metric_field" key={key} onClick={() => onSelectTime(amount, unit)}>
                        {amount}
                        {unit}
                    </div>
                ),
                scheduleDropdown
            )}
        </div>
    );
};

const AttributionDropdown = () => {
    const [attributionDropdown] = useAtom(attribution_atom);
    const [attribution, setAttribution] = useAtom(attribution_state);

    const onSelectAttributionWindow = () => {
        console.log("onSelectAttributionWindow");
        setAttribution({ ...attribution, dropdown_is_open: false });
    };

    return (
        <div className="schedule_dropdown">
            {lomap(
                (value, key) => (
                    <div className="metric_field" key={key} onClick={onSelectAttributionWindow}>
                        {value}
                    </div>
                ),
                attributionDropdown
            )}
        </div>
    );
};

const AssetHeading = ({ asset_id, is_open, is_checked }) => {
    const [ruleAssets, setRuleAssets] = useAtom(rule_assets_atom);

    let heading = pipe(get("campaigns", matching({ asset_id })), values, head)(ruleAssets);

    let all_selected = pipe(
        get("adsets", matching({ campaign_id: asset_id }), "selected"),
        filter((v) => v == false),
        size
    )(ruleAssets);

    const onCheckHeading = (event) => {
        setRuleAssets(
            pipe(
                mod("campaigns", matching({ asset_id }), "selected")(() => event.target.checked),
                mod("adsets", matching({ campaign_id: asset_id }), "selected")(() => event.target.checked)
            )(ruleAssets)
        );
    };

    const onToggleDrawerHeading = () => {
        setRuleAssets(pipe(mod("campaigns", matching({ asset_id }), "is_open")((value) => !value))(ruleAssets));
    };

    return (
        <AccordionItemHeading className="heading_container">
            <AccordionItemButton className="heading_button">
                <div className="heading_icon">
                    {is_open && <CaretDownOutlined onClick={onToggleDrawerHeading} />}
                    {!is_open && <CaretRightOutlined onClick={onToggleDrawerHeading} />}
                </div>
                <div className="heading_checkbox">
                    <input type="checkbox" checked={is_checked && all_selected == 0} onChange={onCheckHeading} />
                </div>
                <div className="heading_name" onClick={onToggleDrawerHeading}>
                    {heading.name}
                </div>
            </AccordionItemButton>
        </AccordionItemHeading>
    );
};

const CampaignHeading = ({ campaign }) => {
    const [rule] = useAtom(rule_atom);
    const [ruleAssets, setRuleAssets] = useAtom(rule_assets_atom);

    const onCheckHeading = () => {
        setRuleAssets(mod(`${rule.scope}`, matching({ asset_id: campaign.asset_id }), "selected")((value) => !value)(ruleAssets));
    };

    return (
        <div className="asset_item_row accordion_parent_container" id={campaign.asset_id} key={campaign.asset_id}>
            <div className="select_checkbox">
                <input type="checkbox" checked={campaign.selected} onChange={onCheckHeading} />
            </div>
            <div className="heading_name">{campaign.name}</div>
        </div>
    );
};

const CampaignsAssets = () => {
    const [rule] = useAtom(rule_atom);
    const [ruleAssets, setRuleAssets] = useAtom(rule_assets_atom);

    let budget_actions = ["increase_budget", "decrease_budget", "set_budget"];
    let campaigns = budget_actions.includes(rule.action.value)
        ? get("campaigns", matching({ is_cbo: true }))(ruleAssets)
        : get("campaigns")(ruleAssets);

    // console.log("campaigns");
    // console.log(campaigns);

    return (
        <div className="assets_container">
            <Accordion className="assets_container_accordion" allowZeroExpanded={true} allowMultipleExpanded={true}>
                {lomap(
                    (campaign) => (
                        <CampaignHeading campaign={campaign} key={campaign.campaign_id} />
                    ),
                    campaigns
                )}
            </Accordion>
        </div>
    );
};

const AdsetAssets = () => {
    const [rule] = useAtom(rule_atom);
    const [ruleAssets, setRuleAssets] = useAtom(rule_assets_atom);

    const campaigns = () => {
        let budget_actions = ["increase_budget", "decrease_budget", "set_budget"];
        return budget_actions.includes(rule.action.value) ? get("campaigns", matching({ is_cbo: false }))(ruleAssets) : get("campaigns")(ruleAssets);
    };

    const onCheckAdset = ({ type, campaign_id, adset_id, checked }) => {
        if (type == "adset") {
            setRuleAssets(pipe(mod("adsets", matching({ campaign_id, adset_id }), "selected")(() => checked))(ruleAssets));
        }
    };

    return (
        <div className="assets_container">
            <Accordion
                className="assets_container_accordion"
                allowZeroExpanded={true}
                allowMultipleExpanded={true}
                id={`campaignaccordion`}
                key={`campaignaccordion`}
            >
                {lomap(
                    (campaign, idx) => (
                        <AccordionItem
                            className="accordion_parent_container"
                            id={`campaignitem${idx}`}
                            key={`campaignitem${idx}`}
                            dangerouslySetExpanded={campaign.is_open}
                        >
                            <AssetHeading
                                scope={"campaigns"}
                                asset_id={campaign.asset_id}
                                id={`campaignheading${campaign.asset_id}`}
                                key={`campaignheading${campaign.asset_id}`}
                                is_open={campaign.is_open}
                                is_checked={campaign.selected}
                            />

                            <AccordionItemPanel className="accordion_items_container" id={`campaignitempanel${idx}`} key={`campaignitempanel${idx}`}>
                                {lomap(
                                    (adset, idx) => (
                                        <div className="asset_item_row" id={adset.asset_id} key={adset.asset_id}>
                                            <div className="select_checkbox">
                                                <input
                                                    type="checkbox"
                                                    checked={adset.selected}
                                                    onChange={(event) =>
                                                        onCheckAdset({
                                                            type: "adset",
                                                            campaign_id: campaign.asset_id,
                                                            adset_id: adset.asset_id,
                                                            checked: event.target.checked,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="name">{adset.name}</div>
                                        </div>
                                    ),
                                    get("adsets", matching({ campaign_id: campaign.campaign_id }))(ruleAssets)
                                )}
                            </AccordionItemPanel>
                        </AccordionItem>
                    ),
                    campaigns()
                )}
            </Accordion>
        </div>
    );
};

const AssetSelection = () => {
    const [rule] = useAtom(rule_atom);

    if (!rule.scope) return <div className="assets_container"></div>;

    if (rule.scope == "campaigns") return <CampaignsAssets />;

    if (rule.scope == "adsets") return <AdsetAssets />;
    if (rule.scope == "ads") {
        return <div className="assets_container"></div>;
    }
};

const AssetsTabs = () => {
    const [rule] = useAtom(rule_atom);
    const [ruleAssets, setRuleAssets] = useAtom(rule_assets_atom);

    const onSelectAll = () => {
        setRuleAssets(
            pipe(
                mod("campaigns", all, "selected")(() => true),
                mod("adsets", all, "selected")(() => true)
            )(ruleAssets)
        );
    };

    const onDeselectAll = () => {
        setRuleAssets(
            pipe(
                mod("campaigns", all, "selected")(() => false),
                mod("adsets", all, "selected")(() => false)
            )(ruleAssets)
        );
    };

    return (
        <div className="assets_tabs_container">
            <div className="tab_container" onClick={onSelectAll}>
                <div className="text">Select All</div>
            </div>
            <div className="tab_container" onClick={onDeselectAll}>
                <div className="text">Deselect All</div>
            </div>
        </div>
    );
};

const ExpressionLog = styled.div`
    background: ${({ status }) => (status == false ? "rgb(238, 238, 238, 0.4)" : "rgb(3, 135, 68, .1)")};
`;

const LogStatusLabel = styled.div`
    background: ${({ status }) => (status == "passed" ? "rgb(3, 135, 68, 0.5)" : "rgb(238, 238, 238, 0.4)")};
`;

const Log = ({ log }) => {
    return (
        <div className="log_container" key={log.log_id}>
            <div className="log_header_container">
                <div className="name">{log.asset_name}</div>
                <div className="asset_identification">
                    <div className="scope">{capitalize(pluralize.singular(log.scope))} ID: </div>
                    <div className="asset_id">{log.asset_id}</div>
                </div>
            </div>
            <div className="log_description_container">
                <div className="crated_at">{moment(log.created_at, "x").format("hh:mm A YYYY-MM-DD")}</div>

                {log.prev_value && (
                    <div className="prev_value">
                        <div className="label">Previous Value:</div>
                        <div className="value">{log.prev_value}</div>
                    </div>
                )}

                {log.updated_value && (
                    <div className="updated_value">
                        <div className="label">Updated Value:</div>
                        <div className="value">{log.updated_value}</div>
                    </div>
                )}

                <div className="status_data_container">
                    <div className="expression_status_totals">
                        <div className="value">{log.num_of_passed}</div>
                        <div className="label">Passed</div>
                    </div>
                    <div className="expression_status_totals">
                        <div className="value">{log.num_of_failed}</div>
                        <div className="label">Failed</div>
                    </div>
                    <LogStatusLabel status={log.status} className="status">
                        {log.status}
                    </LogStatusLabel>
                </div>
            </div>
            <div className="log_validations_container">
                {lomap(
                    (expression, idx) => (
                        <ExpressionLog status={expression.status} className="expression_container" key={idx}>
                            <div>{`${expression.status}` == "false" ? "Failed" : "Passed"}</div>
                            <div>{expression.metric}</div>
                            <div>{expression.predicate}</div>
                            <div>{expression.value}</div>
                            <div>{toFixed(2, expression.asset_value)}</div>
                            <div>{`${expression.start_date}`}</div>
                            <div>{`${expression.end_date}`}</div>
                        </ExpressionLog>
                    ),
                    log.validation
                )}
            </div>
        </div>
    );
};

const LogsQueryDocs = (logs) => ({
    prev_cursor: logs.docs[0],
    next_cursor: logs.docs[logs.docs.length - 1],
    data: logs.docs.map((log) => ({ ...log.data(), log_id: log.id })),
});

const current_offset_atom = atom(0);
const previous_cursors_atom = atom([]);
const next_cursors_atom = atom([]);
const cursor_direction_atom = atom("");

const RuleLogs = () => {
    const [user] = useAtom(user_atom);
    const [logs, setLogs] = useAtom(rule_logs_atom);
    const [filteredLogs, setFilteredLogs] = useAtom(filted_rule_logs_atom);
    const [currentOffset, setCurrentOffset] = useAtom(current_offset_atom);
    const [nextOffsets, setNextOffsets] = useAtom(next_cursors_atom);
    const [prevOffsets, setPrevOffsets] = useAtom(previous_cursors_atom);
    const [cursorDirection] = useAtom(cursor_direction_atom);

    let { rule_id } = useParams();

    useEffect(() => {
        let logs_query;

        const query_limit = 20;

        if (currentOffset == 0) {
            logs_query = getDocs(
                query(
                    collection(db, "rules_logs"),
                    where("rule_id", "==", rule_id),
                    where("user_id", "==", user.uid),
                    orderBy("created_at", "desc"),
                    limit(query_limit)
                )
            );
        }

        if (cursorDirection == "next" && currentOffset !== 0) {
            logs_query = getDocs(
                query(
                    collection(db, "rules_logs"),
                    where("rule_id", "==", rule_id),
                    where("user_id", "==", user.uid),
                    orderBy("created_at", "desc"),
                    startAfter(nextOffsets[currentOffset - 1]),
                    limit(query_limit)
                )
            );
        }

        if (cursorDirection == "prev" && currentOffset !== 0) {
            logs_query = getDocs(
                query(
                    collection(db, "rules_logs"),
                    where("rule_id", "==", rule_id),
                    where("user_id", "==", user.uid),
                    orderBy("created_at", "desc"),
                    startAt(prevOffsets[currentOffset]),
                    limit(query_limit)
                )
            );
        }

        from(logs_query)
            .pipe(rxmap(LogsQueryDocs))
            .subscribe((payload) => {
                let { data, prev_cursor, next_cursor } = payload;
                setNextOffsets(pipe(mod(currentOffset)(() => next_cursor))(nextOffsets));
                setPrevOffsets(pipe(mod(currentOffset)(() => prev_cursor))(prevOffsets));
                setFilteredLogs(data);
            });
    }, [currentOffset]);

    if (isEmpty(filteredLogs))
        return (
            <div className="rule_logs_container">
                <div className="empty_logs_container">No Logs Yet!</div>
            </div>
        );

    return (
        <div className="rule_logs_container">
            <div className="logs_container">
                {lomap(
                    (log, idx) => (
                        <Log log={log} key={idx} />
                    ),
                    filteredLogs
                )}
            </div>
        </div>
    );
};

const FailedRuleLogs = () => {
    const [user] = useAtom(user_atom);
    const [logs, setLogs] = useAtom(rule_logs_atom);
    const [filteredLogs, setFilteredLogs] = useAtom(filted_rule_logs_atom);
    const [currentOffset, setCurrentOffset] = useAtom(current_offset_atom);
    const [nextOffsets, setNextOffsets] = useAtom(next_cursors_atom);
    const [prevOffsets, setPrevOffsets] = useAtom(previous_cursors_atom);
    const [cursorDirection] = useAtom(cursor_direction_atom);

    let { rule_id } = useParams();

    useEffect(() => {
        let logs_query;

        const query_limit = 20;

        if (currentOffset == 0) {
            logs_query = getDocs(
                query(
                    collection(db, "rules_logs"),
                    where("rule_id", "==", rule_id),
                    where("user_id", "==", user.uid),
                    where("status", "==", "failed"),
                    orderBy("created_at", "desc"),
                    limit(query_limit)
                )
            );
        }

        if (cursorDirection == "next" && currentOffset !== 0) {
            logs_query = getDocs(
                query(
                    collection(db, "rules_logs"),
                    where("rule_id", "==", rule_id),
                    where("user_id", "==", user.uid),
                    where("status", "==", "failed"),
                    orderBy("created_at", "desc"),
                    startAfter(nextOffsets[currentOffset - 1]),
                    limit(query_limit)
                )
            );
        }

        if (cursorDirection == "prev" && currentOffset !== 0) {
            logs_query = getDocs(
                query(
                    collection(db, "rules_logs"),
                    where("rule_id", "==", rule_id),
                    where("user_id", "==", user.uid),
                    where("status", "==", "failed"),
                    orderBy("created_at", "desc"),
                    startAt(prevOffsets[currentOffset]),
                    limit(query_limit)
                )
            );
        }

        from(logs_query)
            .pipe(rxmap(LogsQueryDocs))
            .subscribe((payload) => {
                let { data, prev_cursor, next_cursor } = payload;
                setNextOffsets(pipe(mod(currentOffset)(() => next_cursor))(nextOffsets));
                setPrevOffsets(pipe(mod(currentOffset)(() => prev_cursor))(prevOffsets));
                setFilteredLogs(data);
            });
    }, [currentOffset]);

    if (isEmpty(filteredLogs))
        return (
            <div className="rule_logs_container">
                <div className="empty_logs_container">No Logs Yet!</div>
            </div>
        );

    return (
        <div className="rule_logs_container">
            <div className="logs_container">
                {lomap(
                    (log, idx) => (
                        <Log log={log} key={idx} />
                    ),
                    filteredLogs
                )}
            </div>
        </div>
    );
};

const PassedRuleLogs = () => {
    const [user] = useAtom(user_atom);
    const [logs, setLogs] = useAtom(rule_logs_atom);
    const [filteredLogs, setFilteredLogs] = useAtom(filted_rule_logs_atom);
    const [currentOffset, setCurrentOffset] = useAtom(current_offset_atom);
    const [nextOffsets, setNextOffsets] = useAtom(next_cursors_atom);
    const [prevOffsets, setPrevOffsets] = useAtom(previous_cursors_atom);
    const [cursorDirection] = useAtom(cursor_direction_atom);

    let { rule_id } = useParams();

    useEffect(() => {
        let logs_query;

        const query_limit = 20;

        if (currentOffset == 0) {
            logs_query = getDocs(
                query(
                    collection(db, "rules_logs"),
                    where("rule_id", "==", rule_id),
                    where("user_id", "==", user.uid),
                    where("status", "==", "passed"),
                    orderBy("created_at", "desc"),
                    limit(query_limit)
                )
            );
        }

        if (cursorDirection == "next" && currentOffset !== 0) {
            logs_query = getDocs(
                query(
                    collection(db, "rules_logs"),
                    where("rule_id", "==", rule_id),
                    where("user_id", "==", user.uid),
                    where("status", "==", "passed"),
                    orderBy("created_at", "desc"),
                    startAfter(nextOffsets[currentOffset - 1]),
                    limit(query_limit)
                )
            );
        }

        if (cursorDirection == "prev" && currentOffset !== 0) {
            logs_query = getDocs(
                query(
                    collection(db, "rules_logs"),
                    where("rule_id", "==", rule_id),
                    where("user_id", "==", user.uid),
                    where("status", "==", "passed"),
                    orderBy("created_at", "desc"),
                    startAt(prevOffsets[currentOffset]),
                    limit(query_limit)
                )
            );
        }

        from(logs_query)
            .pipe(rxmap(LogsQueryDocs))
            .subscribe((payload) => {
                let { data, prev_cursor, next_cursor } = payload;
                setNextOffsets(pipe(mod(currentOffset)(() => next_cursor))(nextOffsets));
                setPrevOffsets(pipe(mod(currentOffset)(() => prev_cursor))(prevOffsets));
                setFilteredLogs(data);
            });
    }, [currentOffset]);

    if (isEmpty(filteredLogs))
        return (
            <div className="rule_logs_container">
                <div className="empty_logs_container">No Logs Yet!</div>
            </div>
        );

    return (
        <div className="rule_logs_container">
            <div className="logs_container">
                {lomap(
                    (log, idx) => (
                        <Log log={log} key={idx} />
                    ),
                    filteredLogs
                )}
            </div>
        </div>
    );
};

const LogsPaginationArrows = () => {
    const [currentOffset, setCurrentOffset] = useAtom(current_offset_atom);
    const [cursorDirection, setCursorDirection] = useAtom(cursor_direction_atom);

    return (
        <div className="log_tabs_container" style={{ gridTemplateColumns: "auto auto" }}>
            <div
                className="tab"
                onClick={() => {
                    console.log(currentOffset);
                    if (currentOffset !== 0) {
                        setCursorDirection("prev");
                        setCurrentOffset(currentOffset - 1);
                    }
                }}
            >
                Previous
            </div>
            <div
                className="tab"
                onClick={() => {
                    setCursorDirection("next");
                    setCurrentOffset(currentOffset + 1);
                }}
            >
                Next
            </div>
        </div>
    );
};

const LogsTabs = () => {
    const [logs, setLogs] = useAtom(rule_logs_atom);
    const [filterdLogs, setFilterdLogs] = useAtom(filted_rule_logs_atom);
    const [, setLogStatus] = useAtom(logs_status_atom);
    const [, setCurrentOffset] = useAtom(current_offset_atom);
    const [, setNextOffsets] = useAtom(next_cursors_atom);
    const [, setPrevOffsets] = useAtom(previous_cursors_atom);

    const onFilterLogs = (status) => {
        setCurrentOffset(0);
        setNextOffsets([]);
        setPrevOffsets([]);
        setLogStatus(status);
    };

    return (
        <div className="log_tabs_container">
            <div className="tab" onClick={() => onFilterLogs("all")}>
                All
            </div>
            <div className="tab" onClick={() => onFilterLogs("failed")}>
                Failed
            </div>
            <div className="tab" onClick={() => onFilterLogs("passed")}>
                Passed
            </div>
        </div>
    );
};

const logs_status_atom = atom("all");

const Rule = () => {
    const [user] = useAtom(user_atom);
    const [rule, setRule] = useAtom(rule_atom);
    const [schedule, setSchedule] = useAtom(schedule_state);
    const [attribution, setAttribution] = useAtom(attribution_state);
    const [ruleAssets, setRuleAssets] = useAtom(rule_assets_atom);
    const [facebookAccount, setFacebookAccount] = useState({});
    const [logsStatus, setLogStatus] = useAtom(logs_status_atom);

    let { rule_id } = useParams();

    useEffect(() => {
        getDoc(doc(db, "rules", rule_id)).then((doc) => {
            let rule_from_db = doc.data();
            if (rule_from_db) {
                setRule(rule_from_db);
            }
        });
    }, []);

    useEffect(() => {
        let facebook_account_query = query(
            collectionGroup(db, "integrations"),
            where("user_id", "==", user.uid),
            where("account_name", "==", "facebook")
        );
        let facebook_account_doc = from(getDocs(facebook_account_query)).pipe(
            rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
            rxmap(head)
        );
        facebook_account_doc.subscribe((facebook_account) => {
            setFacebookAccount(facebook_account);
        });
    }, []);

    useEffect(() => {
        getDoc(doc(db, "rules", rule_id)).then((doc) => {
            let rule_from_db = doc.data();

            let rule_assets = pipe(defaultTo({ assets: {} }), get("assets"), map(lokeyby("asset_id")))(rule_from_db);
            let rule_assets_size = pipe(keys, size)(rule_assets);

            let campaigns_query = query(
                collectionGroup(db, "asset"),
                where("user_ids", "array-contains", user.uid),
                where("type", "==", "campaign"),
                where("effective_status", "==", "ACTIVE")
            );

            let adsets_query = query(
                collectionGroup(db, "asset"),
                where("user_ids", "array-contains", user.uid),
                where("type", "==", "adset"),
                where("effective_status", "==", "ACTIVE")
            );

            let campaigns_docs = from(getDocs(campaigns_query)).pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(get(matching({ user_ids: (user_ids) => size(user_ids) == 1 }))),
                rxmap(mod(all)(pick(["name", "account_id", "campaign_id", "is_cbo", "type", "asset_id"]))),
                rxmap(mod(all)((asset) => ({ ...asset, selected: false })))
            );

            let adsets_docs = from(getDocs(adsets_query)).pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(get(matching({ user_ids: (user_ids) => size(user_ids) == 1 }))),
                rxmap(mod(all)(pick(["name", "account_id", "adset_id", "campaign_id", "is_abo", "type", "asset_id"]))),
                rxmap(mod(all)((asset) => ({ ...asset, selected: false, is_open: false })))
            );

            zip([campaigns_docs, adsets_docs]).subscribe(([campaigns, adsets]) => {
                campaigns = pipe(lokeyby("asset_id"))(campaigns);
                adsets = pipe(lokeyby("asset_id"))(adsets);

                if (rule_assets_size > 0) {
                    campaigns = mergeDeepRight(campaigns, rule_assets.campaigns);
                    adsets = mergeDeepRight(adsets, rule_assets.adsets);
                    setRuleAssets({ adsets, campaigns });
                } else {
                    console.log("settingfromlocal");
                    setRuleAssets({ adsets, campaigns });
                }
            });
        });
    }, [facebookAccount]);

    const onDropdownClick = () => {
        if (schedule.dropdown_is_open) {
            setSchedule({ ...schedule, dropdown_is_open: false });
        } else {
            setSchedule({ ...schedule, dropdown_is_open: true });
        }
    };

    const onAttributionDropdownClick = () => {
        if (attribution.dropdown_is_open) {
            setAttribution({ ...attribution, dropdown_is_open: false });
        } else {
            setAttribution({ ...attribution, dropdown_is_open: true });
        }
    };

    const onSaveRule = () => {
        console.log("onSaveRule");
        let { uid: user_id } = user;
        let payload = {
            ...rule,
            user_id,
            fb_account_id: facebookAccount.fb_account_id,
            fb_access_token: facebookAccount.access_token,
            assets: ruleAssets,
        };

        setDoc(doc(db, "rules", rule_id), payload).then(() => {
            console.log("rule saved");
            console.log(payload);
        });
    };

    const onSetName = ({ target: { value } }) => {
        setRule({ ...rule, name: value });
    };

    const onSetDescription = ({ target: { value } }) => {
        setRule({ ...rule, description: value });
    };

    let isScheduleDropdownOpen = schedule.dropdown_is_open;
    let isAttributionDropdownOpen = attribution.dropdown_is_open;

    let rule_name = defaultTo("", path(["name"], rule));
    let rule_description = defaultTo("", path(["description"], rule));

    const onCheck = (checkedKeysValue) => {
        console.log("onCheck");
        console.log(checkedKeysValue);
    };

    const onSelect = (selectedKeysValue) => {
        console.log("onSelect");
        console.log(selectedKeysValue);
    };

    return (
        <div className="rule_view">
            <div className="header_container">
                <div className="title_and_actions">
                    <div className="title">
                        <TextareaAutosize rows={1} placeholder={"Untitled automation"} value={capitalize(rule_name)} onChange={onSetName} />
                    </div>

                    <div className="actions">
                        <div className="save" onClick={onSaveRule}>
                            Save
                        </div>
                    </div>
                </div>
                <div className="line_break"></div>
            </div>

            <div className="column_container">
                <div className="left">
                    <div className="rule_settings">
                        <div className="rule_container">
                            <div className="rule_scope_action_container">
                                <RuleScope />
                                <RuleAction />
                            </div>
                            <Conditions />
                        </div>

                        <div className="schedule_container">
                            <div className="title">Schedule</div>
                            <div className="description">Continuously run the rule or within a custom schedule.</div>
                            <div className="scheduler_dropdown_container">
                                <div className="select" onClick={onDropdownClick}>
                                    <div className="value">{`${rule.schedule.amount} ${rule.schedule.unit}`}</div>
                                    <div className="icon">
                                        <CaretDownOutlined />
                                    </div>
                                </div>
                                {isScheduleDropdownOpen && <ScheduleDropdown />}
                            </div>
                        </div>

                        <div className="notifications_container">
                            <div className="title">Notifications</div>
                            <div className="description">Get alerts about triggered actions and errors to email or in Slack.</div>
                            <div className="notifications_dropdown_container">
                                <div className="select">
                                    <div className="value">Select Channels</div>
                                    <div className="icon">
                                        <CaretDownOutlined />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="description_container">
                        <div className="textarea_container">
                            <TextareaAutosize rows={4} placeholder={"Add rule description"} value={rule_description} onChange={onSetDescription} />
                        </div>
                    </div> */}

                    {/* <div className="attributions_container">
                        <div className="title">Attribution window</div>
                        <div className="description">
                            The number of days between when a person viewed or clicked your ad and subsequently took an action.
                        </div>
                        <div className="switch_label_container">
                            <div className="switch">
                                <Switch defaultChecked onChange={onFlipSwitch} />
                            </div>
                            <div className="label">Use ad set attribution window</div>
                        </div>
                        <div className="attributions_dropdown_container">
                            <div className="select" onClick={onAttributionDropdownClick}>
                                <div className="value">Select Window</div>
                                <div className="icon">
                                    <CaretDownOutlined />
                                </div>
                                {isAttributionDropdownOpen && <AttributionDropdown />}
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="right">
                    <AssetsTabs />
                    <AssetSelection />
                </div>
            </div>

            <div className="rule_logs_and_tabs_container">
                <div className="flex flex-row justify-between">
                    <LogsTabs />
                    <LogsPaginationArrows />
                </div>

                {logsStatus == "all" && <RuleLogs />}
                {logsStatus == "failed" && <FailedRuleLogs />}
                {logsStatus == "passed" && <PassedRuleLogs />}
            </div>
        </div>
    );
};

export default Rule;
