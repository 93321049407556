import { atom } from "jotai";

export const advertisers_view_state_atom = atom({
    q: "",
    modal_is_open: true,
    selected_advertiser_id: "",
    selected_advertiser: {},
    is_loading: false,
});

export const advertiser_atom = atom({});
export const advertisers_atom = atom({});
