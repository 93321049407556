import React from "react";
import { Input } from "antd";
import { SearchOutlined, SaveOutlined, LoadingOutlined, BarChartOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { audience_view_state_atom, interest_atom, interests_atom } from "../state/audiences";
import { app_atom } from "../state/app";
import { db, httpsCallable, functions } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { lomap, losortby, toFixed } from "../../utilities/helpers";
import { from, map as rxmap } from "rxjs";
import { prop, defaultTo, pipe, head, pick, reverse, values } from "ramda";
import { toNumber } from "lodash";
import { get, matching } from "shades";
import ScreenModal from "../components/ScreenModal";
import AudiencesTopNav from "../components/AudiencesTopNav";
import { user_atom } from "../state/auth";
import InterestInsights from "../components/InterestInsights";

const interestsFn = httpsCallable(functions, "interests");
const suggestions = httpsCallable(functions, "suggestions");

const Loading = () => {
    return (
        <div className="loading_view">
            <div className="loading_container">
                <LoadingOutlined />
            </div>
        </div>
    );
};

const InterestsSearch = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);
    const [audienceViewState, setAudienceViewState] = useAtom(audience_view_state_atom);
    const [interests, setInterests] = useAtom(interests_atom);

    const onSetQuery = ({ target: { value: q } }) => {
        if (q) {
            setAudienceViewState({ ...audienceViewState, q });
        }
    };

    const onSearch = () => {
        setAudienceViewState({ ...audienceViewState, is_loading: true });

        from(interestsFn({ q: audienceViewState.q }))
            .pipe(rxmap(prop("data")))
            .subscribe((data) => {
                setAudienceViewState({ ...audienceViewState, is_loading: false });
                setInterests({ ...interests, ...data });
            });
    };

    const onGetSuggestions = ({ interest_id, interest_name }) => {
        setAudienceViewState({ ...audienceViewState, is_loading: true });

        from(suggestions({ interest_id, interest_name }))
            .pipe(rxmap(prop("data")))
            .subscribe((data) => {
                setAudienceViewState({ ...audienceViewState, is_loading: false });
                setInterests({ ...interests, ...data });
            });
    };

    const onSelectInterst = (interest_id) => {
        let interest = pipe(get(matching({ interest_id })))(interests);
        setAudienceViewState({ ...audienceViewState, selected_interest_id: interest_id, selected_interest: interest });
        setApp({ ...app, modal_is_open: true, modal_id: "interest_insights" });
    };

    const onSaveInterest = (interest_id) => {
        let interest = pipe(get(matching({ interest_id })), values, head)(interests);

        let payload = {
            ...interest,
            user_id: user.uid,
        };

        let doc_ref = doc(db, "audiences", user.uid, "interests", interest_id);
        setDoc(doc_ref, payload, { merge: true }).then(() => {
            console.log(`saved interest ${interest_id}`);
        });
    };

    return (
        <div className="audiences_view">
            {/* {audienceViewState.is_loading && <Loading />} */}
            <ScreenModal id="interest_insights">
                <InterestInsights />
            </ScreenModal>
            <AudiencesTopNav />

            <div className="audiences_finder_container">
                <div className="search_header_container">
                    <div className="search_container">
                        <div className="search_bar">
                            <Input size="large" onChange={onSetQuery} />
                        </div>

                        <div className="search_icon">
                            <SearchOutlined onClick={onSearch} />
                        </div>
                    </div>
                </div>

                <div className="audience_table_container">
                    <div className="table_container">
                        <div className="audience_table_tabs">
                            <div className="tab_cell">Name</div>
                            <div className="tab_cell">Size</div>
                            <div className="tab_cell">Male</div>
                            <div className="tab_cell">Female</div>
                            <div className="tab_cell">Age</div>
                            <div className="tab_cell"></div>
                        </div>
                        {lomap(
                            (interest, interest_id) => (
                                <div className="audience_row" key={interest_id}>
                                    <div className="audience_cell name_audience_cell" onClick={() => onSelectInterst(interest_id)}>
                                        {interest.interest_name}
                                    </div>
                                    <div className="audience_cell">
                                        {pipe(prop("audience_size"), Number, (value) => value.toLocaleString())(interest)}
                                    </div>
                                    <div className="audience_cell">
                                        {pipe(
                                            get("gender", matching({ name: "Male" })),
                                            defaultTo([]),
                                            head,
                                            defaultTo({}),
                                            prop("value"),
                                            defaultTo(0)
                                        )(interest)}
                                        %
                                    </div>
                                    <div className="audience_cell">
                                        {pipe(
                                            get("gender", matching({ name: "Female" })),
                                            defaultTo([]),
                                            head,
                                            defaultTo({}),
                                            prop("value"),
                                            defaultTo(0)
                                        )(interest)}
                                        %
                                    </div>
                                    <div className="audience_cell">
                                        {pipe(
                                            get("gender_age"),
                                            defaultTo([]),
                                            losortby("percentage_total"),
                                            reverse,
                                            head,
                                            defaultTo({}),
                                            pick(["product", "percentage_total"]),
                                            (value) => (
                                                <div className="age_row">
                                                    <div className="age">{value.product || 0}</div>
                                                    <div className="percentage">
                                                        {pipe(prop("percentage_total"), toNumber, toFixed(3), defaultTo(0))(value)}%
                                                    </div>
                                                </div>
                                            )
                                        )(interest)}
                                    </div>
                                    <div className="actions">
                                        <div className="stats" onClick={() => onSelectInterst(interest_id)}>
                                            <BarChartOutlined />
                                        </div>

                                        <div className="save">
                                            <SaveOutlined className="icon" onClick={() => onSaveInterest(interest_id)} />
                                        </div>

                                        <div className="suggestions">
                                            <SearchOutlined
                                                className="icon"
                                                onClick={() => onGetSuggestions({ interest_name: interest.interest_name, interest_id })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ),
                            interests
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InterestsSearch;
