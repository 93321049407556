import React from "react";
import { flip } from "ramda";
import { map as lodashmap } from "lodash";
import { useAtom } from "jotai";
import { budget_types_atom, rule_atom, rule_state } from "../state/rule";

const lomap = flip(lodashmap);

const BudgetTypeDropdown = () => {
    const [rule, setRule] = useAtom(rule_atom);
    const [budgetTypesDropdown] = useAtom(budget_types_atom);
    const [ruleState, setRuleState] = useAtom(rule_state);

    const onSelect = (type) => {
        setRule({ ...rule, budget: { ...rule.budget, type } });
        setRuleState({ ...ruleState, budget_types_dropdown_is_open: false });
    };

    return (
        <div className="actions_dropdown">
            {lomap(
                (value, key) => (
                    <div className="metric_field" key={key} onClick={() => onSelect(key)}>
                        {value}
                    </div>
                ),
                budgetTypesDropdown
            )}
        </div>
    );
};

export default BudgetTypeDropdown;
