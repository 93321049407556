import React, { useEffect } from "react";
import { user_atom } from "../state/auth";
import { project_atom } from "../state/projects";
import { useAtom } from "jotai";
import { db } from "../firebase";
import { doc, query, collectionGroup, where, getDocs, getDoc, collection } from "firebase/firestore";
import { map as rxmap, from } from "rxjs";
import { head } from "ramda";
import ProjectNav from "../components/ProjectNav";
import TrackingScriptDropdown from "../components/TrackingScriptDropdown";
import TrackingCodeSnippetSection from "../components/TrackingCode";

const FacebookAccountDetails = () => {
    const [user, setUser] = useAtom(user_atom);
    const [project, setProject] = useAtom(project_atom);

    useEffect(() => {
        let user_fb_ref = doc(db, "accounts", user.uid);
        from(getDoc(user_fb_ref))
            .pipe(rxmap((doc) => doc.data()))
            .subscribe((user_account) => {
                let {
                    roas_email,
                    roas_facebook_ad_account_id,
                    roas_shopping_cart,
                    roas_payment_processor,
                    roas_user_name,
                    roas_first_name,
                    roas_last_name,
                } = user_account;
            });
    }, []);

    useEffect(() => {
        let user_fb_ref = query(collectionGroup(db, "integrations"), where("user_id", "==", user.uid), where("account_name", "==", "facebook"));
        from(getDocs(user_fb_ref))
            .pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(head)
            )
            .subscribe((fb_account_data) => {
                let { email, first_name, last_name } = fb_account_data;
            });
    }, []);

    return (
        <div className="facebook_account_details mt-10 divide-y divide-gray-200 divide-solid">
            <div className="space-y-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
                <p className="max-w-2xl text-sm text-gray-500">This information will be displayed publicly so be careful what you share.</p>
            </div>
            <div className="mt-6">
                <dl className="divide-y divide-gray-200 divide-solid">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Name</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">Chelsea Hagon</span>
                            <span className="ml-4 flex-shrink-0">
                                <button
                                    type="button"
                                    className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Update
                                </button>
                            </span>
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">Photo</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                                <img
                                    className="h-8 w-8 rounded-full"
                                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    alt=""
                                />
                            </span>
                            <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                                <button
                                    type="button"
                                    className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Update
                                </button>
                                <span className="text-gray-300" aria-hidden="true">
                                    |
                                </span>
                                <button
                                    type="button"
                                    className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Remove
                                </button>
                            </span>
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">chelsea.hagon@example.com</span>
                            <span className="ml-4 flex-shrink-0">
                                <button
                                    type="button"
                                    className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Update
                                </button>
                            </span>
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                        <dt className="text-sm font-medium text-gray-500">Job title</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">Human Resources Manager</span>
                            <span className="ml-4 flex-shrink-0">
                                <button
                                    type="button"
                                    className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Update
                                </button>
                            </span>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
};

const ProjectTracking = () => {
    const [user, setUser] = useAtom(user_atom);
    const [project, setProject] = useAtom(project_atom);

    useEffect(() => {
        let project_ref = query(collection(db, "projects"), where("roas_user_id", "==", user.uid));
        from(getDocs(project_ref))
            .pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(head)
            )
            .subscribe((project_data) => {
                setProject({ ...project, ...project_data });
            });
    }, []);

    const onSelectWebHost = (option) => {
        setProject({ ...project, shopping_cart_id: option.id });
    };

    return (
        <div className="project_container overflow-scroll">
            <main className="flex-1">
                <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
                    <div className="pt-10 pb-5">
                        <div className="px-4 sm:px-6 md:px-0">
                            <h1 className="text-3xl font-extrabold text-gray-900">Account Settings</h1>
                        </div>
                        <div className="px-4 sm:px-6 md:px-0">
                            <div className="py-6">
                                <ProjectNav selected_tab={"tracking"} />
                                <div className="my-[15px]">
                                    <TrackingScriptDropdown selected_id={project.shopping_cart_id} onSelectCallback={onSelectWebHost} />
                                </div>
                                <TrackingCodeSnippetSection selected_id={project.shopping_cart_id} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ProjectTracking;
