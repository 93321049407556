import React, { useEffect, useState, Fragment } from "react";
import { useAtom } from "jotai";
import { user_atom, facebook_accounts_atom } from "../state/auth";
import { slider_atom, onboarding_atom } from "../state/onboarding";
import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { map, flip, has, pipe, pick, includes, head, isEmpty, keys, sum, identity } from "ramda";
import { map as lodashmap, keyBy, size, toLower } from "lodash";
import { db } from "../firebase";
import { getDocs, query, where, collection, getDoc, doc, onSnapshot, collectionGroup, setDoc, arrayUnion } from "firebase/firestore";
import { from, map as rxmap, zip, of as rxof, filter as rxfilter, tap, iif, defaultIfEmpty, concatMap } from "rxjs";
import { mod, matching, get, all } from "shades";
import { project_atom } from "../state/projects";
const { lomap, domPipeLog: pipeLog, lokeyby } = require("../../utilities/helpers");
const { io } = require("socket.io-client");

const set_project = (user_id, payload) => {
    let project_ref = doc(db, "projects", user_id);
    return from(setDoc(project_ref, { roas_user_id: user_id, ...payload }, { merge: true })).pipe(rxmap(() => payload));
};

const FacebookAdAccounts = () => {
    const [user] = useAtom(user_atom);
    const [fbAccounts, setFbAccounts] = useAtom(facebook_accounts_atom);
    const [filteredFbAccounts, setFilteredFbAccounts] = useState({});
    const [project, setProject] = useAtom(project_atom);

    useEffect(() => {
        let ad_accounts_query = query(collection(db, "fb_ad_accounts"), where("user_ids", "array-contains", user.uid));

        from(getDocs(ad_accounts_query))
            .pipe(
                rxmap((accounts) => accounts.docs.map((account) => account.data())),
                rxmap(pipe(lokeyby("account_id"))),
                rxmap(map(pick(["account_id", "name"]))),
                rxmap(map((value) => ({ ...value, selected: false })))
            )
            .subscribe((accounts) => {
                if (isEmpty(accounts)) {
                    get_fb_ad_accounts();
                } else {
                    setFbAccounts(accounts);
                }
            });
    }, []);

    useEffect(() => {
        if (size(fbAccounts) > 0) {
            setFilteredFbAccounts(fbAccounts);
        }
    }, [fbAccounts]);

    const onSelectAdAccount = (fb_ad_account_id) => {
        from(set_project(user.uid, { fb_ad_account_id })).subscribe(() => {
            setProject({ ...project, fb_ad_account_id, is_selecting_ad_account: false });
        });
    };

    const onFilterFbAccounts = ({ target: { value } }) => {
        setFilteredFbAccounts(pipe(get(matching({ name: pipe(toLower, includes(value)) })))(fbAccounts));
    };

    const AdAccounts = () => {
        return (
            <div className="facebook_accounts_list_container">
                {lomap(
                    (account) => (
                        <div
                            className="ad_account_container flex flex-row justify-between px-[15px] py-[5px] text-sm"
                            key={account.account_id}
                            onClick={() => onSelectAdAccount(account.account_id)}
                        >
                            <div className="ad_account_left flex flex-row items-center justify-center">
                                <div className="select mr-[10px]">
                                    <Checkbox
                                        onChange={() => onSelectAdAccount(account.account_id)}
                                        checked={project.fb_ad_account_id == account.account_id}
                                    ></Checkbox>
                                </div>
                                <div className="name">{account.name}</div>
                            </div>
                            <div className="ad_account_right flex flex-row items-center justify-center">
                                <div className="name">{account.account_id}</div>
                            </div>
                        </div>
                    ),
                    filteredFbAccounts
                )}
            </div>
        );
    };

    const LoadingAdAccounts = () => {
        return (
            <div className="facebook_accounts_list_container loading_fb_accounts_container">
                <div className="loading_icon_container">
                    <div className="text">Loading</div>
                    <div className="icon">
                        <LoadingOutlined />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="carousel_view_container" id="facebook_accounts" key="facebook_accounts">
            <div className="facebook_accounts_container">
                <div className="facebook_accounts_search_filter_container">
                    <input type="text" placeholder="Search by account name" onChange={onFilterFbAccounts} />
                </div>

                {isEmpty(filteredFbAccounts) && <LoadingAdAccounts />}
                {!isEmpty(filteredFbAccounts) && <AdAccounts />}
            </div>
        </div>
    );
};

export default FacebookAdAccounts;
