import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { shopify_atom } from "../state/shopify";
import { user_atom } from "../state/auth";
import { setDoc, doc, getDoc, getDocs, collection, query, where, orderBy, limit } from "firebase/firestore";
import { db } from "../firebase";
import { from, isEmpty, map as rxmap } from "rxjs";
import { prop, pipe, head, split } from "ramda";
import { isEmpty, isNil, isNull, isUndefined } from "lodash";
const ShopifyLogo = require("../assets/shopify_logo.png");

const is_falsy = (value) => isNull(value) || isNil(value) || isEmpty(value) || isUndefined(value);

const ConnectShopify = () => {
    console.log("ConnectShopify");
    const [user] = useAtom(user_atom);
    const [shopifyStoreUrl, setShopifyStoreUrl] = useState("");
    let navigate = useNavigate();

    const onUpdateShopifyUrl = ({ target: { value } }) => {
        setShopifyStoreUrl(value);
    };

    const onConnectShopify = () => {
        let is_valid_shopify_url = pipe((value) => value.includes(".myshopify.com"))(shopifyStoreUrl);

        if (is_valid_shopify_url) {
            let shop = pipe(split("."), head)(shopifyStoreUrl);
            window.location = `https://us-central1-roas-e44b8.cloudfunctions.net/app/auth/shopify?user_id=${user.uid}&shop=${shop}`;
        }
    };

    return (
        <div className="connect_shopify_container">
            <div className="content">
                <div className="logo">
                    <img src={ShopifyLogo} width={200} />
                </div>
                <div className="shop_url_input_container">
                    <div className="input">
                        <input type="text" placeholder="Shopify URL: shop.myshopify.com" value={shopifyStoreUrl} onChange={onUpdateShopifyUrl} />
                    </div>
                </div>
                <div className="connect_button_container">
                    <div className="button" onClick={onConnectShopify}>
                        Connect
                    </div>
                </div>
            </div>
        </div>
    );
};

const Settings = () => {
    const [shopifyAccount, setShopifyAccount] = useAtom(shopify_atom);

    const onToggleIntegrationStatus = () => {};

    return (
        <div className="settings_container">
            <div className="settings_nav_container">
                <div className="nav">
                    <div className="nav_item">Basic</div>
                </div>
            </div>

            <div className="content_container">
                <div className="content">
                    <div className="title">Basic Settings</div>
                    <div className="setting_item_container">
                        <div className="label">Shopify Store URL *:</div>
                        <div className="value">
                            <div className="read_only_input">{`${shopifyAccount.shop}.myshopify.com`}</div>
                        </div>
                    </div>

                    <div className="setting_item_container">
                        <div className="label">Enable Integration:</div>
                        <div className="value">
                            <div className="switch_input">
                                <div className="switch">
                                    <Switch defaultChecked onChange={onToggleIntegrationStatus} />
                                </div>
                                <div className="switch_label">Enabling this integration will allow it to send data to ROAS.</div>
                            </div>
                        </div>
                    </div>

                    <div className="integration_action_buttons_container">
                        <div className="integration_button_container delete">
                            <div className="icon">
                                <DeleteOutlined />
                            </div>
                            <div className="label">Delete</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ShopifySsettings = () => {
    const [user] = useAtom(user_atom);
    const [shopifyAccount, setShopifyAccount] = useAtom(shopify_atom);
    const is_connected_keys = ["shop", "access_token"];
    const is_connected = is_connected_keys.every((key) => Object.keys(shopifyAccount).includes(key));

    useEffect(() => {
        from(getDoc(doc(db, "accounts", user.uid)))
            .pipe(
                rxmap((value) => value.data()),
                rxmap(prop("shopify"))
            )
            .subscribe((shopify_account) => {
                if (shopify_account) {
                    setShopifyAccount(shopify_account);
                }
            });
    }, []);

    return (
        <div className="shopify_settings_view">
            {!is_connected && is_falsy(shopifyAccount) && <ConnectShopify />}
            {is_connected && <Settings />}
        </div>
    );
};

export default ShopifySsettings;
