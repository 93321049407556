import { atom } from "jotai";
import { user_accounts_atom } from "./auth";
import { map, flip, has, pipe, pick, includes } from "ramda";

export const facebook_carousel_atom = atom((get) => {
    let {
        roas_has_facebook_connected,
        roas_has_selected_facebook_ad_account,
        roas_has_facebook_ad_account_data,
        roas_onboard_step,
        active_fb_index,
    } = get(user_accounts_atom);

    let overrides = ["ConnectToFacebook", "FacebookAccounts", "FacebookDone"];

    if (overrides.includes(roas_onboard_step)) {
        if (roas_onboard_step == "ConnectToFacebook") {
            return roas_onboard_step;
        }

        if (roas_onboard_step == "FacebookAccounts" && roas_has_facebook_connected) {
            return roas_onboard_step;
        }

        if (roas_onboard_step == "FacebookDone" && roas_has_facebook_connected && roas_has_selected_facebook_ad_account) {
            return roas_onboard_step;
        }

        return active_fb_index;
    }

    if (roas_has_facebook_connected && !roas_has_selected_facebook_ad_account && !roas_has_facebook_ad_account_data) {
        return "FacebookAccounts";
    }

    if (roas_has_facebook_connected && roas_has_selected_facebook_ad_account && !roas_has_facebook_ad_account_data) {
        return "FacebookDone";
    }

    return "ConnectToFacebook";
});

export const payment_processor_carousel_atom = atom((get) => {
    let {
        roas_has_selected_payment_processor,
        roas_payment_processor,
        roas_onboard_step,
        active_payment_processor_index,
        roas_has_initiated_payment_processor_connection,
    } = get(user_accounts_atom);

    let overrides = ["SelectPaymentProcessor", "PaymentProcessorDone"];

    if (overrides.includes(roas_onboard_step)) {
        if (roas_onboard_step == "SelectPaymentProcessor") {
            return roas_onboard_step;
        }

        if (roas_onboard_step == "PaymentProcessorDone" && roas_has_initiated_payment_processor_connection) {
            return roas_onboard_step;
        }

        return active_payment_processor_index;
    }

    if (roas_has_selected_payment_processor && roas_payment_processor == "stripe" && !roas_has_initiated_payment_processor_connection) {
        return "ConnectToStripePaymentProcessor";
    }

    if (roas_has_selected_payment_processor && roas_payment_processor == "shopify" && !roas_has_initiated_payment_processor_connection) {
        return "ConnectToShopifyPaymentProcessor";
    }

    if (roas_has_selected_payment_processor && roas_payment_processor && roas_has_initiated_payment_processor_connection) {
        return "PaymentProcessorDone";
    }

    return "SelectPaymentProcessor";
});

export const shopping_cart_carousel_atom = atom((get) => {
    let {
        roas_has_selected_shopping_cart,
        roas_has_shopping_cart_connected,
        roas_shopping_cart,
        roas_onboard_step,
        active_shopping_cart_index,
        roas_has_initiated_shopping_cart_connection,
    } = get(user_accounts_atom);

    let overrides = ["SelectShoppingCart", "ShoppingCartDone"];

    if (overrides.includes(roas_onboard_step)) {
        if (roas_onboard_step == "SelectShoppingCart") {
            return roas_onboard_step;
        }

        if (roas_onboard_step == "ShoppingCartDone" && roas_has_initiated_shopping_cart_connection) {
            return roas_onboard_step;
        }

        return active_shopping_cart_index;
    }

    if (roas_has_selected_shopping_cart && roas_shopping_cart == "clickfunnels" && !roas_has_initiated_shopping_cart_connection) {
        return "ConnectToClickFunnelsShopingCart";
    }

    if (roas_has_selected_shopping_cart && roas_shopping_cart == "shopify" && !roas_has_initiated_shopping_cart_connection) {
        return "ConnectToShopifyShopingCart";
    }

    if (roas_has_selected_shopping_cart && roas_shopping_cart == "kajabi" && !roas_has_initiated_shopping_cart_connection) {
        return "ConnectToKajabiShopingCart";
    }

    if (roas_has_selected_shopping_cart && roas_shopping_cart == "woocommerce" && !roas_has_initiated_shopping_cart_connection) {
        return "ConnectToWoocommerceShopingCart";
    }

    if (roas_has_selected_shopping_cart && roas_shopping_cart == "thrivecart" && !roas_has_initiated_shopping_cart_connection) {
        return "ConnectToThriveCartShopingCart";
    }

    if (roas_has_selected_shopping_cart && roas_shopping_cart == "kartra" && !roas_has_initiated_shopping_cart_connection) {
        return "ConnectToKartraShopingCart";
    }

    if (roas_has_selected_shopping_cart && roas_shopping_cart == "keap" && !roas_has_initiated_shopping_cart_connection) {
        return "ConnectToKeapShopingCart";
    }

    if (roas_has_selected_shopping_cart && roas_shopping_cart == "samcart" && !roas_has_initiated_shopping_cart_connection) {
        return "ConnectToSamCartShopingCart";
    }

    if (roas_has_selected_shopping_cart && roas_has_initiated_shopping_cart_connection) {
        return "ShoppingCartDone";
    }

    return "SelectShoppingCart";
});

export const code_snippet_carousel_atom = atom((get) => {
    let { has_js_snippet } = get(user_accounts_atom);

    if (has_js_snippet) {
        return "CodeSnippetDone";
    }

    return "CodeSnippetSection";
});

export const facebook_ad_id_carousel_atom = atom((get) => {
    let { roas_has_initiated_adding_ad_id_paramater_on_ads, roas_onboard_step, active_utm_index } = get(user_accounts_atom);
    console.log("facebook_ad_id_carousel_atom");

    let overrides = ["AddFacebookAdId", "FaceobokAdIdParamaterAddedtDone"];

    if (overrides.includes(roas_onboard_step)) {
        if (roas_onboard_step == "AddFacebookAdId") {
            return roas_onboard_step;
        }

        if (roas_onboard_step == "FaceobokAdIdParamaterAddedtDone" && roas_has_initiated_adding_ad_id_paramater_on_ads) {
            return roas_onboard_step;
        }

        return active_utm_index;
    }

    if (roas_has_initiated_adding_ad_id_paramater_on_ads) {
        return "FaceobokAdIdParamaterAddedtDone";
    }

    return "AddFacebookAdId";
});

export const status_button_atom = atom((get) => {
    let user_account = get(user_accounts_atom);
    let { roas_has_facebook_connected, roas_has_selected_facebook_ad_account, roas_has_selected_payment_processor, roas_has_selected_shopping_cart } =
        user_account;

    if (
        roas_has_facebook_connected &&
        roas_has_selected_facebook_ad_account &&
        roas_has_selected_payment_processor &&
        roas_has_selected_shopping_cart
    ) {
        console.log("done onboarding");
        return true;
    }
});

export const accordion_atom = atom({
    facebook: { is_open: true },
    payment_processor: { is_open: false },
    shopping_cart: { is_open: false },
    facebook_utm: { is_open: false },
});

export const slider_atom = atom({
    autoScrolling: false,
    section: 1,
    slide: "1",
    first_render: true,
});

export const confirmation_modal_atom = atom({
    is_open: false,
    slide: "1",
    text: "",
    checkbox_description: "",
    is_confirmed: false,
});

export const onboarding_atom = atom({
    current_slide: "1",
    roas_has_seen_finish_setup_video: true,
    roas_has_finished_onboarding: false,
    roas_has_facebook_connected: false,
    roas_facebook_ad_account_id: 0,
    roas_tracking_host: "",
    roas_tracking_host_confirmed: false,
    roas_shopping_cart: "",
    roas_shopping_cart_confirmed: false,
    roas_ad_tracking_confirmed: false,
});
