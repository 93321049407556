import React from "react";
import { flip, lensPath, set } from "ramda";
import { map as lodashmap, size } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { rule_atom } from "../state/rule";
import styled from "styled-components";
import uniqid from "uniqid";
import AdsManagerExpressions from "./AdsManagerExpressions";

const lomap = flip(lodashmap);

const ConditionsContainer = styled.div`
    grid-template-columns: ${({ numOfConditions }) => (numOfConditions > 1 ? "30px auto" : "auto")};
`;

const ConditionsGrid = styled.div`
    grid-column: ${({ numOfConditions }) => (numOfConditions > 1 ? "2/2" : "1/1")};
`;

const AdsManagerConditions = () => {
    const [rule, setRule] = useAtom(rule_atom);
    const numOfConditions = size(rule.conditions);

    const onAddGroup = () => {
        let condition_id = uniqid();
        let expression_id = uniqid();
        let condition_path = ["conditions", condition_id];

        let payload = {
            id: condition_id,
            path: condition_path,
            expressions: {
                [expression_id]: {
                    condition_id,
                    id: expression_id,
                    path: ["conditions", condition_id, "expressions", expression_id],
                    metric: { value: "roasspend", text: "Spend" },
                    timeframe: {
                        category: "most_common",
                        text: "Today",
                        args: {
                            unit: "days",
                            value: 0,
                            include_today: true,
                        },
                    },
                    predicate: { text: "Greater than", value: "gt" },
                    value: 0,
                },
            },
        };

        setRule(set(lensPath(condition_path), payload, rule));
    };

    return (
        <ConditionsContainer className="conditions_container" numOfConditions={numOfConditions}>
            {numOfConditions > 1 && (
                <div className="expressions_operand_bar">
                    <div className="background">
                        <div className="text">OR</div>
                    </div>
                </div>
            )}

            <ConditionsGrid className="conditions_grid" numOfConditions={numOfConditions}>
                {lomap(
                    (condition, key) => (
                        <AdsManagerExpressions key={key} condition={condition} />
                    ),
                    rule.conditions
                )}
            </ConditionsGrid>

            {numOfConditions < 1 && (
                <div className="add_filter_button" onClick={onAddGroup}>
                    <div className="icon">
                        <PlusOutlined />
                    </div>
                    <div className="text">Add Filter</div>
                </div>
            )}
        </ConditionsContainer>
    );
};

export default AdsManagerConditions;
