import React, { useRef, useEffect, useState, useCallback } from "react";
import { DatePicker, InputNumber, Switch, Button } from "antd";
const { RangePicker } = DatePicker;

import {
    FilterOutlined,
    CloseOutlined,
    TableOutlined,
    SortAscendingOutlined,
    SmallDashOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    CloseCircleOutlined,
    TeamOutlined,
    PlusOutlined,
    EllipsisOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { doc, getDocs, query, collection, where, getDoc, collectionGroup, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAtom } from "jotai";
import { user_atom, user_accounts_atom } from "../state/auth";
import { rule_atom } from "../state/rule";
import { app_atom } from "../state/app";
import { onboarding_atom, slider_atom } from "../state/onboarding";

import {
    ads_manager_atom,
    tabs,
    all_ads_manager_columns,
    selected_ads_manager_columns,
    selected_data_atom,
    ads_manager_state_atom,
    draft_ads_manager_atom,
    draft_ads_manager_columns,
} from "../state/ads_manager";

import {
    __,
    map,
    sum,
    path,
    flip,
    without,
    pipe,
    values,
    uniq,
    prop,
    mergeDeepRight,
    filter,
    head,
    reject,
    append,
    join,
    init,
    indexOf,
    sortBy,
    tail,
    defaultTo,
    curry,
    flatten,
    view,
    dissocPath,
    lensPath,
    groupBy,
    split,
    of,
    objOf,
    pick,
    anyPass,
    reverse,
    pathOr,
    last,
    identity,
    drop,
    ifElse,
    keys,
    concat,
    omit,
    includes,
    take,
} from "ramda";

import {
    truncate as lodashtruncate,
    keyBy as lodashKeyBy,
    map as lodashmap,
    size,
    isEmpty,
    filter as lodashFilter,
    isFinite,
    isUndefined,
    uniqBy as lodashUniqBy,
    toNumber,
    sortBy as lodashSortBy,
    reduce as lodashreduce,
    toLower,
    has,
    groupBy as lodashGroupBy,
    isNil,
} from "lodash";

import AdsManagerConditions from "../components/AdsManagerConditions";
import ScreenModal from "../components/ScreenModal";
import AdCreation from "./AdCreation";
import moment from "moment";
import { get, all, mod, matching } from "shades";
import { List, arrayMove } from "react-movable";
import { from, of as rxof, BehaviorSubject, defaultIfEmpty, timestamp } from "rxjs";
import {
    switchMap,
    concatMap,
    map as rxmap,
    distinctUntilChanged,
    debounceTime,
    filter as rxfilter,
    startWith,
    tap,
    reduce as rxreduce,
    distinctUntilChanged,
} from "rxjs/operators";
import objflatten, { unflatten } from "flat";
import { lokeyby, losortby, logroupby, loget, loorderby, lofilter, louniqby } from "../../utilities/helpers";
import { ad_launcher_asset_atom, ad_launcher_initial_state } from "../state/ad_launcher";
import pluralize from "pluralize";
var blurred_bg = require("../assets/Screen Shot 2022-05-24 at 5.13.40 PM.png");
import { InlineWidget } from "react-calendly";
import ReactPlayer from "react-player";

function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };
    while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
}
const truncate = flip(lodashtruncate);
const lomap = flip(lodashmap);
const loKeyBy = flip(lodashKeyBy);
const loFilter = flip(lodashFilter);
const loUniqBy = flip(lodashUniqBy);
const loSortBy = curry((props, data) => lodashSortBy(data, props));
const loreduce = curry((transformFn, data) => lodashreduce(data, transformFn));
const lohas = flip(has);
const loGroupBy = flip(lodashGroupBy);
const isNullString = (string) => string == "null";
const clean = pipe(objflatten, reject(anyPass([isNil, isUndefined, isNullString])), unflatten);

const pipeLog = (value) => {
    console.log("pipeLog");
    console.log(value);
    return value;
};

const toFixed = (fixed, num) => {
    let [front = "0", back = ""] = pipe((value) => value.toString(), split("."))(num);
    back = pipe(take(fixed))(back);

    return pipe(
        ifElse(
            isEmpty,
            () => front,
            () => `${front}.${back}`
        )
    )(back);
};

const raToFixed = flip(toFixed);

const numOrZero = (value) => {
    return isNaN(value) || !isFinite(value) || isUndefined(value) ? 0 : toNumber(value);
};

const numOrDefault = curry((value, defaultValue) => {
    return numOrZero(value) == 0 ? defaultValue : numOrZero(value);
});

const column_map = {
    name: ["asset_name"],
    id: ["asset_id"],
    clicks: ["stats", "roasclicks"],
    spend: ["stats", "roasspend"],
    fbmade: ["stats", "fbrevenue"],
    roasmade: ["stats", "roasrevenue"],
    fbcustomers: ["stats", "fbcustomers"],
    roascustomers: ["stats", "roascustomers"],
    fbsales: ["stats", "fbsales"],
    roassales: ["stats", "roassales"],
    fbroas: ["stats", "fbroas"],
    roas: ["stats", "roas"],
    roasleads: ["stats", "roasleads"],
    fbleads: ["stats", "fbleads"],
    fb_cost_per_sale: ["stats", "fbcostpersale"],
    roas_cost_per_sale: ["stats", "roascostpersale"],
    fb_cost_per_customer: ["stats", "fbcostpercustomer"],
    roas_cost_per_customer: ["stats", "roascostpercustomer"],
    fb_cost_per_lead: ["stats", "fbcostperlead"],
    roas_cost_per_lead: ["stats", "roascostperlead"],
    fb_average_order_value: ["stats", "fbaverageordervalue"],
    roas_average_order_value: ["stats", "roasaverageordervalue"],
    fb_margin: ["stats", "fbmargin"],
    roas_margin: ["stats", "roasmargin"],
    fb_average_sales_per_customer: ["stats", "fbaveragesalespercustomer"],
    roas_average_sales_per_customer: ["stats", "roasaveragesalespercustomer"],
};

const TableCell = ({ id, value }) => {
    const [app, setApp] = useAtom(app_atom);
    const [adLauncher, setAdLauncher] = useAtom(ad_launcher_asset_atom);

    const onEditAsset = () => {
        console.log("onEditAsset");
        setApp({ ...app, modal_is_open: true, modal_id: "ad_creation" });
        setAdLauncher({ ...adLauncher, campaign_id: value.asset_id });
    };

    if (id == "name") {
        return (
            <td className={id} onClick={onEditAsset}>
                <span>{loget(column_map[id], value)}</span>
            </td>
        );
    }

    return (
        <td className={id}>
            <span>{loget(column_map[id], value)}</span>
        </td>
    );
};

const Table = ({ table_container_ref }) => {
    const [adsManager, setAdsManager] = useAtom(ads_manager_atom);
    const [adsManagerState, setAdsManagerState] = useAtom(ads_manager_state_atom);
    const [tableHeight, setTableHeight] = useState("auto");
    const [activeIndex, setActiveIndex] = useState(null);
    const tableElement = useRef(null);
    const [columns, setColumns] = useAtom(all_ads_manager_columns);
    const minCellWidth = 200;
    const [data] = useAtom(selected_data_atom);
    const [allAssetsSelected, setAllAssetsSelected] = useState(false);

    const createHeaders = (columns) => {
        return columns.map((item) => ({
            id: item.id,
            ref: useRef(),
        }));
    };

    const headersRefs = createHeaders(columns);

    useEffect(() => {
        let selected_columns = pipe(
            filter((value) => value.selected == true),
            map(prop("id"))
        )(columns);

        let grid_template_columns = pipe(
            filter((value) => selected_columns.includes(value.id)),
            lomap((value, idx) => (size(selected_columns) == idx + 1 ? minCellWidth : value.ref.current.offsetWidth))
        )(headersRefs);

        let table_width = sum(grid_template_columns);

        if (table_width < table_container_ref.current.offsetWidth) {
            let columns_width_without_last = pipe(init, sum)(grid_template_columns);
            let last_column_width = table_container_ref.current.offsetWidth - columns_width_without_last;
            let grid_template_columns_widths = pipe(
                map((value) => `${value}px`),
                join(" ")
            )([...init(grid_template_columns), last_column_width]);
            tableElement.current.style.gridTemplateColumns = grid_template_columns_widths;
        } else {
            let grid_template_columns_widths = pipe(
                map((value) => `${value}px`),
                join(" ")
            )(grid_template_columns);
            tableElement.current.style.gridTemplateColumns = grid_template_columns_widths;
        }
    }, [columns]);

    const onSelectItem = (item) => {
        let selected_tab = adsManagerState.selected_tab;

        const addOrRemoveItem = (items, item) => {
            return items.includes(item) ? without(item, items) : [item, ...items];
        };

        if (selected_tab == "campaigns") {
            setAdsManager({ ...adsManager, selected_campaigns: addOrRemoveItem(adsManager.selected_campaigns, item.campaign_id) });
        }

        if (selected_tab == "adsets") {
            setAdsManager({ ...adsManager, selected_adsets: addOrRemoveItem(adsManager.selected_adsets, item.adset_id) });
        }

        if (selected_tab == "ads") {
            setAdsManager({ ...adsManager, selected_ads: addOrRemoveItem(adsManager.selected_ads, item.ad_id) });
        }
    };

    useEffect(() => {
        setTableHeight(tableElement.current.offsetHeight);
    }, [data]);

    const mouseDown = (index) => {
        setActiveIndex(index);
    };

    const mouseMove = useCallback(
        (e) => {
            // Return an array of px values

            let filtered_columns = pipe(
                filter((column) => column.selected == true),
                sortBy(prop("order"))
            )(columns);

            let sortedHeaderRefs = map((column) => head(get(matching({ id: column.id }))(headersRefs)))(filtered_columns);
            let filtered_column_ids = pipe(map(prop("id")))(filtered_columns);

            const gridColumns = pipe(
                filter((value) => filtered_column_ids.includes(value.id)),
                lomap((col, i) => {
                    if (i === activeIndex) {
                        let { x, width: el_width } = col.ref.current.getBoundingClientRect();
                        let diff = e.clientX - (x + el_width);
                        let new_width = el_width + diff;

                        if (new_width >= minCellWidth) {
                            return new_width;
                        }
                    }

                    return col.ref.current.offsetWidth;
                })
            )(sortedHeaderRefs);

            let table_width = sum(gridColumns);

            if (table_width < table_container_ref.current.offsetWidth) {
                let columns_width_without_last = pipe(init, sum)(gridColumns);
                let last_column_width = table_container_ref.current.offsetWidth - columns_width_without_last;

                tableElement.current.style.gridTemplateColumns = `${[...init(gridColumns), last_column_width]
                    .map((column) => `${column}px`)
                    .join(" ")}`;
            } else {
                tableElement.current.style.gridTemplateColumns = `${gridColumns.map((column) => `${column}px`).join(" ")}`;
            }
        },
        [activeIndex, columns, minCellWidth]
    );

    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove);
        window.removeEventListener("mouseup", removeListeners);
    }, [mouseMove]);

    const mouseUp = useCallback(() => {
        setActiveIndex(null);
        removeListeners();
    }, [setActiveIndex, removeListeners]);

    useEffect(() => {
        if (activeIndex !== null) {
            window.addEventListener("mousemove", mouseMove);
            window.addEventListener("mouseup", mouseUp);
        }

        return () => removeListeners();
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);

    const onSelectAllAssets = () => {
        let selected_assets_ids_prop_getter = `selected_${adsManagerState.selected_tab}`;
        let selected_type_singular = pluralize.singular(adsManagerState.selected_tab);

        setAdsManager({
            ...adsManager,
            [selected_assets_ids_prop_getter]: pipe(
                ifElse(
                    identity,
                    () => [],
                    () => pipe(mod(all)(prop("asset_id")))(adsManager[adsManagerState.selected_tab])
                )
            )(allAssetsSelected),
        });

        setAllAssetsSelected(!allAssetsSelected);
    };

    return (
        <div className="table-wrapper">
            <table
                className="resizeable-table"
                ref={tableElement}
                style={{
                    width: "auto",
                    overflow: "auto",
                    display: "grid",
                    gridTemplateColumns: `
                                        minmax(75px, 1fr)
                                        minmax(75px, 1fr)
                                        minmax(300px, 1fr)
                                        minmax(200px, 1fr)
                                        minmax(200px, 1fr)
                                        minmax(200px, 1fr)
                                        minmax(200px, 1fr)
                                        minmax(200px, 1fr)
                                        minmax(200px, 1fr)
                                        minmax(200px, 1fr)
                                        minmax(200px, 1fr)


                                    `,
                }}
            >
                <thead>
                    <tr>
                        {lomap(
                            ({ name, id }, idx) => (
                                <th ref={head(get(matching({ id }), "ref")(headersRefs))} key={idx} className={id}>
                                    <div className="header_content">
                                        {id == "checkbox" && (
                                            <input
                                                type="checkbox"
                                                className="select_all_checkbox"
                                                onChange={onSelectAllAssets}
                                                checked={allAssetsSelected}
                                            />
                                        )}

                                        {id == "actions" && <div></div>}

                                        {name && <span>{name}</span>}
                                    </div>
                                    <div
                                        style={{ height: tableHeight }}
                                        onMouseDown={() => mouseDown(idx)}
                                        className={`resize-handle ${activeIndex === idx ? "active" : "idle"}`}
                                    />
                                </th>
                            ),
                            pipe(
                                filter((value) => value.selected == true),
                                sortBy(prop("order"))
                            )(columns)
                        )}
                    </tr>
                </thead>
                <tbody>
                    {lomap((value) => {
                        return (
                            <tr key={value.asset_id} id={value.asset_id} className="table_row">
                                <td className="checkbox">
                                    <span>
                                        <input
                                            key={value.asset_id}
                                            checked={pipe(
                                                includes(value.asset_id, __),
                                                defaultTo(false)
                                            )(prop(`selected_${adsManagerState.selected_tab}`, adsManager))}
                                            type="checkbox"
                                            className="table_checkbox"
                                            onChange={({ target: { checked } }) => onSelectItem({ ...value, checked })}
                                        />
                                    </span>
                                </td>

                                <td className="actions">
                                    <EllipsisOutlined />
                                </td>

                                {lomap(
                                    (column, idx) => (
                                        <TableCell value={value} id={column.id} key={idx} />
                                    ),
                                    pipe(
                                        filter((value) => value.selected == true),
                                        sortBy(prop("order")),
                                        drop(2)
                                    )(columns)
                                )}
                            </tr>
                        );
                    }, data)}
                </tbody>
            </table>
        </div>
    );
};

const AdsStatsHeader = () => {
    const [adsManager, setAdsManager] = useAtom(ads_manager_atom);
    const [adsManagerState, setAdsManagerState] = useAtom(ads_manager_state_atom);
    const [stats, setStats] = useState({ spend: 0, made: 0, sales: 0, roas: 0, customers: 0 });
    const [data] = useAtom(selected_data_atom);

    useEffect(() => {
        let scope = adsManagerState.selected_tab;
        let singular_scope = pluralize.singular(scope);
        let scope_prop = `${singular_scope}_id`;
        let selected_item_ids = pipe(get(`selected_${scope}`), defaultTo([]))(adsManager);

        var selected_items = pipe(get(matching({ [scope_prop]: (asset_id) => selected_item_ids.includes(asset_id) })), loUniqBy("asset_id"))(data);
        var filtered_data = isEmpty(selected_items) ? pipe(loUniqBy("asset_id"), defaultTo([]))(data) : selected_items;
        // var filtered_data = pipe(loUniqBy("asset_id"), defaultTo([]))(data);

        // console.log(filtered_data);
        // let fbcustomers = pipe(get(all, "stats", "fbcustomers"), sum)(filtered_data);
        // let fbsales = pipe(get(all, "stats", "fbsales"), sum)(filtered_data);
        // let fbspend = pipe(get(all, "stats", "fbspend"), sum)(filtered_data);
        // let fbmade = pipe(get(all, "stats", "fbrevenue"), sum)(filtered_data);

        // let fbroas = fbmade / fbspend;

        // console.log("fbcustomers", fbcustomers);
        // console.log("fbsales", fbsales);
        // console.log("fbmade", fbmade);
        // console.log("fbroas", fbroas);
        // console.log(filtered_data);

        var spend = pipe(map(pathOr(0, ["stats", "roasspend"])), sum)(filtered_data);
        var made = pipe(map(pathOr(0, ["stats", "roasrevenue"])), sum)(filtered_data);
        var sales = pipe(map(pathOr(0, ["stats", "roassales"])), sum)(filtered_data);
        var customers = pipe(map(pathOr(0, ["stats", "roascustomers"])), sum)(filtered_data);

        var roas = numOrDefault(made / spend, 0);

        let header_stats = {
            spend,
            made,
            sales,
            roas,
            customers,
        };

        setStats({ ...stats, ...header_stats });
    }, [data, adsManager.selected_adsets, adsManager.selected_campaigns, adsManager.selected_ads]);

    return (
        <div className="stats">
            <div className="stat">
                <div className="title">Cost</div>
                <div className="value">{toFixed(0, stats.spend)}</div>
            </div>
            <div className="stat">
                <div className="title">Revenue</div>
                <div className="value">{toFixed(0, stats.made)}</div>
            </div>

            <div className="stat">
                <div className="title">Unique Customers</div>
                <div className="value">{toFixed(0, stats.customers)}</div>
            </div>

            <div className="stat">
                <div className="title">Sales</div>
                <div className="value">{toFixed(0, stats.sales)}</div>
            </div>

            <div className="stat">
                <div className="title">ROAS</div>
                <div className="value">{toFixed(3, stats.roas)}</div>
            </div>
        </div>
    );
};

const Filters = () => {
    const [rule, setRule] = useAtom(rule_atom);
    const filters_container_ref = useRef();
    const filters_outer_container_ref = useRef();

    const createFilters = (rule) => {
        return pipe(
            mod(
                "conditions",
                all,
                "expressions",
                all
            )(({ id: expression_id, metric, predicate, value, condition_id }) => ({
                expression_id,
                condition_id,
                metric: metric.text,
                predicate: predicate.text,
                value,
            })),
            get("conditions", all, "expressions", all),
            map(values),
            values,
            flatten
        )(rule);
    };

    const filters = createFilters(rule);

    const onDeleteExpression = (expression) => {
        let condition_path = ["conditions", expression.condition_id];
        let expressions_path = [...condition_path, "expressions"];

        if (size(view(lensPath(expressions_path), rule)) == 1) {
            setRule(dissocPath(condition_path, rule));
        } else {
            setRule(dissocPath([...expressions_path, expression.expression_id], rule));
        }
    };

    if (size(filters) == 0) return <div></div>;

    return (
        <div className="filters_container">
            <div className="icon">
                <FilterOutlined />
            </div>

            <div className="filters_content_container" ref={filters_outer_container_ref}>
                <div className="filters" ref={filters_container_ref}>
                    {map(
                        ({ metric, predicate, value, expression_id, ...rest }) => (
                            <div className="filter" key={expression_id}>
                                <div className="text">{toLower(`${metric} ${predicate} ${value}`)}</div>
                                <div className="line_break"></div>
                                <div className="delete" onClick={() => onDeleteExpression({ metric, predicate, value, expression_id, ...rest })}>
                                    <CloseOutlined />
                                </div>
                            </div>
                        ),
                        filters
                    )}
                </div>
            </div>
        </div>
    );
};

const AdsManagerTab = styled.div`
    border-top: ${({ isSelected }) => isSelected && "5px solid cornflowerblue !important"};
`;

const TableSettingsColumnsList = () => {
    const [columns, setColumns] = useAtom(all_ads_manager_columns);
    const [selectedColumns, setSelectedColumns] = useAtom(selected_ads_manager_columns);

    useEffect(() => {
        let filtered_columns = pipe(
            reject((value) => value.id == "checkbox"),
            filter((value) => value.selected == true),
            map(prop("name"))
        );

        if (isEmpty(selectedColumns)) {
            setSelectedColumns(filtered_columns(columns));
        }
    }, []);

    useEffect(() => {
        let filtered_columns = pipe(
            reject((value) => value.id == "checkbox"),
            filter((value) => value.selected == true),
            map(prop("name"))
        );

        let prev_selected_columns_state = selectedColumns;
        let next_selected_columns_state = filtered_columns(columns);

        if (isEmpty(selectedColumns)) {
            setSelectedColumns(filtered_columns(columns));
        } else {
            if (size(prev_selected_columns_state) > size(next_selected_columns_state)) {
                let item_to_remove = head(without(next_selected_columns_state, prev_selected_columns_state));
                let result = pipe(
                    reject((value) => value == item_to_remove),
                    uniq
                )(prev_selected_columns_state);
                setSelectedColumns(result);
            }

            if (size(prev_selected_columns_state) < size(next_selected_columns_state)) {
                let item_to_add = head(without(prev_selected_columns_state, next_selected_columns_state));
                let result = pipe(append(item_to_add), uniq)(selectedColumns);
                setSelectedColumns(result);
            }
        }
    }, [columns]);

    const onChangeColumnsOrder = (oldIndex, newIndex) => {
        let [, ...columns_init_state] = pipe(loSortBy(["order"]), map(prop("name")))(columns);
        let new_order = arrayMove(columns_init_state, oldIndex, newIndex);

        let [head, ...tail] = columns;
        let columns_sorted = lomap((value, idx) => {
            return { ...value, order: indexOf(value.name, new_order) + 2 };
        }, tail);

        setColumns([head, ...columns_sorted]);
    };

    const onSelectColumn = (column) => {
        setColumns(mod(matching({ name: column }))((value) => ({ ...value, selected: !value.selected }))(columns));
    };

    return (
        <List
            className="table_columns_list"
            values={pipe(loSortBy(["order"]), lomap(prop("name")), tail)(columns)}
            onChange={({ oldIndex, newIndex }) => onChangeColumnsOrder(oldIndex, newIndex)}
            renderList={({ children, props, isDragged }) => (
                <ul {...props} style={{ padding: 0, cursor: isDragged ? "grabbing" : undefined }}>
                    {children}
                </ul>
            )}
            renderItem={({ value, props, isDragged, isSelected }) => (
                <li
                    {...props}
                    className="table_settings_list_li"
                    style={{
                        ...props.style,
                        cursor: isDragged ? "grabbing" : "grab",
                        backgroundColor: isDragged || isSelected ? "#EEE" : "#FFF",
                    }}
                >
                    <div className="row_container">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                checked={pipe(get(matching({ name: value })), head, prop("selected"))(columns)}
                                onChange={(e) => onSelectColumn(value)}
                            />
                        </div>
                        <div className="label">{value}</div>
                        <div className="drag_handle">
                            <SmallDashOutlined />
                        </div>
                    </div>
                </li>
            )}
        />
    );
};

const OrderRowInnerContainer = styled.div`
    background-color: ${({ isSelected }) => (isSelected ? "#eee" : "#fff")};
`;

const SortDropdown = () => {
    const [columns, setColumns] = useAtom(all_ads_manager_columns);
    const [adsManagerState, setAdsManagerState] = useAtom(ads_manager_state_atom);
    const [adsManager, setAdsManager] = useAtom(ads_manager_atom);
    const [data] = useAtom(selected_data_atom);

    const sortColumn = (sort_column_id, sort_order) => {
        if (sort_order == "ascend") {
            setAdsManager({
                ...adsManager,
                [adsManagerState.selected_tab]: pipe(losortby((value) => pipe(get(...column_map[sort_column_id]), toNumber)(value)))(data),
            });
        }

        if (sort_order == "descend") {
            setAdsManager({
                ...adsManager,
                [adsManagerState.selected_tab]: pipe(
                    losortby((value) => pipe(get(...column_map[sort_column_id]), toNumber)(value)),
                    reverse
                )(data),
            });
        }
    };

    const onSelectColumn = ({ target: { value: column_name } }) => {
        let sort_column_id = pipe(get(matching({ name: column_name })), head, prop("id"))(columns);
        setAdsManagerState({
            ...adsManagerState,
            sort_column_id,
        });
    };

    const onSelectSortOrder = (sort_order) => {
        setAdsManagerState({
            ...adsManagerState,
            sort_order,
        });
    };

    const onCloseColumn = () => {
        setAdsManagerState({ ...adsManagerState, selected_action: "" });
    };

    useEffect(() => {
        let sort_order = adsManagerState.sort_order;
        let sort_column_id = adsManagerState.sort_column_id;
        sortColumn(sort_column_id, sort_order);
    }, [adsManagerState.sort_column_id, adsManagerState.sort_order]);

    return (
        <div className="sort_container">
            <div className="sort_title_container">
                <div className="title">Sort Columns</div>
                <div className="close_icon" onClick={onCloseColumn}>
                    <CloseOutlined />
                </div>
            </div>

            <div className="sort_list_container">
                {pipe(
                    tail,
                    lomap((column, idx) => (
                        <div className="sort_list_item_container" key={idx}>
                            <div className="input">
                                {column.id == adsManagerState.sort_column_id && (
                                    <input type="radio" name="table_sort" value={column.name} onClick={onSelectColumn} defaultChecked="checked" />
                                )}
                                {column.id !== adsManagerState.sort_column_id && (
                                    <input type="radio" name="table_sort" value={column.name} onClick={onSelectColumn} />
                                )}
                            </div>
                            <div className="label">{column.name}</div>
                        </div>
                    ))
                )(columns)}
            </div>

            <div className="sort_order_container">
                <div className="order_row_container">
                    <OrderRowInnerContainer
                        className="order_row_inner_container"
                        onClick={() => onSelectSortOrder("ascend")}
                        isSelected={adsManagerState.sort_order == "ascend"}
                    >
                        <div className="icon">
                            <ArrowUpOutlined />
                        </div>
                        <div className="text">Ascending</div>
                    </OrderRowInnerContainer>
                </div>
                <div className="order_row_container">
                    <OrderRowInnerContainer
                        className="order_row_inner_container"
                        onClick={() => onSelectSortOrder("descend")}
                        isSelected={adsManagerState.sort_order == "descend"}
                    >
                        <div className="icon">
                            <ArrowDownOutlined />
                        </div>
                        <div className="text">Descending</div>
                    </OrderRowInnerContainer>
                </div>
            </div>
        </div>
    );
};

const CustomersModal = () => {
    const [app, setApp] = useAtom(app_atom);
    const [adsManagerState] = useAtom(ads_manager_state_atom);
    const [adsManager] = useAtom(ads_manager_atom);

    let singular_id_getter = pipe(pluralize.singular, (value) => `${value}_id`)(adsManagerState.selected_tab);
    let asset_ids = pipe(get(`selected_${adsManagerState.selected_tab}`))(adsManager);

    let customers_by_product = pipe(
        loorderby(["timestamp"], ["desc"]),
        logroupby("email"),
        mod(all)(head),
        values,
        mod(all)(({ cart, stats, ...rest }) => mod(all)(({ name, ...item }) => ({ product_name: name, ...item, ...rest }))(cart)),
        flatten,
        get(matching({ [singular_id_getter]: (id) => asset_ids.includes(id) })),
        logroupby("product_name")
    )(adsManager.customers);

    const onCloseModal = () => {
        setApp({ ...app, modal_is_open: false, modal_id: "" });
    };

    return (
        <div className="orders_modal_container">
            <div className="orders_modal_content">
                {pipe(
                    lomap((data, product_name) => (
                        <div className="product_sales_container" key={product_name}>
                            <div className="product_header_container">
                                <div className="title">{product_name}</div>
                                <div className="header_stats">
                                    <div className="stats_container">
                                        <div className="label">Sales:</div>
                                        <div className="amount">{size(data)}</div>
                                    </div>
                                    <div className="stats_container">
                                        <div className="label">Total:</div>
                                        <div className="amount">${pipe(map(prop("price")), sum, Math.trunc)(data)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="sales_container">
                                {pipe(
                                    lomap((sale, sale_idx) => (
                                        <div className="sale_row_container" key={sale_idx} style={{ gridTemplateColumns: "300px auto auto auto" }}>
                                            {console.log(sale)}
                                            {!adsManagerState.is_demo && <div className="cell">{sale.email}</div>}
                                            {adsManagerState.is_demo && <div className="cell blur">{sale.email}</div>}
                                            <div className="cell">{sale.campaign_id}</div>
                                            <div className="cell">{sale.adset_id}</div>
                                            <div className="cell">{sale.ad_id}</div>
                                        </div>
                                    ))
                                )(data)}
                            </div>
                        </div>
                    ))
                )(customers_by_product)}
            </div>
            <div className="close_orders_modal_container" onClick={onCloseModal}>
                <CloseCircleOutlined />
            </div>
        </div>
    );
};

const AdsManagerTableActions = () => {
    const [adsManagerState, setAdsManagerState] = useAtom(ads_manager_state_atom);
    const [app, setApp] = useAtom(app_atom);

    const onActionClick = (action_name) => {
        let is_same_action = adsManagerState.selected_action == action_name;
        if (is_same_action) {
            setAdsManagerState({ ...adsManagerState, selected_action: "" });
        } else {
            setAdsManagerState({ ...adsManagerState, selected_action: action_name });
        }
    };

    const onCloseColumn = () => {
        setAdsManagerState({ ...adsManagerState, selected_action: "" });
    };

    const onOpenModal = (modal_id) => {
        setApp({ ...app, modal_is_open: true, modal_id });
    };

    const onCreateAsset = () => {
        setApp({ ...app, modal_is_open: true, modal_id: "ad_creation" });
    };

    return (
        <div className="table_actions">
            {/* <div className="create_asset_container" onClick={onCreateAsset}>
                <div className="icon">
                    <PlusOutlined />
                </div>
                <div className="text">Create</div>
            </div> */}

            <div className="action" onClick={() => onActionClick("filter")}>
                <div className="icon">
                    <FilterOutlined />
                </div>
                <div className="text">Filters</div>
            </div>
            <div className="action" onClick={() => onActionClick("columns")}>
                <div className="icon">
                    <TableOutlined />
                </div>
                <div className="text">Columns</div>
            </div>
            <div className="action" onClick={() => onActionClick("sort")}>
                <div className="icon">
                    <SortAscendingOutlined />
                </div>
                <div className="text">Sort</div>
            </div>
            <div className="action" onClick={() => onOpenModal("customers_modal")}>
                <div className="icon">
                    <TeamOutlined />
                </div>
                <div className="text">Customers</div>
            </div>

            <div className="pagination">
                <div className="prev">
                    <CaretLeftOutlined />
                </div>
                <div className="next">
                    <CaretRightOutlined />
                </div>
            </div>

            {adsManagerState.selected_action == "filter" && (
                <div className="filters_actions_container">
                    <div className="column_title_container">
                        <div className="title">Filter Columns</div>
                        <div className="close_icon" onClick={onCloseColumn}>
                            <CloseOutlined />
                        </div>
                    </div>
                    <AdsManagerConditions />
                </div>
            )}

            {adsManagerState.selected_action == "sort" && <SortDropdown />}

            {adsManagerState.selected_action == "columns" && (
                <div className="columns_table_container">
                    <div className="column_title_container">
                        <div className="title">Order Columns</div>
                        <div className="close_icon" onClick={onCloseColumn}>
                            <CloseOutlined />
                        </div>
                    </div>
                    <TableSettingsColumnsList />
                </div>
            )}
        </div>
    );
};

const Report = {
    utilities: {
        attribution_timestamp: (attribution_window = 7, date) => {
            var attribution_date = moment(date, "YYYY-MM-DD").subtract(attribution_window, "days").startOf("day");
            let timestamp = attribution_date.unix();
            return timestamp;
        },

        toFixed: curry((fixed, num) => {
            var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
            return num.toString().match(re)[0];
        }),

        numOrZero: (value) => {
            return isNaN(value) || !isFinite(value) || isUndefined(value) ? 0 : toNumber(value);
        },

        numOrDefault: curry((value, defaultValue) => {
            return Report.utilities.numOrZero(value) == 0 ? defaultValue : Report.utilities.numOrZero(value);
        }),
    },

    defaults: {
        stats: {
            roas: () => {
                return {
                    roasclicks: 0,
                    roassales: 0,
                    roascustomers: 0,
                    roasleads: 0,
                    roasrevenue: 0,
                    roasspend: 0,
                };
            },
            all: () => {
                return {
                    fbclicks: 0,
                    fbleads: 0,
                    fbsales: 0,
                    roassales: 0,
                    roasrevenue: 0,
                    roascustomers: 0,
                    fbspend: 0,
                    fbmade: 0,
                    fbroas: 0,
                    roas: 0,
                    fbcustomers: 0,
                    fbrevenue: 0,
                    roasclicks: 0,
                    roasleads: 0,
                    roasspend: 0,
                    fbmargin: 0,
                    roasmargin: 0,
                    fbcostpersale: 0,
                    roascostpersale: 0,
                    fbcostperlead: 0,
                    roascostperlead: 0,
                    fbcostpercustomer: 0,
                    roascostpercustomer: 0,
                    fbaverageordervalue: 0,
                    roasaverageordervalue: 0,
                    fbaveragesalespercustomer: 0,
                    roasaveragesalespercustomer: 0,
                };
            },
        },
    },

    customers: {
        get: (report) => {
            return pipe(
                get("customers"),
                values,
                mod(all)((order) => {
                    let result = mod(
                        "ads",
                        all
                    )((ad) => ({
                        ...ad,
                        stats: order.stats,
                        cart: order.cart,
                        email: toLower(order.email),
                        report_date: report.details.date,
                        // type: "campaign",
                    }))(order);

                    return result;
                }),
                get(all, "ads"),
                flatten
            )(report);
        },
    },

    customer: {
        normalize: (customer) => {
            let func_name = `Report:customer:normalize`;
            console.log(func_name);

            let email = pipe(head, get("email"), toLower)(customer);
            let ads = pipe(louniqby("ad_id"), mod(all)(omit(["cart", "stats"])))(customer);
            let cart = pipe(louniqby("report_date"), get(all, "cart"), flatten)(customer);
            let stats = {
                roasrevenue: pipe(louniqby("report_date"), get(all, "stats", "roasrevenue"), sum)(customer),
                roassales: pipe(louniqby("report_date"), get(all, "stats", "roassales"), sum)(customer),
            };

            return pipe(
                mod(all)((order) => ({
                    ...order,
                    cart,
                    email,
                    stats,
                }))
            )(ads);
        },
    },

    ads: {
        stats: (stats) => {
            let func_name = `Report:ads:stats`;
            console.log(func_name);

            return {
                roassales: pipe(get(all, "roassales"), sum)(stats),
                roasrevenue: pipe(get(all, "roasrevenue"), sum)(stats),
                roascustomers: pipe(size)(stats),
            };
        },

        get: (customer) => {
            return get("ads")(customer);
        },

        // normalize: (customer) => {
        //     return pipe(mod("ads", all)((ad) => ({ ...ad, cart: customer.cart, email: customer.email, stats: customer.stats })))(customer);
        // },

        attribution: {
            timestamp: (attribution_window, date) => {
                return Report.utilities.attribution_timestamp(attribution_window, date);
            },

            inside: curry((attribution_window, order) => {
                return order.timestamp > Report.ads.attribution.timestamp(attribution_window, order.report_date);
            }),
        },
    },

    asset: {
        stats: {
            roas: (stats) => {
                let func_name = `Report:asset:stats:roas`;
                console.log(func_name);
                let { fbspend, roasrevenue } = stats;
                return numOrZero(roasrevenue / fbspend);
            },

            all: (stats) => {
                let func_name = `Report:asset:stats:all`;
                console.log(func_name);

                let { fbclicks, fbsales, fbleads, fbmade, fbspend, roassales, roascustomers, roasrevenue } = stats;

                let fbcustomers = fbsales;
                let fbrevenue = fbmade;

                let roasclicks = fbclicks;
                let roasleads = fbleads;
                let roasspend = fbspend;

                let roas = pipe(Report.utilities.numOrZero)(roasrevenue / fbspend);
                let fbroas = pipe(Report.utilities.numOrZero)(fbrevenue / fbspend);

                let fbcostpersale = pipe(Report.utilities.numOrZero)(fbspend / fbsales);
                let roascostpersale = pipe(Report.utilities.numOrZero)(fbspend / roassales);

                let fbcostperlead = pipe(Report.utilities.numOrZero)(fbspend / fbleads);
                let roascostperlead = pipe(Report.utilities.numOrZero)(fbspend / roasleads);

                let fbcostpercustomer = pipe(Report.utilities.numOrZero)(fbspend / roascustomers);
                let roascostpercustomer = pipe(Report.utilities.numOrZero)(fbspend / roascustomers);

                let fbaverageordervalue = pipe(Report.utilities.numOrZero)(fbrevenue / fbsales);
                let roasaverageordervalue = pipe(Report.utilities.numOrZero)(roasrevenue / roascustomers);

                let fbmargin = fbaverageordervalue - fbcostpersale;
                let roasmargin = roasaverageordervalue - roascostpersale;

                let fbaveragesalespercustomer = pipe(Report.utilities.numOrZero)(fbsales / fbcustomers);
                let roasaveragesalespercustomer = pipe(Report.utilities.numOrZero)(roassales / roascustomers);

                return {
                    fbclicks: pipe(defaultTo(0))(fbclicks),
                    fbleads: pipe(defaultTo(0))(fbleads),
                    fbsales: pipe(defaultTo(0))(fbsales),
                    roassales: pipe(defaultTo(0))(roassales),
                    roasrevenue: pipe(defaultTo(0))(roasrevenue),
                    roascustomers: pipe(defaultTo(0))(roascustomers),
                    fbspend: pipe(defaultTo(0))(fbspend),
                    fbmade: pipe(defaultTo(0))(fbmade),
                    fbroas: pipe(defaultTo(0))(fbroas),
                    roas: pipe(defaultTo(0))(roas),
                    fbcustomers: pipe(defaultTo(0))(fbcustomers),
                    fbrevenue: pipe(defaultTo(0))(fbrevenue),
                    roasclicks: pipe(defaultTo(0))(roasclicks),
                    roasleads: pipe(defaultTo(0))(roasleads),
                    roasspend: pipe(defaultTo(0))(roasspend),
                    fbmargin: pipe(defaultTo(0))(fbmargin),
                    roasmargin: pipe(defaultTo(0))(roasmargin),
                    fbcostpersale: pipe(defaultTo(0))(fbcostpersale),
                    roascostpersale: pipe(defaultTo(0))(roascostpersale),
                    fbcostperlead: pipe(defaultTo(0))(fbcostperlead),
                    roascostperlead: pipe(defaultTo(0))(roascostperlead),
                    fbcostpercustomer: pipe(defaultTo(0))(fbcostpercustomer),
                    roascostpercustomer: pipe(defaultTo(0))(roascostpercustomer),
                    fbaverageordervalue: pipe(defaultTo(0))(fbaverageordervalue),
                    roasaverageordervalue: pipe(defaultTo(0))(roasaverageordervalue),
                    fbaveragesalespercustomer: pipe(defaultTo(0))(fbaveragesalespercustomer),
                    roasaveragesalespercustomer: pipe(defaultTo(0))(roasaveragesalespercustomer),
                };
            },
        },
    },

    assets: {
        stats: (stats) => {
            let func_name = `Report:assets:stats`;
            console.log(func_name);

            let fbspend = pipe(get(all, "fbspend"), sum)(stats);
            let fbmade = pipe(get(all, "fbmade"), sum)(stats);
            return {
                fbclicks: pipe(get(all, "fbclicks"), sum)(stats),
                fbleads: pipe(get(all, "fbleads"), sum)(stats),
                fbsales: pipe(get(all, "fbsales"), sum)(stats),
                roassales: 0,
                roasrevenue: 0,
                roascustomers: 0,
                fbspend,
                fbmade,
                fbroas: numOrZero(fbmade / fbspend),
                roas: 0,
            };
        },

        campaigns: {
            details: (assets) => {
                console.log("Report:assets:campaigns:details");

                return {
                    campaign_id: pipe(head, get("campaign_id"))(assets),
                    campaign_name: pipe(head, get("campaign_name"))(assets),
                    asset_id: pipe(head, get("campaign_id"))(assets),
                    asset_name: pipe(head, get("campaign_name"))(assets),
                };
            },
            values: (reports) => {
                return pipe(
                    get(all, "campaigns"),
                    mod(all)(values),
                    flatten,
                    mod(all)(({ details, ...asset }) => ({ ...details, ...asset })),
                    mod(all)(pick(["campaign_id", "campaign_name", "stats"])),
                    mod(all, "stats")((stats) => ({ ...stats, ...Report.defaults.stats.roas() })),
                    logroupby("campaign_id"),
                    mod(all)((assets) => ({
                        campaign_id: pipe(head, get("campaign_id"))(assets),
                        campaign_name: pipe(head, get("campaign_name"))(assets),
                        asset_id: pipe(head, get("campaign_id"))(assets),
                        asset_name: pipe(head, get("campaign_name"))(assets),
                        stats: Report.assets.stats(pipe(get(all, "stats"))(assets)),
                    })),
                    mod(all)((asset) => ({ ...asset, type: "campaign" }))
                )(reports);
            },
        },

        adsets: {
            details: (assets) => {
                console.log("Report:assets:adset:details");

                return {
                    campaign_id: pipe(head, get("campaign_id"))(assets),
                    campaign_name: pipe(head, get("campaign_name"))(assets),
                    adset_id: pipe(head, get("adset_id"))(assets),
                    adset_name: pipe(head, get("adset_name"))(assets),
                    asset_id: pipe(head, get("adset_id"))(assets),
                    asset_name: pipe(head, get("adset_name"))(assets),
                };
            },
            values: (reports) => {
                return pipe(
                    get(all, "adsets"),
                    mod(all)(values),
                    flatten,
                    mod(all)(({ details, ...asset }) => ({ ...details, ...asset })),
                    mod(all)(pick(["campaign_id", "campaign_name", "adset_id", "adset_name", "stats"])),
                    mod(all, "stats")((stats) => ({ ...stats, ...Report.defaults.stats.roas() })),
                    logroupby("adset_id"),
                    mod(all)((assets) => ({
                        campaign_id: pipe(head, get("campaign_id"))(assets),
                        campaign_name: pipe(head, get("campaign_name"))(assets),
                        adset_id: pipe(head, get("adset_id"))(assets),
                        adset_name: pipe(head, get("adset_name"))(assets),
                        asset_id: pipe(head, get("adset_id"))(assets),
                        asset_name: pipe(head, get("adset_name"))(assets),
                        stats: Report.assets.stats(pipe(get(all, "stats"))(assets)),
                    })),
                    mod(all)((asset) => ({ ...asset, type: "adset" }))
                )(reports);
            },
        },

        ads: {
            details: (assets) => {
                console.log("Report:assets:ads:details");
                return {
                    campaign_id: pipe(head, get("campaign_id"))(assets),
                    campaign_name: pipe(head, get("campaign_name"))(assets),
                    adset_name: pipe(head, get("adset_name"))(assets),
                    adset_id: pipe(head, get("adset_id"))(assets),
                    ad_id: pipe(head, get("ad_id"))(assets),
                    ad_name: pipe(head, get("ad_name"))(assets),
                    asset_id: pipe(head, get("ad_id"))(assets),
                    asset_name: pipe(head, get("ad_name"))(assets),
                };
            },
            values: (reports) => {
                return pipe(
                    get(all, "ads"),
                    mod(all)(values),
                    flatten,
                    mod(all)(({ details, ...asset }) => ({ ...details, ...asset })),
                    mod(all)(pick(["campaign_id", "campaign_name", "adset_id", "adset_name", "ad_id", "ad_name", "stats"])),
                    mod(all, "stats")((stats) => ({ ...stats, ...Report.defaults.stats.roas() })),
                    logroupby("ad_id"),
                    mod(all)((assets) => ({
                        campaign_id: pipe(head, get("campaign_id"))(assets),
                        campaign_name: pipe(head, get("campaign_name"))(assets),
                        adset_id: pipe(head, get("adset_id"))(assets),
                        adset_name: pipe(head, get("adset_name"))(assets),
                        ad_id: pipe(head, get("ad_id"))(assets),
                        ad_name: pipe(head, get("ad_name"))(assets),
                        asset_id: pipe(head, get("ad_id"))(assets),
                        asset_name: pipe(head, get("ad_name"))(assets),
                        stats: Report.assets.stats(pipe(get(all, "stats"))(assets)),
                    })),
                    mod(all)((asset) => ({ ...asset, type: "ad" }))
                )(reports);
            },
        },

        details: (type, assets) => {
            console.log("Report:assets:details");

            return pipe(Report.assets[type].details)(assets);
        },

        customers: {
            values: (attribution_window, reports) => {
                return pipe(
                    lomap(Report.customers.get),
                    pipe(mod(all)(lofilter(Report.ads.attribution.inside(attribution_window)))),
                    flatten,
                    mod(all, "email")(toLower),
                    logroupby("email"),
                    values,
                    mod(all)(Report.customer.normalize),
                    flatten,
                    mod(all)(
                        pick([
                            "adset_name",
                            "ad_name",
                            "asset_id",
                            "asset_name",
                            "email",
                            "adset_id",
                            "ad_id",
                            "timestamp",
                            "name",
                            "campaign_name",
                            "campaign_id",
                            "cart",
                            "stats",
                            "type",
                        ])
                    ),
                    loorderby(["timestamp"], ["asc"])
                )(reports);
            },

            groupings: {
                by: {
                    product: curry((type, asset_ids, customers) => {
                        let func_name = "Report:assets:customers:grouping:by:product";
                        console.log(func_name);
                        let type_singularize = pluralize.singular(type);
                        let asset_id_prop_getter = `${type_singularize}_id`;

                        return pipe(
                            identity,
                            get(matching({ [asset_id_prop_getter]: (asset_id) => asset_ids.includes(asset_id) })),
                            mod(all)(({ cart, stats, ...rest }) =>
                                pipe(mod(all)(({ name, ...item }) => ({ product_name: name, ...item, ...rest })))(cart)
                            ),
                            flatten,
                            logroupby("product_name")
                        )(customers);
                    }),
                },
            },
        },
    },

    stats: (report) => {
        let spend = pipe(get(all, "stats", "fbspend"), values, sum)(report);
        let made = pipe(get(all, "stats", "roasrevenue"), values, sum)(report);
        let sales = pipe(get(all, "stats", "roassales"), values, sum)(report);
        let customers = pipe(get(all, "stats", "roascustomers"), values, sum)(report);
        let roas = pipe(Report.utilities.numOrZero)(made / spend);
        return {
            spend,
            made,
            sales,
            roas,
            customers,
        };
    },

    get: (type, attribution_window, reports, attribution_type = "last") => {
        let type_id_getter = `${pluralize.singular(type)}_id`;
        let type_plural = pluralize(type);

        return pipe(
            logroupby("email"),
            lomap((customer_ads) => (attribution_type == "last" ? last(customer_ads) : head(customer_ads))),
            logroupby(type_id_getter),
            mod(all)((assets) => ({
                ...Report.assets.details(type_plural, assets),
                orders: assets,
                stats: Report.ads.stats(get(all, "stats")(assets)),
            })),
            mergeDeepRight(Report.assets[type_plural]["values"](reports)),
            mod(all)((asset) => ({
                ...asset,
                stats: Report.asset.stats.all(pipe(get("stats"))(asset)),
            }))
            // (report) => ({
            //     ...report,
            //     stats: Report.stats(report),
            // }),
        )(Report.assets.customers.values(attribution_window, reports));
    },
};

const ExpiredTrialModal = () => {
    const [user] = useAtom(user_atom);
    let navigate = useNavigate();
    return (
        <div class="relative z-1 w-full h-full" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div class="fixed z-10 inset-0 ">
                <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6 min-w-[500px]">
                        <div>
                            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <svg
                                    class="h-6 w-6 text-red-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div class="mt-3 text-center sm:mt-5">
                                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    Your trial is over
                                </h3>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-6">
                            <button
                                type="button"
                                class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                                onClick={() => navigate(`/order/${user.uid}`)}
                            >
                                Click here to keep using ROAS
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="_bg_img_container w-full h-full">
                <img src={blurred_bg} className="bg_img_container object-cover w-full h-full blur-[3px] opacity-[0.5]" />
            </div>
        </div>

        // <div className="expired_trial_modal_container w-full h-full" style={{ overflow: "hidden !important" }}>
        //     <div className="content_container">
        //         <div className="post_outer_container">
        //             <div className="post_container">
        //                 <div className="text">Your trial is over</div>
        //                 <div className="cta" onClick={() => navigate(`/order/${user.uid}`)}>
        //                     Click here to keep using ROAS
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="_bg_img_container">
        //         <img src={blurred_bg} className="bg_img_container object-cover w-full h-full blur-[3px] opacity-[0.5]" />
        //     </div>
        // </div>
    );
};

const CompleteAccountSetupModal = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);
    let navigate = useNavigate();

    const onScheduleCall = () => {
        setApp({ ...app, modal_is_open: true, modal_id: "schedule_a_call_modal" });
    };

    return (
        <div className="complete_setup_modal" style={{ overflow: "hidden !important" }}>
            <div className="content_container">
                <div className="post_outer_container">
                    <div className="post_container" style={{ width: 600, height: 550 }}>
                        <div className="text">Almost There.</div>
                        <div className="video_container" style={{ marginBottom: 20 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <iframe
                                    src={`https://www.loom.com/embed/16b2ae7e23de41df9675db08cfb852ed`}
                                    frameBorder="0"
                                    webkitAllowFullscreen="true"
                                    mozAllowFullscreen="true"
                                    allowFullscreen="true"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "400px",
                                        height: "266px",
                                    }}
                                ></iframe>
                            </div>
                        </div>
                        <div className="call_to_actions">
                            <div className="cta" onClick={() => navigate(`/onboarding`)}>
                                Finish Setting Up My Account Myself
                            </div>
                            <div className="cta" onClick={onScheduleCall}>
                                Schedule A Setup Call
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg_img_container">
                <img
                    src={blurred_bg}
                    style={{
                        position: "absolute",
                        width: "150%",
                        left: "50%",
                        margin: "-30px 0 0 -75%",
                        textAlign: "center",
                        height: "100%",
                        filter: "blur(3px)",
                        opacity: 0.7,
                        overflow: "hidden",
                    }}
                />
            </div>
        </div>
    );
};

const ScheduleACalllModal = () => {
    const [user] = useAtom(user_atom);
    let navigate = useNavigate();
    return <InlineWidget url="https://calendly.com/roas-onboarding" />;
};

const DemoToggle = () => {
    const [adsManagerState, setAdsManagerState] = useAtom(ads_manager_state_atom);

    let { is_demo = false } = adsManagerState;

    return (
        <div class="flex ">
            <button
                onClick={() => setAdsManagerState({ ...adsManagerState, is_demo: !is_demo })}
                type="button"
                class={`${
                    is_demo ? "bg-blue-600" : "bg-gray-200"
                }  relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                role="switch"
                aria-checked="false"
            >
                <span class="sr-only">Demo</span>

                <span
                    aria-hidden="true"
                    class={`${
                        is_demo ? "translate-x-5" : "translate-x-0"
                    } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                ></span>
            </button>
            <span class="ml-3" id="annual-billing-label">
                <span class="text-sm font-medium text-gray-900">Demo</span>
            </span>
        </div>
    );
};

const AdsManager = () => {
    const [user] = useAtom(user_atom);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);
    const [slider, setSlider] = useAtom(slider_atom);
    const [adsManager, setAdsManager] = useAtom(ads_manager_atom);
    const [adsManagerState, setAdsManagerState] = useAtom(ads_manager_state_atom);
    const [rule, setRule] = useAtom(rule_atom);
    const table_container_ref = useRef();
    const [rangeSubject, setRangeSubject] = useState(null);
    const [app, setApp] = useAtom(app_atom);
    let navigate = useNavigate();
    const [, setAdlauncher] = useAtom(ad_launcher_asset_atom);
    const [isTrialExpired] = useState(true);

    const get_dates_range_array = (since, until) => {
        let since_date = pipe(
            split("-"),
            map(toNumber),
            mod(1)((value) => value - 1),
            (value) => new Date(...value)
        )(since);

        let until_date = pipe(
            split("-"),
            map(toNumber),
            mod(1)((value) => value - 1),
            (value) => new Date(...value)
        )(until);

        const dates = pipe(
            ([since_date, until_date]) => getDates(since_date, until_date),
            mod(all)((date) => moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"))
        )([since_date, until_date]);

        return dates;
    };

    const get_range_stats = ({ reports, ...rest }) => {
        let selected_tab_singular = pluralize.singular(adsManagerState.selected_tab);

        // console.log("get_range_stats");
        // console.log(pipe(values)(Report.get(selected_tab_singular, adsManagerState.attribution_window, reports)));

        return {
            [adsManagerState.selected_tab]: values(
                Report.get(selected_tab_singular, adsManagerState.attribution_window, reports, adsManagerState.attribution_click_setting)
            ),
            ...rest,
        };
    };

    const reports_with_customers = (reports) => {
        return {
            reports,
            customers: Report.assets.customers.values(adsManagerState.attribution_window, reports),
        };
    };

    const get_date_data_from_db = (date) => {
        let user_id = user.uid;
        let docs_data = (snapshot) => snapshot.docs.map((doc) => doc.data());

        return from(getDocs(query(collection(db, "projects"), where("roas_user_id", "==", user_id)))).pipe(
            rxmap(docs_data),
            rxmap(head),
            concatMap((project) => {
                let { fb_ad_account_id } = project;
                let date_string = join("", split("-", date));
                let report_id = `${fb_ad_account_id}${date_string}`;

                let q = query(
                    collection(db, "reports"),
                    where("user_id", "==", user_id),
                    where("date", "==", date),
                    where("report_id", "==", report_id)
                );

                return from(getDocs(q)).pipe(rxmap(docs_data));
            }),
            rxmap((reports) => {
                return {
                    [adsManagerState.selected_tab]: pipe(
                        get(matching({ type: (type) => type == adsManagerState.selected_tab })),
                        get(all, adsManagerState.selected_tab),
                        flatten
                    )(reports),
                    customers: pipe(get(matching({ type: "customers" })), get(all, "customers"), mod(all)(values), flatten)(reports),
                    details: pipe(get(matching({ type: "details" })), head)(reports),
                };
            }),
            rxmap(of),
            rxmap(reject(isEmpty)),
            defaultIfEmpty([])
        );
    };

    const get_date_range_data = (range) => {
        let { since, until } = range;
        let dates_range_array = get_dates_range_array(since, until);

        if (since && until) {
            if (since == until) {
                return from(get_date_data_from_db(since)).pipe(defaultIfEmpty([]));
            } else {
                return from(map((date) => get_date_data_from_db(date), dates_range_array)).pipe(
                    concatMap((value) => value),
                    rxreduce((prev, curr) => [...prev, ...curr]),
                    defaultIfEmpty([])
                );
            }
        } else {
            return rxof([]);
        }
    };

    useEffect(() => {
        if (rangeSubject == null) {
            console.log("rangesubject");
            const sub = new BehaviorSubject("");
            setRangeSubject(sub);
            sub.next(adsManagerState.date_range);
        } else {
            rangeSubject
                .pipe(
                    startWith(adsManagerState.date_range),
                    distinctUntilChanged(),
                    debounceTime(1000),
                    rxfilter((date) => !isEmpty(date.since) && !isEmpty(date.until)),
                    tap(setAdsManager({ ...adsManager, campaigns: [], adsets: [], ads: [], customers: [] })),
                    switchMap(get_date_range_data),
                    rxmap(reports_with_customers),
                    rxmap(get_range_stats)
                    // rxmap(set_table_defaults),
                    // rxmap(mod(adsManagerState.selected_tab)(values))
                )
                .subscribe((data) => {
                    setAdsManager({ ...adsManager, ...data });
                });
        }
    }, [rangeSubject, user.account_id, adsManagerState.attribution_window, adsManagerState.attribution_click_setting, adsManagerState.selected_tab]);

    const onSelectTab = (value) => {
        setAdsManagerState({ ...adsManagerState, selected_tab: value });
        setRule({ ...rule, scope: value });
    };

    const onUpdateReport = () => {
        let since = document.getElementsByClassName("ant-picker-input")[0].getElementsByTagName("input")[0].value;
        let until = document.getElementsByClassName("ant-picker-input")[1].getElementsByTagName("input")[0].value;
        let date_range = { since, until };
        rangeSubject.next(date_range);
        setAdsManagerState({ ...adsManagerState, is_date_picker_open: false });
    };

    const onSetAttributionWindow = (value) => {
        if (value || value == 0) {
            setAdsManagerState({ ...adsManagerState, attribution_window: value });
        }
    };

    const onSelectAttributionClickOrder = (attribution_click_setting) => {
        setAdsManagerState({ ...adsManagerState, attribution_click_setting });
    };

    const onCloseModal = () => {
        setAdlauncher(ad_launcher_initial_state);
        setApp({ ...app, modal_is_open: false, modal_id: "" });
    };

    const onCloseModalCalendlyModal = () => {
        setApp({ ...app, modal_is_open: false, modal_id: "" });
    };

    const onDateRangeOpen = () => {
        setAdsManagerState({
            ...adsManagerState,
            is_date_picker_open: true,
        });
    };

    const onCloseDatePicker = () => {
        setAdsManagerState({ ...adsManagerState, is_date_picker_open: false });
    };

    useEffect(() => {
        if (user && !user.is_admin) {
            let intercom_defaults = {
                api_base: "https://api-iam.intercom.io",
                app_id: "wfjbdiuj",
                roas_email: user.roas_email,
                email: user.roas_email,
                roas_user_id: user.roas_user_id,
                user_id: user.roas_user_id,
            };

            let roas_keys = pipe(keys, lofilter(includes("roas")))(user);
            let meta = pipe(pick(roas_keys))(user);

            window.Intercom("update", { ...intercom_defaults, ...meta });
        }
    }, []);

    useEffect(() => {
        console.log("user");

        if (user.roas_account_status == "trial") {
            const days_since_sign_up = moment
                .duration(moment().diff(moment(user.roas_created_at_timestamp, "x")))
                .asDays()
                .toFixed(0);

            if (days_since_sign_up > 7) {
                setApp({ ...app, modal_is_open: true, modal_id: "expired_trial_modal" });
            }
        }
    }, []);

    const isRoasAccountActive = (user) => {
        let {
            roas_dna_script_installed = 0,
            roas_shopping_cart_webhook_installed = 0,
            roas_shopping_cart = "",
            roas_facebook_ad_account_id = "",
        } = user;

        let has_installed_dna = roas_dna_script_installed > 0;
        let has_installed_webhook = roas_shopping_cart_webhook_installed > 0;
        let has_selected_shopping_cart = size(roas_shopping_cart) > 0;
        let has_selected_ad_account = size(roas_facebook_ad_account_id) > 0;

        if (has_installed_dna && has_installed_webhook && has_selected_shopping_cart && has_selected_ad_account) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (!isRoasAccountActive && app.modal_id == "" && onboarding.is_first_time_user !== false) {
            setApp({ ...app, modal_is_open: true, modal_id: "complete_account_setup" });
        }
    }, [app]);

    useEffect(() => {
        if (onboarding.roas_has_seen_finish_setup_video && !user.roas_has_finished_onboarding) {
            navigate("/onboarding");
        }
    }, []);

    useEffect(() => {
        if (!onboarding.roas_has_seen_finish_setup_video && !user.roas_has_finished_onboarding) {
            setApp({ ...app, modal_is_open: true, modal_id: "complete_account_setup" });
        }
    }, []);

    const onNavigateToOnboarding = () => {
        setOnboarding({ ...onboarding, roas_has_seen_finish_setup_video: true });
        setSlider({ ...slider, slide: "3" });
        navigate("/onboarding");
    };

    return (
        <div className="ads_manager_view">
            {/* <ScreenModal id="ad_creation">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <AdCreation />
            </ScreenModal> */}

            <ScreenModal id="customers_modal">
                <CustomersModal />
            </ScreenModal>

            <ScreenModal id="expired_trial_modal" style={{ overflow: "hidden" }}>
                <ExpiredTrialModal />
            </ScreenModal>

            {/* <ScreenModal id="complete_account_setup" style={{ overflow: "hidden" }}>
                <CompleteAccountSetupModal />
            </ScreenModal> */}

            <ScreenModal id="schedule_a_call_modal" style={{ overflow: "hidden" }}>
                <div className="close_modal_container" onClick={onCloseModalCalendlyModal}>
                    <CloseCircleOutlined />
                </div>
                <ScheduleACalllModal />
            </ScreenModal>

            {app.modal_id == "complete_account_setup" && (
                <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6 min-w-[700px]">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                        <svg
                                            className="h-6 w-6 text-green-600"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5 flex flex-col items-center justify-center">
                                        <ReactPlayer
                                            url="https://frankkern.wistia.com/medias/9ijexcwpb4"
                                            playing={true}
                                            controls={true}
                                            width={"640px"}
                                            height={"360px"}
                                            playsinline={true}
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        onClick={onNavigateToOnboarding}
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        Finish Set Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <div className="account_status_container">
                <div className="status_table">
                    <div className="headers">
                        <div className="header" style={{ color: "#EB072A" }}>
                            <div className="cell">
                                <div className="label">Facebook</div>
                                <div className="value">{user.roas_has_facebook_connected ? <CloseCircleOutlined /> : <CloseCircleOutlined />}</div>
                            </div>
                            <div className="action">Fix It</div>
                        </div>
                        <div className="header">
                            <div className="cell">
                                <div className="label">Ad Account </div>
                                <div className="value">
                                    {user.roas_has_selected_facebook_ad_account ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                                </div>
                            </div>
                        </div>
                        <div className="header">
                            <div className="cell">
                                <div className="label">Shopping Cart</div>
                                <div className="value">{user.roas_shopping_cart ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</div>
                            </div>
                        </div>
                        <div className="header">
                            <div className="cell">
                                <div className="label">Tracking Script</div>
                                <div className="value">{user.roas_dna_script_installed ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</div>
                            </div>
                        </div>
                        <div className="header">
                            <div className="cell">
                                <div className="label">Webhook</div>
                                <div className="value">
                                    {user.roas_shopping_cart_webhook_installed ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="ads_manager_top_toolbar">
                <div className="left_adjusted">
                    {user.is_admin && <DemoToggle />}
                    {/* <div className="adsmanger_view_switch_container">
                        <div className="switch">
                            <Switch checked={adsManagerState.view == "active"} onChange={onSwitchAdsManagerView} size="small" />
                        </div>
                        <div className="text">{adsManagerState.view == "active" && "Active mode"}</div>
                        <div className="text">{adsManagerState.view == "draft" && "Draft mode"}</div>
                    </div> */}
                </div>

                <div className="right_adjusted">
                    <div className="attribution_click_setting_container">
                        <div className="attribution_click_setting_row_container">
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="attribution_click_setting"
                                    value="first_click"
                                    // defaultChecked="checked"
                                    onClick={() => onSelectAttributionClickOrder("first")}
                                />
                            </div>
                            <div className="text">First Click</div>
                        </div>
                        <div className="attribution_click_setting_row_container">
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="attribution_click_setting"
                                    value="last_click"
                                    defaultChecked="checked"
                                    onClick={() => onSelectAttributionClickOrder("last")}
                                />
                            </div>
                            <div className="text">Last Click</div>
                        </div>
                    </div>

                    <div className="conversion_window_container">
                        <div className="label">Attribution Days</div>
                        <div className="input">
                            <InputNumber min={0} max={180} defaultValue={7} onChange={onSetAttributionWindow} />
                        </div>
                    </div>

                    <div className="date_select_container">
                        <RangePicker
                            onOpenChange={onDateRangeOpen}
                            size="large"
                            open={adsManagerState.is_date_picker_open}
                            renderExtraFooter={() => (
                                <div className="range_picker_actions">
                                    <Button type="default" className="range_picker_button" onClick={onCloseDatePicker}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" className="range_picker_button" onClick={onUpdateReport}>
                                        Update
                                    </Button>
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="ads_manager_container" ref={table_container_ref}>
                <div className="tabs">
                    {lomap(
                        (value, key) => (
                            <AdsManagerTab
                                className="tab"
                                isSelected={adsManagerState.selected_tab == key}
                                onClick={() => onSelectTab(key)}
                                key={key}
                            >
                                <div className="text">{value}</div>
                            </AdsManagerTab>
                        ),
                        tabs
                    )}
                </div>

                <div className="data_content_container">
                    <AdsStatsHeader />
                    <Filters table_container_ref={table_container_ref} />
                    <AdsManagerTableActions />
                    <Table table_container_ref={table_container_ref} />
                </div>
            </div>
        </div>
    );
};

export default AdsManager;
