import React, { useRef, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword } from "../firebase";
import { useAtom } from "jotai";
import { user_atom } from "../state/auth";
import Footer from "./Footer";

const Login = () => {
    const [user, setUser] = useAtom(user_atom);

    const onUpdateEmail = (event) => setUser({ ...user, email: event.target.value });
    const onUpdatePassword = (event) => setUser({ ...user, password: event.target.value });

    const onSignIn = () => {
        console.log("onSignIn");
        let { email, password } = user;

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                setUser(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    };

    return (
        <div className="login_view">
            <div className="auth_container">
                <div className="auth_form_container">
                    <div className="header">
                        <div className="title">Log In</div>
                    </div>

                    <div className="auth_input_container">
                        <div className="label">Email</div>
                        <div className="value">
                            <input type="text" onChange={onUpdateEmail} />
                        </div>
                    </div>

                    <div className="auth_input_container">
                        <div className="label">Password</div>
                        <div className="value">
                            <input type="password" onChange={onUpdatePassword} />
                        </div>
                    </div>

                    <div className="cta_container" onClick={onSignIn}>
                        <div className="login-button">Log In</div>
                    </div>

                    <div className="footer_actions">
                        <div className="action_link">
                            <div className="text">Forgot Password?</div>
                        </div>
                        <div className="action_link">
                            <Link className="text" to="/signup">
                                <div className="regular">Don't have an account?</div>
                                <div className="">Start your free trial!</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Login;
