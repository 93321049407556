import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { user_atom } from "../state/auth";
import { ad_accounts_atom } from "../state/ad_accounts";
import { Switch } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { User } from "../apis/facebook";
import { db } from "../firebase";
import { getDoc, query, doc, setDoc, arrayUnion, getDocs, collection, where } from "firebase/firestore";
import { flip, pipe, map, path, sum, defaultTo } from "ramda";
import { isEmpty, map as lodashmap } from "lodash";
import { from, map as rxmap } from "rxjs";

const lomap = flip(lodashmap);

let dollarUSLocale = Intl.NumberFormat("en-US");

const AdAccounts = () => {
    const [user, setUser] = useAtom(user_atom);
    const [adAccounts, setAdAccounts] = useAtom(ad_accounts_atom);
    const [fbUser, setFbUser] = useState({});

    useEffect(() => {
        getDoc(query(doc(db, "accounts", user.uid))).then((document) => {
            console.log("authaccounts");

            let {
                facebook: { access_token },
            } = document.data();

            User({ access_token })
                .profile.get()
                .then((value) => {
                    let { data: fb_user } = value;
                    let { id: fb_user_id, ...rest } = fb_user;

                    User({ access_token })
                        .profile_picture.get({ fb_user_id })
                        .then((value) => {
                            let {
                                data: { profile_picture_url },
                            } = value;
                            setFbUser({ ...fbUser, id: fb_user_id, profile_picture_url, ...rest });
                        });

                    from(getDocs(query(collection(db, "fb_ad_accounts"), where("user_ids", "array-contains", user.uid))))
                        .pipe(rxmap((data) => data.docs.map((value) => value.data())))
                        .subscribe((ad_accounts) => {
                            if (!isEmpty(ad_accounts)) {
                                setAdAccounts(ad_accounts);
                            } else {
                                User({ access_token })
                                    .ad_accounts.get({ fb_user_id })
                                    .then((value) => {
                                        let { data: ad_accounts } = value;
                                        let user_ids = arrayUnion(user.uid);
                                        map((ad_account) => {
                                            let { account_id: fb_account_id } = ad_account;
                                            setDoc(doc(db, "fb_ad_accounts", fb_account_id), { ...ad_account, access_token, user_ids });
                                        }, ad_accounts);
                                    });
                            }
                        });
                });
        });

        let total = pipe(
            map((value) => value.amount_spent / 100),
            sum
        );
    }, []);

    const onClickAdAccountStatus = (fb_account, switch_state) => {
        console.log("onClickAdAccountStatus");
        console.log(switch_state);
        let { account_id } = fb_account;
        setDoc(doc(db, "fb_ad_accounts", account_id), { roas_account_status: switch_state }, { merge: true });
    };

    let profile_picture_url = defaultTo("", path(["profile_picture_url"], fbUser));

    return (
        <div className="ad_accounts_view">
            <div className="account_container">
                <div className="header">Profile</div>

                <div className="profile_container">
                    <div className="profile_picture">
                        <img src={profile_picture_url} />
                    </div>
                    <div className="profile_info">
                        <div className="name">{fbUser.name}</div>
                        <div className="id">
                            <span>ID:</span> {fbUser.id}
                        </div>
                    </div>
                    <div className="profile_actions">
                        <div className="close">
                            <CloseOutlined />
                        </div>
                    </div>
                </div>
            </div>

            <div className="ad_accounts_container">
                <div className="ad_accounts_header">
                    <div className="title">Ad accounts</div>
                </div>

                <div className="ad_accounts_container">
                    <div className="ad_accounts_header_columns">
                        <div className="status"></div>
                        <div className="name">Name</div>
                        <div className="id">ID</div>
                        <div className="spend">Total Spent</div>
                    </div>

                    {lomap(
                        (value) => (
                            <div key={value.account_id} className="ad_account_container">
                                <div className="ad_account_status">
                                    <Switch defaultChecked={value.roas_account_status} onChange={(state) => onClickAdAccountStatus(value, state)} />
                                </div>
                                <div className="ad_account_name">{value.name}</div>
                                <div className="ad_account_id">{value.account_id}</div>
                                <div className="ad_account_spent">${dollarUSLocale.format(value.amount_spent / 100)}</div>
                            </div>
                        ),
                        adAccounts
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdAccounts;
