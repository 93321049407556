import React from "react";
import { capitalize } from "lodash";

const RuleScopeUneditable = ({ rule }) => {
    let icon_map = {
        campaigns: "C",
        adsets: "A",
        ads: "a",
    };

    let Icon = icon_map[rule.scope];

    return (
        <div className="action_container">
            <div className="icon_container">{Icon}</div>
            <div className="scope_description_container">
                <div className="text">{capitalize(rule.scope)}</div>
            </div>
        </div>
    );
};

export default RuleScopeUneditable;
