import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import {
    payment_processor_carousel_atom,
    facebook_carousel_atom,
    shopping_cart_carousel_atom,
    code_snippet_carousel_atom,
    status_button_atom,
    accordion_atom,
    facebook_ad_id_carousel_atom,
} from "../state/onboarding";
import { user_atom, user_accounts_atom, facebook_accounts_atom } from "../state/auth";
import { Radio, Checkbox, Button } from "antd";
import { CheckCircleOutlined, LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import { useSpring, animated } from "react-spring";
import Highlight from "react-highlight";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import { db } from "../firebase";
import { getDocs, query, where, collection, getDoc, doc, onSnapshot, collectionGroup, setDoc, arrayUnion } from "firebase/firestore";
import { map, flip, has, pipe, pick, includes, head, isEmpty, keys, sum } from "ramda";
import { map as lodashmap, keyBy, size, toLower } from "lodash";
import { from, map as rxmap, zip } from "rxjs";
import { mod, matching, get, all } from "shades";
import { lofilter } from "../../utilities/helpers";
const StripeLogo = require("../assets/stripe_logo.png");
const ShopifyLogo = require("../assets/shopify_logo.png");
const PaypalLogo = require("../assets/paypal_logo.png");
const BraintreeLogo = require("../assets/braintree_logo.png");
const EasyPayDirectLogo = require("../assets/easy_pay_direct_logo.png");
const ClickfunnelsLogo = require("../assets/clickfunnels_logo.png");
const KajabiLogo = require("../assets/kajabi_logo.svg");
const WoocommerceLogo = require("../assets/woo_commerce_logo.png");
const ThriveCartLogo = require("../assets/thrive_cart_logo.png");
const KartraLogo = require("../assets/kartra_logo.png");
const KeapLogo = require("../assets/keap_logo.png");
const SamcartLogo = require("../assets/samcart_logo.png");
import ScreenModal from "../components/ScreenModal";
import { app_atom } from "../state/app";
var axios = require("axios");
import moment from "moment";

const lomap = flip(lodashmap);
const lokeyby = flip(keyBy);

const ConnectToFacebook = () => {
    const [user] = useAtom(user_atom);

    return (
        <div className="carousel_view_container" id="stripe_processor" key="stripe_processor">
            <div className="connect_to_fb_container">
                <a className="connect_to_fb_button" href={`https://roas-oauth-service.herokuapp.com/auth/facebook?user_id=${user.uid}`}>
                    <div className="text">Connect to Facebook</div>
                </a>
            </div>
        </div>
    );
};

const FacebookAccounts = () => {
    const [user] = useAtom(user_atom);
    const [fbAccounts, setFbAccounts] = useAtom(facebook_accounts_atom);
    const [filteredFbAccounts, setFilteredFbAccounts] = useState({});
    const [selectedFbAccount, setSelectedFbAccount] = useState("");
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    const save_ad_accounts = (ad_accounts) => {
        from(ad_accounts).subscribe((ad_account) => {
            let { account_id: fb_account_id, roas_user_id } = ad_account;
            let user_ids = arrayUnion(roas_user_id);
            from(setDoc(doc(db, "fb_ad_accounts", fb_account_id), { ...ad_account, user_ids }, { merge: true })).subscribe(() => {
                console.log(`saved ad account ${fb_account_id}`);
            });
        });
    };

    const get_fb_ad_accounts = () => {
        console.log("get_fb_ad_accounts");

        let user_fb_ref = query(collectionGroup(db, "integrations"), where("user_id", "==", user.uid), where("account_name", "==", "facebook"));
        from(getDocs(user_fb_ref))
            .pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(head)
            )
            .subscribe((fb_account_data) => {
                let { access_token, fb_account_id: fb_user_id, user_id: roas_user_id } = fb_account_data;

                let credentials = { access_token, fb_user_id, roas_user_id };

                var config = {
                    method: "get",
                    url: `https://us-central1-roas-e44b8.cloudfunctions.net/app/ad_accounts?access_token=${access_token}&fb_user_id=${fb_user_id}&roas_user_id=${roas_user_id}`,
                };

                from(axios(config))
                    .pipe(rxmap(get("data")), rxmap(mod(all)((ad_account) => ({ ...ad_account, ...credentials }))))
                    .subscribe((ad_accounts) => {
                        let roas_ad_amount_spent = pipe(
                            get(all, "amount_spent"),
                            mod(all)(Number),
                            mod(all)((value) => value / 100),
                            sum
                        )(ad_accounts);

                        update_user_intercom({ roas_has_facebook_ad_accounts: true, roas_ad_amount_spent });
                        update_user_db({ roas_has_facebook_ad_accounts: true, roas_ad_amount_spent });
                        setFbAccounts(ad_accounts);
                        save_ad_accounts(ad_accounts);
                    });
            });
    };

    useEffect(() => {
        let ad_accounts_query = query(collection(db, "fb_ad_accounts"), where("user_ids", "array-contains", user.uid));

        from(getDocs(ad_accounts_query))
            .pipe(
                rxmap((accounts) => accounts.docs.map((account) => account.data())),
                rxmap(pipe(lokeyby("account_id"))),
                rxmap(map(pick(["account_id", "name"]))),
                rxmap(map((value) => ({ ...value, selected: false })))
            )
            .subscribe((accounts) => {
                if (isEmpty(accounts)) {
                    get_fb_ad_accounts();
                } else {
                    update_user_intercom({ roas_has_facebook_ad_accounts: true });
                    update_user_db({ roas_has_ad_accounts: true });
                    setFbAccounts(accounts);
                }
            });
    }, []);

    useEffect(() => {
        if (size(fbAccounts) > 0) {
            setFilteredFbAccounts(fbAccounts);
        }
    }, [fbAccounts]);

    const save_ad_account_to_project = (ad_account_id) => {
        console.log("save_ad_account_to_project");

        let project_query = query(collection(db, "projects"), where("roas_user_id", "==", user.uid));
        let project_doc = from(getDocs(project_query)).pipe(
            rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
            rxmap(head)
        );

        project_doc.subscribe((project) => {
            let { project_id } = project;

            let project_ref = doc(db, "projects", project_id);
            setDoc(project_ref, { facebook_ad_account_id: ad_account_id, fb_ad_account_id: ad_account_id }, { merge: true });
        });
    };

    const update_user_db = (payload) => {
        let user_ref = doc(db, "accounts", user.uid);
        setDoc(user_ref, payload, { merge: true });
    };

    const update_user_intercom = (meta) => {
        console.log("update_user_intercom");
        if (user && !user.is_admin) {
            let intercom_defaults = {
                api_base: "https://api-iam.intercom.io",
                app_id: "wfjbdiuj",
                roas_email: user.roas_email,
                email: user.roas_email,
                roas_user_id: user.roas_user_id,
                user_id: user.roas_user_id,
            };

            window.Intercom("update", { ...intercom_defaults, ...meta });
        }
    };

    const onSelectFbAccount = (account_id) => {
        if (account_id == selectedFbAccount) {
            setSelectedFbAccount("");
        } else {
            save_ad_account_to_project(account_id);
            update_user_db({
                roas_has_selected_facebook_ad_account: true,
                roas_facebook_ad_account_id: account_id,
                roas_facebook_ad_account_id_selected_at: Number(moment().format("x")),
            });
            update_user_intercom({
                roas_has_selected_facebook_ad_account: true,
                roas_facebook_ad_account_id: account_id,
            });
            setSelectedFbAccount(account_id);
            setUserAccount({ ...userAccount, roas_has_selected_facebook_ad_account: true, roas_facebook_ad_account_id: account_id });
        }
    };

    const onFilterFbAccounts = ({ target: { value } }) => {
        setFilteredFbAccounts(pipe(get(matching({ name: pipe(toLower, includes(value)) })))(fbAccounts));
    };

    const AdAccounts = () => {
        return (
            <div className="facebook_accounts_list_container">
                {lomap(
                    (account) => (
                        <div className="account" key={account.account_id} onClick={() => onSelectFbAccount(account.account_id)}>
                            <div className="select">
                                <Checkbox
                                    onChange={() => onSelectFbAccount(account.account_id)}
                                    checked={account.account_id == selectedFbAccount}
                                ></Checkbox>
                            </div>
                            <div className="name">{account.name}</div>
                            <div className="name">{account.account_id}</div>
                        </div>
                    ),
                    filteredFbAccounts
                )}
            </div>
        );
    };

    const LoadingAdAccounts = () => {
        return (
            <div className="facebook_accounts_list_container loading_fb_accounts_container">
                <div className="loading_icon_container">
                    <div className="text">Loading</div>
                    <div className="icon">
                        <LoadingOutlined />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="carousel_view_container" id="facebook_accounts" key="facebook_accounts">
            <div className="facebook_accounts_container">
                <div className="facebook_accounts_search_filter_container">
                    <input type="text" placeholder="Search by account name" onChange={onFilterFbAccounts} />
                </div>

                {isEmpty(filteredFbAccounts) && <LoadingAdAccounts />}
                {!isEmpty(filteredFbAccounts) && <AdAccounts />}
            </div>
        </div>
    );
};

const AddFacebookAdId = () => {
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onAddFbIDToAdsClick = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_adding_ad_id_paramater_on_ads: true });
        setVideoIsVisible(false);
    };

    const onVerifyFbAdIDClick = () => {
        setUserAccount({ ...userAccount, roas_has_ad_id_paramater_on_ads: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_adding_ad_id_paramater_on_ads || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/4662986a0cc24f50923020f4e1c51ab1"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_adding_ad_id_paramater_on_ads && (
                    <a
                        href="https://business.facebook.com/adsmanager"
                        className="connect_to_stripe_button"
                        target="_blank"
                        onClick={onAddFbIDToAdsClick}
                    >
                        <div className="text">Add fb_id paramater to Facebook ads</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_adding_ad_id_paramater_on_ads && (
                    <div className="connect_to_stripe_button" onClick={onVerifyFbAdIDClick}>
                        <div className="text">Click To Verify That You Have Added The fb_id Paramater To Your Facebook Ads</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

const FacebookDone = () => {
    return (
        <div className="carousel_view_container" id="stripe_processor" key="stripe_processor">
            <div className="done_container">
                <div className="step">
                    <div className="name">Connect Facebook account</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
                <div className="step">
                    <div className="name">Select ad account</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
                {/* <div className="step">
                    <div className="name">Add fb_id paramater to all ads</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div> */}
                <div className="step">
                    <div className="name">Fetching ad account data</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                        {/* <LoadingOutlined /> */}
                    </div>
                </div>
                <div className="step">
                    <div className="name">Status</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                        {/* <CloseCircleTwoTone twoToneColor="#eb2f96" /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const ConnectFacebookCarousel = () => {
    const [activeSlideIndex] = useAtom(facebook_carousel_atom);

    let CarouselMap = {
        ConnectToFacebook,
        FacebookAccounts,
        FacebookDone,
    };

    const Component = CarouselMap[activeSlideIndex];

    return (
        <div className="carousel_container">
            <Component />
        </div>
    );
};

const SelectPaymentProcessor = () => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    const set_project = (data) => {
        console.log("set_project");

        let project_query = query(collection(db, "projects"), where("roas_user_id", "==", user.uid));
        let project_doc = from(getDocs(project_query)).pipe(
            rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
            rxmap(head)
        );

        project_doc.subscribe((project) => {
            let { project_id } = project;

            let payload = {
                payment_processor_name: data,
                payment_processor_id: data,
            };

            let project_ref = doc(db, "projects", project_id);
            setDoc(project_ref, payload, { merge: true });
        });
    };

    const update_user_db = (data) => {
        let user_ref = doc(db, "accounts", user.uid);

        let payload = {
            roas_has_selected_payment_processor: true,
            roas_payment_processor: data,
            roas_payment_processor_selected_at: Number(moment().format("x")),
        };

        setDoc(user_ref, payload, { merge: true });
    };

    const update_user_intercom = (data) => {
        console.log("update_user_intercom");
        if (user && !user.is_admin) {
            let meta = {
                roas_has_selected_payment_processor: true,
                roas_payment_processor: data,
            };

            let intercom_defaults = {
                api_base: "https://api-iam.intercom.io",
                app_id: "wfjbdiuj",
                roas_email: user.roas_email,
                email: user.roas_email,
                roas_user_id: user.roas_user_id,
                user_id: user.roas_user_id,
            };

            window.Intercom("update", { ...intercom_defaults, ...meta });
        }
    };

    const onSelectPaymentProcessor = (payment_processor) => {
        set_project(payment_processor);
        update_user_db(payment_processor);
        update_user_intercom(payment_processor);

        setUserAccount({
            ...userAccount,
            roas_has_selected_payment_processor: true,
            roas_payment_processor: payment_processor,
            roas_onboard_step: "",
        });
    };

    return (
        <div className="carousel_view_container" id="select_processor" key="select_processor">
            <div className="select_container">
                <div className="select_item_container" onClick={() => onSelectPaymentProcessor("stripe")}>
                    <div className="radio">
                        <Checkbox checked={userAccount.has_selected_payment_processor}></Checkbox>
                    </div>
                    <div className="name">Stripe</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={StripeLogo} width={70} />
                        </div>
                    </div>
                </div>
                <div className="select_item_container" onClick={() => onSelectPaymentProcessor("shopify")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">Shopify</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={ShopifyLogo} width={70} />
                        </div>
                    </div>
                </div>
                <div className="select_item_container" onClick={() => onSelectPaymentProcessor("none")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">Don't see my payment processor</div>
                </div>
                {/* <div className="select_item_container">
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">Braintree</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={BraintreeLogo} width={70} />
                        </div>
                    </div>
                </div> */}
                {/* <div className="select_item_container">
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">Easy Pay Direct</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={EasyPayDirectLogo} width={70} />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

const ConnectToStripePaymentProcessor = () => {
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onConnectToPaymentProcessorClick = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_payment_processor_connection: true });
        setVideoIsVisible(false);
    };

    const onConnectedToPaymentProcessorClick = () => {
        setUserAccount({ ...userAccount, roas_has_payment_processor_connected: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_payment_processor_connection || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/5c744f3735404ff885706c516a3ce9ad"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_payment_processor_connection && (
                    <a href="https://www.stripe.com" target="_blank" className="connect_to_stripe_button" onClick={onConnectToPaymentProcessorClick}>
                        <div className="text">Connect To Stripe</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_payment_processor_connection && (
                    <div className="connect_to_stripe_button" onClick={onConnectedToPaymentProcessorClick}>
                        <div className="text">Click To Verify That You Have Connected To Stripe</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

const ConnectToShopifyPaymentProcessor = () => {
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onConnectToPaymentProcessorClick = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_payment_processor_connection: true });
        setVideoIsVisible(false);
    };

    const onConnectedToPaymentProcessorClick = () => {
        setUserAccount({ ...userAccount, roas_has_payment_processor_connected: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_payment_processor_connection || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, marginBottom: "20px" }}>
                            <iframe
                                src="https://www.loom.com/embed/208788f69d9444f4835e529aecb189fd"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>

                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/5e3bc3bdf65c49c59fd021508d35893f"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_payment_processor_connection && (
                    <a href="https://www.shopify.com" target="_blank" className="connect_to_stripe_button" onClick={onConnectToPaymentProcessorClick}>
                        <div className="text">Connect To Shopify</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_payment_processor_connection && (
                    <div className="connect_to_stripe_button" onClick={onConnectedToPaymentProcessorClick}>
                        <div className="text">Click To Verify That You Have Connected To Shopify</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

const PaymentProcessorDone = () => {
    return (
        <div className="carousel_view_container" id="stripe_processor" key="stripe_processor">
            <div className="done_container">
                <div className="step">
                    <div className="name">Select payment processor</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
                <div className="step">
                    <div className="name">Connect payment processor</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
                {/* <div className="step">
                    <div className="name">Verify connection to payment processor</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div> */}
                <div className="step">
                    <div className="name">Status</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
            </div>
        </div>
    );
};

const PaymentProcessorCarousel = () => {
    const [activeSlideIndex] = useAtom(payment_processor_carousel_atom);
    let CarouselMap = {
        SelectPaymentProcessor,
        ConnectToStripePaymentProcessor,
        ConnectToShopifyPaymentProcessor,
        PaymentProcessorDone,
    };

    const Component = CarouselMap[activeSlideIndex];

    return (
        <div className="carousel_container">
            <Component />
        </div>
    );
};

const SelectShoppingCart = () => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    const set_project = (data) => {
        console.log("set_project");

        let project_query = query(collection(db, "projects"), where("roas_user_id", "==", user.uid));
        let project_doc = from(getDocs(project_query)).pipe(
            rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
            rxmap(head)
        );

        project_doc.subscribe((project) => {
            let { project_id } = project;

            let payload = {
                shopping_cart_name: data,
                shopping_cart_id: data,
            };

            let project_ref = doc(db, "projects", project_id);
            setDoc(project_ref, payload, { merge: true });
        });
    };

    const update_user_db = (data) => {
        let user_ref = doc(db, "accounts", user.uid);

        let payload = {
            roas_has_selected_shopping_cart: true,
            roas_shopping_cart: data,
            roas_shopping_cart_selected_at: Number(moment().format("x")),
        };

        setDoc(user_ref, payload, { merge: true });
    };

    const update_user_intercom = (data) => {
        console.log("update_user_intercom");
        if (user && !user.is_admin) {
            let meta = {
                roas_has_selected_shopping_cart: true,
                roas_shopping_cart: data,
            };

            let intercom_defaults = {
                api_base: "https://api-iam.intercom.io",
                app_id: "wfjbdiuj",
                roas_email: user.roas_email,
                email: user.roas_email,
                roas_user_id: user.roas_user_id,
                user_id: user.roas_user_id,
            };

            window.Intercom("update", { ...intercom_defaults, ...meta });
        }
    };

    const onSelectShoppingCart = (shopping_cart) => {
        set_project(shopping_cart);
        update_user_db(shopping_cart);
        update_user_intercom(shopping_cart);
        setUserAccount({ ...userAccount, roas_has_selected_shopping_cart: true, roas_shopping_cart: shopping_cart, roas_onboard_step: "" });
    };

    return (
        <div className="carousel_view_container" id="select_processor" key="select_processor">
            <div className="select_container">
                <div className="select_item_container" onClick={() => onSelectShoppingCart("clickfunnels")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">Click Funnels</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={ClickfunnelsLogo} width={70} />
                        </div>
                    </div>
                </div>
                <div className="select_item_container" onClick={() => onSelectShoppingCart("shopify")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">Shopify</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={ShopifyLogo} width={70} />
                        </div>
                    </div>
                </div>
                {/* <div className="select_item_container" onClick={() => onSelectShoppingCart("kajabi")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">Kajabi</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={KajabiLogo} width={70} />
                        </div>
                    </div>
                </div> */}
                <div className="select_item_container" onClick={() => onSelectShoppingCart("woocommerce")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">WooCommerce</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={WoocommerceLogo} width={70} />
                        </div>
                    </div>
                </div>
                {/* <div className="select_item_container" onClick={() => onSelectShoppingCart("thrivecart")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">ThriveCart</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={ThriveCartLogo} width={70} />
                        </div>
                    </div>
                </div> */}
                <div className="select_item_container" onClick={() => onSelectShoppingCart("kartra")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">Kartra</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={KartraLogo} width={70} />
                        </div>
                    </div>
                </div>
                <div className="select_item_container" onClick={() => onSelectShoppingCart("keap")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">Keap</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={KeapLogo} width={70} />
                        </div>
                    </div>
                </div>
                <div className="select_item_container" onClick={() => onSelectShoppingCart("samcart")}>
                    <div className="radio">
                        <Checkbox></Checkbox>
                    </div>
                    <div className="name">SamCart</div>
                    <div className="right_adjusted">
                        <div className="logo">
                            <img src={SamcartLogo} width={70} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ConnectToClickFunnelsShopingCart = () => {
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onInitiateShopingCartInstall = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_shopping_cart_connection: true });
        setVideoIsVisible(false);
    };

    const onVerifyShoppingCartConnection = () => {
        setUserAccount({ ...userAccount, roas_has_shopping_cart_connected: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_shopping_cart_connection || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/ad375f631f9c4f2da269a56c9d533e6b"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_shopping_cart_connection && (
                    <a
                        href="https://app.clickfunnels.com/users/sign_in"
                        target="_blank"
                        className="connect_to_stripe_button"
                        onClick={onInitiateShopingCartInstall}
                    >
                        <div className="text">Connect To Click Funnels</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_shopping_cart_connection && (
                    <div className="connect_to_stripe_button" onClick={onVerifyShoppingCartConnection}>
                        <div className="text">Click To Verify That You Have Connected To Click Funnels</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

// const ConnectToKajabiShopingCart = () => {
//     const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
//     const [videoIsVisible, setVideoIsVisible] = useState(true);

//     const onInitiateShopingCartInstall = () => {
//         setUserAccount({ ...userAccount, roas_has_initiated_shopping_cart_connection: true });
//         setVideoIsVisible(false);
//     };

//     const onVerifyShoppingCartConnection = () => {
//         setUserAccount({ ...userAccount, roas_has_shopping_cart_connected: true });
//     };

//     const onShowVideoClick = () => {
//         setVideoIsVisible(true);
//     };

//     return (
//         <animated.div
//             className="carousel_view_container"
//             id="stripe_processor"
//             key="stripe_processor"
//             style={useSpring({
//                 from: { transform: "translate(500px,0)" },
//                 to: { transform: "translate(0px,0)" },
//                 config: { duration: 600 },
//                 reset: false,
//             })}
//         >
//             <div className="connect_to_payment_processor_container">
//                 {!videoIsVisible && (
//                     <div className="show_video_container" onClick={onShowVideoClick}>
//                         Show Video
//                     </div>
//                 )}

//                 {(!userAccount.roas_has_initiated_shopping_cart_connection || videoIsVisible) && (
//                     <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
//                         <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
//                             <iframe
//                                 src="https://www.loom.com/embed/b4539102d4864404b1c1c3ed2066707c"
//                                 frameBorder="0"
//                                 webkitAllowFullscreen="true"
//                                 mozAllowFullscreen="true"
//                                 allowFullscreen="true"
//                                 style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
//                             ></iframe>
//                         </div>
//                     </div>
//                 )}

//                 {!userAccount.roas_has_initiated_shopping_cart_connection && (
//                     <a href="https://app.kajabi.com" target="_blank" className="connect_to_stripe_button" onClick={onInitiateShopingCartInstall}>
//                         <div className="text">Connect To Kajabi</div>
//                     </a>
//                 )}

//                 {userAccount.roas_has_initiated_shopping_cart_connection && (
//                     <div className="connect_to_stripe_button" onClick={onVerifyShoppingCartConnection}>
//                         <div className="text">Click To Verify That You Have Connected To Kajabi</div>
//                     </div>
//                 )}
//             </div>
//         </animated.div>
//     );
// };

const ConnectToWoocommerceShopingCart = () => {
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onInitiateShopingCartInstall = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_shopping_cart_connection: true });
        setVideoIsVisible(false);
    };

    const onVerifyShoppingCartConnection = () => {
        setUserAccount({ ...userAccount, roas_has_shopping_cart_connected: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_shopping_cart_connection || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/74678c3d10724094be9aded897b32355"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_shopping_cart_connection && (
                    <a href="https://wwww.wordpress.org" target="_blank" className="connect_to_stripe_button" onClick={onInitiateShopingCartInstall}>
                        <div className="text">Connect To Woocommerce</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_shopping_cart_connection && (
                    <div className="connect_to_stripe_button" onClick={onVerifyShoppingCartConnection}>
                        <div className="text">Click To Verify That You Have Connected To Woocommerce</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

const ConnectToThriveCartShopingCart = () => {
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onInitiateShopingCartInstall = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_shopping_cart_connection: true });
        setVideoIsVisible(false);
    };

    const onVerifyShoppingCartConnection = () => {
        setUserAccount({ ...userAccount, roas_has_shopping_cart_connected: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_shopping_cart_connection || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/2a4107d619fd470480be15e0c5833adb"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_shopping_cart_connection && (
                    <a
                        href="https://thrivecart.com/login/"
                        target="_blank"
                        className="connect_to_stripe_button"
                        onClick={onInitiateShopingCartInstall}
                    >
                        <div className="text">Connect To Thrivecart</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_shopping_cart_connection && (
                    <div className="connect_to_stripe_button" onClick={onVerifyShoppingCartConnection}>
                        <div className="text">Click To Verify That You Have Connected To Thrivecart</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

const ConnectToKartraShopingCart = () => {
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onInitiateShopingCartInstall = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_shopping_cart_connection: true });
        setVideoIsVisible(false);
    };

    const onVerifyShoppingCartConnection = () => {
        setUserAccount({ ...userAccount, roas_has_shopping_cart_connected: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_shopping_cart_connection || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/db882973ad9e41c2947a564b93138de1"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_shopping_cart_connection && (
                    <a
                        href="https://app.kartra.com/login"
                        target="_blank"
                        className="connect_to_stripe_button"
                        onClick={onInitiateShopingCartInstall}
                    >
                        <div className="text">Connect To Kartra</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_shopping_cart_connection && (
                    <div className="connect_to_stripe_button" onClick={onVerifyShoppingCartConnection}>
                        <div className="text">Click To Verify That You Have Connected To Kartra</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

const ConnectToKeapShopingCart = () => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onInitiateShopingCartInstall = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_shopping_cart_connection: true });
        setVideoIsVisible(false);
    };

    const onVerifyShoppingCartConnection = () => {
        setUserAccount({ ...userAccount, roas_has_shopping_cart_connected: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_shopping_cart_connection || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/972a2c0a0f1c4d35b6611948b1b7ed30"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_shopping_cart_connection && (
                    <a
                        href={`https://us-central1-roas-e44b8.cloudfunctions.net/oauth/auth/keap/?user_id=${user.uid}`}
                        target="_blank"
                        className="connect_to_stripe_button"
                        onClick={onInitiateShopingCartInstall}
                    >
                        <div className="text">Connect To Keap</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_shopping_cart_connection && (
                    <div className="connect_to_stripe_button" onClick={onVerifyShoppingCartConnection}>
                        <div className="text">Click To Verify That You Have Connected To Keap</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

const ConnectToSamCartShopingCart = () => {
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onInitiateShopingCartInstall = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_shopping_cart_connection: true });
        setVideoIsVisible(false);
    };

    const onVerifyShoppingCartConnection = () => {
        setUserAccount({ ...userAccount, roas_has_shopping_cart_connected: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_shopping_cart_connection || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/5f2b29dfcb1347d487345503fc81f110"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_shopping_cart_connection && (
                    <a
                        href="https://samcart.com/auth/login"
                        target="_blank"
                        className="connect_to_stripe_button"
                        onClick={onInitiateShopingCartInstall}
                    >
                        <div className="text">Connect To SamCart</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_shopping_cart_connection && (
                    <div className="connect_to_stripe_button" onClick={onVerifyShoppingCartConnection}>
                        <div className="text">Click To Verify That You Have Connected To SamCart</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

const ConnectToShopifyShopingCart = () => {
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [videoIsVisible, setVideoIsVisible] = useState(true);

    const onInitiateShopingCartInstall = () => {
        setUserAccount({ ...userAccount, roas_has_initiated_shopping_cart_connection: true });
        setVideoIsVisible(false);
    };

    const onVerifyShoppingCartConnection = () => {
        setUserAccount({ ...userAccount, roas_has_shopping_cart_connected: true });
    };

    const onShowVideoClick = () => {
        setVideoIsVisible(true);
    };

    return (
        <animated.div
            className="carousel_view_container"
            id="stripe_processor"
            key="stripe_processor"
            style={useSpring({
                from: { transform: "translate(500px,0)" },
                to: { transform: "translate(0px,0)" },
                config: { duration: 600 },
                reset: false,
            })}
        >
            <div className="connect_to_payment_processor_container">
                {!videoIsVisible && (
                    <div className="show_video_container" onClick={onShowVideoClick}>
                        Show Video
                    </div>
                )}

                {(!userAccount.roas_has_initiated_shopping_cart_connection || videoIsVisible) && (
                    <div className="carousel_video_container" style={{ marginBottom: "20px" }}>
                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, marginBottom: "20px" }}>
                            <iframe
                                src="https://www.loom.com/embed/208788f69d9444f4835e529aecb189fd"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>

                        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                            <iframe
                                src="https://www.loom.com/embed/5e3bc3bdf65c49c59fd021508d35893f"
                                frameBorder="0"
                                webkitAllowFullscreen="true"
                                mozAllowFullscreen="true"
                                allowFullscreen="true"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            ></iframe>
                        </div>
                    </div>
                )}

                {!userAccount.roas_has_initiated_shopping_cart_connection && (
                    <a
                        href="https://accounts.shopify.com/store-login"
                        target="_blank"
                        className="connect_to_stripe_button"
                        onClick={onInitiateShopingCartInstall}
                    >
                        <div className="text">Connect To Shopify</div>
                    </a>
                )}

                {userAccount.roas_has_initiated_shopping_cart_connection && (
                    <div className="connect_to_stripe_button" onClick={onVerifyShoppingCartConnection}>
                        <div className="text">Click To Verify That You Have Connected To Shopify</div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};

const ShoppingCartDone = () => {
    return (
        <div className="carousel_view_container" id="stripe_processor" key="stripe_processor">
            <div className="done_container">
                <div className="step">
                    <div className="name">Select shopping cart</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
                <div className="step">
                    <div className="name">Connect shopping cart</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
                <div className="step">
                    <div className="name">Verify connection to shopping cart</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
                <div className="step">
                    <div className="name">Status</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
            </div>
        </div>
    );
};

const ShoppingCartCarousel = () => {
    const [activeSlideIndex] = useAtom(shopping_cart_carousel_atom);

    let CarouselMap = {
        SelectShoppingCart,
        ConnectToClickFunnelsShopingCart,
        // ConnectToKajabiShopingCart,
        ConnectToWoocommerceShopingCart,
        ConnectToThriveCartShopingCart,
        ConnectToKartraShopingCart,
        ConnectToSamCartShopingCart,
        ConnectToShopifyShopingCart,
        ConnectToKeapShopingCart,
        ShoppingCartDone,
    };

    const Component = CarouselMap[activeSlideIndex];

    return (
        <div className="carousel_container">
            <Component />
        </div>
    );
};

// let code = (user_id) => `
//     <script>
//         (async function roas(params) {
//             var { user_id } = params;
//             localStorage.setItem("roas_user_id", user_id);
//             var response = await fetch("https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js").then(function(res){return res.json()});
//             var { downloadTokens } = response;
//             var head = document.head;
//             var script = document.createElement("script");
//             script.type = "text/javascript";
//             script.src = "https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js?alt=media&token=" + downloadTokens
//             head.appendChild(script);
//         })({ user_id: "${user_id}" });
//     </script>
// `;

let code = (user_id) => `
    <script>
        (function roas(params) {
            var user_id  = params.user_id;
            localStorage.setItem("roas_user_id", user_id);
            fetch("https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js").then(function(res){return res.json()}).then(function(response){
                var downloadTokens  = response.downloadTokens;
                var head = document.head;
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js?alt=media&token=" + downloadTokens
                head.appendChild(script);
            })
        })({ user_id: "${user_id}" });
    </script>
`;

const CodeSnippetDone = () => {
    return (
        <div className="carousel_view_container" id="stripe_processor" key="stripe_processor">
            <div className="done_container">
                <div className="step">
                    <div className="name">Install tracking script</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
                <div className="step">
                    <div className="name">Test tracking script</div>
                    <div className="status">
                        <LoadingOutlined />
                    </div>
                </div>
                <div className="step">
                    <div className="name">Status</div>
                    <div className="status">
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const PaymentProcessorWebhook = () => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    if (userAccount.roas_payment_processor == "stripe") {
        return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/stripe/${user.uid}`}</div>;
    }

    if (userAccount.roas_payment_processor == "shopify") {
        return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/shopify/${user.uid}`}</div>;
    }

    return <div className="webhooks_url_container">Select a payment processor first to get your webhook url</div>;
};

const ShoppingCartWebhook = () => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    if (userAccount.roas_shopping_cart == "clickfunnels") {
        return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/clickfunnels/${user.uid}`}</div>;
    }

    if (userAccount.roas_shopping_cart == "shopify") {
        return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/shopify/${user.uid}`}</div>;
    }

    // if (userAccount.roas_shopping_cart == "kajabi") {
    //     return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/kajabi/${user.uid}`}</div>;
    // }

    if (userAccount.roas_shopping_cart == "woocommerce") {
        return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/woocommerce/${user.uid}`}</div>;
    }

    if (userAccount.roas_shopping_cart == "thrivecart") {
        return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/thrivecart/${user.uid}`}</div>;
    }

    if (userAccount.roas_shopping_cart == "kartra") {
        return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/kartra/${user.uid}`}</div>;
    }

    if (userAccount.roas_shopping_cart == "keap") {
        return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/keap/${user.uid}`}</div>;
    }

    if (userAccount.roas_shopping_cart == "samcart") {
        return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/samcart/${user.uid}`}</div>;
    }

    return <div className="webhooks_url_container">Select a shopping cart first to get your webhook url</div>;
};

const CodeSnippetSection = () => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    const onCopySnippetToClipboard = (type) => {
        if (type == "payment_processor") {
            let { roas_payment_processor } = userAccount;
            if (roas_payment_processor == "") return;
            let url = `https://us-central1-roas-e44b8.cloudfunctions.net/app/${roas_payment_processor}/${user.uid}`;
            copy(url);
        }

        if (type == "shopping_cart") {
            let { roas_shopping_cart } = userAccount;
            if (roas_shopping_cart == "") return;
            let url = `https://us-central1-roas-e44b8.cloudfunctions.net/app/${roas_shopping_cart}/${user.uid}`;
            copy(url);
        }

        if (type == "tracking_code") {
            copy(code(user.uid));
        }
    };

    return (
        <div className="carousel_view_container" id="stripe_processor" key="stripe_processor">
            {userAccount.roas_payment_processor !== "none" && (
                <div className="webhooks_container">
                    <div className="title">Payment Processor Webhook URL</div>
                    <PaymentProcessorWebhook />
                    <div className="copy_code_snippet_to_clipboard_container" onClick={() => onCopySnippetToClipboard("payment_processor")}>
                        Copy To Clipboard
                    </div>
                </div>
            )}

            <div className="webhooks_container">
                <div className="title">Shopping Cart Webhook URL</div>
                <ShoppingCartWebhook />
                <div className="copy_code_snippet_to_clipboard_container" onClick={() => onCopySnippetToClipboard("shopping_cart")}>
                    Copy To Clipboard
                </div>
            </div>

            <div className="tracking_code_container">
                <div className="title">ROAS Tracking Script</div>
                <div className="tracking_code">
                    <Highlight language={"javascript"}>{code(user.uid)}</Highlight>
                </div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={() => onCopySnippetToClipboard("tracking_code")}>
                    Copy To Clipboard
                </div>
            </div>
        </div>
    );
};

const CodeSnippetCarousel = () => {
    const [activeSlideIndex] = useAtom(code_snippet_carousel_atom);

    let CarouselMap = {
        CodeSnippetSection,
        CodeSnippetDone,
    };

    const Component = CarouselMap[activeSlideIndex];

    return (
        <div className="carousel_container">
            <Component />
        </div>
    );
};

const FaceobokAdIdParamaterAddedtDone = () => {
    return (
        <div className="carousel_view_container" id="stripe_processor" key="stripe_processor">
            <div className="done_container">
                <div className="step">
                    <div className="name">Add Facebook fb_id utm paramater</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
                <div className="step">
                    <div className="name">Status</div>
                    <div className="status">
                        <CheckCircleTwoTone />
                    </div>
                </div>
            </div>
        </div>
    );
};

const FacebookUTMParamaterCarousel = () => {
    const [activeSlideIndex] = useAtom(facebook_ad_id_carousel_atom);

    console.log("FacebookUTMParamaterCarousel");
    console.log(activeSlideIndex);

    let CarouselMap = {
        AddFacebookAdId,
        FaceobokAdIdParamaterAddedtDone,
    };

    const Component = CarouselMap[activeSlideIndex];

    return (
        <div className="carousel_container">
            <Component />
        </div>
    );
};

const OnboardingContainer = styled.div`
    justify-content: ${({ overflowed }) => (overflowed ? "flex-start" : "center")} !important;
    padding-top: ${({ overflowed }) => overflowed && "25px"};
    padding-bottom: ${({ overflowed }) => overflowed && "25px"};
`;

const OnboardingStatusButton = () => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [onboardingStatus] = useAtom(status_button_atom);

    let navigate = useNavigate();

    const onFinishOnboardingClick = () => {
        let intercom_defaults = {
            api_base: "https://api-iam.intercom.io",
            app_id: "wfjbdiuj",
            roas_email: user.roas_email,
            email: user.roas_email,
            roas_user_id: user.roas_user_id,
            user_id: user.roas_user_id,
        };

        let meta = { roas_has_onboarded: true };

        let user_ref = doc(db, "accounts", user.uid);
        from(setDoc(user_ref, { roas_has_onboarded: true }, { merge: true })).pipe(() => {
            window.Intercom("update", { ...intercom_defaults, ...meta });
            navigate(`/ads-manager`);
        });
    };

    return (
        <div className="onboarding_status_button">
            {onboardingStatus && (
                <div className="success_status_button" onClick={onFinishOnboardingClick}>
                    I'm All Set, Show Me My Stats!
                </div>
            )}

            {!onboardingStatus && <div className="disabled_status_button">I'm All Set, Show Me My Stats!</div>}
        </div>
    );
};

const Onboarding = () => {
    const [app, setApp] = useAtom(app_atom);
    const [user] = useAtom(user_atom);
    const onboardingContainerRef = useRef();
    const viewRef = useRef();
    const [overflowed, setOverflow] = useState("");
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [accordion, setAccordion] = useAtom(accordion_atom);
    const [fbActiveIndex] = useAtom(facebook_carousel_atom);
    const [utmActiveIndex] = useAtom(facebook_ad_id_carousel_atom);
    const [paymentProcessorActiveIndex] = useAtom(payment_processor_carousel_atom);
    const [shoppingCartActiveIndex] = useAtom(shopping_cart_carousel_atom);
    const [showCloseInitialVideo, setShowCloseInitialVideo] = useState(false);
    var abstract = fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=02ef5f720b7840a8a9e00667586d575b").then((res) => res.json());
    var ip_geo = fetch("https://api.ipgeolocation.io/ipgeo?apiKey=0849e8e06c884d1f96fc94de6e7738d8").then((res) => res.json());

    zip([abstract, ip_geo]).subscribe((ips) => {
        let [abstract, ipgeo] = ips;
        let { ip_address } = abstract;

        let roas_ipv4 = localStorage.getItem("roas_ipv4");

        if (!roas_ipv4) {
            console.log("noipv4");
            localStorage.setItem("roas_ipv4", ip_address);

            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, "script", "dataLayer", "GTM-TJ4NN74");
        } else {
            console.log("yesipv4");
        }
    });

    const get_user_account = (user_id) => {
        let q = doc(db, "accounts", user_id);
        return from(getDoc(q)).pipe(rxmap((doc) => doc.data()));
    };

    useEffect(() => {
        if (user.uid) {
            get_user_account(user.uid).subscribe((user_account) => {
                setUserAccount({ ...user_account });
            });
        }
    }, [user.uid]);

    useEffect(() => {
        if (user && !user.is_admin) {
            let intercom_defaults = {
                api_base: "https://api-iam.intercom.io",
                app_id: "wfjbdiuj",
                roas_email: user.roas_email,
                email: user.roas_email,
                roas_user_id: user.roas_user_id,
                user_id: user.roas_user_id,
            };

            let roas_keys = pipe(keys, lofilter(includes("roas")))(userAccount);
            let meta = pipe(pick(roas_keys))(userAccount);

            window.Intercom("update", { ...intercom_defaults, ...meta });
        }
    }, [userAccount]);

    useEffect(() => {
        onToggleAccordionItem();
    }, [userAccount]);

    const onToggleAccordionItem = () => {
        setTimeout(() => {
            let view_coordinates = viewRef.current.getBoundingClientRect();
            let container_coordinates = onboardingContainerRef.current.getBoundingClientRect();

            if (view_coordinates.height < container_coordinates.height) {
                setOverflow(true);
            } else {
                setOverflow(true);
            }
        }, 0);
    };

    const onSetOnboardStep = (args) => {
        setUserAccount({ ...userAccount, ...args });
    };

    const onCloseAccordionStep = (args) => {
        setAccordion({ ...accordion, ...args });
    };

    return (
        <div className="onboarding_view" ref={viewRef}>
            <div className="schedule_setup_call_container">
                <div className="text_container">
                    <div className="text_row" style={{ fontWeight: "bold" }}>
                        Want us to do this FOR YOU?
                    </div>
                    <div className="text_row">Click here to pick a time that works and we'll take care of it together.</div>
                    <div className="text_row">This is NOT a sales call 😊</div>
                </div>
                <a href="https://calendly.com/roas-onboarding/roas-onboarding" target="_blank" className="cta">
                    Let's do it!
                </a>
            </div>

            <OnboardingContainer className="onboarding_container" overflowed={overflowed}>
                <div className="onboarding_inner_container" ref={onboardingContainerRef}>
                    <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
                        <AccordionItem dangerouslySetExpanded={accordion.facebook.is_open}>
                            <AccordionItemHeading onClick={onToggleAccordionItem}>
                                <AccordionItemButton className="onboarding_header">
                                    <div className="step_container">
                                        <div className="step_count">1</div>
                                        <div></div>
                                    </div>
                                    <div className="description_container">
                                        <div className="title" onClick={() => onCloseAccordionStep({ facebook: { is_open: true } })}>
                                            Lets connect your Facebook account!
                                        </div>
                                        <div className="step_progress_container">
                                            <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({ roas_onboard_step: "ConnectToFacebook", active_fb_index: fbActiveIndex })
                                                }
                                            >
                                                1
                                            </div>
                                            <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({ roas_onboard_step: "FacebookAccounts", active_fb_index: fbActiveIndex })
                                                }
                                            >
                                                2
                                            </div>
                                            {/* <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({ roas_onboard_step: "AddFacebookAdId", active_fb_index: fbActiveIndex })
                                                }
                                            >
                                                3
                                            </div> */}
                                            <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({ roas_onboard_step: "FacebookDone", active_fb_index: fbActiveIndex })
                                                }
                                            >
                                                3
                                            </div>
                                            <div className="step" onClick={() => onCloseAccordionStep({ facebook: { is_open: false } })}>
                                                <CloseCircleOutlined />
                                            </div>
                                        </div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="onboarding_panel_container">
                                <div className="step_line_container">
                                    <div className="line"></div>
                                </div>
                                <div className="content">
                                    <ConnectFacebookCarousel />
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem dangerouslySetExpanded={accordion.shopping_cart.is_open}>
                            <AccordionItemHeading onClick={onToggleAccordionItem}>
                                <AccordionItemButton className="onboarding_header">
                                    <div className="step_container">
                                        <div className="step_count">2</div>
                                        <div></div>
                                    </div>
                                    <div className="description_container">
                                        <div className="title" onClick={() => onCloseAccordionStep({ shopping_cart: { is_open: true } })}>
                                            Lets connect your shopping cart.
                                        </div>
                                        <div className="step_progress_container">
                                            <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({
                                                        roas_onboard_step: "SelectShoppingCart",
                                                        active_shopping_cart_index: shoppingCartActiveIndex,
                                                    })
                                                }
                                            >
                                                1
                                            </div>
                                            <div className="step">2</div>
                                            <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({
                                                        roas_onboard_step: "ShoppingCartDone",
                                                        active_shopping_cart_index: shoppingCartActiveIndex,
                                                    })
                                                }
                                            >
                                                3
                                            </div>
                                            <div className="step" onClick={() => onCloseAccordionStep({ shopping_cart: { is_open: false } })}>
                                                <CloseCircleOutlined />
                                            </div>
                                        </div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="onboarding_panel_container">
                                <div className="step_line_container">
                                    <div className="line"></div>
                                </div>
                                <div className="content">
                                    <ShoppingCartCarousel />
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem dangerouslySetExpanded={accordion.payment_processor.is_open}>
                            <AccordionItemHeading onClick={onToggleAccordionItem}>
                                <AccordionItemButton className="onboarding_header">
                                    <div className="step_container">
                                        <div className="step_count">3</div>
                                        <div></div>
                                    </div>
                                    <div className="description_container">
                                        <div className="title" onClick={() => onCloseAccordionStep({ payment_processor: { is_open: true } })}>
                                            Lets connect your payment processor.
                                        </div>
                                        <div className="step_progress_container">
                                            <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({
                                                        roas_onboard_step: "SelectPaymentProcessor",
                                                        active_payment_processor_index: paymentProcessorActiveIndex,
                                                    })
                                                }
                                            >
                                                1
                                            </div>
                                            <div className="step">2</div>
                                            <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({
                                                        roas_onboard_step: "PaymentProcessorDone",
                                                        active_payment_processor_index: paymentProcessorActiveIndex,
                                                    })
                                                }
                                            >
                                                3
                                            </div>
                                            <div className="step" onClick={() => onCloseAccordionStep({ payment_processor: { is_open: false } })}>
                                                <CloseCircleOutlined />
                                            </div>
                                        </div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>

                            <AccordionItemPanel className="onboarding_panel_container">
                                <div className="step_line_container">
                                    <div className="line"></div>
                                </div>
                                <div className="content">
                                    <PaymentProcessorCarousel />
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem dangerouslySetExpanded={accordion.facebook_utm.is_open}>
                            <AccordionItemHeading onClick={onToggleAccordionItem}>
                                <AccordionItemButton className="onboarding_header">
                                    <div className="step_container">
                                        <div className="step_count">4</div>
                                        <div></div>
                                    </div>
                                    <div className="description_container">
                                        <div className="title" onClick={() => onCloseAccordionStep({ facebook_utm: { is_open: true } })}>
                                            Lets add your UTM paramaters to Facebook.
                                        </div>
                                        <div className="step_progress_container">
                                            <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({
                                                        roas_onboard_step: "AddFacebookAdId",
                                                        active_utm_index: utmActiveIndex,
                                                    })
                                                }
                                            >
                                                1
                                            </div>
                                            <div
                                                className="step"
                                                onClick={() =>
                                                    onSetOnboardStep({
                                                        roas_onboard_step: "FaceobokAdIdParamaterAddedtDone",
                                                        active_utm_index: utmActiveIndex,
                                                    })
                                                }
                                            >
                                                2
                                            </div>
                                            <div className="step" onClick={() => onCloseAccordionStep({ facebook_utm: { is_open: false } })}>
                                                <CloseCircleOutlined />
                                            </div>
                                        </div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="onboarding_panel_container">
                                <div className="step_line_container">
                                    <div className="line"></div>
                                </div>
                                <div className="content">
                                    <FacebookUTMParamaterCarousel />
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem dangerouslySetExpanded={true}>
                            <AccordionItemHeading onClick={onToggleAccordionItem}>
                                <AccordionItemButton className="onboarding_header">
                                    <div className="step_container">
                                        <div className="step_count">5</div>
                                        <div></div>
                                    </div>
                                    <div className="description_container">
                                        <div className="title">Your tracking scripts!</div>
                                        <div className="step_progress_container">
                                            <div className="step">1</div>
                                            <div className="step">2</div>
                                            <div className="step">
                                                <CloseCircleOutlined />
                                            </div>
                                        </div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="onboarding_panel_container">
                                <div className="step_line_container">
                                    <div className="line"></div>
                                </div>
                                <div className="content">
                                    <CodeSnippetCarousel />
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        {/* <AccordionItem>
                            <AccordionItemHeading onClick={onToggleAccordionItem}>
                                <AccordionItemButton className="onboarding_header">
                                    <div className="step_container">
                                        <div className="step_count">5</div>
                                        <div></div>
                                    </div>
                                    <div className="description_container">
                                        <div className="title">Final Step! Let's make sure your ads are tracking!</div>
                                        <div className="step_progress_container">
                                            <div className="step">1</div>
                                            <div className="step">2</div>
                                            <div className="step">
                                                <CheckCircleOutlined />
                                            </div>
                                        </div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="onboarding_panel_container">
                                <div className="step_line_container">
                                    <div className="line"></div>
                                </div>
                                <div className="content">
                                    <div className="ad_dynamic_urls_container">
                                        <iframe
                                            width="560"
                                            height="315"
                                            src="https://www.youtube.com/embed/X18EzSaWtnA?controls=0"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem> */}
                    </Accordion>
                </div>
                <OnboardingStatusButton />
            </OnboardingContainer>
        </div>
    );
};

export default Onboarding;
