import React,  from "react";
import {  Button } from "antd";
import {  CloseCircleOutlined,  UserOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { audience_view_state_atom,  } from "../state/audiences";
import { app_atom } from "../state/app";
import { lomap,  toFixed, loreduce } from "../../utilities/helpers";
import { prop,  defaultTo, pipe, head, pick, reverse,  values, isEmpty } from "ramda";
import {  mod, all } from "shades";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip,  ResponsiveContainer, PieChart, Pie,  } from "recharts";
import { PieChart, Pie, Sector } from "recharts";
import { loreduce, toFixed } from "../../utilities/helpers";

const InterestInsights = () => {
    const [app, setApp] = useAtom(app_atom);
    const [audienceViewState, setAudienceViewState] = useAtom(audience_view_state_atom);

    if (isEmpty(audienceViewState.selected_interest)) return <div></div>;

    let data = pipe(values, head)(audienceViewState.selected_interest);

    let genders = pipe(
        prop("gender_age"),
        mod(all)(pick(["Male", "Female"])),
        loreduce((left, right) => {
            let Female = pipe(prop("Female"), defaultTo(0))(left) + pipe(prop("Female"), defaultTo(0))(right);
            let Male = pipe(prop("Male"), defaultTo(0))(left) + pipe(prop("Male"), defaultTo(0))(right);
            return { Male, Female };
        })
    )(data);

    let gender_data = pipe(lomap((value, key) => ({ name: key, value })))(genders);

    let regions_data = pipe(
        prop("regions"),
        lomap((value, region) => ({ value, region })),
        reverse
    )(data);

    const PIE_COLORS = ["#0088FE", "#00C49F"];

    const renderLabel = (entry) => {
        return entry.name;
    };

    const onCloseModal = () => {
        setApp({ ...app, modal_is_open: false, modal_id: "" });
    };

    return (
        <div className="interst_insights_container">
            <div className="modal_content">
                <div className="interest_header">
                    <div className="left_adjusted">
                        <div className="interest_name">{data.interest_name}</div>
                    </div>
                    <div className="right_adjusted">
                        <div className="size_container">
                            <div className="icon">
                                <UserOutlined />
                            </div>
                            <div className="text">{pipe(Number, (value) => value.toLocaleString())(data.audience_size)}</div>
                        </div>
                        <div className="add_audience_container">
                            <Button type="primary">Save interst</Button>
                        </div>
                    </div>
                </div>

                <div className="full_container">
                    <div className="half_outer_container">
                        <div className="inner_container" style={{ width: "calc(100% - 20px)" }}>
                            <div className="chart_header">
                                <div className="title">Gender</div>
                            </div>
                            <div className="chart_container">
                                <div className="chart">
                                    <ResponsiveContainer>
                                        <PieChart margin={{ top: 30, right: 25, bottom: 30, left: 25 }}>
                                            <Pie
                                                data={gender_data}
                                                innerRadius="75%"
                                                outerRadius="100%"
                                                fill="#8884d8"
                                                paddingAngle={5}
                                                dataKey="value"
                                                label={renderLabel}
                                            >
                                                {gender_data.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="labels_container">
                                    {lomap(
                                        (value, key) => (
                                            <div className="label_container" key={key}>
                                                <div className="name">{key}</div>
                                                <div className="value">{toFixed(2, value)}%</div>
                                            </div>
                                        ),
                                        genders
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="half_outer_container">
                        <div className="inner_container">
                            <div className="chart_header">
                                <div className="title">Age</div>
                            </div>
                            <div className="chart_container" style={{ width: "95%" }}>
                                <div className="chart ">
                                    <ResponsiveContainer>
                                        <BarChart data={pipe(prop("gender_age"))(data)}>
                                            <Tooltip />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="product" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="Female" stackId="a" fill="#00C49F" barSize={25} />
                                            <Bar dataKey="Male" stackId="a" fill="#0088FE" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="labels_container">
                                    {lomap(
                                        (value, key) => (
                                            <div className="label_container" key={key}>
                                                <div className="name">{key}</div>
                                            </div>
                                        ),
                                        genders
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full_container" style={{ minHeight: 600 }}>
                    <div className="full_outer_container">
                        <div className="inner_container">
                            <div className="chart_header">
                                <div className="title">Cities</div>
                            </div>
                            <div className="chart_container" style={{ width: "90%", gridTemplateRows: "100%" }}>
                                <div className="chart ">
                                    <ResponsiveContainer>
                                        <BarChart
                                            data={regions_data}
                                            layout="vertical"
                                            margin={{ top: 0, right: 0, bottom: 0, left: 100 }}
                                            barCategoryGap={30}
                                            barGap={70}
                                        >
                                            <Tooltip />
                                            <CartesianGrid strokeDasharray="3 3" />

                                            <YAxis dataKey="region" type="category" />
                                            <XAxis type="number" />
                                            <Tooltip />
                                            <Bar dataKey="value" stackId="a" fill="#00C49F" barSize={15} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="audience_close_modal_container" onClick={onCloseModal}>
                <CloseCircleOutlined />
            </div>
        </div>
    );
};

export default InterestInsights;
