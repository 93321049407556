import React, { useRef, useEffect, useState } from "react";
import { isEmpty } from "ramda";
import { Input } from "antd";

const Visualizer = () => {
    let iframe = useRef(null);
    const [iframeUrl, setIframeUrl] = useState("");

    useEffect(() => {
        const visualize = () => {
            window.onmessage = function (e) {
                console.log("data");
                console.log(e.data);
                iframe.current.contentWindow.postMessage("ZWxlYW5vcg==", "*");
            };
        };

        visualize();
    }, []);

    const onSetUrl = () => {
        setIframeUrl("http://go.frankkern.com/skip-bi-trial1635465417902");
    };

    if (isEmpty(iframeUrl)) {
        return (
            <div className="visualizer_search_view">
                <div className="search_container">
                    <div className="header">Enter Your URL Here To Track Events!</div>
                    <div className="input">
                        <Input size="large" onBlur={onSetUrl} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="visualizer_view">
            <iframe className="page_view_iframe" src={iframeUrl} ref={iframe}></iframe>
        </div>
    );
};

export default Visualizer;
