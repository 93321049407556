import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ styles }) => {
    return (
        <div className="footer" style={{ ...styles }}>
            <Link to="/policies" className="footer_link">
                Conditions of Use
            </Link>
            <Link to="/privacy" className="footer_link">
                Privacy Notice
            </Link>
            <Link to="/cookies" className="footer_link">
                Cookies
            </Link>
        </div>
    );
};

export default Footer;
