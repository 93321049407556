import React from "react";
import { useParams, useNavigate } from "react-router-dom";

const ProjectNav = ({ selected_tab }) => {
    let { project_id } = useParams();
    let navigate = useNavigate();

    return (
        <div>
            <div className="lg:hidden flex flex-row relative cursor-pointer">
                <select
                    id="selected-tab"
                    name="selected-tab"
                    className="appearance-none cursor-pointer mt-1 block w-full pl-[10px] pr-[10px] py-2 text-base border border-gray-300 border-solid focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                    <option selected>Ad Account</option>
                    <option>Tracking Script</option>
                    <option>Webhook</option>
                    <option>Settings</option>
                </select>
                <div className="flex flex-col items-center justify-center absolute right-0 top-0 bottom-0 m-auto mr-[10px]">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </div>
            <div className="hidden lg:block">
                <div className="border-b border-gray-200 border-solid ">
                    <nav className="-mb-px flex space-x-8">
                        <div
                            onClick={() => navigate(`/projects/${project_id}/ad_account`)}
                            className={`${
                                selected_tab == "ad_account"
                                    ? "border-solid border-blue-500 border-b-[2px] text-blue-600"
                                    : "border-transparent hover:border-b-[2px] hover:border-solid hover:border-gray-30 text-gray-500 hover:text-gray-700"
                            }  whitespace-nowrap py-4 px-1 font-medium text-sm cursor-pointer`}
                        >
                            Ad Account
                        </div>
                        <div
                            onClick={() => navigate(`/projects/${project_id}/tracking`)}
                            className={`${
                                selected_tab == "tracking"
                                    ? "border-solid border-blue-500 border-b-[2px] text-blue-600"
                                    : "border-transparent hover:border-b-[2px] hover:border-solid hover:border-gray-30 text-gray-500 hover:text-gray-700"
                            }  whitespace-nowrap py-4 px-1 font-medium text-sm cursor-pointer`}
                        >
                            Tracking Script
                        </div>
                        <div
                            onClick={() => navigate(`/projects/${project_id}/webhook`)}
                            className={`${
                                selected_tab == "webhook"
                                    ? "border-solid border-blue-500 border-b-[2px] text-blue-600"
                                    : "border-transparent hover:border-b-[2px] hover:border-solid hover:border-gray-30 text-gray-500 hover:text-gray-700"
                            } whitespace-nowrap py-4 px-1 font-medium text-sm cursor-pointer`}
                        >
                            Webhook
                        </div>
                        {/* <div
                            onClick={() => navigate(`/projects/${project_id}/webhook`)}
                            className={`${
                                selected_tab == "settings"
                                    ? "border-solid border-blue-500 border-b-[2px] text-blue-600"
                                    : "border-transparent hover:border-b-[2px] hover:border-solid hover:border-gray-30 text-gray-500 hover:text-gray-700"
                            } whitespace-nowrap py-4 px-1 font-medium text-sm cursor-pointer`}
                        >
                            Settings
                        </div> */}
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default ProjectNav;
