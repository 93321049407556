import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Outlet, useNavigate, Navigate, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { app_atom } from "./state/app";
import { onboarding_atom } from "./state/onboarding";
import { auth, onAuthStateChanged, db } from "./firebase";
import { getDoc, doc, setDoc, deleteDoc, addDoc, collection } from "firebase/firestore";
import { user_atom, accounts_atom } from "./state/auth";
import Visualizer from "./views/Visualizer";
import LeftNav from "./components/LeftNav";
import AdsManager from "./views/AdsManager";
import Rule from "./views/Rule";
import Rules from "./views/Rules";
import Integrations from "./views/Integrations";
import Login from "./views/Login";
import Privacy from "./views/Privacy";
import Conditions from "./views/Conditions";
import Cookies from "./views/Cookies";
import SignUp from "./views/SignUp";
import AdAccounts from "./views/AdAccounts";
import UserAccounts from "./views/UserAccounts";
import Accounts from "./views/admin/Accounts/Accounts";
import TrackingCode from "./views/TrackingCode";
import Logs from "./views/Logs";
import ShopifySsettings from "./views/ShopifySettings";
import Onboarding from "./views/Onboarding";
import OnboardingTwo from "./views/OnboardingTwo";
import OnboardingThree from "./views/OnboardingThree";
import InterestsSearch from "./views/InterestsSearch";
import Projects from "./views/Projects";
import ProjectAdAccount from "./views/ProjectAdAccount";
import VideoPlayer from "./views/VideoPlayer";
import ProjectTracking from "./views/ProjectTracking";
import ProjectWebhook from "./views/ProjectWebhook";
import Advertisers from "./views/Advertisers";
import Audiences from "./views/Audiences";
import AdCreation from "./views/Adcreation";
import Home from "./views/Home";
import Order from "./views/Order";
import styled from "styled-components";
import axios from "axios";
import { hasPath, isEmpty, keys, flip, of, includes, pipe, pick } from "ramda";
import { keyBy, hasIn, capitalize, size } from "lodash";
import { from, map as rxmap, of as rxof, concatMap, reduce as rxreduce } from "rxjs";
import { lofilter } from "../utilities/helpers";
import moment from "moment";

const AppView = styled.div`
    grid-template-columns: ${({ leftNavIsOpen }) => (leftNavIsOpen ? "200px auto" : "50px auto")};
`;

const AppNav = () => {
    return (
        <nav className="bg-white shadow">
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex justify-between h-16">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>

                            <svg
                                className="block h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>

                            <svg
                                className="hidden h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex-shrink-0 flex items-center">
                            <img
                                className="block lg:hidden h-8 w-auto"
                                src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                                alt="Workflow"
                            />
                            <img
                                className="hidden lg:block h-8 w-auto"
                                src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                                alt="Workflow"
                            />
                        </div>
                        <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                            <a href="#" className="border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                                {" "}
                                Dashboard{" "}
                            </a>
                            <a
                                href="#"
                                className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            >
                                {" "}
                                Team{" "}
                            </a>
                            <a
                                href="#"
                                className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            >
                                {" "}
                                Projects{" "}
                            </a>
                            <a
                                href="#"
                                className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            >
                                {" "}
                                Calendar{" "}
                            </a>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <button
                            type="button"
                            className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <span className="sr-only">View notifications</span>

                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                />
                            </svg>
                        </button>

                        <div className="ml-3 relative">
                            <div>
                                <button
                                    type="button"
                                    className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    id="user-menu-button"
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                >
                                    <span className="sr-only">Open user menu</span>
                                    <img
                                        className="h-8 w-8 rounded-full"
                                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        alt=""
                                    />
                                </button>
                            </div>

                            <div
                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="user-menu-button"
                                tabindex="-1"
                            >
                                <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0">
                                    Your Profile
                                </a>
                                <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-1">
                                    Settings
                                </a>
                                <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">
                                    Sign out
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sm:hidden" id="mobile-menu">
                <div className="pt-2 pb-4 space-y-1">
                    <a href="#" className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium">
                        Dashboard
                    </a>
                    <a
                        href="#"
                        className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                        Team
                    </a>
                    <a
                        href="#"
                        className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                        Projects
                    </a>
                    <a
                        href="#"
                        className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                        Calendar
                    </a>
                </div>
            </div>
        </nav>
    );
};

const App = () => {
    const [user, setUser] = useAtom(user_atom);
    const [app] = useAtom(app_atom);
    let navigate = useNavigate();

    const onGoToCheckoutClick = () => {
        let collection_ref = collection(db, "app_stats");
        let payload = { roas_user_id: user.uid, created_at: Number(moment().format("x")), destination: "/orders" };
        from(addDoc(collection_ref, payload))
            .pipe(
                rxmap(() => {
                    navigate(`/order/${user.uid}`);
                })
            )
            .subscribe();
    };

    const days_since_sign_up = moment
        .duration(moment().diff(moment(user.roas_created_at_timestamp, "x")))
        .asDays()
        .toFixed(0);

    let extened_trial_dates_start = Number(moment("2022-05-01", "YYYY-MM-DD").format("x"));
    let extened_trial_dates_end = Number(moment("2022-05-15", "YYYY-MM-DD").format("x"));
    let signup_date = Number(moment(user.roas_created_at_timestamp, "x"));

    const is_trial_user = user.roas_account_status == "trial" || size(user.roas_account_status) < 1;

    // return <AppNav />;
    return (
        <AppView id="app" leftNavIsOpen={app.left_nav_is_open} style={{ top: is_trial_user && !location.href.includes("order") ? "40px" : 0 }}>
            {/* <div
                id="_app"
                className="w-full h-full"
                leftNavIsOpen={app.left_nav_is_open}
                // style={{ top: is_trial_user && !location.href.includes("order") ? "40px" : 0 }}
            >
                <Notifications />

                <div
                    className="h-full"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "200px auto",
                        gridTemplateRows: "66px auto",
                    }}
                >
                    <div
                        style={{
                            gridColumn: "1/3",
                            gridRow: "1/2",
                        }}
                    >
                        <AppNav />
                    </div>
                    <div
                        style={{
                            gridColumn: "1/2",
                            gridRow: "2/3",
                        }}
                    >
                        <LeftNav />
                    </div>
                    <div
                        style={{
                            gridColumn: "2/3",
                            gridRow: "2/3",
                        }}
                    >
                        <Outlet />
                    </div>
                </div>
            </div> */}

            {is_trial_user && !location.href.includes("order") && (
                <div
                    className="top_notification_banner"
                    onClick={onGoToCheckoutClick}
                    style={{
                        position: "fixed",
                        top: 0,
                        right: 0,
                        left: 0,
                        height: "40px",
                        backgroundColor: "#eb072a",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        cursor: "pointer",
                    }}
                >
                    {signup_date < extened_trial_dates_end && (
                        <div className="text">
                            Hey {capitalize(user.roas_first_name)}​​, looks like your trial ends in{" "}
                            {moment.duration(moment("2022-05-31", "YYYY-MM-DD").diff(moment())).asDays().toFixed(0)} days. Upgrade now to keep
                            tracking and scaling your ads! <span style={{ fontWeight: "bold", textDecoration: "underline" }}>KEEP MY ACCOUNT</span>
                        </div>
                    )}

                    {days_since_sign_up < 7 && signup_date > extened_trial_dates_end && (
                        <div className="text">
                            Hey {capitalize(user.roas_first_name)}​​, looks like your trial ends in {7 - days_since_sign_up} days. Upgrade now to keep
                            tracking and scaling your ads! <span style={{ fontWeight: "bold", textDecoration: "underline" }}>KEEP MY ACCOUNT</span>
                        </div>
                    )}

                    {days_since_sign_up >= 7 && signup_date > extened_trial_dates_end && (
                        <div className="text">Hey {capitalize(user.roas_first_name)}​​, looks like your trial has expired.</div>
                    )}
                </div>
            )}

            {!location.href.includes("onboarding") && <LeftNav />}
            <Outlet />
        </AppView>
    );
};

const EmptyDiv = () => {
    return <div></div>;
};

const NoMatch = () => {
    let navigate = useNavigate();
    useEffect(() => navigate("/", { replace: true }), []);
    return <EmptyDiv />;
};

const request = async (payload) => {
    try {
        let res = await axios(payload);

        let data = (response) => {
            if (hasPath(["data", "data"], response)) {
                return response.data.data;
            } else {
                return response.data;
            }
        };

        return data(res);
    } catch (error) {
        console.log({ ...error.response.data.error });
        return;
    }
};

const SignOut = () => {
    const [user, setUser] = useAtom(user_atom);
    let navigate = useNavigate();

    auth.signOut().then(() => {
        setUser({ uid: null, email: "" });
        navigate("/login", { replace: true });
    });

    return <div></div>;
};

const ProtectedRoutes = () => {
    const [user] = useAtom(user_atom);
    const location = useLocation();

    let { pathname } = location;
    let default_protected_path = "/";
    let onboarding_path = "/onboarding";

    if (!user.uid && pathname !== default_protected_path) {
        return <Navigate to="/login" replace={true} state={{ from: location }} />;
    }

    if (!user.uid && pathname == default_protected_path) {
        return <Navigate to="/login" replace={true} />;
    }

    if (user.uid && !user.roas_has_finished_onboarding && pathname !== onboarding_path && user.roas_has_watched_initial_video) {
        return <Navigate to="/onboarding" replace={true} />;
    }

    if (user.uid && user.roas_has_finished_onboarding && hasPath(["state", "from"], location)) {
        return <Navigate to={location.state.from} replace={true} />;
    }

    return <Outlet />;
};

const DefaultPublicRoute = () => {
    const [user] = useAtom(user_atom);
    const location = useLocation();

    if (user.uid && hasPath(["state", "from"], location)) {
        return <Navigate to="/" state={{ from: location.state.from }} replace />;
    }

    if (user.uid && !hasPath(["state", "from"], location)) {
        return <Navigate to="/" replace />;
    }

    return <Outlet />;
};

const admins = ["aobouNIIRJMSjsDs2dIXAwEKmiY2", "8Hisz8QFZgTk0n0Eruqqpn1x3a12", "a2lcszdqbWh511Q7MBct2kN6nrv2"];

const Main = () => {
    const [user, setUser] = useAtom(user_atom);

    useEffect(() => {
        let { uid: user_id = undefined } = user;
        const is_admin = admins.includes(user_id);

        if (typeof window.Intercom == "function" && user_id && !is_admin) {
            if (!user.is_admin) {
                let intercom_defaults = {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "wfjbdiuj",
                    roas_user_id: user.uid,
                    user_id: user.uid,
                    roas_user_name: user.email,
                    email: user.email,
                };

                window.Intercom("update", {
                    hide_default_launcher: false,
                });

                window.Intercom("boot", intercom_defaults);
            }
        } else {
            window.Intercom("update", {
                hide_default_launcher: true,
            });
        }
    }, [window.Intercom, user.uid]);

    useEffect(() => {
        if (user && !user.is_admin) {
            window.loadIntercom();
        }
    }, [user]);

    useEffect(() => {
        onAuthStateChanged(auth, (logged_in_user) => {
            if (logged_in_user) {
                let { uid: user_id, email } = logged_in_user;

                const is_admin = admins.includes(user_id);

                from(getDoc(doc(db, "accounts", user_id)))
                    .pipe(rxmap((user) => user.data()))
                    .subscribe((account) => setUser({ ...user, ...logged_in_user, ...account, is_admin }));
            }
        });
    }, []);

    return (
        <div id="main">
            <Routes>
                <Route element={<ProtectedRoutes />}>
                    <Route path="onboarding" element={<OnboardingThree />} />
                    <Route path="/" element={<App />}>
                        {/* <Route index element={<VideoPlayer />} /> */}
                        {/* <Route path="/" element={<VideoPlayer />} /> */}
                        <Route index element={<AdsManager />} />
                        <Route path="/" element={<AdsManager />} />
                        <Route path="advertisers-search" element={<Advertisers />} />
                        <Route path="interests-search" element={<InterestsSearch />} />
                        <Route path="audiences" element={<Audiences />} />
                        <Route path="ads-launcher" element={<AdCreation />} />
                        <Route path="tracking-code" element={<TrackingCode />} />
                        <Route path="shopify-settings" element={<ShopifySsettings />} />
                        <Route path="ads-manager" element={<AdsManager />} />
                        <Route path="user-accounts" element={<UserAccounts />} />
                        <Route path="users" element={<Accounts />} />
                        <Route path="rules" element={<Rules />} />
                        <Route path="rule/:rule_id/logs" element={<Logs />} />
                        <Route path="rule/:rule_id" element={<Rule />} />
                        <Route path="ad-accounts" element={<AdAccounts />} />
                        <Route path="integrations" element={<Integrations />} />
                        <Route path="visualizer" element={<Visualizer />} />
                        <Route path="tracking" element={<TrackingCode />} />
                        <Route path="projects" element={<Projects />} />
                        <Route path="hype" element={<VideoPlayer />} />
                        <Route path="hv1" element={<VideoPlayer video_id={"t36ywipel7"} />} />
                        <Route path="hv2" element={<VideoPlayer video_id={"lgm1cncxoc"} />} />
                        <Route path="projects/:project_id/ad_account" element={<ProjectAdAccount />} />
                        <Route path="projects/:project_id/tracking" element={<ProjectTracking />} />
                        <Route path="projects/:project_id/webhook" element={<ProjectWebhook />} />
                        <Route path="order/:user_id" element={<Order />} />
                        <Route path="*" element={<NoMatch />} />
                    </Route>
                </Route>
                <Route element={<DefaultPublicRoute />}>
                    <Route path="login" element={<Login />} />
                </Route>
                <Route path="cookies" element={<Cookies />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="policies" element={<Conditions />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="signout" element={<SignOut />} />
                <Route path="home" element={<Home />} />
                <Route path="order" element={<Order />} />
                <Route path="*" element={<NoMatch />} />
            </Routes>
        </div>
    );
};

ReactDOM.render(
    <BrowserRouter>
        <Main />
    </BrowserRouter>,
    document.getElementById("root")
);
