import React, { useEffect, useState, useRef } from "react";
import { useAtom } from "jotai";
import { user_atom, accounts_atom, accounts_search_filter_atom, accounts_table_atom, users_accounts_atom } from "../state/auth";
import { map as lodashmap, isUndefined, size, truncate } from "lodash";
import { defaultTo, flip, pipe, reject, ifElse, always, identity, either, curry, pathOr, sum, path, equals, cond, not } from "ramda";
import { CheckOutlined, CloseCircleOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { from, map as rxmap } from "rxjs";
import { mod, all, get } from "shades";
import { pipeLog } from "../../utilities/helpers";
import moment from "moment";
import styled from "styled-components";
import AdsManager from "./AdsManager";
import ScreenModal from "../components/ScreenModal";
import { app_atom } from "../state/app";

const UserTableCell = styled.td`
    background-color: ${(props) => props.table_row_color} !important;
    // background-color: black !important;
`;

const lomap = flip(lodashmap);
let dollarUSLocale = Intl.NumberFormat("en-US");

const TableRow = ({ data }) => {
    const [app, setApp] = useAtom(app_atom);
    const [user, setUser] = useAtom(user_atom);
    const [tableRowColor, setTableRowColor] = useState("#EE4035");

    const onSelectUser = () => {
        console.log("onSelectUser");
        setUser({ ...user, uid: raeither(data.roas_user_id, data.user_id), is_admin: true });
    };

    const numOrZero = (value) => {
        return value == NaN || value == undefined || value == "NaN" ? 0 : value;
    };

    let maybe = (value) => (value == undefined ? false : value);
    let raeither = curry((v1, v2) => (maybe(v1) == false ? maybe(v2) : v1));

    useEffect(() => {
        let roas_has_facebook_connected = data.roas_has_facebook_connected ? 1 : 0;
        let roas_has_ad_accounts = data.roas_has_ad_accounts ? 1 : 0;
        let roas_has_selected_facebook_ad_account = data.roas_has_selected_facebook_ad_account ? 1 : 0;
        let roas_shopping_cart = size(data.roas_shopping_cart) < 1 ? 0 : 1;
        let payment_processor = size(data.roas_payment_processor) < 1 ? 0 : 1;
        let roas_dna_script_installed = data.roas_dna_script_installed ? 1 : 0;
        let roas_shopping_cart_webhook_installed = data.roas_shopping_cart_webhook_installed ? 1 : 0;

        let total = sum([
            roas_has_facebook_connected,
            roas_has_ad_accounts,
            roas_has_selected_facebook_ad_account,
            roas_shopping_cart,
            payment_processor,
            roas_dna_script_installed,
            roas_shopping_cart_webhook_installed,
        ]);

        if (total == 0) {
            setTableRowColor("#EE4035");
        }

        if (total > 0 && total < 3) {
            setTableRowColor("#F37836");
        }

        if (total > 3 && total < 7) {
            setTableRowColor("#FDF497");
        }

        if (total == 7) {
            setTableRowColor("#7CC043");
        }
    }, [data]);

    const human_readable_time_elapased = cond([
        [equals(0), always("")],
        [
            pipe(equals(0), not),
            pipe(
                (value) => moment.duration(moment(moment().format("x"), "x").diff(moment(value, "x"))).asMinutes(),
                (minutes) => (minutes > 60 ? `${(minutes / 60).toFixed()} hours ago` : `${minutes.toFixed()} minutes ago`)
            ),
        ],
    ]);

    const onOpenModal = (modal_id) => {
        setUser({ ...user, uid: raeither(data.roas_user_id, data.user_id), is_admin: true });
        setApp({ ...app, modal_is_open: true, modal_id });
    };

    return (
        <tr className="table_row">
            <UserTableCell
                className="row_cell"
                style={{ textAlign: "left" }}
                onClick={() => onOpenModal("ads_manager")}
                table_row_color={tableRowColor}
            >
                {data.user_id || data.roas_user_id}
            </UserTableCell>
            <td style={{ textAlign: "left" }}>{data.roas_user_name || data.roas_email}</td>
            <td className="row_cell" style={{ textAlign: "left" }}>
                {data.roas_user_name}
            </td>
            <td className="row_cell" style={{ textAlign: "left" }}>
                {truncate(data.roas_password, { length: 30 })}
            </td>
            <td className="row_cell" style={{ textAlign: "left" }}>
                {data.roas_phone_number}
            </td>
            <td className="row_cell" style={{ textAlign: "left" }}>
                ${dollarUSLocale.format(numOrZero(data.roas_ad_amount_spent))}
            </td>
            <td className="row_cell">{data.roas_has_facebook_connected ? <CheckOutlined /> : <CloseCircleOutlined />}</td>
            <td className="row_cell">{data.roas_has_ad_accounts ? <CheckOutlined /> : <CloseCircleOutlined />}</td>
            <td className="row_cell">{data.roas_has_selected_facebook_ad_account ? <CheckOutlined /> : <CloseCircleOutlined />}</td>
            <td className="row_cell">{data.roas_has_selected_shopping_cart ? data.roas_shopping_cart : <CloseCircleOutlined />}</td>
            <td className="row_cell">{data.roas_payment_processor ? data.roas_payment_processor : <CloseCircleOutlined />}</td>
            <td className="row_cell">{data.roas_dna_script_installed ? <CheckOutlined /> : <CloseCircleOutlined />}</td>
            <td className="row_cell">{data.roas_shopping_cart_webhook_installed ? <CheckOutlined /> : <CloseCircleOutlined />}</td>
            <td className="row_cell">{pipe(pathOr(0, ["stats", "spent"]), (value) => Number(value).toFixed(2))(data)}</td>
            <td className="row_cell">{pipe(pathOr(0, ["stats", "made"]), (value) => Number(value).toFixed(2))(data)}</td>
            <td className="row_cell">{pathOr(0, ["stats", "num_of_customers"], data)}</td>
            <td className="row_cell">{pipe(pathOr(0, ["stats", "stats_updated_at"]), human_readable_time_elapased)(data)}</td>
            <td className="row_cell">{pathOr(0, ["stats", "num_of_campaigns_active"], data)}</td>
            <td className="row_cell">{pipe(pathOr(0, ["stats", "campaing_insights_updated_at"]), human_readable_time_elapased)(data)}</td>
            <td className="row_cell">{pathOr(0, ["stats", "num_of_adsets_active"], data)}</td>
            <td className="row_cell">{pipe(pathOr(0, ["stats", "adset_insights_updated_at"]), human_readable_time_elapased)(data)}</td>
            <td className="row_cell">{pathOr(0, ["stats", "num_of_ads_active"], data)}</td>
            <td className="row_cell">{pipe(pathOr(0, ["stats", "ad_insights_updated_at"]), human_readable_time_elapased)(data)}</td>
            <td className="row_cell">{pipe(pathOr(0, ["stats", "report_updated_at"]), human_readable_time_elapased)(data)}</td>
            <td className="row_cell">{pipe(pathOr(0, ["stats", "num_of_rules_checked"]))(data)}</td>
            <td className="row_cell">{pipe(pathOr(0, ["stats", "rules_checked_at"]), human_readable_time_elapased)(data)}</td>
        </tr>
    );
};

const UserAccounts = () => {
    const [app, setApp] = useAtom(app_atom);
    const [searchInput, setSearchInput] = useAtom(accounts_search_filter_atom);
    const [, setAccounts] = useAtom(users_accounts_atom);
    const [filteredAccounts] = useAtom(accounts_table_atom);

    const onInputChange = ({ target: { value } }) => {
        setSearchInput(value);
    };

    useEffect(() => {
        let accounts_ref = collection(db, "accounts");

        from(getDocs(accounts_ref))
            .pipe(rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())))
            .subscribe((dbaccounts) => {
                setAccounts(dbaccounts);
            });
    }, []);

    const onCloseModal = () => {
        setApp({ ...app, modal_is_open: false, modal_id: "" });
    };

    return (
        <div className="user_accounts_view">
            <ScreenModal id="ads_manager">
                <div className="close_modal_container" onClick={onCloseModal}>
                    <CloseCircleOutlined />
                </div>
                <AdsManager />
            </ScreenModal>

            <div className="user_accounts_table_container">
                <div className="user_search_bar_container">
                    <div className="icon">
                        <SearchOutlined />
                    </div>
                    <div className="search_input">
                        <input type="text" placeholder="Search by email address OR User ID" value={searchInput} onChange={onInputChange} />
                    </div>
                </div>

                <div className="user_accounts_list_container">
                    <div className="table_container">
                        <table>
                            <thead>
                                <tr>
                                    <th className="row_cell">User ID</th>
                                    <th className="row_cell">Email</th>
                                    <th className="row_cell">User Name</th>
                                    <th className="row_cell">Password</th>
                                    <th className="row_cell">Phone</th>
                                    <th className="row_cell">Total Spent</th>
                                    <th className="row_cell">Facebook Connected</th>
                                    <th className="row_cell">Ad Accounts</th>
                                    <th className="row_cell">Ad Account Selected</th>
                                    <th className="row_cell">Shoping Cart</th>
                                    <th className="row_cell">Payment Processor</th>
                                    <th className="row_cell">Dna Script</th>
                                    <th className="row_cell">Webhook</th>
                                    <th className="row_cell">Spent</th>
                                    <th className="row_cell">Made</th>
                                    <th className="row_cell">Customeres</th>
                                    <th className="row_cell">Stats Update At</th>
                                    <th className="row_cell">Campaigns Active</th>
                                    <th className="row_cell">Campaigns Updated At</th>
                                    <th className="row_cell">Adsets Active</th>
                                    <th className="row_cell">Adsets Updated At</th>
                                    <th className="row_cell">Ads Active</th>
                                    <th className="row_cell">Ads Updated At</th>
                                    <th className="row_cell">Report Update At</th>
                                    <th className="row_cell">Automations Ran</th>
                                    <th className="row_cell">Automations Checked At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lomap(
                                    (value, key) => (
                                        <TableRow key={key} data={value} />
                                    ),
                                    filteredAccounts
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserAccounts;
