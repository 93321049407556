import React, { useState, useEffect } from "react";
import { flip, lensPath, set, includes, pipe, toLower, map, filter, values, flatten, view } from "ramda";
import { includes, map as lodashmap, isEmpty } from "lodash";
import { Input } from "antd";
import { useAtom } from "jotai";
import { rule_atom } from "../state/rule";
import { materialized_triggers_atom, expressions_state, filtered_triggers_atom, triggers_default_atom, triggers_default } from "../state/expression";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";

const lomap = flip(lodashmap);

let triggers_category_map = {
    general: "General",
    mobile_app_standard_events: "Mobile App Standard Events",
    most_common: "Most Common",
    standard_events_total: "Standard Events Total",
    website_standard_events: "Website Standard Events",
};

const TriggerDropdown = ({ expression }) => {
    const [searchValue, setSearchValue] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [rule, setRule] = useAtom(rule_atom);
    const [defaultTriggers] = useAtom(triggers_default_atom);
    const [, setTriggers] = useAtom(filtered_triggers_atom);
    const [triggersDropdown] = useAtom(materialized_triggers_atom);
    const [expressions, setExpressions] = useAtom(expressions_state);

    useEffect(() => {
        setTriggers(triggers_default);
    }, []);

    const onSelectMetric = (metric) => {
        let { value, text } = metric;
        let data = {
            value,
            text,
        };
        setRule(set(lensPath([...expression.path, "metric"]), data, rule));
        setExpressions({ ...expressions, dropdown_is_open: false, expression_id: null, dropdown_type: null });
    };

    const onSearch = ({ target: { value } }) => {
        let res = pipe(
            map(
                filter((metric) => {
                    return includes(toLower(metric.text), toLower(value));
                })
            ),
            values,
            flatten
        );

        if (!isEmpty(value)) {
            setTriggers(res(defaultTriggers));
        } else {
            setTriggers(triggers_default);
        }

        setSearchValue(value);
    };

    const onSlectCategory = (key) => {
        if (key == selectedCategory) {
            setSelectedCategory("");
        } else {
            setSelectedCategory(key);
        }
    };

    return (
        <div className="trigger_container">
            <div className="header_container">
                <div className="title_text">Metric Level:</div>
                <div className="scopes_container">
                    <div className="metric_scope">Ad</div>
                    <div className="metric_scope">Ad Set</div>
                    <div className="metric_scope">Campaign</div>
                    <div className="metric_scope">Account</div>
                </div>
            </div>

            <div className="search_bar">
                <Input placeholder="Search" size="large" onChange={onSearch} />
            </div>

            <div className="categories">
                <Accordion className="categories_dropdown_container" allowZeroExpanded={true}>
                    {lomap(
                        (values, key, idx) => (
                            <AccordionItem key={key} dangerouslySetExpanded={!isEmpty(searchValue) || selectedCategory == key}>
                                <AccordionItemHeading className="category_container" onClick={() => onSlectCategory(key)}>
                                    <AccordionItemButton>{triggers_category_map[key]}</AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    {lomap(
                                        (value, idx) => (
                                            <div className="metric_field" key={idx} onClick={() => onSelectMetric(value)}>
                                                {value.text}
                                            </div>
                                        ),
                                        values
                                    )}
                                </AccordionItemPanel>
                            </AccordionItem>
                        ),
                        triggersDropdown
                    )}
                </Accordion>
            </div>
        </div>
    );
};

export default TriggerDropdown;
