import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, auth, createUserWithEmailAndPassword } from "../firebase";
import { setDoc, doc } from "firebase/firestore";
import { useAtom } from "jotai";
import { user_atom } from "../state/auth";
import moment from "moment";
import { from, map as rxmap } from "rxjs";
import Footer from "./Footer";
import { pipe, ifElse, isEmpty, map, split, fromPairs, identity, defaultTo, curry, zipObj, keys, values } from "ramda";
const removeFirstCharacterFromString = (string) => string.substring(1);
const urlToParams = split("&");
const paramsToPairs = map(split("="));
const urlToSearchParams = pipe(removeFirstCharacterFromString, urlToParams, paramsToPairs, fromPairs);
const toSearchParams = ifElse(isEmpty, identity, urlToSearchParams);
const mapKeys = curry((fn, obj) => zipObj(map(fn, keys(obj)), values(obj)));

const SignUp = () => {
    let navigate = useNavigate();
    const [user, setUser] = useAtom(user_atom);

    const onUpdateEmail = (event) => setUser({ ...user, email: event.target.value });
    const onUpdatePassword = (event) => setUser({ ...user, password: event.target.value });
    const onUpdateFirstName = (event) => setUser({ ...user, first_name: event.target.value });
    const onUpdateLastName = (event) => setUser({ ...user, last_name: event.target.value });
    const onUpdatePhoneNumber = (event) => setUser({ ...user, phone_number: event.target.value });

    const days_until_next_payment = (start, end) => {
        return end.diff(start, "days");
    };

    const save_user_account = (db_user) => {
        let { email, password, first_name = "", last_name = "", phone_number = 0 } = user;
        let { uid: user_id } = db_user;

        let [, href_params] = pipe(defaultTo(""), split("?"))(location.href);

        let params = {};
        if (href_params) {
            params = { ...toSearchParams(`?${href_params}`), ...params };
        }

        if (href_params) {
            params = { ...toSearchParams(location.search), ...params };
        }

        let url_params = mapKeys((value) => `roas_${value}`, params);

        let user_payload = {
            roas_user_id: user_id,
            roas_email: email,
            roas_user_name: email,
            roas_password: password,
            roas_first_name: first_name,
            roas_last_name: last_name,
            roas_phone_number: phone_number,
            roas_created_at_timestamp: Number(moment().format("x")),
            roas_created_at_date: moment().format("YYYY-MM-DD"),
            roas_num_of_continuous_days_active: 0,
            roas_account_status: "trial",
            roas_account_type: "user",
            roas_next_payment_date: moment().add(7, "days").format("YYYY-MM-DD"),
            roas_days_until_next_payment: days_until_next_payment(moment(), moment().add(7, "days")),
            roas_has_facebook_connected: false,
            roas_has_selected_facebook_ad_account: false,
            roas_has_facebook_ad_account_data: false,
            roas_has_selected_shopping_cart: false,
            roas_has_shopping_cart_connected: false,
            roas_has_selected_payment_processor: false,
            roas_has_payment_processor_connected: false,
            roas_has_dna_snippet_connected: false,
            roas_has_ad_id_paramater_on_ads: false,
            ...url_params,
        };

        return from(setDoc(doc(db, "accounts", user_id), user_payload)).pipe(rxmap(() => user_payload));
    };

    const set_intercom = (data) => {
        let intercom_defaults = {
            api_base: "https://api-iam.intercom.io",
            app_id: "wfjbdiuj",
        };

        let intercom_payload = {
            ...intercom_defaults,
            ...data,
            email: data.roas_email,
            user_id: data.roas_user_id,
        };

        window.Intercom("update", intercom_payload);
    };

    const onSignUp = () => {
        let { email, password } = user;

        from(createUserWithEmailAndPassword(auth, email, password)).subscribe((data) => {
            let { user: db_user = undefined } = data;
            if (db_user) {
                from(save_user_account(db_user)).subscribe((user_payload) => {
                    set_intercom(user_payload);
                    setUser({ ...user, ...user_payload, uid: db_user.uid });
                    navigate("/", { replace: true });
                });
            }
        });
    };

    return (
        <div className="signup_view">
            <div className="auth_container">
                <div className="headline">TRY IT FREE</div>

                <div className="auth_form_container">
                    <div className="header">
                        <div className="title">Create Your Account - No Credit Card Needed</div>
                    </div>

                    <div className="auth_input_container">
                        <div className="label">First Name</div>
                        <div className="value">
                            <input type="text" onChange={onUpdateFirstName} />
                        </div>
                    </div>

                    <div className="auth_input_container">
                        <div className="label">Last Name</div>
                        <div className="value">
                            <input type="text" onChange={onUpdateLastName} />
                        </div>
                    </div>

                    <div className="auth_input_container">
                        <div className="label">Email</div>
                        <div className="value">
                            <input type="text" onChange={onUpdateEmail} />
                        </div>
                    </div>

                    <div className="auth_input_container">
                        <div className="label">Create a password</div>
                        <div className="value">
                            <input type="password" onChange={onUpdatePassword} />
                        </div>
                    </div>

                    <div className="auth_input_container">
                        <div className="label">Phone Number (So we can call you if you ask for us help.)</div>
                        <div className="value">
                            <input type="text" onChange={onUpdatePhoneNumber} />
                        </div>
                    </div>

                    <div className="cta_container" onClick={onSignUp}>
                        <div className="login-button">Create account</div>
                    </div>

                    <div className="footer_actions">
                        <div className="action_link">
                            <Link className="text" to="/login">
                                <div className="regular">Have an account?</div>
                                <div className="">Log In</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SignUp;
