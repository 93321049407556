import React from "react";
import { map, flip, pluck, values, product } from "ramda";
import { map as lodashmap, size } from "lodash";

import styled from "styled-components";

import ExpressionUneditable from "./ExpressionUneditable";

const lomap = flip(lodashmap);

const ExpressionsContainer = styled.div`
    grid-template-columns: ${({ numOfTotalExpressions }) => (numOfTotalExpressions > 1 ? "30px auto" : "auto")};
`;

const ExpressionsGrid = styled.div`
    grid-column: ${({ numOfTotalExpressions }) => (numOfTotalExpressions > 1 ? "2/2" : "1/1")};
`;

const ExpressionsUneditable = ({ condition, rule }) => {
    let numOfTotalExpressions = product(values(map(size, pluck("expressions", rule.conditions))));
    let numOfExpressions = size(condition.expressions);

    return (
        <ExpressionsContainer className="expressions_container" numOfTotalExpressions={numOfTotalExpressions}>
            {numOfExpressions > 1 && (
                <div className="expressions_operand_bar" style={{ height: `calc(100% - 15px)` }}>
                    <div className="background">
                        <div className="text">AND</div>
                    </div>
                </div>
            )}

            <ExpressionsGrid className="expressions_grid" numOfTotalExpressions={numOfTotalExpressions}>
                {lomap(
                    (expression, key) => (
                        <ExpressionUneditable key={key} expression={expression} />
                    ),
                    condition.expressions
                )}
            </ExpressionsGrid>
        </ExpressionsContainer>
    );
};

export default ExpressionsUneditable;
