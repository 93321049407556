import React, { useEffect, useState, Fragment } from "react";
import { useAtom } from "jotai";
import { app_atom } from "../state/app";
import { user_atom, user_accounts_atom, facebook_accounts_atom } from "../state/auth";
import { slider_atom, onboarding_atom, confirmation_modal_atom } from "../state/onboarding";
import { CaretDownOutlined, CloseCircleOutlined, LoadingOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Typography, Divider, Image, List, Radio, Checkbox, Button } from "antd";
import { map, flip, has, pipe, pick, includes, head, isEmpty, keys, sum, identity, defaultTo } from "ramda";
import { map as lodashmap, keyBy, size, toLower } from "lodash";
import { db } from "../firebase";
import { getDocs, query, where, collection, getDoc, doc, onSnapshot, collectionGroup, setDoc, arrayUnion } from "firebase/firestore";
import { from, map as rxmap, zip, of as rxof, filter as rxfilter, tap, iif, defaultIfEmpty, concatMap } from "rxjs";
import { mod, matching, get, all } from "shades";
import Highlight from "react-highlight";
import copy from "copy-to-clipboard";
const { Title, Paragraph, Text, Link } = Typography;
import ReactFullpage from "@fullpage/react-fullpage";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Result } from "postcss";
const FbUrlParamaterImgOne = require("../assets/fb_url_paramater_one.png");
const onboarding_1_fb_2 = require("../assets/onboarding_1_fb_2.png");
const onboarding_1_fb_3 = require("../assets/onboarding_1_fb_3.png");
const onboarding_1_fb_4 = require("../assets/onboarding_1_fb_4.png");
const onboarding_1_fb_5 = require("../assets/onboarding_1_fb_5.png");
const onboarding_3_cf_1 = require("../assets/onboarding_3_cf_1.png");
const onboarding_4_shopify_1 = require("../assets/onboarding_4_shopify_1.png");
const onboarding_4_shopify_2 = require("../assets/onboarding_4_shopify_2.png");
const onboarding_4_shopify_3 = require("../assets/onboarding_4_shopify_3.png");
const onboarding_4_shopify_4 = require("../assets/onboarding_4_shopify_4.png");
const onboarding_5_gtm_1 = require("../assets/onboarding_5_gtm_1.png");
const onboarding_5_gtm_2 = require("../assets/onboarding_5_gtm_2.png");
const onboarding_5_gtm_3 = require("../assets/onboarding_5_gtm_3.png");
const onboarding_5_gtm_4 = require("../assets/onboarding_5_gtm_4.png");
const onboarding_5_gtm_5 = require("../assets/onboarding_5_gtm_5.png");
const onboarding_5_gtm_6 = require("../assets/onboarding_5_gtm_6.png");
const onboarding_5_gtm_7 = require("../assets/onboarding_5_gtm_7.png");
const onboarding_5_gtm_8 = require("../assets/onboarding_5_gtm_8.png");
const onboarding_6_elementor_1 = require("../assets/onboarding_6_elementor_1.png");
const onboarding_6_elementor_2 = require("../assets/onboarding_6_elementor_2.png");
const onboarding_6_elementor_3 = require("../assets/onboarding_6_elementor_3.png");
const onboarding_6_elementor_4 = require("../assets/onboarding_6_elementor_4.png");
const onboarding_7_thrive_thems_1 = require("../assets/onboarding_7_thrive_thems_1.png");
const onboarding_7_thrive_thems_2 = require("../assets/onboarding_7_thrive_thems_2.png");
const onboarding_7_thrive_thems_3 = require("../assets/onboarding_7_thrive_thems_3.png");
const onboarding_7_thrive_thems_4 = require("../assets/onboarding_7_thrive_thems_4.png");
const onboarding_7_thrive_thems_5 = require("../assets/onboarding_7_thrive_thems_5.png");
const onboarding_8_kartra_1 = require("../assets/onboarding_8_kartra_1.png");
const onboarding_8_kartra_2 = require("../assets/onboarding_8_kartra_2.png");
const onboarding_8_kartra_3 = require("../assets/onboarding_8_kartra_3.png");
const onboarding_8_kartra_4 = require("../assets/onboarding_8_kartra_4.png");
const onboarding_8_kartra_5 = require("../assets/onboarding_8_kartra_5.png");
const onboarding_9_keap_1 = require("../assets/onboarding_9_keap_1.png");
const onboarding_9_keap_2 = require("../assets/onboarding_9_keap_2.png");
const onboarding_9_keap_3 = require("../assets/onboarding_9_keap_3.png");
const onboarding_9_keap_4 = require("../assets/onboarding_9_keap_4.png");
const onboarding_9_keap_5 = require("../assets/onboarding_9_keap_5.png");
const onboarding_9_keap_6 = require("../assets/onboarding_9_keap_6.png");
const onboarding_10_shopify_1 = require("../assets/onboarding_10_shopify_1.png");
const onboarding_10_shopify_2 = require("../assets/onboarding_10_shopify_2.png");
const onboarding_10_shopify_3 = require("../assets/onboarding_10_shopify_3.png");
const onboarding_10_shopify_4 = require("../assets/onboarding_10_shopify_4.png");
const onboarding_10_shopify_5 = require("../assets/onboarding_10_shopify_5.png");
const onboarding_10_shopify_6 = require("../assets/onboarding_10_shopify_6.png");
const onboarding_11_clickfunnels_1 = require("../assets/onboarding_11_clickfunnels_1.png");
const onboarding_11_clickfunnels_2 = require("../assets/onboarding_11_clickfunnels_2.png");
const onboarding_11_clickfunnels_3 = require("../assets/onboarding_11_clickfunnels_3.png");
const onboarding_11_clickfunnels_4 = require("../assets/onboarding_11_clickfunnels_4.png");
const onboarding_12_woocommerce_1 = require("../assets/onboarding_12_woocommerce_1.png");
const onboarding_12_woocommerce_2 = require("../assets/onboarding_12_woocommerce_2.png");
const onboarding_12_woocommerce_3 = require("../assets/onboarding_12_woocommerce_3.png");
const onboarding_12_woocommerce_4 = require("../assets/onboarding_12_woocommerce_4.png");
const onboarding_12_woocommerce_5 = require("../assets/onboarding_12_woocommerce_5.png");
const onboarding_12_woocommerce_6 = require("../assets/onboarding_12_woocommerce_6.png");
const onboarding_12_woocommerce_7 = require("../assets/onboarding_12_woocommerce_7.png");
const onboarding_12_woocommerce_8 = require("../assets/onboarding_12_woocommerce_8.png");
const onboarding_13_kartra_1 = require("../assets/onboarding_13_kartra_1.png");
const onboarding_13_kartra_2 = require("../assets/onboarding_13_kartra_2.png");
const onboarding_13_kartra_3 = require("../assets/onboarding_13_kartra_3.png");
const onboarding_13_kartra_4 = require("../assets/onboarding_13_kartra_4.png");
const onboarding_13_kartra_5 = require("../assets/onboarding_13_kartra_5.png");
const StripeLogo = require("../assets/stripe_logo.png");
const ShopifyLogo = require("../assets/shopify_logo.png");
const PaypalLogo = require("../assets/paypal_logo.png");
const BraintreeLogo = require("../assets/braintree_logo.png");
const EasyPayDirectLogo = require("../assets/easy_pay_direct_logo.png");
const ClickfunnelsLogo = require("../assets/clickfunnels_logo.png");
const KajabiLogo = require("../assets/kajabi_logo.svg");
const WoocommerceLogo = require("../assets/woo_commerce_logo.png");
const ThriveCartLogo = require("../assets/thrive_cart_logo.png");
const KartraLogo = require("../assets/kartra_logo.png");
const KeapLogo = require("../assets/keap_logo.png");
const SamcartLogo = require("../assets/samcart_logo.png");
const ThriveThemesLogo = require("../assets/thrive_theme.webp");
const ElementorLogo = require("../assets/elementor_logo.webp");
const GoogleTagManagerLogo = require("../assets/google_tag_manager_logo.svg");
const { lomap, domPipeLog: pipeLog } = require("../../utilities/helpers");
import moment from "moment";

const lokeyby = flip(keyBy);

const common_paragraph_styles = {
    fontSize: 14,
    marginBottom: 10,
    marginTop: 10,
};

const set_project = (user_id, payload) => {
    let project_ref = doc(db, "projects", user_id);
    return from(setDoc(project_ref, { roas_user_id: user_id, ...payload }, { merge: true })).pipe(rxmap(() => payload));
};

const set_user_account = (user_id, payload) => {
    let account_ref = doc(db, "accounts", user_id);
    return from(setDoc(account_ref, payload, { merge: true })).pipe(rxmap(() => payload));
};

let tracking_code = (user_id) => `
    <script>
        (function roas(params) {
            var user_id  = params.user_id;
            localStorage.setItem("roas_user_id", user_id);
            fetch("https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js").then(function(res){return res.json()}).then(function(response){
                var downloadTokens  = response.downloadTokens;
                var head = document.head;
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js?alt=media&token=" + downloadTokens
                head.appendChild(script);
            })
        })({ user_id: "${user_id}" });
    </script>
`;

let kartra_tracking_code = (user_id) => `    
    (function roas(params) {
        var user_id  = params.user_id;
        localStorage.setItem("roas_user_id", user_id);
        fetch("https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js").then(function(res){return res.json()}).then(function(response){
            var downloadTokens  = response.downloadTokens;
            var head = document.head;
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://firebasestorage.googleapis.com/v0/b/roas-e44b8.appspot.com/o/roas.js?alt=media&token=" + downloadTokens
            head.appendChild(script);
        })
    })({ user_id: "${user_id}" });
`;

const TrackingCodeSnippetSection = () => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);

    const onCopySnippetToClipboard = (type) => {
        copy(tracking_code(user.uid));
    };

    return (
        <div className="tracking_code_container">
            <div className="title">ROAS Tracking Script</div>
            <div className="tracking_code">
                {onboarding.roas_tracking_host !== "kartra" && <Highlight language={"javascript"}>{tracking_code(user.uid)}</Highlight>}
                {onboarding.roas_tracking_host == "kartra" && <Highlight language={"javascript"}>{kartra_tracking_code(user.uid)}</Highlight>}
            </div>
            <div className="copy_code_snippet_to_clipboard_container" onClick={() => onCopySnippetToClipboard("tracking_code")}>
                Copy To Clipboard
            </div>
        </div>
    );
};

const ShoppingCartWebhook = ({ roas_shopping_cart }) => {
    const [user] = useAtom(user_atom);
    const [userAccount, setUserAccount] = useAtom(user_accounts_atom);

    const onCopySnippetToClipboard = () => {
        if (roas_shopping_cart == "") return;
        let url = `https://us-central1-roas-e44b8.cloudfunctions.net/app/${roas_shopping_cart}/${user.uid}`;
        copy(url);
    };

    if (roas_shopping_cart == "clickfunnels") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/clickfunnels/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "shopify") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/shopify/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    // if (userAccount.roas_shopping_cart == "kajabi") {
    //     return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/kajabi/${user.uid}`}</div>;
    // }

    if (roas_shopping_cart == "woocommerce") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/woocommerce/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "thrivecart") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/thrivecart/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "kartra") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/kartra/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "keap") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/keap/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "samcart") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/samcart/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    return <div className="webhooks_url_container">Select a shopping cart first to get your webhook url</div>;
};

const VideoCardElm = ({ title = "", modal_id = "", thumbnail_id = "", dna_script = false, webhook = false } = {}) => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);

    const onViewVideo = () => {
        console.log("onViewVideo");
        console.log("modal_id", modal_id);
        setApp({ ...app, modal_is_open: true, modal_id });
    };

    return (
        <div className="video_card_container" onClick={onViewVideo}>
            {/* <div className="title">{title}</div> */}
            <div className="video_container w-full">
                <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                    <iframe
                        src={`https://www.loom.com/embed/${thumbnail_id}`}
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen={true}
                        style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    ></iframe>
                </div>
            </div>

            {dna_script && <TrackingCodeSnippetSection />}

            {webhook && (
                <Paragraph className="w-full text-[14px]">
                    <ShoppingCartWebhook roas_shopping_cart={webhook} />
                </Paragraph>
            )}
        </div>
    );
};

const SlideTabs = ({ fullpageApi }) => {
    const [user] = useAtom(user_atom);
    const [slider, setSlider] = useAtom(slider_atom);
    const [confirmationModal, setConfirmationModal] = useAtom(confirmation_modal_atom);
    let navigate = useNavigate();

    const moveTo = (slide = "1") => {
        _wq.push({
            id: "_all",
            onReady: function (video) {
                video.pause();
                video.time(0);
            },
        });
        setSlider({ ...slider, slide });
    };

    const onModalClick = ({ slide, text, checkbox_description }) => {
        setConfirmationModal({ ...confirmationModal, is_open: true, slide, text, checkbox_description });
    };

    let slide_num = pipe(parseInt, defaultTo(1))(slider.slide);

    return (
        <div className="onboarding_tabs">
            <nav aria-label="Progress">
                <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8 w-[900px]">
                    <li className="md:flex-1 flex flex-col justify-center">
                        <a
                            onClick={() => moveTo("1")}
                            className={`${
                                slide_num >= 1 ? "border-blue-600 hover:border-blue-800" : "border-gray-200 hover:border-gray-300"
                            } group pl-4 py-2 flex flex-col border-solid border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4`}
                        >
                            <span
                                className={`${
                                    slide_num >= 1 ? "text-blue-600 group-hover:text-blue-800" : " text-gray-500 group-hover:text-gray-700"
                                } text-xs font-semibold tracking-wide uppercase`}
                            >
                                Step 1
                            </span>
                            <span className="text-xs font-medium">Connect Facebook </span>
                        </a>
                    </li>

                    <li className="md:flex-1 flex flex-col justify-center">
                        <a
                            onClick={() =>
                                onModalClick({
                                    slide: "2",
                                    text: "Click the checkbox to confirm that you have completed Step 1",
                                    checkbox_description: `I've completed Step 1`,
                                })
                            }
                            className={`${
                                slide_num >= 2 ? "border-blue-600 hover:border-blue-800" : "border-gray-200 hover:border-gray-300"
                            } group pl-4 py-2 flex flex-col border-solid border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4`}
                        >
                            <span
                                className={`${
                                    slide_num >= 2 ? "text-blue-600 group-hover:text-blue-800" : " text-gray-500 group-hover:text-gray-700"
                                } text-xs font-semibold tracking-wide uppercase`}
                            >
                                Step 2
                            </span>
                            <span className="text-xs font-medium">Select Ad Account</span>
                        </a>
                    </li>

                    <li className="md:flex-1 flex flex-col justify-center">
                        <a
                            onClick={() =>
                                onModalClick({
                                    slide: "3",
                                    text: `Click the checkbox to confirm that you have completed Step 2`,
                                    checkbox_description: `I've completed Step 2`,
                                })
                            }
                            className={`${
                                slide_num >= 3 ? "border-blue-600 hover:border-blue-800" : "border-gray-200 hover:border-gray-300"
                            } group pl-4 py-2 flex flex-col border-solid border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4`}
                            aria-current="step"
                        >
                            <span
                                className={`${
                                    slide_num >= 3 ? "text-blue-600 group-hover:text-blue-800" : " text-gray-500 group-hover:text-gray-700"
                                } text-xs font-semibold tracking-wide uppercase`}
                            >
                                Step 3
                            </span>
                            <span className="text-xs font-medium">Add Roas Script</span>
                        </a>
                    </li>

                    <li className="md:flex-1 flex flex-col justify-center">
                        <a
                            onClick={() =>
                                onModalClick({
                                    slide: "5",
                                    text: `Click the checkbox to confirm that you have completed Step 3 and have installed your tracking script`,
                                    checkbox_description: `I've completed Step 3 and installed the tracking script`,
                                })
                            }
                            className={`${
                                slide_num >= 5 ? "border-blue-600 hover:border-blue-800" : "border-gray-200 hover:border-gray-300"
                            } group pl-4 py-2 flex flex-col border-solid border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4`}
                        >
                            <span
                                className={`${
                                    slide_num >= 5 ? "text-blue-600 group-hover:text-blue-800" : " text-gray-500 group-hover:text-gray-700"
                                } text-xs font-semibold tracking-wide uppercase`}
                            >
                                Step 4
                            </span>
                            <span className="text-xs font-medium">Connect Cart</span>
                        </a>
                    </li>

                    <li className="md:flex-1 flex flex-col justify-center">
                        <a
                            onClick={() =>
                                onModalClick({
                                    slide: "7",
                                    text: `Click the checkbox to confirm that you have completed Step 4 and have installed your webhook`,
                                    checkbox_description: `I've completed Step 4 and installed the webhook`,
                                })
                            }
                            className={`${
                                slide_num >= 7 ? "border-blue-600 hover:border-blue-800" : "border-gray-200 hover:border-gray-300"
                            } group pl-4 py-2 flex flex-col border-solid border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4`}
                        >
                            <span
                                className={`${
                                    slide_num >= 7 ? "text-blue-600 group-hover:text-blue-800" : " text-gray-500 group-hover:text-gray-700"
                                } text-xs font-semibold tracking-wide uppercase`}
                            >
                                Step 5
                            </span>
                            <span className="text-xs font-medium">Track Ads</span>
                        </a>
                    </li>

                    {user.roas_has_finished_onboarding && (
                        <li className="md:flex-1 flex flex-col justify-center">
                            <a
                                onClick={() => navigate("/ads-manager")}
                                className={`${
                                    slide_num >= 7 ? "border-blue-600 hover:border-blue-800" : "border-gray-200 hover:border-gray-300"
                                } group pl-4 py-2 flex flex-col border-solid border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4`}
                            >
                                <span
                                    className={`${
                                        slide_num >= 7 ? "text-blue-600 group-hover:text-blue-800" : " text-gray-500 group-hover:text-gray-700"
                                    } text-xs font-semibold tracking-wide uppercase`}
                                >
                                    Done
                                </span>
                                <span className="text-sm font-medium">Dashboard</span>
                            </a>
                        </li>
                    )}
                </ol>
            </nav>
        </div>
    );
};

const ListTimeLine = ({ list }) => {
    return (
        <ol className="relative border-l border-gray-200 border-solid dark:border-gray-700">
            {pipe(
                lomap((item, idx) => (
                    <li className="mb-10 ml-4" key={idx}>
                        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                        <h1 className="text-lg font-semibold text-gray-900 dark:text-white">Step {item.id}</h1>
                        <p className="mb-4 text-sm font-normal text-gray-500 dark:text-gray-400">{item.text}</p>
                    </li>
                ))
            )(list)}
        </ol>
    );
};

const OnboardingImage = ({ src }) => {
    return (
        <div className="flex flex-col justify-center w-full rounded  my-[20px]">
            <img src={src} className="rounded max-w-full h-auto align-middle rounded border border-[3px] border-solid border-gray-200" />
        </div>
    );
};

const QuoteElm = ({ text, title }) => {
    return (
        <div className="w-full mx-auto rounded-lg bg-white shadow-sm p-[25px] text-gray-800 my-[10px] border border-solid border-gray-100">
            <div className="w-full">
                <p className="text-md text-indigo-500 font-bold text-center">{title}</p>
            </div>
            <div className="w-full mb-[10px]">
                <div className="text-3xl text-indigo-500 text-left leading-tight h-3">“</div>
                <p className="text-sm text-gray-600 text-center px-5">{text}</p>
                <div className="text-3xl text-indigo-500 text-right leading-tight h-3 -mt-3">”</div>
            </div>
        </div>
    );
};

const ConfirmationModal = () => {
    const [user] = useAtom(user_atom);
    const [slider, setSlider] = useAtom(slider_atom);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);
    const [confirmationModal, setConfirmationModal] = useAtom(confirmation_modal_atom);
    let navigate = useNavigate();

    const moveTo = (slide = "1") => {
        setConfirmationModal({ ...confirmationModal, is_open: false, is_confirmed: false });
        _wq.push({
            id: "_all",
            onReady: function (video) {
                video.pause();
                video.time(0);
            },
        });

        if (slide == "0") {
            let doc_ref = doc(db, "accounts", user.uid);
            let payload = { roas_has_finished_onboarding: true };
            from(setDoc(doc_ref, { ...payload }, { merge: true }))
                .pipe(tap(() => setOnboarding({ ...onboarding, ...payload })))
                .subscribe(() => {
                    navigate("/ads-manager");
                });
        } else {
            setSlider({ ...slider, slide });
        }
    };

    const onCloseConfirmationModal = () => {
        setConfirmationModal({ ...confirmationModal, is_open: false, text: "", checkbox_description: "", is_confirmed: false });
    };

    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6 min-w-[700px]">
                        <div className="icon_container">
                            {!confirmationModal.is_confirmed && (
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-red-600"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            )}

                            {confirmationModal.is_confirmed && (
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <svg
                                        className="h-6 w-6 text-green-600"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                            )}
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    Account Setup
                                </h3>
                                <div className="mt-2 w-[65%] mx-auto">
                                    <p className="text-sm text-gray-500">{confirmationModal.text}</p>
                                </div>
                                <div className="mt-2">
                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0 border-solid">
                                            <div className="sm:divide-y sm:divide-gray-200">
                                                <div className="py-[10px] flex flex-row justify-between border-solid border-gray-100 px-[10px]">
                                                    <div className="set_up_confirmation_left_container flex flex-row items-center">
                                                        <div className="text-sm font-medium text-gray-500 ">
                                                            <input
                                                                type="checkbox"
                                                                value=""
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer flex flex-col items-center justify-center mr-[10px]"
                                                                onChange={() => setConfirmationModal({ ...confirmationModal, is_confirmed: true })}
                                                                checked={confirmationModal.is_confirmed}
                                                            ></input>
                                                        </div>
                                                        <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ">
                                                            {confirmationModal.checkbox_description}
                                                        </div>
                                                    </div>
                                                    <div className="setup_confirmation_right_container">
                                                        <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ">
                                                            {!confirmationModal.is_confirmed && (
                                                                <svg
                                                                    className="h-6 w-6 text-red-600"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2"
                                                                    stroke="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            )}

                                                            {confirmationModal.is_confirmed && (
                                                                <svg
                                                                    className="h-6 w-6 text-green-600"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2"
                                                                    stroke="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                            {!confirmationModal.is_confirmed && (
                                <button
                                    disabled={true}
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm"
                                >
                                    Please Confirm First
                                </button>
                            )}

                            {confirmationModal.is_confirmed && (
                                <button
                                    onClick={() => moveTo(confirmationModal.slide)}
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                >
                                    Next
                                </button>
                            )}
                            <button
                                onClick={onCloseConfirmationModal}
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const NextButton = ({ requireConfirmation, slide, modalText, checkboxDescription }) => {
    const [slider, setSlider] = useAtom(slider_atom);
    const [confirmationModal, setConfirmationModal] = useAtom(confirmation_modal_atom);

    const moveTo = (slide = "1") => {
        _wq.push({
            id: "_all",
            onReady: function (video) {
                video.pause();
                video.time(0);
            },
        });
        setSlider({ ...slider, slide });
    };

    const onModalClick = () => {
        setConfirmationModal({ ...confirmationModal, is_open: true, slide, text: modalText, checkbox_description: checkboxDescription });
    };

    useEffect(() => {
        setConfirmationModal({ ...confirmationModal, is_open: false });
    }, [slider.slide]);

    if (requireConfirmation == false) {
        return (
            <div className="next_section" onClick={() => moveTo(slide)}>
                <div className="text">Next</div>
            </div>
        );
    }

    return (
        <div className="next_section" onClick={() => onModalClick(true)}>
            <div className="text">Next</div>
        </div>
    );
};

const hosts = [
    { id: "clickfunnels", name: "Click Funnels" },
    { id: "shopify", name: "Shopify" },
    { id: "gtm", name: "Google Tag Manager" },
    { id: "elementor", name: "Elementor" },
    { id: "thrive", name: "Thrive" },
    { id: "kartra", name: "Kartra" },
    { id: "samcart", name: "SamCart" },
    { id: "none", name: "None" },
];

const shopping_carts = [
    { id: "clickfunnels", name: "Click Funnels" },
    { id: "shopify", name: "Shopify" },
    { id: "woocommerce", name: "Woocommerce" },
    { id: "kartra", name: "Kartra" },
    { id: "samcart", name: "SamCart" },
    { id: "keap", name: "Keap" },
    { id: "none", name: "None" },
];

const WebHostSelectOptions = ({ host, setSelectIsVisible }) => {
    const [isHoverOver, setIsHoverOver] = useState(false);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);

    const onSelectShoppingCart = () => {
        setSelectIsVisible(false);
        setOnboarding({ ...onboarding, roas_tracking_host: host.id });
    };

    return (
        <li
            onMouseEnter={() => setIsHoverOver(true)}
            onMouseLeave={() => setIsHoverOver(false)}
            onClick={onSelectShoppingCart}
            className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-indigo-600 hover:cursor-pointer"
            id="listbox-option-0"
            role="option"
        >
            <span className="font-normal block truncate">{host.name}</span>

            {host.id == onboarding.roas_tracking_host && (
                <span className={`absolute inset-y-0 right-0 flex items-center pr-4 ${isHoverOver ? "text-white" : "text-indigo-600"}`}>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </span>
            )}
        </li>
    );
};

const WebHostDropdown = () => {
    const [selectIsVisible, setSelectIsVisible] = useState(false);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);

    return (
        <div className="shopping_cart_select_container mb-[10px] flex flex-col items-center justify-center w-[800px]">
            <label id="listbox-label" className="block text-sm font-medium text-gray-700 flex flex-col justify-start w-full">
                Web Host
            </label>
            <div className="mt-1 relative w-full">
                <button
                    type="button"
                    className="bg-white relative w-full border border-gray-300 border-solid rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm cursor-pointer"
                    aria-haspopup="listbox"
                    aria-expanded="false"
                    aria-labelledby="listbox-label"
                    onClick={() => setSelectIsVisible(!selectIsVisible)}
                >
                    <span className="block truncate">{pipe(get(matching({ id: onboarding.roas_tracking_host })), head, get("name"))(hosts)}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                            className="h-5 w-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                </button>
                {selectIsVisible && (
                    <ul
                        className={`absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${
                            selectIsVisible ? "opacity-100" : "opacity-0"
                        }`}
                        tabIndex="-1"
                        role="listbox"
                        aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-option-3"
                    >
                        {pipe(lomap((host, idx) => <WebHostSelectOptions host={host} key={idx} setSelectIsVisible={setSelectIsVisible} />))(hosts)}
                    </ul>
                )}
            </div>
        </div>
    );
};

const ShoppingCartSelectOptions = ({ cart, setSelectIsVisible }) => {
    const [isHoverOver, setIsHoverOver] = useState(false);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);

    const onSelectShoppingCart = () => {
        setSelectIsVisible(false);
        setOnboarding({ ...onboarding, roas_shopping_cart: cart.id });
    };

    return (
        <li
            onMouseEnter={() => setIsHoverOver(true)}
            onMouseLeave={() => setIsHoverOver(false)}
            onClick={onSelectShoppingCart}
            className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-indigo-600 hover:cursor-pointer"
            id="listbox-option-0"
            role="option"
        >
            <span className="font-normal block truncate">{cart.name}</span>

            {cart.id == onboarding.roas_shopping_cart && (
                <span className={`absolute inset-y-0 right-0 flex items-center pr-4 ${isHoverOver ? "text-white" : "text-indigo-600"}`}>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </span>
            )}
        </li>
    );
};

const WebhookDropdown = () => {
    const [selectIsVisible, setSelectIsVisible] = useState(false);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);

    return (
        <div className="shopping_cart_select_container mb-[10px] flex flex-col items-center justify-center w-[800px]">
            <label id="listbox-label" className="block text-sm font-medium text-gray-700 flex flex-col justify-start w-full">
                Shopping Cart
            </label>
            <div className="mt-1 relative w-full">
                <button
                    type="button"
                    className="bg-white relative w-full border border-gray-300 border-solid rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm cursor-pointer"
                    aria-haspopup="listbox"
                    aria-expanded="false"
                    aria-labelledby="listbox-label"
                    onClick={() => setSelectIsVisible(!selectIsVisible)}
                >
                    <span className="block truncate">
                        {pipe(get(matching({ id: onboarding.roas_shopping_cart })), head, get("name"))(shopping_carts)}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                            className="h-5 w-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                </button>
                {selectIsVisible && (
                    <ul
                        className={`absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${
                            selectIsVisible ? "opacity-100" : "opacity-0"
                        }`}
                        tabIndex="-1"
                        role="listbox"
                        aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-option-3"
                    >
                        {pipe(lomap((cart, idx) => <ShoppingCartSelectOptions cart={cart} key={idx} setSelectIsVisible={setSelectIsVisible} />))(
                            shopping_carts
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

const FacebookAccounts = () => {
    const [user] = useAtom(user_atom);
    const [slider, setSlider] = useAtom(slider_atom);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);
    const [fbAccounts, setFbAccounts] = useAtom(facebook_accounts_atom);
    const [filteredFbAccounts, setFilteredFbAccounts] = useState({});

    const onAdAccountsFetchedListener = (cb) => {
        let ad_accounts_status_query = doc(db, "fb_ad_accounts_status", user.uid);
        const unsubscribe = onSnapshot(ad_accounts_status_query, cb);
    };

    const onAdAccountsFetched = (querySnapshot) => {
        let { status } = querySnapshot.data();
        if (status == 1) {
            get_ad_accounts_from_db().subscribe(set_ad_accounts);
        }
    };

    useEffect(() => {
        if (slider.slide == "2") {
            get_ad_accounts_from_db().subscribe((ad_accounts) => {
                if (size(ad_accounts) < 1) {
                    from(fetch(`https://roas-workers-services.herokuapp.com/get_user_facebook_ad_accounts_from_api?roas_user_id=${user.uid}`))
                        .pipe(concatMap((value) => value.json()))
                        .subscribe((response) => onAdAccountsFetchedListener(onAdAccountsFetched));
                } else {
                    rxof(ad_accounts).subscribe(set_ad_accounts);
                }
            });
        }
    }, [slider.slide]);

    const get_ad_accounts_from_db = () => {
        let ad_accounts_query = query(collection(db, "fb_ad_accounts"), where("user_ids", "array-contains", user.uid));
        return from(getDocs(ad_accounts_query)).pipe(rxmap((accounts) => accounts.docs.map((account) => account.data())));
    };

    const set_ad_accounts = (ad_accounts) => {
        rxof(ad_accounts)
            .pipe(
                rxmap(pipe(lokeyby("account_id"))),
                rxmap(map(pick(["account_id", "name"]))),
                rxmap(map((value) => ({ ...value, selected: false })))
            )
            .subscribe((accounts) => {
                if (!isEmpty(accounts)) {
                    setFbAccounts(accounts);
                }
            });
    };

    useEffect(() => {
        if (size(fbAccounts) > 0) {
            setFilteredFbAccounts(fbAccounts);
        }
    }, [fbAccounts]);

    const getFbAccountFromDb = (user_id) => {
        let user_fb_ref = query(collectionGroup(db, "integrations"), where("user_id", "==", user_id), where("account_name", "==", "facebook"));
        return from(getDocs(user_fb_ref)).pipe(
            rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
            rxmap(head)
        );
    };

    const onSelectAdAccount = (fb_ad_account_id) => {
        let user_id = user.uid;

        getFbAccountFromDb(user_id)
            .pipe(
                rxmap(pipe(get("fb_account_id"))),
                concatMap((facebook_account_id) => {
                    let payload = {
                        facebook_account_id,
                        fb_ad_account_id,
                        roas_user_id: user_id,
                        members: [user_id],
                        roles: { [user_id]: "owner" },
                        created_at: Number(moment().format("x")),
                    };

                    return set_project(user_id, payload);
                })
            )
            .subscribe(() => {
                let payload = {
                    roas_has_facebook_ad_accounts: true,
                    roas_facebook_ad_account_id: fb_ad_account_id,
                    roas_has_facebook_ad_account_data: true,
                    roas_has_selected_facebook_ad_account: true,
                    roas_facebook_ad_account_id_selected_at: Number(moment().format("x")),
                };
                setOnboarding({ ...onboarding, ...payload });
            });
    };

    const onFilterFbAccounts = ({ target: { value } }) => {
        setFilteredFbAccounts(pipe(get(matching({ name: pipe(toLower, includes(value)) })))(fbAccounts));
    };

    const AdAccounts = () => {
        return (
            <div className="facebook_accounts_list_container">
                {lomap(
                    (account) => (
                        <div
                            className="ad_account_container flex flex-row justify-between px-[15px] py-[5px] text-md"
                            key={account.account_id}
                            onClick={() => onSelectAdAccount(account.account_id)}
                        >
                            <div className="ad_account_left flex flex-row items-center justify-center">
                                <div className="select mr-[10px]">
                                    <Checkbox
                                        onChange={() => onSelectAdAccount(account.account_id)}
                                        checked={onboarding.roas_facebook_ad_account_id == account.account_id}
                                    ></Checkbox>
                                </div>
                                <div className="name">{account.name}</div>
                            </div>
                            <div className="ad_account_right flex flex-row items-center justify-center">
                                <div className="name">{account.account_id}</div>
                            </div>
                        </div>
                    ),
                    filteredFbAccounts
                )}
            </div>
        );
    };

    const LoadingAdAccounts = () => {
        return (
            <div className="facebook_accounts_list_container loading_fb_accounts_container">
                <div className="loading_icon_container">
                    <div className="text">Loading</div>
                    <div className="icon">
                        <LoadingOutlined />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="carousel_view_container" id="facebook_accounts" key="facebook_accounts">
            <div className="facebook_accounts_container">
                <div className="facebook_accounts_search_filter_container">
                    <input type="text" placeholder="Search by account name" onChange={onFilterFbAccounts} />
                </div>

                {isEmpty(filteredFbAccounts) && <LoadingAdAccounts />}
                {!isEmpty(filteredFbAccounts) && <AdAccounts />}
            </div>
        </div>
    );
};

const ConnectToFacebook = () => {
    const [slider, setSlider] = useAtom(slider_atom);
    const [user] = useAtom(user_atom);

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4  sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">Step 1</h2>
                            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                                Connect To Facebook Business Manager.
                            </p>
                        </div>
                    </div>
                </div>

                <Paragraph className="text-base">There are two steps in this process and they are both easy.</Paragraph>
                <Paragraph className="text-base">First, we’ll connect to your Facebook Business Manager.</Paragraph>
                <Paragraph className="text-base">Then, we’ll choose the Facebook ad account that you want to track.</Paragraph>

                {slider.slide == "1" && (
                    <div className="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                        <div className="wistia_responsive_wrapper" style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}>
                            <ReactPlayer
                                url="https://frankkern.wistia.com/medias/wjrfo54640"
                                playing={true}
                                controls={true}
                                width={"100%"}
                                height={"100%"}
                                playsinline={true}
                            />
                        </div>
                    </div>
                )}

                <div className="connect_to_fb_container">
                    <a className="connect_to_fb_button" href={`https://roas-oauth-service.herokuapp.com/auth/facebook?user_id=${user.uid}`}>
                        <div className="text">Connect to Facebook</div>
                    </a>
                </div>

                <Paragraph className="text-base">Make sure that you click “Allow” on all the steps from Facebook </Paragraph>
                <Paragraph className="text-base">(Note: this may request you login to your facebook account)</Paragraph>
                <Paragraph className="text-base">Once you have allowed ROAS app to connect, you will want to select the ad account.</Paragraph>
            </div>
        </div>
    );
};

const SelectFacebookAdAccount = () => {
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);
    const [slider, setSlider] = useAtom(slider_atom);
    let navigate = useNavigate();

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">Step 2</h2>
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">
                                Select Your Ad Account From Within Your Business Manager.
                            </p>
                        </div>
                    </div>
                </div>

                {slider.slide == "2" && (
                    <div className="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                        <div className="wistia_responsive_wrapper" style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}>
                            <ReactPlayer
                                url="https://frankkern.wistia.com/medias/salf8lziy4"
                                playing={true}
                                controls={true}
                                width={"100%"}
                                height={"100%"}
                                playsinline={true}
                            />
                        </div>
                    </div>
                )}

                <Paragraph className="text-base py-[10px]">
                    Now you should see a list of all the accounts in your Business Manager. If you have multiple accounts, this part might take a few
                    minutes to load. (It’s Facebook being ...well ...being Facebook.)
                </Paragraph>

                <div style={{ marginBottom: 20 }}>
                    <FacebookAccounts />
                </div>

                <Paragraph className="text-base py-[10px]">
                    Be sure to select the proper account you want to track (only select one ad account per ROAS account), and click "Next”.
                </Paragraph>

                {!onboarding.roas_has_seen_finish_setup_video && (
                    <div className="next_section" onClick={() => navigate("/ads-manager")}>
                        <div className="text">Next</div>
                    </div>
                )}

                {onboarding.roas_has_seen_finish_setup_video && (
                    <NextButton
                        requireConfirmation={true}
                        slide={"3"}
                        modalText={"Click the checkbox to confirm that you have selected the correct ad account"}
                        checkboxDescription={"This is the ad account i want to track with ROAS"}
                    />
                )}
            </div>
        </div>
    );
};

const ClickFunnelsTracking = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);

    const list_1 = [
        { id: 1, text: "Select your funnel you wish to track" },
        { id: 2, text: "In the top right select the “Settings”" },
        { id: 3, text: "Then paste your tracking code into the “head” section”" },
        { id: 4, text: "Remember to scroll down to the bottom the the page and click SAVE." },
        { id: 5, text: "Please do this for ALL of the funnels you want to track in Clickfunnels." },
    ];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebHostDropdown />

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">ROAS Tracking Snippet</h2> */}
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">Click Funnels</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"clickfunnels_dna_tracking"}
                    thumbnail_id={"7b97363c8ea840da95d78407aaa78bfd"}
                    dna_script={true}
                    webhook={false}
                />

                <ListTimeLine list={list_1} />
                <OnboardingImage src={onboarding_3_cf_1} />

                <NextButton
                    requireConfirmation={true}
                    slide={"5"}
                    modalText={"Click the checkbox to confirm that you have installed your tracking code on your Click Funnels Page"}
                    checkboxDescription={"Added Tracking Code To Click Funnels"}
                />
            </div>
        </div>
    );
};

const ShopifyTracking = () => {
    const [user] = useAtom(user_atom);
    const [app, setApp] = useAtom(app_atom);

    const list_1 = [
        { id: 1, text: "From the home screen, select “Online Store”" },
        { id: 2, text: "From the dropdown menu, select “Themes”. (You probably won’t see “themes” until you click “Online Store”.)" },
    ];

    const list_2 = [
        { id: 3, text: "When the “Themes” page loads, click on “Actions” button at the top right and then click on “Edit Code” from the drop down." },
    ];

    const list_3 = [
        {
            id: 4,
            text: "On the next page, you’ll see a bunch of code and stuff ...when you’re doing this. DON’T LET ALL THAT CODE FREAK YOU OUT! WE’RE JUST PASTING SOMETHING :-)",
        },
        { id: 5, text: "First, at the top left, under the “Layout” icon, click on “Theme.liquid.”" },
        {
            id: 6,
            text: `
                Next, look at the top part of the code that appears in the main bondy of the page.

                Right near the top, you’ll see a little snippet of code that says "<head>"
        `,
        },
        {
            id: 7,
            text: `
                When you find it, put your cursor right at the end of it and press the “enter” key on your keyboard a few times.

                We’re just wanting to add some space so we can paste the tracking code here.
        `,
        },
        { id: 8, text: `Then paste your tracking code into that space you created. (See below).` },
    ];

    const list_4 = [{ id: 9, text: "Here is your tracking code in case you need it" }];

    const list_5 = [{ id: 10, text: `10. Don’t forget to click "SAVE"` }];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebHostDropdown />

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">ROAS Tracking Snippet</h2> */}
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">Shopify</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"shopify_dna_tracking"}
                    thumbnail_id={"6a4cfc8960ad40c0973f1398df725296"}
                    dna_script={true}
                    webhook={false}
                />

                <ListTimeLine list={list_1} />
                <OnboardingImage src={onboarding_4_shopify_1} />

                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_4_shopify_2} />

                <ListTimeLine list={list_3} />
                <OnboardingImage src={onboarding_4_shopify_3} />

                <ListTimeLine list={list_4} />

                <TrackingCodeSnippetSection />

                <ListTimeLine list={list_5} />

                <OnboardingImage src={onboarding_4_shopify_4} />

                <NextButton
                    requireConfirmation={true}
                    slide={"5"}
                    modalText={"Click the checkbox to confirm that you have installed your tracking code on your Shopify Page"}
                    checkboxDescription={"Added Tracking Code To Shopify"}
                />
            </div>
        </div>
    );
};

const GtTracking = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const list_1 = [
        { id: 1, text: "First, log into your Google Tag Manager workspace." },
        { id: 2, text: "Then click on Tags." },
    ];

    const list_2 = [{ id: 3, text: "Then click on “New”" }];

    const list_3 = [{ id: 4, text: "Now name the tag and select “Custom HTML” from the menu on the right." }];

    const list_4 = [
        { id: 5, text: "When you select “Custom HTML”, a blank space will appear where you’ll be able to paste your code." },
        { id: 6, text: `Paste your code there (see screenshot below).` },
    ];

    const list_5 = [
        { id: 7, text: "Next, you’ll want to tell Google Tag manager what needs to happen in order for this tag to fire." },
        { id: 8, text: `We’ll do this by creating a “Trigger”.` },
        { id: 9, text: `Right below the space where you pasted your code, you’ll see the “Triggering” section.` },
        { id: 10, text: `Click the little pencil on the right so you can create a new trigger.` },
    ];

    const list_6 = [
        { id: 11, text: "When you do that, you’ll be taken to the “Triggers” screen." },
        { id: 12, text: `Click the plus sign in the top right to create a new trigger.` },
    ];

    const list_7 = [
        { id: 13, text: "Now you can create a new trigger." },
        { id: 14, text: `In the top right, name it “All Web Pages For ROAS”` },
        { id: 15, text: `Next, click “Page View” from the menu on the left.` },
    ];

    const list_8 = [
        { id: 16, text: "15. On the next screen, just make sure that “all page views” is selected." },
        { id: 17, text: `Then click “Save”.` },
    ];

    const list_9 = [{ id: 18, text: "Be sure to save the trigger and the tag, and then be sure to PUBLISH your changes." }];

    return (
        <div className="slide_content_container">
            <WebHostDropdown />

            <div className="inner_content">
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">ROAS Tracking Snippet</h2> */}
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">Google Tag Manager</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"gtm_dna_tracking"}
                    thumbnail_id={"a836b1d855f44994a28dd2597cf52fc7"}
                    dna_script={true}
                    webhook={false}
                />

                <ListTimeLine list={list_1} />
                <OnboardingImage src={onboarding_5_gtm_1} />

                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_5_gtm_2} />

                <ListTimeLine list={list_3} />
                <OnboardingImage src={onboarding_5_gtm_3} />

                <ListTimeLine list={list_4} />

                <Paragraph style={{ ...common_paragraph_styles }}>Here’s your code in case you need it:</Paragraph>

                <TrackingCodeSnippetSection />

                <OnboardingImage src={onboarding_5_gtm_4} />

                <ListTimeLine list={list_5} />
                <OnboardingImage src={onboarding_5_gtm_5} />

                <ListTimeLine list={list_6} />
                <OnboardingImage src={onboarding_5_gtm_6} />

                <ListTimeLine list={list_7} />
                <OnboardingImage src={onboarding_5_gtm_7} />

                <ListTimeLine list={list_8} />
                <OnboardingImage src={onboarding_5_gtm_8} />

                <ListTimeLine list={list_9} />

                <NextButton
                    requireConfirmation={true}
                    slide={"5"}
                    modalText={"Click the checkbox to confirm that you have installed your tracking code on Google Tag Manager"}
                    checkboxDescription={"Added Tracking Code To Google Tag Manager"}
                />
            </div>
        </div>
    );
};

const ElementorTracking = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const list_1 = [
        { id: 1, text: "Log into your Wordpress admin." },
        { id: 2, text: "Find the Elementor menu in the main menu on the left." },
        { id: 3, text: "From the Elementor menu, select “Custom Code”" },
    ];

    const list_2 = [{ id: 4, text: "At the Custom Code menu, select “add new custom code”." }];

    const list_3 = [
        { id: 5, text: "In the custom code interface, be sure you select “<head>” in the dropdown menu of the Location settings." },
        { id: 6, text: "Then paste your code." },
        { id: 7, text: "When you’re done, click PUBLISH." },
    ];

    const list_4 = [{ id: 8, text: "At the next screen, make sure you select “Entire Site” as shown below." }];

    const list_5 = [{ id: 9, text: "Be sure to click “Save” when you’re done." }];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebHostDropdown />
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">ROAS Tracking Snippet</h2> */}
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">Elementor</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"elementor_dna_tracking"}
                    thumbnail_id={"2f875dbe62a849ed8b2c77c58b05036d"}
                    dna_script={true}
                    webhook={false}
                />

                <Paragraph style={{ ...common_paragraph_styles }}>
                    There are lots of page builders for Wordpress. many people use Elementor, so we included instructions for adding the code using
                    that system specifically.
                </Paragraph>

                <ListTimeLine list={list_1} />
                <OnboardingImage src={onboarding_6_elementor_1} />

                <ListTimeLine list={list_2} />

                <OnboardingImage src={onboarding_6_elementor_2} />

                <ListTimeLine list={list_3} />

                <TrackingCodeSnippetSection />

                <OnboardingImage src={onboarding_6_elementor_3} />

                <ListTimeLine list={list_4} />

                <OnboardingImage src={onboarding_6_elementor_4} />

                <ListTimeLine list={list_5} />

                <NextButton
                    requireConfirmation={true}
                    slide={"5"}
                    modalText={"Click the checkbox to confirm that you have installed your tracking code on your Elementor Page"}
                    checkboxDescription={"Added Tracking Code To Elementor"}
                />
            </div>
        </div>
    );
};

const ThriveThemesTracking = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const list_1 = [
        { id: 1, text: "From your main Wordpress admin screen, select “Thrive Dashboard” from the menu." },
        { id: 2, text: "Then select the Thrive Dashboard AGAIN when the Thrive menu opens up. (I know, that is weird.)" },
        { id: 3, text: "See screenshot:" },
    ];

    const list_2 = [
        { id: 4, text: "Next, you’ll see a lot of menu options." },
        { id: 5, text: "Select “Analytics and Scripts” (Click the green “manage Scripts” button as shown below)." },
    ];

    const list_3 = [{ id: 6, text: "On the next screen, click “Add New” at the top right." }];

    const list_4 = [
        { id: 6, text: "When you do, you’ll see a window appear like in the screenshot below." },
        { id: 7, text: "This is where you’ll paste your code (Copy from below):" },
    ];

    const list_5 = [
        { id: 8, text: "Paste your script in the “script” area as shown below." },
        { id: 9, text: "Type “ROAS CODE” for the script label." },
        { id: 10, text: "In the dropdown menu for placement, select “Before </head>”." },
        { id: 11, text: "Then click “Continue”" },
    ];

    const list_6 = [
        { id: 12, text: "IMPORTANT:" },
        { id: 13, text: "Don’t forget to turn it ON (see below):" },
    ];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebHostDropdown />

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">ROAS Tracking Snippet</h2> */}
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">Thrive Themes</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"thrive_dna_tracking"}
                    thumbnail_id={"2a4107d619fd470480be15e0c5833adb"}
                    dna_script={true}
                    webhook={false}
                />

                <Paragraph style={{ ...common_paragraph_styles }}>
                    There are many page builders for Wordpress. Since ThriveThemes is so popular, we created a specific tutorial for it.
                </Paragraph>

                <ListTimeLine list={list_1} />
                <OnboardingImage src={onboarding_7_thrive_thems_1} />

                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_7_thrive_thems_2} />

                <ListTimeLine list={list_3} />
                <OnboardingImage src={onboarding_7_thrive_thems_3} />

                <ListTimeLine list={list_4} />

                <TrackingCodeSnippetSection />

                <Paragraph style={{ fontSize: 14 }}>Do this:</Paragraph>

                <ListTimeLine list={list_5} />
                <OnboardingImage src={onboarding_7_thrive_thems_4} />

                <ListTimeLine list={list_6} />
                <OnboardingImage src={onboarding_7_thrive_thems_5} />

                <NextButton
                    requireConfirmation={true}
                    slide={"5"}
                    modalText={"Click the checkbox to confirm that you have installed your tracking code on your Thrive Themes Page"}
                    checkboxDescription={"Added Tracking Code To Thrive Themes"}
                />
            </div>
        </div>
    );
};

const KartraTracking = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const list_1 = [
        {
            id: 1,
            text: "First, navigate to “my Pages and find the specific page that you want to track. (Note - you’re going to want to do these steps for ALL pages that you’re tracking).",
        },
        { id: 2, text: "Next, click the three dots next to the page name as shown below:" },
    ];

    const list_2 = [{ id: 3, text: "When you click the three buttons, a menu will appear. Select “Edit” from that menu." }];

    const list_3 = [
        { id: 4, text: "When you do that, ANOTHER menu will appear." },
        { id: 5, text: `Click on “Edit” in that menu, too:` },
    ];

    const list_4 = [
        { id: 6, text: "At the next screen, select “Settings” from the menu on the left." },
        { id: 7, text: `When you do, another menu will expand and you’ll want to select “Tracking Code” from there.` },
    ];

    const list_5 = [
        { id: 8, text: `Next, place your tracking code at the top left part of the page where it says “EMBED TRACKING CODE INTO THE "<HEAD>"` },
        { id: 9, text: `Here is your code if you need it:` },
    ];

    const list_6 = [
        { id: 10, text: `Hit “Apply” when you’re done.` },
        { id: 11, text: `Then “Publish LIVE”` },
    ];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebHostDropdown />

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">ROAS Tracking Snippet</h2> */}
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">Kartra</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"kartra_dna_tracking"}
                    thumbnail_id={"ffa1de1ef06a4237ba0690a289a62e87"}
                    dna_script={true}
                    webhook={false}
                />

                <Paragraph style={{ ...common_paragraph_styles }}>
                    You want to make sure the tracking code is on EVERY page that you’re sending traffic to.
                </Paragraph>

                <ListTimeLine list={list_1} />
                <OnboardingImage src={onboarding_8_kartra_1} />

                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_8_kartra_2} />

                <ListTimeLine list={list_3} />
                <OnboardingImage src={onboarding_8_kartra_3} />

                <ListTimeLine list={list_4} />
                <OnboardingImage src={onboarding_8_kartra_4} />

                <ListTimeLine list={list_5} />

                <TrackingCodeSnippetSection />

                <ListTimeLine list={list_6} />
                <OnboardingImage src={onboarding_8_kartra_5} />

                <QuoteElm
                    title={"NOTE"}
                    text={`Be sure to do this for ALL pages you’re sending traffic to (as well as all pages in your funnel such as upsell pages,
                            checkout pages, etc. Anywhere Kartra will allow you to paste this code, paste it.)`}
                />

                <NextButton
                    requireConfirmation={true}
                    slide={"5"}
                    modalText={"Click the checkbox to confirm that you have installed your tracking code on your Kartra Page"}
                    checkboxDescription={"Added Tracking Code To Kartra"}
                />
            </div>
        </div>
    );
};

const SamcartTracking = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebHostDropdown />
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">ROAS Tracking Snippet</h2> */}
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">SamCart</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"samcart_webhook"}
                    thumbnail_id={"6470a69a15314487ae7d4c656f53a2be"}
                    dna_script={true}
                    webhook={false}
                />

                <NextButton
                    requireConfirmation={true}
                    slide={"5"}
                    modalText={"Click the checkbox to confirm that you have installed your tracking code on your Samcart Page"}
                    checkboxDescription={"Added Tracking Code To Samcart"}
                />
            </div>
        </div>
    );
};

const NotSupportedTracking = () => {
    const [user] = useAtom(user_atom);
    const [inputValue, setInputValue] = useState("");

    const onInputChange = ({ target: { value } }) => {
        setInputValue(value);
        set_project(user.uid, { unsupported_tracking_host_name: pipe(toLower, (str) => str.replace(/\s/g, ""))(value) });
    };

    const onBlur = ({ target: { value } }) => {
        set_project(user.uid, { unsupported_tracking_host_name: pipe(toLower, (str) => str.replace(/\s/g, ""))(value) });
    };

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebHostDropdown />
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl pb-[20px]">:(</p>
                            <p className="text-base text-gray-400 tracking-wide uppercase mb-[10px]">Let us know where you host your website</p>
                        </div>
                    </div>
                </div>

                <div className="input_container ">
                    <input
                        onChange={onInputChange}
                        type="text"
                        name="email"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-solid border-gray-300 rounded-md min-h-[40px] pl-[10px] mb-[20px]"
                        placeholder="Web Hosting Platform"
                        value={inputValue}
                        onBlur={onBlur}
                    />
                </div>

                <NextButton
                    requireConfirmation={true}
                    slide={"5"}
                    modalText={"Click the checkbox to confirm that you have installed your tracking code on your Samcart Page"}
                    checkboxDescription={"Added Tracking Code To Samcart"}
                />
            </div>
        </div>
    );
};

const Empty = () => {
    return (
        <div className="slide_content_container">
            <div className="inner_content"></div>
        </div>
    );
};

const SelectTrackingHost = () => {
    const [user] = useAtom(user_atom);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);
    const [slider, setSlider] = useAtom(slider_atom);

    const onSelectTrackingHost = (roas_tracking_host) => {
        from(set_project(user.uid, { tracking_host_id: roas_tracking_host, tracking_host_name: roas_tracking_host })).subscribe(() => {
            setOnboarding({ ...onboarding, roas_tracking_host });
            setSlider({ ...slider, slide: "4" });
        });
    };

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4  sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">Step 3</h2>
                            <p className="mt-1 text-5xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">
                                Paste The ROAS Tracking Code ON ALL Of Your Web Pages
                            </p>
                            <p className="max-w-xl mt-5 mx-auto text-l text-gray-500">
                                In order for ROAS to track your visitors, we need the following code installed in the HEADER section of all of your
                                webpages.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="text-gray-900 bg-white border border-solid border-[3px] border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white w-full">
                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 rounded-t-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                            onClick={() => onSelectTrackingHost("clickfunnels")}
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Click Funnels
                        </label>
                        <img className="absolute right-[20px] w-[100px] -mr-[3px]" src={ClickfunnelsLogo} width={70} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectTrackingHost("shopify")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Shopify
                        </label>
                        <img className="absolute right-[20px] w-[80px]" src={ShopifyLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectTrackingHost("gtm")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Google Tag Manager
                        </label>
                        <img className="absolute right-[20px] w-[130px]" src={GoogleTagManagerLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectTrackingHost("elementor")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Elementor
                        </label>
                        <img className="absolute right-[20px] w-[100px] -mr-[0px]" src={ElementorLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectTrackingHost("thrive")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Thrive
                        </label>
                        <img className="absolute right-[20px] w-[120px]" src={ThriveThemesLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectTrackingHost("kartra")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Kartra
                        </label>
                        <img className="absolute right-[20px] w-[90px] -mr-[4px]" src={KartraLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectTrackingHost("samcart")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            SamCart
                        </label>
                        <img className="absolute right-[20px] w-[90px] mr-[3px]" src={SamcartLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-gray-200 rounded-b-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectTrackingHost("none")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            None of the above
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TrackingScriptInstructions = () => {
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);

    let components = {
        "": Empty,
        clickfunnels: ClickFunnelsTracking,
        shopify: ShopifyTracking,
        gtm: GtTracking,
        elementor: ElementorTracking,
        thrive: ThriveThemesTracking,
        kartra: KartraTracking,
        samcart: SamcartTracking,
        none: NotSupportedTracking,
    };

    let Component = components[onboarding.roas_tracking_host];

    return <Component />;
};

const ClickfunnelsWebhook = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const list_1 = [
        { id: 1, text: "It’s really simple." },
        { id: 2, text: "First, understand that we need to do this for EACH FUNNEL you’re wanting to track." },
        { id: 3, text: "It takes roughly 20 seconds per funnel." },
        { id: 4, text: "Select the funnel you want to track and click on “Settings”" },
    ];

    const list_2 = [{ id: 5, text: "Next, SCROLL DOWN toward the bottom of the page and click “Manage Your Funnel Webhooks”." }];

    const list_3 = [{ id: 6, text: "On the webhooks screen, click “New Webhook” at the top right." }];

    const list_4 = [
        { id: 7, text: "On the next screen, simply paste your ROAS webhook in the URL field." },
        { id: 8, text: "Here’s your webhook for ClickFunnels:" },
    ];

    const list_5 = [
        { id: 8, text: "The “Event” field can be set to “All Events”." },
        { id: 9, text: "The “Version” field can be be set to “1”" },
        { id: 10, text: "The “Adapter” field can be set to “Attributes”" },
        {
            id: 11,
            text: `(These fields are usually set this way by default - just like in the screenshot below - so you really should only need to paste your WebHook and then click the “Create Funnel Webhook” button.`,
        },
        {
            id: 12,
            text: `Like so:`,
        },
    ];

    const list_6 = [
        { id: 13, text: "Be sure to do this for EVERY funnel you want to track." },
        { id: 14, text: "Also - when you’re done adding the webhook, you’ll be taken back to the “settngs” page for your funnel." },
        { id: 15, text: "ALWAYS CLICK “SAVE AND UPDATE SETTINGS” at the bottom :-)" },
    ];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebhookDropdown />

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">ClickFunnels</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"clickfunnels_webhook"}
                    thumbnail_id={"d646eb20419d48188e0d868634a9865d"}
                    dna_script={false}
                    webhook={"clickfunnels"}
                />

                <Paragraph style={{ ...common_paragraph_styles }}>If you use Clickfunnels order forms, you need to do this step.</Paragraph>

                <ListTimeLine list={list_1} />
                <OnboardingImage src={onboarding_11_clickfunnels_1} />

                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_11_clickfunnels_2} />

                <ListTimeLine list={list_3} />
                <OnboardingImage src={onboarding_11_clickfunnels_3} />

                <ListTimeLine list={list_4} />

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"clickfunnels"} />
                </Paragraph>

                <OnboardingImage src={onboarding_11_clickfunnels_4} />

                <ListTimeLine list={list_5} />
                <OnboardingImage src={onboarding_11_clickfunnels_4} />

                <ListTimeLine list={list_6} />

                <NextButton
                    requireConfirmation={true}
                    slide={"7"}
                    modalText={"Click the checkbox to confirm that you have installed your webhook code on your Click Funnels Account"}
                    checkboxDescription={"Added Webhook Code To Click Funnels"}
                />
            </div>
        </div>
    );
};

const ShopifyWebhook = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const list_1 = [{ id: 1, text: "First, click on “Settings” from the main menu. It’s kind of hidden down at the bottom:" }];

    const list_2 = [{ id: 2, text: "From the settings window, click on “Notifications” in the left hand menu." }];

    const list_3 = [{ id: 3, text: "On the “Notifications” screen, scroll all the way down to the bottom and click on “Create Webhook”" }];

    const list_4 = [{ id: 4, text: "First, here’s your Shopify Webhook:" }];

    const list_5 = [
        { id: 5, text: "Go ahead and copy that." },
        { id: 6, text: "Now, when you click on “Create Webhook” above, a little window like the one below will pop up." },
        { id: 7, text: "The first thing you want to do is select ”Order Creation” from the “Event” drop down menu like you see below." },
        { id: 8, text: "Then paste your webhook into the URL field." },
        { id: 9, text: "Leave the Webhook API version on Unstable. (Yes, that sounds weird but its fine)." },
        { id: 10, text: "Then hit save. Just like below:" },
    ];

    const list_6 = [
        { id: 11, text: "SECOND TIME:" },
        { id: 12, text: "Click on “Create Webhook” again in the Notifications Menu." },
        { id: 13, text: "The little window will pop up again." },
        { id: 14, text: "The only thing that changes this time is what you selcect from the Events drop down menu." },
        { id: 15, text: "Instead of choosing 'Order Creation', you want to choose “Order Payment”." },
        { id: 16, text: "Then paste your webhook URL again. (Here it is in case you need it)" },
    ];

    const list_7 = [
        { id: 17, text: "The format is JSON (it should already be selected like you see below)." },
        { id: 18, text: "...And the “Unstable” webhook API version will also be showing." },
        {
            id: 19,
            text: "So really all you’re doing is selecting a new event from the “Event” drop down menu, pasting your webhook, and pressing save.",
        },
        { id: 20, text: "Like so:" },
    ];

    const list_9 = [
        { id: 21, text: "THIRD TIME:" },
        { id: 22, text: "Click on “Create Webhook” again in the Notifications Menu." },
        { id: 23, text: "The little window will pop up again." },
        { id: 24, text: "The only thing that changes this time is what you selcect from the Events drop down menu." },
        { id: 25, text: "Instead of choosing 'Order Payment', you want to choose “Order Update”." },
        { id: 26, text: "Then paste your webhook URL again. (Here it is in case you need it)" },
    ];

    const list_10 = [
        { id: 27, text: "The format is JSON (it should already be selected like you see below)." },
        { id: 28, text: "...And the “Unstable” webhook API version will also be showing." },
        {
            id: 29,
            text: "So really all you’re doing is selecting a new event from the “Event” drop down menu, pasting your webhook, and pressing save.",
        },
        { id: 30, text: "Like so:" },
    ];

    const list_11 = [{ id: 31, text: "Now you’re fully connected to Shopify!" }];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebhookDropdown />
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">Shopify</p>
                        </div>
                    </div>
                </div>
                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"shopify_webhook"}
                    thumbnail_id={"5d1fa2824e314d95b37246eed3202b21"}
                    dna_script={false}
                    webhook={"shopify"}
                />
                <ListTimeLine list={list_1} />
                <OnboardingImage src={onboarding_10_shopify_1} />
                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_10_shopify_2} />
                <ListTimeLine list={list_3} />
                <OnboardingImage src={onboarding_10_shopify_3} />
                <QuoteElm
                    title={"IMPORTANT"}
                    text={"We’re about to do the following step THREE times. This is super easy as long as you pay attention."}
                />
                <ListTimeLine list={list_4} />
                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"shopify"} />
                </Paragraph>
                <ListTimeLine list={list_5} />
                <OnboardingImage src={onboarding_10_shopify_4} />
                <ListTimeLine list={list_6} />
                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"shopify"} />
                </Paragraph>
                <ListTimeLine list={list_7} />
                <OnboardingImage src={onboarding_10_shopify_5} />
                <ListTimeLine list={list_9} />
                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"shopify"} />
                </Paragraph>
                <ListTimeLine list={list_10} />
                <OnboardingImage src={onboarding_10_shopify_6} />
                <ListTimeLine list={list_11} />
                <NextButton
                    requireConfirmation={true}
                    slide={"7"}
                    modalText={"Click the checkbox to confirm that you have installed your webhook code on your Shopify Account"}
                    checkboxDescription={"Added Webhook Code To Shopify"}
                />
            </div>
        </div>
    );
};

const KartraWebhook = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const list_1 = [
        { id: 1, text: "Adding the webhook to Kartra is simple" },
        { id: 2, text: "From the main Kartra interfect, select “My Intergrations” from the menu on the left." },
    ];

    const list_2 = [{ id: 3, text: "On the next screen, click “My API” in the top menu." }];

    const list_3 = [
        { id: 4, text: "When you do, ANOTHER little menu will appear right at the top." },
        { id: 5, text: "Click “My API” in that little menu." },
    ];

    const list_4 = [
        { id: 6, text: "As soon as you do, you’ll see the screen change to what you see in the screenshot below." },
        { id: 7, text: "You want to click “CHANGE” on the right hand side of the “Outbound API” section like so:" },
    ];

    const list_5 = [
        { id: 8, text: "Once you click that, you’ll now have the option to paste you Webhook." },
        { id: 9, text: "Here is your Karta webhook in case you need it:" },
    ];

    const list_6 = [{ id: 10, text: "Here’s what the next screen looks like:" }];

    const list_7 = [
        { id: 11, text: "First, make sure the “Yes, Activate Outbound API is checked." },
        { id: 12, text: "In the URL field, make sure you select “https” from the dropdown menu on the left." },
        { id: 13, text: "Next, paste your webhook in the URL field - but only paste everything AFTER the https:// part." },
        { id: 14, text: "Here’s your webhook again if you need it:" },
    ];

    const list_8 = [
        { id: 11, text: "Finally, we need to select what events make this webhook fire." },
        { id: 12, text: "This is simple." },
        { id: 13, text: "In the bottom row of dropdown menus, select “Customer Buys Product” in the menu on the left." },
        { id: 14, text: "In the menu to the right of that, select “And Product”." },
        { id: 14, text: "Then click “Add”" },
    ];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebhookDropdown />

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">Kartra</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"kartra_webhook"}
                    thumbnail_id={"bbdf9ba8e6924e52ac96df5bf07dffe1"}
                    dna_script={false}
                    webhook={"kartra"}
                />

                <ListTimeLine list={list_1} />
                <OnboardingImage src={onboarding_13_kartra_1} />

                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_13_kartra_2} />

                <ListTimeLine list={list_3} />
                <OnboardingImage src={onboarding_13_kartra_3} />

                <ListTimeLine list={list_4} />
                <OnboardingImage src={onboarding_13_kartra_4} />

                <ListTimeLine list={list_5} />

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"kartra"} />
                </Paragraph>

                <ListTimeLine list={list_6} />
                <OnboardingImage src={onboarding_13_kartra_5} />

                <ListTimeLine list={list_7} />

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"kartra"} />
                </Paragraph>

                <ListTimeLine list={list_8} />

                <NextButton
                    requireConfirmation={true}
                    slide={"7"}
                    modalText={"Click the checkbox to confirm that you have installed your webhook code on your Kartra Account"}
                    checkboxDescription={"Added Webhook Code To Click Funnels"}
                />
            </div>
        </div>
    );
};

const SamcartWebhook = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebhookDropdown />

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">SamCart</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"samcart_webhook"}
                    thumbnail_id={"058a6a2357e8494ea14504e4510dca93"}
                    dna_script={false}
                    webhook={"samcart"}
                />

                <NextButton
                    requireConfirmation={true}
                    slide={"7"}
                    modalText={"Click the checkbox to confirm that you have installed your webhook code on your Samcart Account"}
                    checkboxDescription={"Added Webhook Code To Samcart"}
                />
            </div>
        </div>
    );
};

const WoocommerceWebhook = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const list_1 = [
        { id: 1, text: "ROAS can get your sales data directly from WooCommerce so you don’t have to rely on outdated stuff like cookies or pixels." },
        { id: 2, text: "To set it up, we just need to paste your WebHook three times." },
        { id: 3, text: "Here’s your WooCommerce Webhook. You’re going to need to copy/paste it during this simple process." },
    ];

    const list_2 = [
        { id: 4, text: "Start in your wordpress admin panel by finding and clicking the WooCommerce link in the left hand menu." },
        { id: 5, text: "Then click “Settings” from within the WooCommerce menu that drops down:" },
    ];

    const list_3 = [{ id: 6, text: "Once you’re in the settings menu, click the “Advanced” tab:" }];

    const list_4 = [
        { id: 7, text: "Once you’re in the “Advanced” tab, click “Webhooks”." },
        { id: 8, text: "It’s kind of hidden, so see the screenshot below:" },
    ];

    const list_5 = [{ id: 9, text: "Once you’re in the “Webhooks” screen, click “Add Webhook”:" }];

    const list_6 = [
        { id: 10, text: "When you do, you’ll see this screen." },
        { id: 11, text: "We’re going to add the webhook to this screen THREE times." },
        { id: 12, text: "(All we’re doing is pasting it.)" },
    ];

    const list_7 = [
        { id: 13, text: "The only thing that will change is:" },
        { id: 14, text: "The name of each webhook." },
        { id: 15, text: "The “Topic” of each webhook." },
        { id: 16, text: "We always want to make sure we turn it on by selecting “Active”." },
        { id: 16, text: "The “Topic” is simply a dropdown menu option ...and you can name it whatever you want." },
        { id: 17, text: "Name it ROAS Order Created." },
        { id: 18, text: "Set it to Active in the “Status” Dropdown." },
        { id: 19, text: "Select “Order Created” from the “Topic” Dropdown." },
        { id: 20, text: "Paste your Webhook in the “Delivery URL” field." },
        { id: 21, text: "Click on Save Webhook." },
        { id: 22, text: "Like so:" },
        { id: 23, text: "Let’s get started." },
        { id: 24, text: "First, here’s your webhook:" },
    ];

    const list_8 = [
        { id: 25, text: "When you get to the webhook screen for your first webhook, do this:" },
        { id: 26, text: "Name it ROAS Order Created." },
        { id: 27, text: "Set it to Active in the “Status” Dropdown." },
        { id: 28, text: "Select “Order Created” from the “Topic” Dropdown." },
        { id: 29, text: "Paste your Webhook in the “Delivery URL” field." },
        { id: 30, text: "Click on Save Webhook." },
        { id: 31, text: "Like so:" },
    ];

    const list_9 = [
        { id: 32, text: "Now we want to do this a SECOND time." },
        { id: 33, text: "All we’re changing is:" },
        { id: 34, text: "The name of each webhook." },
        { id: 35, text: "The “Topic” of each webhook." },
        { id: 36, text: "We always want to make sure we turn it on by selecting “Active”." },
        { id: 37, text: "In case you need it, here’s your webhook:" },
    ];

    const list_10 = [
        { id: 38, text: "When you get to the webhook screen for your second webhook, do this:" },
        { id: 39, text: "Name it ROAS Order Updated." },
        { id: 40, text: "Set it to Active in the “Status” Dropdown." },
        { id: 41, text: "Select “Order Updated from the “Topic” Dropdown." },
        { id: 42, text: "Paste your Webhook in the “Delivery URL” field." },
        { id: 43, text: "Click on Save Webhook." },
        { id: 44, text: "Like so:" },
    ];

    const list_11 = [
        { id: 45, text: "Now we want to do this a THIRD (and final) time." },
        { id: 46, text: "All we’re changing is:" },
        { id: 47, text: "The name of each webhook." },
        { id: 48, text: "The “Topic” of each webhook." },
        { id: 49, text: "We always want to make sure we turn it on by selecting “Active”." },
        { id: 50, text: "In case you need it, here’s your webhook:" },
    ];

    const list_12 = [
        { id: 51, text: "When you get to the webhook screen for your third webhook, do this:" },
        { id: 52, text: "Name it ROAS Order Paid." },
        { id: 53, text: "Set it to Active in the “Status” Dropdown." },
        { id: 54, text: "Select “Order Updated from the “Topic” Dropdown." },
        { id: 55, text: "Paste your Webhook in the “Delivery URL” field." },
        { id: 56, text: "Click on Save Webhook." },
        { id: 57, text: "Like so:" },
    ];

    const list_13 = [
        { id: 58, text: "That’s all there is to it!" },
        { id: 59, text: "Sounds like a lot but it’s really just clicking a few buttons three times." },
        { id: 60, text: "Once again, here’s your WooCommerce webhook in case you need it." },
    ];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebhookDropdown />

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">Woocommerce</p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"woocommerce_webhook"}
                    thumbnail_id={"63647793eade4276aff8fe4c330d0abd"}
                    dna_script={false}
                    webhook={"woocommerce"}
                />

                <ListTimeLine list={list_1} />

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>

                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_12_woocommerce_1} />

                <ListTimeLine list={list_3} />
                <OnboardingImage src={onboarding_12_woocommerce_2} />

                <ListTimeLine list={list_4} />
                <OnboardingImage src={onboarding_12_woocommerce_3} />

                <ListTimeLine list={list_5} />
                <OnboardingImage src={onboarding_12_woocommerce_4} />

                <ListTimeLine list={list_6} />
                <OnboardingImage src={onboarding_12_woocommerce_5} />

                <ListTimeLine list={list_7} />

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>

                <ListTimeLine list={list_8} />
                <OnboardingImage src={onboarding_12_woocommerce_6} />

                <ListTimeLine list={list_9} />

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>

                <ListTimeLine list={list_10} />

                <OnboardingImage src={onboarding_12_woocommerce_7} />

                <ListTimeLine list={list_11} />

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>

                <ListTimeLine list={list_12} />

                <OnboardingImage src={onboarding_12_woocommerce_8} />
                <ListTimeLine list={list_13} />

                <Paragraph style={{ fontSize: 14 }}>
                    <ShoppingCartWebhook roas_shopping_cart={"woocommerce"} />
                </Paragraph>

                <NextButton
                    requireConfirmation={true}
                    slide={"7"}
                    modalText={"Click the checkbox to confirm that you have installed your webhook code on your Woocommerce Account"}
                    checkboxDescription={"Added Webhook Code To Woocommerce"}
                />
            </div>
        </div>
    );
};

const KeapWebhook = () => {
    const [user] = useAtom(user_atom);

    const list_1 = [{ id: 1, text: "First, connect to Keap/Infusionsoft." }];

    const list_2 = [{ id: 2, text: "Next select which Keap/Infusionsoft account you want to create and click “Allow”." }];

    const list_3 = [
        {
            id: 3,
            text: "If you’re processing orders through Keap/Infusionsoft ...but your actual order forms are powered by another provider such as Clickfunnels, this is all you need to do.",
        },
        { id: 4, text: "If you’re using Keap/Infusionsoft’s HOSTED ORDER FORMS, you’ll also need to do these steps:" },
        { id: 5, text: "Log into your Keap account and select “E-Commerce Setup” from the main menu." },
    ];

    const list_4 = [{ id: 6, text: "From the E-Commerce Setup menu, select “Order Forms”" }];

    const list_5 = [
        { id: 7, text: "From the “order Forms” menu, select the order form you want to track." },
        { id: 8, text: "NOTE: You’ll want to do this for ALL order forms." },
    ];

    const list_6 = [{ id: 9, text: "Next, select “HTML” areas from the top of that order form’s menu." }];

    const list_7 = [
        { id: 10, text: "Next, paste your tracking code in the “Custom Header” area and then click the blue “SAVE” button." },
        { id: 11, text: "Here’s your tracking code in case you need it:" },
    ];

    const list_8 = [
        { id: 12, text: "Click “Save All” for good measure." },
        { id: 13, text: "You are now integrated with Infusionsoft/Keap" },
        {
            id: 14,
            text: "REMEMBER: You don’t need to paste the code on the order forms unless you’re actually using Infusionsoft’s HOSTED ORDER FORMS.",
        },
    ];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebhookDropdown />

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">
                                Keap / Infusionsoft
                            </p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"keap_webhook"}
                    thumbnail_id={"e4ee7543f925458b95638e2fbb8d1070"}
                    dna_script={false}
                    webhook={false}
                />

                <Paragraph className="text-base">
                    (Even if your order forms are hosted on Clickfunnels ...but you actually process the sales through the Infusionsoft Gateway, use
                    this.)
                </Paragraph>

                <ListTimeLine list={list_1} />

                <div className="connect_to_fb_container">
                    <a
                        className="connect_to_fb_button"
                        href={`https://us-central1-roas-e44b8.cloudfunctions.net/oauth/auth/keap/?user_id=${user.uid}`}
                        target="_blank"
                    >
                        <div className="text">Click here to open the connection window in a new tab</div>
                    </a>
                </div>

                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_9_keap_1} />

                <ListTimeLine list={list_3} />
                <OnboardingImage src={onboarding_9_keap_2} />

                <ListTimeLine list={list_4} />
                <OnboardingImage src={onboarding_9_keap_3} />

                <ListTimeLine list={list_5} />
                <OnboardingImage src={onboarding_9_keap_4} />

                <ListTimeLine list={list_6} />
                <OnboardingImage src={onboarding_9_keap_5} />

                <ListTimeLine list={list_7} />
                <OnboardingImage src={onboarding_9_keap_6} />

                <TrackingCodeSnippetSection />

                <OnboardingImage src={onboarding_9_keap_6} />

                <ListTimeLine list={list_8} />

                <NextButton
                    requireConfirmation={true}
                    slide={"7"}
                    modalText={"Click the checkbox to confirm that you have connected your Keap Account"}
                    checkboxDescription={"Connected Keap Account"}
                />
            </div>
        </div>
    );
};

const NotSupportedShoppingCart = () => {
    const [user] = useAtom(user_atom);
    const [inputValue, setInputValue] = useState("");

    const onInputChange = ({ target: { value } }) => {
        setInputValue(value);
        set_project(user.uid, { unsupported_shopping_cart_name: pipe(toLower, (str) => str.replace(/\s/g, ""))(value) });
    };

    const onBlur = ({ target: { value } }) => {
        set_project(user.uid, { unsupported_shopping_cart_name: pipe(toLower, (str) => str.replace(/\s/g, ""))(value) });
    };

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <WebhookDropdown />
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl pb-[20px]">:(</p>
                            <p className="text-base text-gray-400 tracking-wide uppercase mb-[10px]">
                                Let us know what software you use to process payments and host your shopping cart forms
                            </p>
                        </div>
                    </div>
                </div>

                <div className="input_container ">
                    <input
                        onChange={onInputChange}
                        type="text"
                        name="email"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-solid border-gray-300 rounded-md min-h-[40px] pl-[10px] mb-[20px]"
                        placeholder="Shopping Cart Platform"
                        value={inputValue}
                        onBlur={onBlur}
                    />
                </div>

                <NextButton
                    requireConfirmation={true}
                    slide={"5"}
                    modalText={"Click the checkbox to confirm that you have installed your tracking code on your Samcart Page"}
                    checkboxDescription={"Added Tracking Code To Samcart"}
                />
            </div>
        </div>
    );
};

const SelectShoppingCart = () => {
    const [user] = useAtom(user_atom);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);
    const [slider, setSlider] = useAtom(slider_atom);

    const onSelectShoppingCart = (roas_shopping_cart) => {
        from(set_project(user.uid, { shopping_cart_id: roas_shopping_cart, shopping_cart_name: roas_shopping_cart })).subscribe(() => {
            setOnboarding({ ...onboarding, roas_shopping_cart });
            setSlider({ ...slider, slide: "6" });
        });
    };

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4  sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">Step 4</h2>

                            <p className="mt-1 text-5xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">
                                Add The WebHook To Your Shopping Cart
                            </p>

                            <p className="max-w-xl mt-5 mx-auto text-l text-gray-500">
                                When we say “Shopping Cart”, we just mean “The thing that powers your order forms.”
                            </p>

                            <p className="max-w-xl mt-5 mx-auto text-l text-gray-500">
                                Instead of relying on Pixels and other outdated tech that doesn’t work, ROAS tracks orders directly from your order
                                forms ...so there’s no depending on cookies or pixels for real tracking.
                            </p>

                            <p className="max-w-xl mt-5 mx-auto text-l text-gray-500">
                                This little step takes a maximum of three minutes, but it makes it so that your tracking is VERY accurate.Select your
                                Shopping Cart/Order Form Provider from the options below:
                            </p>
                        </div>
                    </div>
                </div>

                <div className="text-gray-900 bg-white border border-solid border-[3px] border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white w-full">
                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 rounded-t-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                            onClick={() => onSelectShoppingCart("clickfunnels")}
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Click Funnels
                        </label>
                        <img className="absolute right-[20px] w-[100px] -mr-[3px]" src={ClickfunnelsLogo} width={70} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectShoppingCart("shopify")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Shopify
                        </label>
                        <img className="absolute right-[20px] w-[80px]" src={ShopifyLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectShoppingCart("woocommerce")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Woocommerce
                        </label>
                        <img className="absolute right-[20px] w-[110px]" src={WoocommerceLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectShoppingCart("kartra")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Kartra
                        </label>
                        <img className="absolute right-[20px] w-[90px] -mr-[4px]" src={KartraLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectShoppingCart("samcart")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            SamCart
                        </label>
                        <img className="absolute right-[20px] w-[90px] mr-[3px]" src={SamcartLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-solid border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectShoppingCart("keap")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Keap
                        </label>
                        <img className="absolute right-[20px] w-[60px] mr-[3px]" src={KeapLogo} />
                    </div>

                    <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-gray-200 rounded-b-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                        <input
                            onClick={() => onSelectShoppingCart("none")}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 cursor-pointer"
                        ></input>
                        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Don't see my Shopping Cart
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

const WebhookInstructions = () => {
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);

    let components = {
        "": Empty,
        clickfunnels: ClickfunnelsWebhook,
        shopify: ShopifyWebhook,
        woocommerce: WoocommerceWebhook,
        kartra: KartraWebhook,
        samcart: SamcartWebhook,
        keap: KeapWebhook,
        none: NotSupportedShoppingCart,
    };

    let Component = components[onboarding.roas_shopping_cart];

    return <Component />;
};

const AddTrackingToAds = () => {
    const [app, setApp] = useAtom(app_atom);
    const [expanded, setExpanded] = React.useState(false);

    const list_1 = [
        { id: 1, text: "You’re going to want to use this on ALL of the URLs in ALL of the ads you’re running traffic to so we can track them." },
        { id: 2, text: "Here’s how to do it." },
        { id: 3, text: "Log into your Facebook ads manager." },
        { id: 4, text: "Select the campaigns, ad sets, and ads you want to edit." },
        { id: 5, text: "In the ads section we’re going to setup UTM parameters." },
        {
            id: 6,
            text: "Edit the ads that you will be, or are already running traffic to. You can use Facebook’s “Bulk Editing” feature by selecting multiple ads and clicking “edit”.",
        },
        { id: 7, text: "Under the “Destination” area look for Build a URL parameter underneath Website URL." },
        { id: 8, text: "Click the “Build URL Parameter” link that’s right below the field where your website goes." },
        { id: 9, text: "When you do, this will pop up:" },
    ];

    const list_2 = [
        { id: 8, text: "Click the “Build URL Parameter” link that’s right below the field where your website goes." },
        { id: 9, text: "When you do, this will pop up:" },
    ];

    const list_3 = [
        { id: 10, text: "Click the “Add Parameter” button as shown above." },
        { id: 11, text: "When you do, you’ll see options appear under a heading called “Custom Parameters”." },
    ];

    const list_4 = [{ id: 12, text: "When you click on the “value” box on the right hand side, a drop down menu will appear:" }];

    const list_5 = [
        { id: 13, text: "When it does, select {{ad.id}} as shown." },
        { id: 14, text: "When you do this, notice how you’ll see the parameter in the URL Preview section:" },
    ];

    const list_6 = [
        { id: 15, text: "Verify that you see the parameter in the URL preview as shown above, and then click “apply." },
        { id: 16, text: "Assuming you’ve done this to ALL ads you’re wanting to track, you’ve completed this step." },
    ];

    return (
        <div className="slide_content_container">
            <div className="inner_content">
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-[10px] sm:pb-[24px] sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase mb-[10px]">Step 5</h2>
                            <p className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">
                                Add The Tracking To Your Ads.
                            </p>
                        </div>
                    </div>
                </div>

                <VideoCardElm
                    title="Watch a video walkthorugh of this step here"
                    modal_id={"add_tracking_to_your_ads_video"}
                    thumbnail_id={"4662986a0cc24f50923020f4e1c51ab1"}
                    dna_script={false}
                    webhook={false}
                />

                <Paragraph className="text-base">Now we’re going to add one little snippet to the URLs in your Facebook ads.</Paragraph>
                <Paragraph className="text-base">Here’s the snippet itself:</Paragraph>
                <Paragraph className="text-xl flex flex-col items-center justify-center">
                    <Text code={true}>{`fb_id={{ad.id}}`}</Text>
                </Paragraph>

                <ListTimeLine list={list_1} />
                <OnboardingImage src={FbUrlParamaterImgOne} />

                <ListTimeLine list={list_2} />
                <OnboardingImage src={onboarding_1_fb_2} />

                <ListTimeLine list={list_3} />

                <Paragraph className="text-base">
                    On the left hand side, type <Text className="text-lg" code={true}>{`fb_id`}</Text>.
                </Paragraph>

                <OnboardingImage src={onboarding_1_fb_3} />

                <ListTimeLine list={list_4} />
                <OnboardingImage src={onboarding_1_fb_4} />

                <ListTimeLine list={list_5} />
                <OnboardingImage src={onboarding_1_fb_5} />

                <ListTimeLine list={list_6} />

                <NextButton
                    requireConfirmation={true}
                    slide={"0"}
                    modalText={"Click the checkbox to confirm that you have completed all the steps"}
                    checkboxDescription={"I'm All Set, Show Me My Stats!"}
                />
            </div>
        </div>
    );
};

const Slider = ({ fullpageApi }) => {
    const [slider, setSlider] = useAtom(slider_atom);
    const [confirmationModal, setConfirmationModal] = useAtom(confirmation_modal_atom);

    useEffect(() => {
        if (slider && fullpageApi) {
            fullpageApi.moveTo(1, slider.slide);
        }
    }, [slider, fullpageApi]);

    return (
        <ReactFullpage.Wrapper>
            {confirmationModal.is_open && <ConfirmationModal />}
            <div className="section">
                <div className="slide" data-anchor="1">
                    <div className="slide_container">
                        <ConnectToFacebook />
                    </div>
                </div>
                <div className="slide" data-anchor="2">
                    <div className="slide_container">
                        <SelectFacebookAdAccount />
                    </div>
                </div>
                <div className="slide" data-anchor="3">
                    <div className="slide_container">
                        <SelectTrackingHost />
                    </div>
                </div>
                <div className="slide" data-anchor="4">
                    <div className="slide_container">
                        <TrackingScriptInstructions />
                    </div>
                </div>
                <div className="slide" data-anchor="5">
                    <div className="slide_container">
                        <SelectShoppingCart />
                    </div>
                </div>
                <div className="slide" data-anchor="6">
                    <div className="slide_container">
                        <WebhookInstructions />
                    </div>
                </div>
                <div className="slide" data-anchor="7">
                    <div className="slide_container">
                        <AddTrackingToAds />
                    </div>
                </div>
            </div>
        </ReactFullpage.Wrapper>
    );
};

const Onboarding = () => {
    const [user] = useAtom(user_atom);
    const [slider, setSlider] = useAtom(slider_atom);
    const [onboarding, setOnboarding] = useAtom(onboarding_atom);
    let navigate = useNavigate();

    const resetOnboarding = () => {
        let reset_values = {
            current_slide: "2",
            roas_has_seen_finish_setup_video: false,
            roas_has_facebook_connected: true,
            roas_facebook_ad_account_id: 0,
            roas_tracking_host: "",
            roas_tracking_host_confirmed: false,
            roas_shopping_cart: "",
            roas_shopping_cart_confirmed: false,
            roas_ad_tracking_confirmed: false,
        };

        let account_ref = doc(db, "accounts", user.uid);
        from(setDoc(account_ref, reset_values, { merge: true })).subscribe(() => {
            localStorage.removeItem("onboarding");
            console.log("onboarding reset");
        });
    };

    const queryDocs = (snapshot) => snapshot.docs.map((doc) => ({ ...doc.data(), doc_id: doc.id }));

    const getOnboardingStateFromDb = () => {
        return from(getDocs(query(collection(db, "accounts"), where("roas_user_id", "==", user.uid)))).pipe(
            rxmap(queryDocs),
            rxmap(head),
            rxfilter((value) => size(value) > 0),
            rxmap((value) => {
                let {
                    current_slide = "1",
                    roas_has_seen_finish_setup_video = false,
                    roas_has_facebook_connected = false,
                    roas_facebook_ad_account_id = 0,
                    roas_tracking_host = "",
                    roas_tracking_host_confirmed = false,
                    roas_shopping_cart = "",
                    roas_shopping_cart_confirmed = false,
                    roas_ad_tracking_confirmed = false,
                } = value;

                return {
                    current_slide,
                    roas_has_seen_finish_setup_video,
                    roas_has_facebook_connected,
                    roas_facebook_ad_account_id,
                    roas_tracking_host,
                    roas_tracking_host_confirmed,
                    roas_shopping_cart,
                    roas_shopping_cart_confirmed,
                    roas_ad_tracking_confirmed,
                };
            }),
            defaultIfEmpty(onboarding)
        );
    };

    const getOnboardingState = () => {
        // return rxof(getOnboardingStateFromLocalStorage())

        return getOnboardingStateFromDb()
            .pipe
            // tap((value) => {
            //     console.log("onboardingfromdb");
            //     console.log(value);
            // }),
            // rxfilter((value) => size(value) > 0),
            // tap((value) => setOnboarding(value)),
            // defaultIfEmpty(undefined),
            // concatMap(getOnboardingStateFromDb),
            // rxmap((value) => (value.source ? identity : rxof(value))),
            // concatMap(identity),
            // rxfilter((value) => size(value) > 0)
            // defaultIfEmpty(onboarding)
            // tap((value) => setOnboarding(value))
            ();
    };

    useEffect(() => {
        getOnboardingStateFromDb().subscribe((data) => {
            setOnboarding(data);
            setSlider({ ...slider, slide: data.current_slide });
        });
    }, []);

    // resetOnboarding();

    const update_user_intercom = (meta) => {
        console.log("update_user_intercom");
        if (user && !user.is_admin) {
            let intercom_defaults = {
                api_base: "https://api-iam.intercom.io",
                app_id: "wfjbdiuj",
                roas_email: user.roas_email,
                email: user.roas_email,
                roas_user_id: user.roas_user_id,
                user_id: user.roas_user_id,
            };

            window.Intercom("update", { ...intercom_defaults, ...meta });
        }
    };

    useEffect(() => {
        if (slider.slide && slider.first_render == false) {
            let payload = { ...onboarding, current_slide: slider.slide };
            update_user_intercom(onboarding);
            set_user_account(user.uid, payload);
        } else {
            update_user_intercom(onboarding);
            setSlider({ ...slider, first_render: false });
        }
    }, [slider.slide]);

    return (
        <div className="onboarding_three_view">
            <ReactFullpage
                licenseKey={"KJ6JH-71PN9-6H527-33WJK-RXULP"}
                scrollHorizontally={true}
                scrollingSpeed={1000}
                controlArrows={false}
                fitToSection={true}
                render={({ state, fullpageApi }) => (
                    <div className="onboarding_container">
                        <SlideTabs fullpageApi={fullpageApi} />
                        <Slider fullpageApi={fullpageApi} />
                    </div>
                )}
            />
        </div>
    );
};

export default Onboarding;
