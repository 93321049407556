import React from "react";
import { useAtom } from "jotai";
import { user_atom } from "../state/auth";
import copy from "copy-to-clipboard";

const ShoppingCartWebhook = ({ roas_shopping_cart }) => {
    const [user] = useAtom(user_atom);

    const onCopySnippetToClipboard = () => {
        if (roas_shopping_cart == "") return;
        let url = `https://us-central1-roas-e44b8.cloudfunctions.net/app/${roas_shopping_cart}/${user.uid}`;
        copy(url);
    };

    if (roas_shopping_cart == "clickfunnels") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/clickfunnels/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "shopify") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/shopify/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    // if (userAccount.roas_shopping_cart == "kajabi") {
    //     return <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/kajabi/${user.uid}`}</div>;
    // }

    if (roas_shopping_cart == "woocommerce") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/woocommerce/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "thrivecart") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/thrivecart/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "kartra") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/kartra/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "keap") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/keap/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    if (roas_shopping_cart == "samcart") {
        return (
            <div className="webhooks_container text-xs">
                <div className="title">Shopping Cart Webhook URL</div>
                <div className="webhooks_url_container">{`https://us-central1-roas-e44b8.cloudfunctions.net/app/samcart/${user.uid}`}</div>
                <div className="copy_code_snippet_to_clipboard_container" onClick={onCopySnippetToClipboard}>
                    Copy To Clipboard
                </div>
            </div>
        );
    }

    return <div className="webhooks_url_container">Select a shopping cart first to get your webhook url</div>;
};

export default ShoppingCartWebhook;
