import React, { useEffect } from "react";
import { BarChartOutlined } from "@ant-design/icons";
import AudiencesTopNav from "../components/AudiencesTopNav";
import { interests_atom, audience_view_state_atom } from "../state/audiences";
import { db } from "../firebase";
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { from, map as rxmap } from "rxjs";
import { useAtom } from "jotai";
import { user_atom } from "../state/auth";
import { lomap, losortby, toFixed, lokeyby } from "../../utilities/helpers";
import { pipe, prop, defaultTo, head, pick, reverse } from "ramda";
import { toNumber } from "lodash";
import { get, matching, mod, all } from "shades";
import ScreenModal from "../components/ScreenModal";
import InterestInsights from "../components/InterestInsights";
import { app_atom } from "../state/app";

const Audiences = () => {
    const [user, setUser] = useAtom(user_atom);
    const [interests, setInterests] = useAtom(interests_atom);
    const [audienceViewState, setAudienceViewState] = useAtom(audience_view_state_atom);
    const [app, setApp] = useAtom(app_atom);

    useEffect(() => {
        let interests_query = query(collectionGroup(db, "interests"), where("user_id", "==", user.uid));

        from(getDocs(interests_query))
            .pipe(
                rxmap((snapshot) => snapshot.docs.map((doc) => doc.data())),
                rxmap(lokeyby("interest_id"))
            )
            .subscribe((interests_db_results) => {
                console.log("interests_db_results");
                console.log(interests_db_results);
                setInterests({ ...interests, ...interests_db_results });
            });
    }, []);

    const onSelectInterst = (interest_id) => {
        let interest = pipe(get(matching({ interest_id })))(interests);
        setAudienceViewState({ ...audienceViewState, selected_interest_id: interest_id, selected_interest: interest });
        setApp({ ...app, modal_is_open: true, modal_id: "interest_insights" });
    };

    return (
        <div className="audiences_view">
            <ScreenModal id="interest_insights">
                <InterestInsights />
            </ScreenModal>

            <AudiencesTopNav />

            <div className="audiences_finder_container">
                <div className="audience_table_container">
                    <div className="table_container">
                        <div className="audience_table_tabs">
                            <div className="tab_cell">Name</div>
                            <div className="tab_cell">Size</div>
                            <div className="tab_cell">Male</div>
                            <div className="tab_cell">Female</div>
                            <div className="tab_cell">Age</div>
                            <div className="tab_cell"></div>
                        </div>
                        {lomap(
                            (interest, interest_id) => (
                                <div className="audience_row" key={interest_id}>
                                    <div className="audience_cell name_audience_cell" onClick={() => onSelectInterst(interest_id)}>
                                        {interest.interest_name}
                                    </div>
                                    <div className="audience_cell">
                                        {pipe(prop("audience_size"), Number, (value) => value.toLocaleString())(interest)}
                                    </div>
                                    <div className="audience_cell">
                                        {pipe(
                                            get("gender", matching({ name: "Male" })),
                                            defaultTo([]),
                                            head,
                                            defaultTo({}),
                                            prop("value"),
                                            defaultTo(0)
                                        )(interest)}
                                        %
                                    </div>
                                    <div className="audience_cell">
                                        {pipe(
                                            get("gender", matching({ name: "Female" })),
                                            defaultTo([]),
                                            head,
                                            defaultTo({}),
                                            prop("value"),
                                            defaultTo(0)
                                        )(interest)}
                                        %
                                    </div>
                                    <div className="audience_cell">
                                        {pipe(
                                            get("gender_age"),
                                            defaultTo([]),
                                            losortby("percentage_total"),
                                            reverse,
                                            head,
                                            defaultTo({}),
                                            pick(["product", "percentage_total"]),
                                            (value) => (
                                                <div className="age_row">
                                                    <div className="age">{value.product || 0}</div>
                                                    <div className="percentage">
                                                        {pipe(prop("percentage_total"), toNumber, toFixed(3), defaultTo(0))(value)}%
                                                    </div>
                                                </div>
                                            )
                                        )(interest)}
                                    </div>
                                    <div className="actions">
                                        <div className="stats" onClick={() => onSelectInterst(interest_id)}>
                                            <BarChartOutlined />
                                        </div>
                                    </div>
                                </div>
                            ),
                            interests
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Audiences;
