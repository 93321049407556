import { atom } from "jotai";

let account_assets = {
    campaigns: {
        campaign_1: {
            id: "campaign_1",
            name: "Test Campaign",
            adsets: {
                adset_1: {
                    id: "adset_1",
                    name: "Test Adset",
                    ads: {
                        ad_1: {
                            id: "ad_1",
                            name: "Test Ad",
                        },
                        ad_2: {
                            id: "ad_2",
                            name: "Test Ad 2",
                        },
                        ad_3: {
                            id: "ad_3",
                            name: "Test Ad 3",
                        },
                    },
                },
                adset_2: {
                    id: "adset_2",
                    name: "Test Adset",
                    ads: {
                        ad_1: {
                            id: "ad_1",
                            name: "Test Ad",
                        },
                    },
                },
            },
        },
    },
};

export const ad_launcher_initial_state = {
    campaign_id: "",
    buying_type: "AUCTION",
    budget_type: "abo",
};

export const ad_launcher_view_state_atom = atom({
    view_id: "campaign",
});
export const ad_launcher_left_nav_atom = atom({});
export const ad_launcher_asset_atom = atom(ad_launcher_initial_state);
