import React, { useEffect } from "react";
import { user_atom } from "../state/auth";
import { projects_atom } from "../state/projects";
import { useAtom } from "jotai";
import { from, map as rxmap } from "rxjs";
import { db } from "../firebase";
import { getDoc, getDocs, collection, query, where } from "firebase/firestore";
import { pipe } from "ramda";
import { lomap } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ProjectCard = ({ project }) => {
    const navigate = useNavigate();

    const onViewProjectClick = () => {
        navigate(`/projects/${project.project_id}/ad_account`);
    };

    return (
        <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            <div className="w-full flex flex-col items-start justify-start p-4">
                <div className="folder_icon_container mb-[30px] bg-blue-600 p-[4px] rounded">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 fill-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="0"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                        />
                    </svg>
                </div>
                <div className="project_ad_account_info_container flex flex-row flex-wrap mb-[3px] font-bold text-gray-800 text-sm">
                    <div className="label mr-[10px]">Ad Account ID:</div>
                    <div className="ad_account_id">{project.fb_ad_account_id}</div>
                </div>

                {project.created_at && (
                    <div className="project_ad_account_info_container flex flex-row mb-[3px] text-sm text-gray-400">
                        <div className="label mr-[10px]">Created:</div>
                        <div className="ad_account_id">{moment(project.created_at, "x").format("MM/DD/YYYY")}</div>
                    </div>
                )}
            </div>
            <div>
                <div className="-mt-px flex divide-x divide-gray-200 border-t-[1px] border-gray-100 border-solid">
                    <div className="w-0 flex-1 flex">
                        <div
                            className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-500 font-medium border border-transparent rounded-bl-lg rounded-br-lg  cursor-pointer hover:bg-blue-600 hover:text-white"
                            onClick={onViewProjectClick}
                        >
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>

                            <span className="ml-3">Edit</span>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};

const Projects = () => {
    const [user] = useAtom(user_atom);
    const [projects, setProjects] = useAtom(projects_atom);

    useEffect(() => {
        let projects_query = from(getDocs(query(collection(db, "projects"), where("roas_user_id", "==", user.uid)))).pipe(
            rxmap((snapshot) => snapshot.docs.map((doc) => ({ ...doc.data(), doc_id: doc.id })))
        );

        projects_query.subscribe((projects) => {
            setProjects(projects);
        });
    }, []);

    return (
        <div className="bg-white">
            <div className="py-[16px] sm:py-[24px] px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">ROAS</h2>
                    <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Accounts</p>
                </div>
                <div className="pb-5 border-b border-gray-200 border-solid"></div>
            </div>

            <ul role="list" className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 px-4 sm:px-6 lg:px-8 ">
                {pipe(lomap((project) => <ProjectCard key={project.doc_id} project={project} />))(projects)}
            </ul>
        </div>
    );
};

export default Projects;
