import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { db } from "../firebase";
import { getDoc, query, doc } from "firebase/firestore";
import ConditionsUneditable from "./ConditionsUneditable";
import RuleActionUneditable from "./RuleActionUneditable";
import RuleScopeUneditable from "./RuleScopeUneditable";

const RuleUneditable = ({ rule }) => {
    return (
        <div className="rule_container">
            <div className="rule_scope_action_container">
                <RuleScopeUneditable rule={rule} />
                <RuleActionUneditable rule={rule} />
            </div>
            <ConditionsUneditable rule={rule} />
        </div>
    );
};

export default RuleUneditable;
