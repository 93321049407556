import React from "react";
import { map, flip, lensPath, set, pluck, values, product } from "ramda";
import { map as lodashmap, size } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useAtom } from "jotai";
import { rule_atom } from "../state/rule";
import styled from "styled-components";
import uniqid from "uniqid";
import AdsManagerExpression from "./AdsManagerExpression";

const lomap = flip(lodashmap);

const ExpressionsContainer = styled.div`
    grid-template-columns: ${({ numOfTotalExpressions }) => (numOfTotalExpressions > 1 ? "30px auto" : "auto")};
`;

const ExpressionsGrid = styled.div`
    grid-column: ${({ numOfTotalExpressions }) => (numOfTotalExpressions > 1 ? "2/2" : "1/1")};
`;

const AdsManagerExpressions = ({ condition }) => {
    const [rule, setRule] = useAtom(rule_atom);

    let numOfTotalExpressions = product(values(map(size, pluck("expressions", rule.conditions))));
    let numOfExpressions = size(condition.expressions);

    const onAddCondition = () => {
        console.log("onAddCondition");

        let condition_id = condition.id;
        let expression_id = uniqid();

        let path = ["conditions", condition_id, "expressions", expression_id];
        let condition_path = lensPath(path);

        let payload = {
            id: expression_id,
            condition_id,
            path,
            metric: "",
            timeframe: "",
            predicate: "",
            value: 0,
        };

        setRule(set(condition_path, payload, rule));
    };

    const AddCondition = () => (
        <div className="add_and_condition">
            <Button type="dashed" icon={<PlusOutlined />} onClick={onAddCondition}>
                Filter
            </Button>
        </div>
    );

    return (
        <ExpressionsContainer className="expressions_container" numOfTotalExpressions={numOfTotalExpressions}>
            {numOfExpressions > 1 && (
                <div className="expressions_operand_bar">
                    <div className="background">
                        <div className="text">AND</div>
                    </div>
                </div>
            )}

            <ExpressionsGrid className="expressions_grid" numOfTotalExpressions={numOfTotalExpressions}>
                {lomap(
                    (expression, key) => (
                        <AdsManagerExpression key={key} expression={expression} />
                    ),
                    condition.expressions
                )}

                <div className="group_actions_container">
                    <AddCondition />
                </div>
            </ExpressionsGrid>
        </ExpressionsContainer>
    );
};

export default AdsManagerExpressions;
