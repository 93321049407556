import React from "react";
import { flip } from "ramda";
import { map as lodashmap } from "lodash";
import { useAtom } from "jotai";
import { actions_atom, rule_atom, rule_state } from "../state/rule";

const lomap = flip(lodashmap);

const ActionsDropdown = () => {
    const [rule, setRule] = useAtom(rule_atom);
    const [actionsDropdown] = useAtom(actions_atom);
    const [ruleState, setRuleState] = useAtom(rule_state);

    const onSelectAction = (text, key) => {
        setRule({ ...rule, action: { text, value: key }, budget: { type: "amount", value: 0 } });
        setRuleState({ ...ruleState, dropdown_is_open: false });
    };

    return (
        <div className="actions_dropdown">
            {lomap(
                (value, key) => (
                    <div className="metric_field" key={key} onClick={() => onSelectAction(value, key)}>
                        {value}
                    </div>
                ),
                actionsDropdown
            )}
        </div>
    );
};

export default ActionsDropdown;
