import React from "react";
import { Link } from "react-router-dom";

const AudiencesTopNav = () => {
    return (
        <div className="tabs">
            <Link to="/audiences" className="tab_container">
                <div className="text">Audiences</div>
            </Link>
            <Link to="/interests-search" className="tab_container">
                <div className="text">Finder</div>
            </Link>
        </div>
    );
};

export default AudiencesTopNav;
