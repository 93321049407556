import React from "react";

const Cookies = () => {
    return (
        <div className="terms_view_container">
            <iframe
                src="https://app.termly.io/document/cookie-policy/e0dbbc6b-2600-4949-9442-49910f78bcda"
                frameBorder="0"
                style={{ position: "absolute", right: 0, left: 0, bottom: 0, top: 0, width: "100%", height: "100%" }}
            ></iframe>
        </div>
    );
};

export default Cookies;
