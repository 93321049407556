import { atom } from "jotai";
import uniqid from "uniqid";

const rule_id = uniqid();
const condition_id = uniqid();
const expression_id = uniqid();

export const rules_atom = atom([]);

export const sensible_rule_default = () => {
    return {
        id: uniqid(),
        action: { value: "", text: "" },
        scope: "campaigns",
        schedule: { amount: 15, unit: "minutes" },
        action: { value: "pause", text: "Pause" },
        last_checked: null,
        status: "paused",
        conditions: {},
        selected_ids: [],
    };
};

export const rule_state = atom({
    dropdown_is_open: false,
    scope_dropdown_is_open: false,
    budget_types_dropdown_is_open: false,
    is_new: false,
});

export const rule_atom = atom(sensible_rule_default());

const actions = {
    // start: "Start",
    pause: "Pause",
    // delete: "Delete",
    // duplicate: "Duplicate",
    // notify: "Notify",
    increase_budget: "Increase Budget",
    decrease_budget: "Decrease Budget",
    // set_budget: "Set Budget",
};

export const actions_atom = atom(actions);

const scopes = {
    campaigns: "Campaigns",
    adsets: "Adsets",
    ads: "Ads",
};

export const scopes_atom = atom(scopes);

const budget_types = {
    percent: "Percent",
    amount: "Amount",
};

export const budget_types_atom = atom(budget_types);

const schedule = {
    15: { amount: 15, unit: "minutes", type: "frequency" },
    30: { amount: 30, unit: "minutes", type: "frequency" },
    60: { amount: 60, unit: "minutes", type: "frequency" },
    180: { amount: 3, unit: "hours", type: "frequency" },
    360: { amount: 6, unit: "hours", type: "frequency" },
    720: { amount: 12, unit: "hours", type: "frequency" },
    1440: { amount: 24, unit: "hours", type: "frequency" },
    2160: { amount: 36, unit: "hours", type: "frequency" },
    4320: { amount: 72, unit: "hours", type: "frequency" },
};

export const schedule_state = atom({ dropdown_is_open: false });
export const schedule_atom = atom(schedule);

const attribution = {
    "7dc1dv": "7-day click and 1-day view",
    "1dc1dv": "1-day click and 1-day view",
    "7dc": "7-day click",
    "1dc": "1-day click",
    "1dv": "1-day view",
};

export const attribution_state = atom({ dropdown_is_open: false });
export const attribution_atom = atom(attribution);

export const rule_assets_atom = atom({});

export const rule_logs_atom = atom([]);
export const filted_rule_logs_atom = atom([]);
