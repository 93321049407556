import React from "react";

const Privacy = () => {
    return (
        <div className="terms_view_container">
            <iframe
                src="https://app.termly.io/document/privacy-policy/4f0ce52d-7a77-4587-a4d5-131abf3bec24#othersources"
                frameBorder="0"
                style={{ position: "absolute", right: 0, left: 0, bottom: 0, top: 0, width: "100%", height: "100%" }}
            ></iframe>
        </div>
    );
};

export default Privacy;
