import React, { useEffect } from "react";
import { Button, Carousel } from "antd";
import { CloseCircleOutlined, UserOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { advertisers_view_state_atom, advertiser_atom } from "../state/advertisers";
import { app_atom } from "../state/app";
import { lomap, toFixed, loreduce } from "../../utilities/helpers";
import { prop, defaultTo, pipe, head, pick, reverse, values, isEmpty } from "ramda";
import { mod, all } from "shades";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie } from "recharts";
import { PieChart, Pie, Sector } from "recharts";
import { loreduce, toFixed } from "../../utilities/helpers";
import { db, httpsCallable, functions } from "../firebase";
import { from, map as rxmap } from "rxjs";

const pageFn = httpsCallable(functions, "page");

const FacebookAdMock = ({ ad }) => {
    return (
        <div className="fb_ad_outline_container">
            <div className="page_header_container">
                <div className="left_adjusted">
                    <div className="page_avatar_container">
                        <img className="avatar" src={ad.advertiser_logo_url} alt="" />
                    </div>
                    <div className="page_info_container">
                        <div className="page_name">{ad.advertiser_name}</div>
                        <div className="post_type_container">Sponsored</div>
                    </div>
                </div>
                <div className="right_adjusted">
                    <div className="actions_container">
                        <EllipsisOutlined />
                    </div>
                </div>
            </div>
            {/* <div className="ad_headline_container">test headline here</div> */}
            <div className="ad_text_container">
                <div dangerouslySetInnerHTML={{ __html: ad.body }} />
                {/* {ad.body} */}
            </div>
            <div className="ad_content_container">
                <div className="content_container">
                    <div className="ad_image_container">
                        {ad.material_type == 1 && <img src={ad.preview_image_url} style={{ width: "100%" }} />}

                        {ad.material_type == 2 && (
                            <video controls={true} style={{ width: "100%" }}>
                                <source src={pipe(prop("resources_url"), head)(ad)} type="video/mp4" />
                            </video>
                        )}

                        {ad.material_type == 3 && (
                            <Carousel dots={true}>
                                {lomap(
                                    (image_url) => (
                                        <img src={image_url} style={{ width: "100%" }} />
                                    ),
                                    ad.resources_url
                                )}
                            </Carousel>
                        )}
                    </div>
                </div>
            </div>
            <div className="ad_actions_container">
                <div className="left_adjusted">
                    <div className="link">https://roas.org</div>
                    <div className="link_text">Headline copy here</div>
                </div>
                <div className="right_adjusted">
                    <div className="action_button">Learn More</div>
                </div>
            </div>
        </div>
    );
};

const AdvertiserInsights = () => {
    console.log("AdvertiserInsights");
    const [app, setApp] = useAtom(app_atom);
    const [advertisersViewState, setAdvertisersViewState] = useAtom(advertisers_view_state_atom);
    const [advertiser, setAdvertiser] = useAtom(advertiser_atom);

    useEffect(() => {
        if (!isEmpty(advertisersViewState.selected_advertiser)) {
            console.log("advertisersViewState");
            console.log(advertisersViewState);

            from(pageFn({ page_id: advertisersViewState.selected_advertiser_id }))
                .pipe(rxmap(prop("data")))
                .subscribe((data) => {
                    console.log("data");
                    console.log(data);

                    let ads = pipe(prop("page_data"))(data);
                    setAdvertiser({ ...advertiser, ads });
                });
        }
    }, [advertisersViewState.selected_advertiser]);

    if (isEmpty(advertisersViewState.selected_advertiser)) return <div></div>;

    let data = pipe(values, head)(advertisersViewState.selected_advertiser);

    let genders = pipe(
        prop("gender_age"),
        mod(all)(pick(["Male", "Female"])),
        loreduce((left, right) => {
            let Female = pipe(prop("Female"), defaultTo(0))(left) + pipe(prop("Female"), defaultTo(0))(right);
            let Male = pipe(prop("Male"), defaultTo(0))(left) + pipe(prop("Male"), defaultTo(0))(right);
            return { Male, Female };
        })
    )(data);

    let gender_data = pipe(lomap((value, key) => ({ name: key, value })))(genders);

    let regions_data = pipe(
        prop("regions"),
        lomap((value, region) => ({ value, region })),
        reverse
    )(data);

    const PIE_COLORS = ["#0088FE", "#00C49F"];

    const renderLabel = (entry) => {
        return entry.name;
    };

    const onCloseModal = () => {
        setApp({ ...app, modal_is_open: false, modal_id: "" });
    };

    return (
        <div className="page_insights_container">
            <div className="modal_content">
                <div className="interest_header">
                    <div className="left_adjusted">
                        <div className="interest_name">{data.interest_name}</div>
                    </div>
                    <div className="right_adjusted">
                        <div className="size_container">
                            <div className="icon">
                                <UserOutlined />
                            </div>
                            <div className="text">{pipe(Number, (value) => value.toLocaleString())(data.audience_size)}</div>
                        </div>
                        <div className="add_audience_container">
                            <Button type="primary">Save advertiser</Button>
                        </div>
                    </div>
                </div>

                <div className="full_container">
                    <div className="facebook_ads_container">
                        {lomap(
                            (ad, idx) => (
                                <FacebookAdMock key={idx} ad={ad} />
                            ),
                            advertiser.ads
                        )}
                    </div>
                </div>
                {/* <div className="full_container" style={{ minHeight: 600 }}>
                    <div className="full_outer_container"></div>
                </div> */}
            </div>
            <div className="audience_close_modal_container" onClick={onCloseModal}>
                <CloseCircleOutlined />
            </div>
        </div>
    );
};

export default AdvertiserInsights;
