import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { pipe } from "ramda";
import { lomap } from "../../utilities/helpers";
import { useAtom, atom } from "jotai";
import { user_atom } from "../state/auth";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { size } from "lodash";

const video_ids = [
    {
        id: "t36ywipel7",
        name: "Watch This Or Lose Money",
        available_after: 0,
    },
    {
        id: "lgm1cncxoc",
        name: "Automated Scaling",
        available_after: 18,
    },
];

const selected_video_atom = atom("");
const set_video_id_atom = atom(null, (get, set, update) => {
    set(selected_video_atom, update);
});

const PlaylistItem = ({ id, name, available_after = 0 }) => {
    const [user] = useAtom(user_atom);
    const [videoId] = useAtom(selected_video_atom);
    const [, setVideoId] = useAtom(set_video_id_atom);
    const [availableDate, setAvailableDate] = useState();
    const [isAvailable, setIsAvailable] = useState(false);

    const onSelectVideo = () => setVideoId(id);

    useEffect(() => {
        let available_date = moment(moment(user.roas_created_at_timestamp, "x").add(available_after, "hours")).format("MM-DD-YYYY");
        let today = moment().format("MM-DD-YYYY");
        if (available_date == today || user.is_admin) {
            setIsAvailable(true);
        }
        setAvailableDate(available_date);
    }, []);

    return (
        <li class="group py-3 sm:py-4 rounded">
            <div class="flex space-x-3">
                <div class="flex-1 min-w-0">
                    <p class="text-sm font-medium text-indigo-600 mb-[8px]">{name}</p>
                    {isAvailable && (
                        <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">available now</p>
                    )}
                    {!isAvailable && (
                        <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">available {availableDate}</p>
                    )}
                </div>

                {videoId == id && (
                    <div class="h-[30px] inline-flex items-center px-4 py-0.5 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white cursor-default">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-8 w-8 stroke-gray-300 drop-shadow-sm"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="1"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                            />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                )}

                {isAvailable && videoId !== id && (
                    <div
                        class="h-[30px] inline-flex items-center shadow-sm px-4 py-0.5 border border-solid border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
                        onClick={onSelectVideo}
                    >
                        View
                    </div>
                )}

                {!isAvailable && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 mt-[5px] fill-white stroke-red-500"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                        />
                    </svg>
                )}
            </div>
        </li>
    );
};

const VideoPlayer = ({ video_id = "" }) => {
    const [videoId, setVideoId] = useAtom(selected_video_atom);

    useEffect(() => {
        if (size(video_id) > 0) {
            setVideoId(video_id);
        } else {
            setVideoId("t36ywipel7");
        }
    }, []);

    return (
        <div className="video_palyer_view flex flex-col justify-center items-center max-w-7xl w-full overflow-y-scroll overflow-x-hidden justify-self-center h-full">
            {/* <div className="absolute -inset-x-0 -top-48 -bottom-14 overflow-hidden bg-pink-50 -z-[1] ">
                <div className="absolute top-0 left-0 -translate-y-[10%] -translate-x-[55%] -scale-x-1 sm:left-1/2 sm:-translate-y-[6%] sm:-translate-x-[100%]">
                    <img src={backgroundImage} alt="" layout="fixed" width={918} height={1495} priority unoptimized />
                </div>
                <div className="absolute inset-x-0 top-0 h-40 bg-gradient-to-b from-white" />
                <div className="absolute inset-x-0 bottom-0 h-40 bg-gradient-to-t from-white" />
            </div> */}
            <div className="player_container flex flex-col relative h-full w-full px-[20px] xl:justify-center">
                <div class="top_cta_with_title mx-auto py-[12px] lg:flex lg:items-center lg:justify-between w-full mb-[20px]">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                        <span class="block">Ready to dive in?</span>
                        <span class="block text-indigo-600">Go to your Ads Manager dashboard.</span>
                    </h2>
                    <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div class="inline-flex rounded-md shadow">
                            <Link
                                class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 hover:text-white cursor-pointer"
                                to={"/"}
                            >
                                Ads Manager
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col relative xl:flex-row gap-5">
                    <div className="video_container w-full bg-transparent flex flex-col">
                        <div className="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                            <div
                                className="wistia_responsive_wrapper overflow-hidden rounded-lg"
                                style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}
                            >
                                <ReactPlayer
                                    url={`https://frankkern.wistia.com/medias/${videoId}`}
                                    className="rounded-lg border-[2px] border-solid border-black overflow-hidden"
                                    playing={true}
                                    controls={true}
                                    width={"100%"}
                                    height={"100%"}
                                    playsinline={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div class="playlist_container p-[15px] w-full xl:max-w-[300px] mb-[15px] xl:mb-0 rounded-md border border-solid border-black divide-solid divide-y divide-gray-200 shadow">
                        <div class="flex justify-between items-center mb-4">
                            <h5 class="text-lg leading-6 font-medium text-gray-900">How to use ROAS</h5>
                        </div>
                        <div class="flow-root">
                            <ul role="list" class="divide-y divide-solid divide-gray-200 ">
                                {pipe(lomap((video) => <PlaylistItem key={video.id} {...video} />))(video_ids)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;
